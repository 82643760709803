import React, { useEffect } from "react";
import { SAButton, SAModal, SAText } from "@saux/design-system-react";
import {
  ButtonWrapper,
  ModalBody,
  SAModalBody,
  StyledModalText
} from "../AgencyContactDetails/AgencyContactDetailsStyles/AgencyContactDetailsStyles";

interface ConfirmEditModalProps {
  confirmModal: Function;
}

const ConfirmEditModal = ({ confirmModal }: ConfirmEditModalProps) => {
  const renderModal = () => (
    <ModalBody>
      <SAModalBody variant="simple" title={`You have unsaved changes`}>
        <StyledModalText>
          <SAText
            type="standard"
            text={`Continue editing and save your changes or they will be discarded.`}/>
        </StyledModalText>
        <ButtonWrapper>
          <SAButton
            label="CONTINUE EDITING"
            onClick={(e) => confirmModal(e, 'close')}
            color="text"
            variant="link-medium"
          />
          <SAButton
            label="CANCEL EDITS"
            variant="primary-medium"
            onClick={(e) => confirmModal(e, 'discard')}
          />
        </ButtonWrapper>
      </SAModalBody>
    </ModalBody>
  )

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modalconfirmedit') as HTMLElement;
      if (modal) {
        const element = modal.querySelector('button.sa-button') as HTMLElement;
        if (element) {
          element.focus();
        }
      } 
    }

    function focusModalConfirmEdit(e: any) {
      const modal = document.querySelector('#modalconfirmedit') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          const element = modal.querySelector('button.sa-button') as HTMLElement;
          if (element) {  
            element.focus();
          }
        }
      }
    }

    focusDefault();
    document.addEventListener('focusin', focusModalConfirmEdit);
    document.addEventListener('focusout', focusModalConfirmEdit);

    return () => {
      document.removeEventListener('focusin', focusModalConfirmEdit);
      document.removeEventListener('focusout', focusModalConfirmEdit);
    }
  }, []);

  return (
    <SAModal id="modalconfirmedit" onClickHandler={(event: React.MouseEvent) => confirmModal(event, 'cancel')}>
      <div>{renderModal()}</div>
    </SAModal>
  );
};

export default ConfirmEditModal;
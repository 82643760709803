import React, { useState, useEffect } from "react";
import {
  SACard,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
  SARadioGroup,
  SARadio,
  SASeparator,
  SASearch,
  SAUXTheme
} from '@saux/design-system-react';
import { ContactTypeKeyPair, LocationKeyPair } from '../../../interfaces/interfaces';
import styled from 'styled-components';
import ModalLearnMore from "../ModalLearnMore/ModalLearnMore";

const mobileWidth = 600;

type FilterProps = {
  contactTypes: ContactTypeKeyPair[];
  hideContactTypes: boolean;
  locations: LocationKeyPair[];
  onClickCancel: Function;
  onClickApply: Function;
  selectedContactType: string;
  selectedLocation?: string;
};

type StyleProps = {
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const Filter = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 600px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 45px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    }
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      display: flex;
    }
  }
`;

const ModalBody = styled.div`
  legend {
    display: none;
  }
`;

const ModalFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
  }};
`;

const RadioGroup = styled(SARadioGroup)`
  ${({ theme }: StyleProps) => {
    return `
      margin-top: ${theme?.size.spacer.medium};
      border: rgb(217,223,235) solid 1px;
      padding: ${theme?.size.spacer.medium};
      max-height: 270px;
      overflow-y: auto;

      label {
        color: ${theme?.colors.text};
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 92px;
        border: none;
      }
    
      &::-webkit-scrollbar-track {
          background: #F0EFEF;
          border-radius: 7px;
      }
    
      &::-webkit-scrollbar-thumb {
          background: #8A8A8A;
          border-radius: 7px;
          box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      }
    `;
  }};
`;

const Title = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: ${theme?.size.spacer.large} 0px ${theme?.size.spacer.medium} 0px;

      button:first-child {
        display: none;
      }
    
      @media only screen and (max-width: ${mobileWidth}px) {
        button:first-child {
          display: flex;
        }
      }
    `;
  }};
`;

const SearchContainer = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      .saInputWrapper {
        margin-top: 0;
        font-size: ${theme?.font.primary.size.medium};
      }
      
      ul {
        align-items: center;
        span {
          button {
            margin-bottom: 0;
          }
        }
        button {
          margin-bottom: 5px;
        }
      }
    `;
  }};
`;

export default function ModalFilter({
  contactTypes,
  hideContactTypes,
  locations,
  onClickCancel,
  onClickApply,
  selectedContactType,
  selectedLocation
}: FilterProps) {
  const [contactType, setContactType] = useState<string>(selectedContactType as string);
  const [location, setLocation] = useState<string>(selectedLocation as string);
  const [filteredLocations, setFilteredLocations] = useState<LocationKeyPair[]>(locations);
  const [displayLearnMoreModal, setDisplayLearnMoreModal] = useState<boolean>(false);

  function handleClickChangeLocationModal(event: React.MouseEvent) {
    event.stopPropagation();

    // get the card element inside the modal
    const cardElement = document.querySelector('#modal > div > article > section');

    // close modal when clicking outside of the card element
    if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
      onClickCancel(event);
    }
  }

  function handleClickCancel(event: React.MouseEvent) {
    event.stopPropagation();
    onClickCancel(event);
  }

  function handleClickApply(event: React.MouseEvent) {
    event.stopPropagation();
    onClickApply(event, contactType, location);
  }

  function handleRadioChangeLocation(event: any) {
    setLocation(event.target.value);
  }

  function handleRadioChangeContactType(event: any) {
    setContactType(event.target.value);
  }

  function handleSearch(search: Array<string> | string) {
    // The callback function you provide in the onSearch prop will receive the current array
    // of search terms when a new search term is added or removed from the array.
    if (typeof search !== 'string') {
      // filtered results
      const filteredResults = locations.filter((location) => {
        let containsAllSearchTerms: boolean = true;
        search.forEach((searchTerm) => {
          let containsSearchTerm: boolean = false;
          if (location.value.toLowerCase().includes(searchTerm.toLowerCase())) {
            containsSearchTerm = true;
          };
          if (!containsSearchTerm) {
            containsAllSearchTerms = false;
          }
        });

        if (containsAllSearchTerms) {
          return true;
        } else {
          return false;
        }
      });        

      setFilteredLocations(filteredResults);
    }
  }

  function handleClickLearnMore(event: React.MouseEvent) {
    setDisplayLearnMoreModal(!displayLearnMoreModal);
  }

  function handleClickLearnMoreModalClose(event: React.MouseEvent) {
    setDisplayLearnMoreModal(!displayLearnMoreModal);
  }

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modalfilter') as HTMLElement;
      if (modal) {
        const radio = modal.querySelector('label.checked') as HTMLElement;
        if (radio) {
          (radio?.firstChild as HTMLElement).focus();
        } 
      } 
    }
  
    function focusModalFilter(e: any) {
      const modal = document.querySelector('#modalfilter') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          let element = null;
          if (document.documentElement.clientWidth <= mobileWidth) {
            element = modal.querySelector('button') as HTMLElement;
          } else {
            element = modal.querySelector('button.sa-button') as HTMLElement;
          }
          if (element) {  
            element.focus();
          }
        }
      }
    }
  
    focusDefault();
    document.addEventListener('focusin', focusModalFilter);
    document.addEventListener('focusout', focusModalFilter);
  
    return () => {
      document.removeEventListener('focusin', focusModalFilter);
      document.removeEventListener('focusout', focusModalFilter);
    }
  }, []);

  return (
    <>
      {!displayLearnMoreModal &&
        <Modal id="modalfilter" onClickHandler={handleClickChangeLocationModal}>
          <Filter variant="minimal">
            <ModalHeader>
              <ModalTitle>
                <SAText type="heading-2" text="Filter" />
                <SAIcon className="closemodal" icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" onClick={handleClickCancel} />
              </ModalTitle>
            </ModalHeader>
            <SASeparator horizontal />
            <ModalBody>
              {!hideContactTypes &&
                <>
                  <Title>
                    <SAText type="heading-4" text="Contact Type" />
                    <SAButton
                      label="Learn More"
                      onClick={handleClickLearnMore}
                      textTransform="uppercase"
                      variant="primary-link-medium"
                    />
                  </Title>
                  <RadioGroup id="contacttypes" value={contactType} variant="standard" direction="vertical" onChange={handleRadioChangeContactType}>
                    <SARadio key="all" value="all" label="All Contact Types" variant="standard" />
                    <SASeparator horizontal />
                    {contactTypes.map((ct) => {
                      return (
                        <SARadio key={ct.key} value={ct.key} label={ct.value} variant="standard" />
                      )
                    })}
                  </RadioGroup>
                </>
              }
              <Title>
                <SAText type="heading-4" text="Locations" />
              </Title>
              <SearchContainer>
                <SASearch
                  fullWidth
                  id="changelocationsearchbar"
                  placeholder="Search by agency name, city, state"
                  onSearch={handleSearch}
                  variant="search-terms"
                />
              </SearchContainer>
              <RadioGroup id="changelocation" value={location} variant="standard" direction="vertical" onChange={handleRadioChangeLocation}>
                <SARadio key="all" value="all" label="All Locations" variant="standard" />
                <SASeparator horizontal />
                {filteredLocations.map((loc) => {
                  return (
                    <SARadio key={loc.key} value={loc.key} label={loc.value} variant="standard" />
                  )
                })}
              </RadioGroup>
            </ModalBody>
            <ModalFooter>
              <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
              <SAButton fullWidthUnder={mobileWidth} label="Apply" onClick={handleClickApply} variant="primary-large" textTransform="uppercase" />
            </ModalFooter>
          </Filter>
        </Modal>
      }
      {displayLearnMoreModal &&
        <ModalLearnMore onClickClose={handleClickLearnMoreModalClose} />
      }
    </>
  );
}

ModalFilter.defaultProps = {
  selectedLocation: 'all',
}

import { Auth, Cache } from "aws-amplify";
import { useEffect } from "react";

export default function Logout() {
  useEffect(() => {
    async function signout() {
      Cache.clear();
      localStorage.setItem('loggingout', 'true');
      console.log('beginning sign out');
      await Auth.signOut({ global: true });
    }

    signout();
  }, []);

  return <></>;
}

import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import {
  SACard,
  SAColumns,
  SAButton,
  SASeparator,
  SAIcon,
  SAIcons,
  SAIconSize,
  SATable,
  SAPopover,
  SASearch,
  SAPagination,
  SASpinner,
  SASnackbar,
  SAUXTheme,
  SAUX360Theme,
  SATableColumn
} from '@saux/design-system-react';
import styled from 'styled-components';
import ModalContactTypes from '../modal/ModalContactTypes/ModalContactTypes';
import ModalChangeLocation from '../modal/ModalChangeLocation/ModalChangeLocation';
import ModalFilter from '../modal/ModalFilter/ModalFilter';
import ModalAddContact from '../modal/ModalAddContact/ModalAddContact';
import ModalTerminateContact from '../modal/ModalTerminateContact/ModalTerminateContact';
import ContactData from '../Model/ContactModel';
import {
  Location,
  Locations,
  Persons,
  ContactPerson,
  ContactLocation,
  Contacts,
  TrackProgressLocation,
  TrackProgress,
  ContactTypeKeyPair,
  LocationKeyPair,
  AgencyCode,
  PendingData,
  PendingPerson,
  AgencyCodes,
  Constants,
  Terminable,
} from '../../interfaces/interfaces';
import ModalSpinner from '../modal/ModalSpinner/ModalSpinner';
import ModalContactAgencyOperations from '../modal/ModalContactAgencyOperations/ModalContactAgencyOperations';
import ReactDOM from 'react-dom';
import { encrypt, decrypt } from '../../utils/crypto';
import { constants } from '../../utils/constants';
import simpleGTMDataLayer from "../../utils/GTMHelpers/simpleGTMDataLayer";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { AdminPersonDetailsIsTerminableQuery, AgencyLandingPageQuery, GetagencyadminAgencyLocationsQuery, LandingPageAgencyCodeQuery, PostPersonPendingStatusMutation, UpdatePendingPersonsTerminateMutation } from '../../API';
import handleGraphQLErrors from '../../utils/handleGraphQLErrors';
import { checkAuthStatus } from '../../utils/utils';
import env from '../../env_config';
import ModalEmailAlreadyUsedForLP from '../modal/ModalEmailAlreadyUsedForLP/ModalEmailAlreadyUsedForLP';
import ModalAlert from '../modal/ModalTerminateContactsAlert/ModalTerminateContactsAlert';

const mobileWidth = 600;

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
  isEmployee?: boolean;
};

type LicenseApplicationRequested = {
  email: string;
  adminName: string;
  requestedOn: string;
};


enum TableHeader {
  NAME = 0,
  CONTACT_TYPE = 1,
  LOGIN_ID = 2,
  EMAIL_ADDRESS = 3
}

const AgencyContactsCard = styled(SACard).attrs(({ constants, isEmployee }: StyleProps) => ({
  constants,
  isEmployee
}))`
  ${({ theme, constants, isEmployee }: StyleProps) => {
    const con: Constants = constants as Constants;
    let marginTop: number = isEmployee
    ? con.headerHeight + con.dockedMenuHeight + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.verticalPageOffset
    : con.headerHeight + con.dockedMenuHeight + con.verticalPageOffset;
    let marginTopMobile: number = isEmployee
    ? con.headerHeightMobile + con.dockedMenuHeight + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.verticalPageOffset
    : con.headerHeightMobile + con.dockedMenuHeight + con.verticalPageOffset;

    return `
      margin-top: ${marginTop}px;
      margin-bottom: 60px;
      margin-left: ${theme?.size.spacer.medium};
      margin-right: ${theme?.size.spacer.medium};

      @media only screen and (max-width: ${mobileWidth}px) {
        margin-top: ${marginTopMobile}px;
        margin-left: ${theme?.size.spacer.medium};
        margin-right: ${theme?.size.spacer.medium};
      }
    `;
  }};
`;

const Columns = styled(SAColumns)`
  flex-grow: 1;
  flex-wrap: nowrap;
  justify-content: flex-end;

  div {
    flex-grow: 0;
  }
`;

const HeaderButton = styled.button`
  background-color: transparent;
  border: none;
  font-weight: bold;
  list-style: none;
  font-size: small;
  padding: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: hsl(0,0%,23%);
`;

const SortIcon = styled.div`
  display: flex;
  flex-direction: column;
  svg {
    width: 12px;
    height: 12px;
  }
`;

const Button = styled(SAButton)`
  ${({ theme }: StyleProps) => {
    return `  
      font-weight: ${theme?.font.primary.weight.normal};
      white-space: nowrap;
    `;
  }};
`;

const Container = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: ${theme?.size.spacer.medium} ${theme?.size.spacer.medium} 0 ${theme?.size.spacer.medium};

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-direction: column;
      }
    `;
  }};
`;

const SearchContainer: any = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 0;
      max-width: 70%;
      min-width: 350px;
      padding-right: ${theme?.size.spacer.medium};
    
      .saInputWrapper {
        margin-top: 0;
        font-size: ${theme?.font.primary.size.medium};
      }

      ul {
        align-items: center;
        span {
          button {
            margin-bottom: 0;
          }
        }
        button {
          margin-bottom: 5px;
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        max-width: 100%;
        min-width: 0;
        padding-right: 0;
      }
    `;
  }};
`;

const AddContactContainer: any = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      justify-content: flex-end;
      gap: 16px;
    
      @media only screen and (max-width: ${mobileWidth}px) {
        padding-top: ${theme?.size.spacer.medium};
        justify-content: center;
    `;
  }};
`;

const PaginationContainer = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: ${theme?.size.spacer.medium};

      .pagedetails {
        display: flex;
        font-size: ${theme?.font.primary.size.smallTEMP};
        align-items: center;
      }

      .pagination {
        align-items: center;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        .pagedetails {
          display: none;
        }

        .pagination {
          flex-grow: 1;
          justify-content: center;
        }
      }

      button {
        &:focus {
          outline: auto;
        }
      }
    `;
  }};
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`;

const Table = styled(SATable)`
  ${({ theme }: StyleProps) => {
    return `
      font-size: ${theme?.font.primary.size.smallTEMP};

      section {
        ul {
          li:last-child {
            &.pending {
              list-style: none;
              margin-left: 0;
              margin-right: 5px
            }
          }
        }
      }

      li {
        span {
          &:hover {
            cursor: pointer;
          }
        }

        button {
          padding: 0;
        }
      }

      svg.pending {
        display: none;
      }

      section.pending {
        pointer-events: none;

        li {
          list-style: none;
        }

        section:first-child {
          ul:last-child {

            &.blue {
              li::before {
                color: ${theme?.colors.blue};
              }

              li {
                color: ${theme?.colors.blue};
              }
            }

            &.red {
              li::before {
                color: ${theme?.colors.red600};
              }

              li {
                color: ${theme?.colors.red600};
              }
            }

            li::before {
              font-size: 18px;
              line-height: 24px;
              content: "●";
              padding-right: 5px;
            }

            li {
              font-size: ${theme?.font.primary.size.small};
            }
          }
        }
      }
    `;
  }};
`;

const NoContactsMessage = styled.div`
  font-style: italic;
  margin: 45px 30px 45px 30px;
`;

export default function AgencyContacts(props: any) {
  const history = useHistory();
  const [dataFetchProgress, setDataFetchProgress] = useState<TrackProgress>({ completed: false, locations: [] });
  const [agencyContacts, setAgencyContacts] = useState<Contacts>({ locations: [] });
  const [updatePendingStatus, setUpdatePendingStatus] = useState<boolean>(false);
  const [pendingData, setPendingData] = useState<PendingData>({ pending_persons: [] });
  const [priorPendingData, setPriorPendingData] = useState<PendingData>({ pending_persons: [] });
  const [pendingPersons, setPendingPersons] = useState<string[]>(getPendingPersons());
  const [retrievePendingData, setRetrievePendingData] = useState<boolean>(true);
  const [continueFetch, setContinueFetch] = useState<boolean>(false);
  const [fetchAllLocations, setFetchAllLocations] = useState<boolean>(false);
  const isAdmin: boolean = decrypt(localStorage.getItem('isAdmin')) === 'true' ? true : false;
  const [gtm, setGTM] = useState(false);

  // No Contacts Message
  const [showNoContactsMessage, setShowNoContactsMessage] = useState<boolean>(false);

  // Snackbar
  const contactAdded: boolean = !!decrypt(localStorage.getItem('contactAdded'));
  const contactTerminated: boolean = !!decrypt(localStorage.getItem('contactTerminated'));
  const [showSnackbar, setShowSnackbar] = useState<boolean>(contactAdded || contactTerminated);
  const [snackbarTitle, setSnackbarTitle] = useState<string>(setDefaultSnackbarTitle());
  const [snackbarSubtitle, setSnackbarSubtitle] = useState<string>(setDefaultSnackbarSubtitle());

  // Loading Spinner
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(true);
  const [showModalSpinner, setShowModalSpinner] = useState<boolean>(false);

  // Session
  const personId = decrypt(localStorage.getItem('personId'));
  const locationId = decrypt(localStorage.getItem('locationId'));

  // Button Icons
  const iconPin = <SAIcon icon={SAIcons.pin} size="20px" colorVariant="white" />;
  const iconChevronDownBold = <SAIcon icon={SAIcons.chevronDownBold} size={SAIconSize.small} colorVariant="white" />;
  const iconAddContact = <SAIcon icon={SAIcons.addContact} size="20px" colorVariant="white" />;
  const iconPerson = <SAIcon icon={SAIcons.person} size="20px" colorVariant="primary" />;
  const iconFilter = <SAIcon icon={SAIcons.filter} size="20px" colorVariant="white" />;

  // Buttons - Dynamic Labels
  const [labelContactTypes, setLabelContactTypes] = useState<string>('All Contact Types');
  const [labelLocations, setLabelLocations] = useState<string>('');

  // Card Header - Buttons
  const [mobile, setMobile] = useState<boolean>(true);
  const headerButtons: JSX.Element = mobile ? (
    <Columns spacing="small">
      <Button className="filterbutton" label="Filter" onClick={handleClickFilter} variant="link-small-inverse" startIcon={iconFilter} />
    </Columns>
  ) : (
    (showNoContactsMessage ?
      <Columns spacing="small">
        <Button label={labelLocations} onClick={handleClickLocations} variant="link-small-inverse" startIcon={iconPin} endIcon={iconChevronDownBold} textTransform="none" />
      </Columns>
      :
      <Columns spacing="small">
        <Button label={labelContactTypes} onClick={handleClickContactTypes} variant="link-small-inverse" endIcon={iconChevronDownBold} textTransform="none" />
        <SASeparator vertical />
        <Button label={labelLocations} onClick={handleClickLocations} variant="link-small-inverse" startIcon={iconPin} endIcon={iconChevronDownBold} textTransform="none" />
      </Columns>
    )
  );

  // Search
  const [searchTerms, setSearchTerms] = useState<string[]>(getDefaultSearchTerms());
  const [sorting, setSorting] = useState<{ columnIndex: number, order: "asc" | "desc" }>({ columnIndex: 0, order: "asc"});
  
  function handleSorting(columnIndex: TableHeader) {
    if (sorting.columnIndex === columnIndex) {
      setSorting({ columnIndex: columnIndex, order: sorting.order === "asc" ? "desc" : "asc" });
    } else {
      setSorting({ columnIndex: columnIndex, order: "asc" });
    }
  }
  
  // Table - Column Configuration
  const sortTableHeaderColumn = (header: TableHeader, headerName: string): React.ReactNode => {
    return (
      <HeaderButton onClick={() => handleSorting(header)}>
        <span>{headerName} </span>
        {sorting.columnIndex === header && (
          <SortIcon>
            {sorting.order === "asc" ? (
              <SAIcon icon={SAIcons.chevronUpBold} size={SAIconSize.small} colorVariant="dark" />
            ) : (
              <SAIcon icon={SAIcons.chevronDownBold} size={SAIconSize.small} colorVariant="dark" />
            )}
          </SortIcon>
        )}
      </HeaderButton>
    );
  };

  const columns: SATableColumn[] = [
    {
      name: sortTableHeaderColumn(TableHeader.NAME, "Name"),
    },
    {
      name: "Contact Type",
      process: processContactTypes,
    },
    {
      name: sortTableHeaderColumn(TableHeader.LOGIN_ID, "Login ID"),
    },
    {
      name: sortTableHeaderColumn(TableHeader.EMAIL_ADDRESS, "Email Address"),
      process: processEmailAddress,
    },
  ];

  // Table - Menu Popover
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const [rowClicked, setRowClicked] = useState<number>(-1);
  const popoverData: string[] = ['View/Edit Profile', 'Add/Remove Agency Code', 'Terminate Contact'];
  const [rawTableData, setRawTableData] = useState<string[][]>([]);
  const [personIdsForSelectedLoc, setPersonIdsForSelectedLoc] = useState<string[]>([]);
  const [tableData, setTableData] = useState<string[][]>([]);

  // Pagination
  const itemsPerPage: number = 10;
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(getDefaultPageIndex());

  // Modal - Contact Types
  const [displayContactTypesModal, setDisplayContactTypesModal] = useState<boolean>(false);
  const [contactTypeList, setContactTypeList] = useState<ContactTypeKeyPair[]>([]);
  const [selectedContactType, setSelectedContactType] = useState<string>('all');

  // Modal - Change Locations
  const [displayChangeLocationModal, setDisplayChangeLocationModal] = useState<boolean>(false);
  const [locationList, setLocationList] = useState<LocationKeyPair[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>('');

  // Modal - Filter
  const [displayFilterModal, setDisplayFilterModal] = useState<boolean>(false);

  // Modal - Add Contact
  const [displayAddContactModal, setDisplayAddContactModal] = useState<boolean>(false);
  const [addContact, setAddContact] = useState<boolean>(false);

  const [displayEmailAlreadyUsedModal, setDisplayEmailAlreadyUsedModal] = useState<LicenseApplicationRequested | null>(null);

  // Modal - Terminate Contact
  const [displayTerminateContactModal, setDisplayTerminateContactModal] = useState<boolean>(false);
  const [terminableResponse, setTerminableResponse] = useState<Terminable | null>(null);

  // Modal - Agency Operations
  const [displayAgencyOperationsModal, setDisplayAgencyOperationsModal] = useState<boolean>(false);
  const [requiredContactTypes, setRequiredContactTypes] = useState<boolean>(false);
  const [agencyOpsModalText, setAgencyOpsModalText] = useState<string>('');

  // Is Employee currently impersonating a contact
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;

  // Polling
  const [retrievePendingStatusData, setRetrievePendingStatusData] = useState<boolean>(false);
  const [intervalStatus, setIntervalStatus] = useState<'stopped' | 'running'>('stopped');
  const [intervalCommand, setIntervalCommand] = useState<'stop' | 'start' | ''>('');
  const intervalRef = useRef<any>(null);
  const delay: number = env['pingInterval']; //milliseconds

  // Update Pending Status Icon
  const [updateIcon, setUpdateIcon] = useState<number>(0);

  // show modal when admin have no loaction
  const [showAlertModal, setShowAlertModal] = useState(false);

  function getPendingPersons() {
    const pendingPersons: string[] = [];
    pendingData.pending_persons.forEach((person: PendingPerson) => {
      pendingPersons.push(person.person_id);
    });
    return pendingPersons;
  }

  useEffect(() => {
    if (!gtm) {
      simpleGTMDataLayer({
        event: 'pageview',
        page: {
          path: '/agency-contacts',
          title: 'ADJSS: Dashboard'
        }
      });
      setGTM(true);
    }
  }, []);


  function getDefaultPageIndex() {
    return decrypt(localStorage.getItem('pageIndex')) ? Number(decrypt(localStorage.getItem('pageIndex'))) : 0;
  }

  function setDefaultSnackbarTitle() {
    let title = '';
    if (contactAdded) {
      title = 'Contact Added';
    }
    if (contactTerminated) {
      title = 'Contact Terminated';
    }
    return title;
  }

  function setDefaultSnackbarSubtitle() {
    let subtitle = '';
    if (contactAdded) {
      subtitle = 'Your new contact will be pending until we update our systems.';
    }
    if (contactTerminated) {
      subtitle = 'Termination of the contact will be pending until we update our systems.';
    }
    return subtitle;
  }

  function onSnackbarClose() {
    localStorage.removeItem('contactAdded');
    localStorage.removeItem('contactTerminated');
    setShowSnackbar(false);
  }

  function getDefaultSearchTerms() {
    // const filterSearchTerms: string[] = JSON.parse(decrypt(localStorage.getItem('FilterSearchTerms')));
    // if (filterSearchTerms) {
    //   return filterSearchTerms;
    // } else {
    //   return [];
    // }
    return [];
  }

  function handleClickContactTypes(e: React.MouseEvent<Element>) {
    e.preventDefault();
    setDisplayContactTypesModal(!displayContactTypesModal);
  }

  function handleClickLocations(e: React.MouseEvent<Element>) {
    e.preventDefault();
    setDisplayChangeLocationModal(!displayChangeLocationModal);
  }

  function handleClickFilter(e: React.MouseEvent<Element>) {
    e.preventDefault();
    setDisplayFilterModal(!displayFilterModal);
  }

  function handleSearch(search: Array<string> | string) {
    // The callback function you provide in the onSearch prop will receive the current array
    // of search terms when a new search term is added or removed from the array.
    if (typeof search !== 'string') {
      localStorage.removeItem('pageIndex');
      setCurrentPageIndex(0);
      setSearchTerms(search);
      // localStorage.setItem('FilterSearchTerms', encrypt(JSON.stringify(search)));
    }
  }

  function handleClickAddContact(event: React.MouseEvent<Element>) {
    event.preventDefault();

    simpleGTMDataLayer({
      event: 'addContactEvent',
      event_action: 'Button Click',
      event_category: window.location.pathname,
      event_label: 'Add Contact'
    });

    setAddContact(true);
  }

  function handleAgencyOpsModalClose(event: React.MouseEvent<Element, MouseEvent>) {
    setDisplayAgencyOperationsModal(!displayAgencyOperationsModal);
  }

  function setAddContactAgencyCodes() {
    const agencyCodesArray: AgencyCode[] = [];
    agencyContacts.locations.forEach((loc: ContactLocation) => {
      loc.agencyCodes.forEach((ac: AgencyCode) => {
        const agencyCode: AgencyCode = {
          agency_id: ac.agency_id,
          agency_code: ac.agency_code,
          agency_name: ac.agency_name,
          dba: ac.dba,
          agency_city: loc.agency_city,
          agency_state: loc.agency_state,
          agency_zip: loc.agency_zip
        };
        agencyCodesArray.push(agencyCode);
      });
    });

    agencyCodesArray.sort((a, b) => {
      const agencyNameA = a.dba !== '' ? a.dba : a.agency_name;
      const agencyNameB = b.dba !== '' ? b.dba : b.agency_name;
      const sortStringA = `${agencyNameA} ${a.agency_city} ${a.agency_state} ${a.agency_zip.substr(0, 5)} ${a.agency_code}`;
      const sortStringB = `${agencyNameB} ${b.agency_city} ${b.agency_state} ${b.agency_zip.substr(0, 5)} ${b.agency_code}`;
      if (sortStringA.toLowerCase() === sortStringB.toLowerCase()) {
        return 0;
      } else {
        return (sortStringA.toLowerCase() < sortStringB.toLowerCase()) ? -1 : 1;
      }
    });

    localStorage.setItem('AddContactAgencyCodes', encrypt(JSON.stringify(agencyCodesArray)));
  }

  function handleActionMenuClick(event: React.MouseEvent<HTMLElement>, rowIndex: number) {
    event.preventDefault();
    event.stopPropagation();

    // saving the clicked row index for later
    setRowClicked(rowIndex);

    // assign SATable's event.currentTarget to referenceElement,
    // which is then passed on to SAPopover and used as an anchor element.
    if (referenceElement !== event.currentTarget) {
      setReferenceElement(event.currentTarget);
    } else {
      setReferenceElement(null);
    }
  }

  function handleRowClick(event: React.MouseEvent<HTMLElement>, rowIndex: number) {
    event.preventDefault();

    const personId: string = tableData[rowIndex][4];
    if (!pendingPersons.includes(personId)) {
      const row: string[] = tableData[rowIndex];
      localStorage.setItem('selectedLastName', encrypt(row[0].split(',')[0].trim()));
      localStorage.setItem('selectedFirstName', encrypt(row[0].split(',')[1].trim()));
      localStorage.setItem('selectedContactTypes', encrypt(processContactTypes(row[1])));
      localStorage.setItem('selectedContactTypesAll', encrypt(processContactTypes(row[1], false)));
      localStorage.setItem('selectedLoginId', encrypt(row[2]));
      localStorage.setItem('selectedEmail', encrypt(row[3]));
      localStorage.setItem('selectedPersonId', encrypt(row[4]));

      if (decrypt(localStorage.getItem('AddContactAgencyCodes')) === null) {
        setAddContactAgencyCodes();
      }

      localStorage.setItem('agencyContacts', encrypt(JSON.stringify(agencyContacts)));
      localStorage.setItem('dataFetchProgress', encrypt(JSON.stringify(dataFetchProgress)));

      clearInterval(intervalRef.current);
      console.log(`stop polling: ${intervalRef.current}`);

      // Same as View/Edit Profile sub menu redirection
      history.push({
        pathname: `/agency-contact-profile`,
      });
    }
  }

  async function handleClose(event: React.MouseEvent<HTMLElement> | MouseEvent, index: number | null) {
    // SAPopover calls our callback provided in the onClose prop.  It will return mouse event and
    // index of the menu option selected.  Be sure to set referenceElement to null to close the SAPopover.
    setReferenceElement(null);

    if (index !== null) {
      const row: string[] = tableData[rowClicked];
      localStorage.setItem('selectedLastName', encrypt(row[0].split(',')[0].trim()));
      localStorage.setItem('selectedFirstName', encrypt(row[0].split(',')[1].trim()));
      localStorage.setItem('selectedContactTypes', encrypt(processContactTypes(row[1])));
      localStorage.setItem('selectedContactTypesAll', encrypt(processContactTypes(row[1], false)));
      localStorage.setItem('selectedLoginId', encrypt(row[2]));
      localStorage.setItem('selectedEmail', encrypt(row[3]));
      localStorage.setItem('selectedPersonId', encrypt(row[4]));

      if (decrypt(localStorage.getItem('AddContactAgencyCodes')) === null) {
        setAddContactAgencyCodes();
      }

      localStorage.setItem('agencyContacts', encrypt(JSON.stringify(agencyContacts)));
      localStorage.setItem('dataFetchProgress', encrypt(JSON.stringify(dataFetchProgress)));

      switch (index) {
        case 0:  // View/Edit Profile
        case 1:  // Add/Remove Agency Code
          clearInterval(intervalRef.current);
          console.log(`stop polling: ${intervalRef.current}`);
          history.push({
            pathname: `/agency-contact-profile`
          });
          break;
        case 2:
          // Terminate Contact

          // if logged in user's agency codes match or exceed contact's assigned agency codes, and contact's contact types doesn't include principal then show terminate contact modal
        
          setShowModalSpinner(true);

          const isAuthenticated: boolean = await checkAuthStatus();
          if (!isAuthenticated) {
            return;
          }
          const promiseTerminable = API.graphql(
            graphqlOperation(
              queries.adminPersonDetailsIsTerminable,
              {
                admin_person_id: personId,
                person_id: row[4]
              }
            ),
            {
              Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
            }
          ) as Promise<{ data: AdminPersonDetailsIsTerminableQuery }>;

          promiseTerminable.then((res) => {
            if (res.data) {
              return res.data.adminPersonDetailsIsTerminable;
            } else {
              handleGraphQLErrors(res);
            }
          })
          .then((res) => {
            if (res?.statusCode === 200) {
              const response = res.body as Terminable;
              if (response.status === 'success') {

                simpleGTMDataLayer({
                  event: 'terminateContact',
                  event_action: 'Button Click',
                  event_category: window.location.pathname,
                  event_label: 'Dashboard: Terminate Contact'
                });

                // you have permission to terminate
                setDisplayTerminateContactModal(!displayTerminateContactModal);
              }
            } else if (res?.statusCode === 400) {
              const response = res.body as Terminable;
              if (response.status === 'failure') {
                switch (response.reason) {
                  case 'permissions':
                    // Do not have adequate permission to terminate
                    setAgencyOpsModalText('You do not have permission to terminate. Contact Agency Operations for assistance.');
                    setRequiredContactTypes(false);
                    setDisplayAgencyOperationsModal(!displayAgencyOperationsModal);
                    break;
                  case 'principal':
                    // Principal cannot be terminated
                    setAgencyOpsModalText('Principal cannot be terminated. Contact Agency Operations for assistance.');
                    setRequiredContactTypes(false);
                    setDisplayAgencyOperationsModal(!displayAgencyOperationsModal);
                    break;
                  case 'contact types':
                    // you have permission to terminate, but are attempting to remove the last required contact type of an agency
                    setAgencyOpsModalText('');
                    setRequiredContactTypes(true);
                    setTerminableResponse(response);
                    setDisplayAgencyOperationsModal(!displayAgencyOperationsModal);
                    break;
                  case 'self terminate':
                    // admin cannot terminate self
                    setAgencyOpsModalText('You cannot self-terminate. For assistance, contact another Agency Admin or reach out to Agency Operations.');
                    setRequiredContactTypes(false);
                    setDisplayAgencyOperationsModal(!displayAgencyOperationsModal);
                    break;
                }
              }
            } else {
              throw new Error('Something went wrong - 24');
            }
          })
          .catch((err) => {
            if (err?.message) {
              console.error(err.message, err);
            } else {
              handleGraphQLErrors(err);
            }
            window.location.href = "error";
          })
          .finally(() => {
            setShowModalSpinner(false);
          });

          break;
      }
    }
  }

  function handlePaginationChange(newCurrentPageIndex: number) {
    localStorage.setItem('pageIndex', encrypt(newCurrentPageIndex.toString()));
    setCurrentPageIndex(newCurrentPageIndex);
  }

  function getPageRangeLabel() {
    let rangeFrom: number = 0;
    let rangeTo: number = 0;

    if (totalItems > 0) {
      rangeFrom = (currentPageIndex * itemsPerPage) + 1;
      if ((currentPageIndex + 1) > Math.floor(totalItems / itemsPerPage)) {
        rangeTo = totalItems;
      } else {
        rangeTo = ((currentPageIndex + 1) * itemsPerPage);
      }
      return `${rangeFrom} - ${rangeTo} of ${totalItems}`;
    } else {
      return '';
    }
  }

  function getStartIndex() {
    return currentPageIndex * itemsPerPage;
  }

  function getEndIndex() {
    if ((currentPageIndex + 1) > Math.floor(totalItems / itemsPerPage)) {
      return totalItems;
    } else {
      return (currentPageIndex + 1) * itemsPerPage;
    }
  }

  function handleClickContactTypesModalCancel(event: React.MouseEvent) {
    setDisplayContactTypesModal(!displayContactTypesModal);
  }

  function handleClickContactTypesModalSubmit(event: React.MouseEvent, contactType: string) {
    setDisplayContactTypesModal(!displayContactTypesModal);
    setSelectedContactType(contactType);
    // localStorage.setItem('FilterContactType', encrypt(contactType));
    localStorage.removeItem('pageIndex');
    setCurrentPageIndex(0);
  }

  function handleClickChangeLocationModalCancel(event: React.MouseEvent) {
    setDisplayChangeLocationModal(!displayChangeLocationModal);
  }

  function handleClickChangeLocationModalSubmit(event: React.MouseEvent, location: string) {
    setDisplayChangeLocationModal(!displayChangeLocationModal);
    changeLocation(location);
  }

  function changeLocation(location: string) {
    if (!continueFetch) {
      if (selectedLocation !== location) {
        // a new location has been selected
        setSelectedLocation(location);
        localStorage.removeItem('pageIndex');
        setCurrentPageIndex(0);
      }

      // Stop prior polling
      if (intervalStatus === 'running' && intervalCommand === '') {
        setIntervalCommand('stop');
        setRawTableData([]);
        setRetrievePendingData(true);
      }
  
      localStorage.setItem('FilterLocation', encrypt(location));
  
      const tracker: TrackProgress = dataFetchProgress;
      const locationNotCompleted = tracker.locations.filter((loc) => {
        return (loc.location_id === location && (!loc.persons.completed || !loc.agencyCodes.completed)) ? true : false;
      });
  
      if (locationNotCompleted.length > 0) {
        setShowLoadingSpinner(true);
        tracker.completed = false;
        const newTracker: TrackProgress = JSON.parse(JSON.stringify(tracker));
        setDataFetchProgress(newTracker);
        const contFetch: boolean = true;
        setContinueFetch(contFetch);
      } else {
        setShowLoadingSpinner(true);
        setRetrievePendingData(true);
      }
    }
  }

  function handleClickFilterModalCancel(event: React.MouseEvent) {
    setDisplayFilterModal(!displayFilterModal);
  }

  function handleClickFilterModalApply(event: React.MouseEvent, contactType: string, location: string) {
    setDisplayFilterModal(!displayFilterModal);
    setSelectedContactType(contactType);
    // localStorage.setItem('FilterContactType', encrypt(contactType));
    changeLocation(location);
  }

  function handleClickAddContactModalCancel(event: React.MouseEvent) {
    setDisplayAddContactModal(!displayAddContactModal);
  }

  function handleClickAddContactModalContinue() {
    setDisplayAddContactModal(!displayAddContactModal);
    clearInterval(intervalRef.current);
    console.log(`stop polling: ${intervalRef.current}`);
    history.push({
      pathname: `/contact-types`,
    });
  }

  function onClickClose(): void {
    window.location.href = env.saconnect;
  }

  function handleClickAgencyContactsTerminate() {
    clearInterval(intervalRef.current);
    console.log(`stop polling: ${intervalRef.current}`);
    history.push({
      pathname: `/terminate-agency-contacts`,
    });
  }

  function handleClickTerminateContactModalCancel(event: React.MouseEvent) {
    setDisplayTerminateContactModal(!displayTerminateContactModal);
  }

  async function handleClickTerminateContactModalConfirm(additionalComments: string) {
    // make API call to send terminate contact email
    const personId = decrypt(localStorage.getItem('selectedPersonId'));
    const adminPersonId = decrypt(localStorage.getItem('personId'));
    const body = {
      agency_admin_person_id: adminPersonId?.toString(),
      comments: additionalComments.trim()
    }

    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseTerminateContactEmail = API.graphql(
      graphqlOperation(
        mutations.updatePendingPersonsTerminate,
        {
          body: body,
          person_id: personId
        }
      ),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
      }
    ) as Promise<{ data: UpdatePendingPersonsTerminateMutation }>;
    
    promiseTerminateContactEmail.then((res) => {
      if (res.data) {
        return res.data.updatePendingPersonsTerminate;
      } else {
        handleGraphQLErrors(res);
      }
    })
    .then((res) => {
      if (res?.statusCode === 200) {
        localStorage.setItem('contactTerminated', encrypt('true'));
        history.push({
          pathname: `/agency-contacts`,
        });
        window.location.reload();
      } else {
        throw new Error('Something went wrong - 25');
      }
    })
    .catch((err) => {
      if (err?.message) {
        console.error(err.message, err); 
      } else {
        handleGraphQLErrors(err);
      }
      window.location.href = "error";
    });

    setDisplayTerminateContactModal(!displayTerminateContactModal);
    setShowModalSpinner(true);
  }

  function processContactTypes(contactTypes: string, abbreviate: boolean = true) {
    const ctArray: string[] = contactTypes.split(',');

    // get the contact type descriptions
    const descriptiveContactTypes: string[] = [];
    ctArray.forEach((ct) => {
      const found = ContactData.contacttype.find((item) => item.contacttype === parseInt(ct));
      if (typeof found !== 'undefined') {
        descriptiveContactTypes.push(found.description);
      }
    });

    descriptiveContactTypes.sort((a, b) => {
      if (a === b) {
        return 0;
      } else {
        return (a.toLowerCase() < b.toLowerCase()) ? -1 : 1;
      }
    });

    if (abbreviate) {
      if (descriptiveContactTypes.length === 2) {
        return descriptiveContactTypes.join(', ');
      } else if (descriptiveContactTypes.length > 2) {
        return `${descriptiveContactTypes[0]}, ${descriptiveContactTypes[1]}, +${descriptiveContactTypes.length - 2}`;
      } else if (descriptiveContactTypes.length === 0) {
        return '-';
      } else {
        return descriptiveContactTypes.toString();
      }
    } else {
      return descriptiveContactTypes.toString();
    }
  }

  function processEmailAddress(email: string) {
    if (typeof email !== 'undefined' && email !== null) {
      return email.toLowerCase();
    } else {
      return '-';
    }
  }

  function wait(ms: number) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(ms)
      }, ms);
    });
  }

  useEffect(() => {
    function tick() {
      setRetrievePendingStatusData(true);
    }
    if (delay !== null && intervalStatus === 'stopped' && intervalCommand === 'start') {
      intervalRef.current = setInterval(tick, delay);
      localStorage.setItem('intervalRef', encrypt(JSON.stringify(intervalRef)));
      setIntervalCommand('');
      setIntervalStatus('running');
      console.log(`start polling: ${intervalRef.current}`);
    }
    if (delay !== null && intervalStatus === 'running' && intervalCommand === 'stop') {
      clearInterval(intervalRef.current);
      setIntervalCommand('');
      setIntervalStatus('stopped');
      console.log(`stop polling: ${intervalRef.current}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalStatus, intervalCommand]);

  useEffect(() => {
    async function getPendingStatusData() {
      if (intervalStatus === 'stopped' && intervalCommand === '') {
        setIntervalCommand('start');
      }
  
      if (personIdsForSelectedLoc.length) {
        const body: { persons: string[] } = {
          persons: []
        };
        
        personIdsForSelectedLoc.forEach((person: string) => {
          body.persons.push(person);
        });

        const isAuthenticated: boolean = await checkAuthStatus();
        if (!isAuthenticated) {
          return;
        }
        const promisePendingStatus = API.graphql(
          graphqlOperation(
            mutations.postPersonPendingStatus,
            {
              body: body
            }
          ),
          {
            Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
          }
        ) as Promise<{ data: PostPersonPendingStatusMutation }>;
    
        promisePendingStatus.then((res) => {
          if (res.data) {
            return res.data.postPersonPendingStatus;
          } else {
            handleGraphQLErrors(res);
          }
        })
        .then((res) => {
          setShowLoadingSpinner(false);
          if (res?.statusCode === 200) {
            return res.body as PendingData | null;
          } else {
            throw new Error('Something went wrong - 26');
          }
        })
        .then((res: null | PendingData ) => {
          if (res) {  
            const newPendingData: PendingData = { pending_persons: [] };
            res.pending_persons.forEach((person: PendingPerson) => {
              if (['Pending Addition', 'Pending Updates', 'Pending Termination'].includes(person.pending_status)) {
                newPendingData.pending_persons.push({
                  person_id: `${person.person_id}`,
                  pending_status: person.pending_status
                });
              }
            });
  
            const terminatedPersons: PendingPerson[] = [];
  
            priorPendingData.pending_persons.forEach((person) => {
              if (person.pending_status === 'Pending Termination') {
                const filterResults = newPendingData.pending_persons.filter((newPerson) => {
                  if (person.person_id === newPerson.person_id && person.pending_status === newPerson.pending_status) {
                    return true;
                  } else {
                    return false;
                  }
                });
  
                if (filterResults.length === 0) {
                  terminatedPersons.push({ person_id: person.person_id, pending_status: 'Pending Termination'});
                }
              }
            });
  
            if (terminatedPersons.length) {
              terminatedPersons.forEach((person) => {
                newPendingData.pending_persons.push(person);
              });
            }

            setPendingData(newPendingData);
            setPriorPendingData(newPendingData);
            setRetrievePendingData(false);
            setRetrievePendingStatusData(false);
          } else {
            throw new Error('Something went wrong - 27');
          }
        })
        .catch((err) => {
          if (err?.message) {
            console.error(err.message, err); 
          } else {
            handleGraphQLErrors(err);
          }
          window.location.href = "error";
        });
      }
    }

    if (retrievePendingStatusData) {
      getPendingStatusData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrievePendingStatusData]);

  useEffect(() => {
    if (addContact && dataFetchProgress.completed) {
      const tracker: TrackProgress = dataFetchProgress;
      const locationNotCompleted = tracker.locations.filter((loc) => (!loc.agencyCodes.completed));

      if (locationNotCompleted.length > 0) {
        setShowModalSpinner(true);
        tracker.completed = false;
        const newTracker: TrackProgress = JSON.parse(JSON.stringify(tracker));
        setDataFetchProgress(newTracker);
        const contFetch: boolean = true;
        setFetchAllLocations(true);
        setContinueFetch(contFetch);
      } else {
        setAddContact(false);
        setShowModalSpinner(false);
        setAddContactAgencyCodes();
        setDisplayAddContactModal(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addContact, dataFetchProgress]);

  useEffect(() => {
    async function checkContinueFetch() {
      if (continueFetch) {
        const contFetch: boolean = false;
        setContinueFetch(contFetch);
        console.log('Continue to Fetch');

        let requests = 0;
        let interval = 100; // milliseconds
        let maxConcurrentRequests = 6;

        const trackProgress: TrackProgress = dataFetchProgress;
          
        agencyContacts.locations.forEach(async (location: Location) => {

          while (requests > maxConcurrentRequests) {
            await wait(interval);
          }

          if (location.agency_id === selectedLocation) {
            if (!trackProgress.completed) {
              trackProgress.locations.forEach(async (trackLoc: TrackProgressLocation) => {
                if (trackLoc.location_id === location.agency_id && trackLoc.persons && !trackLoc.persons.completed) {
                  const agencyId = location.agency_id;
                  requests++;

                  const isAuthenticated: boolean = await checkAuthStatus();
                  if (!isAuthenticated) {
                    return;
                  }
                  const promisePerson = API.graphql(
                    graphqlOperation(
                      queries.agencyLandingPage,
                      {
                        admin_person_id: personId,
                        agency_id: agencyId
                      }
                    ),
                    {
                      Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
                    }
                  ) as Promise<{ data: AgencyLandingPageQuery }>;

                  promisePerson.then((res) => {
                    if (res.data) {
                      return res.data.agencyLandingPage;
                    } else {
                      handleGraphQLErrors(res);
                    }
                  })
                  .then((res) => {
                    if (res?.statusCode === 200) {
                      return res.body as Persons | null;
                    } else {
                      throw new Error('Something went wrong - 28');
                    }
                  })
                  .then((result: Persons | null) => {
                    if (result !== null) {
                      const contacts = agencyContacts;
                      contacts.locations.forEach((loc) => {
                        if (loc.agency_id === result.location_id) {
                          result.persons.forEach((person) => {
                            const newPerson: ContactPerson = {
                              person_id: person.person_id,
                              gain_contact_id: person.gain_contact_id,
                              prdcr_first_name: person.prdcr_first_name,
                              prdcr_last_name: person.prdcr_last_name,
                              is_active: person.is_active,
                              login_id: person.login_id,
                              preferred_email: person.preferred_email,
                              pending_status: person?.pending_status,
                              agency_code_map: [],
                              name: `${person.prdcr_last_name}, ${person.prdcr_first_name}`,
                              primary_phone_number: person.primary_phone_number,
                              contact_type: person.contact_type,
                            };

                            loc.persons.push(newPerson);
                          });

                          trackProgress.locations.forEach((loc: TrackProgressLocation) => {
                            if (loc.location_id === result.location_id) {
                              loc.persons.completed = true;
                            }
                          });
                        }
                      });

                      const newTracker: TrackProgress = JSON.parse(JSON.stringify(trackProgress));
                      setDataFetchProgress(newTracker);

                      setAgencyContacts(contacts);

                      return result;
                    } else {
                      throw new Error('Something went wrong - 29');
                    }
                  })
                  .catch((err) => {
                    setAgencyContacts({
                      locations: []
                    });
                    if (err?.message) {
                      console.error(err.message, err); 
                    } else {
                      handleGraphQLErrors(err);
                    }
                    window.location.href = "error";
                    return undefined;
                  })
                  .finally(() => {
                    requests--;
                  });
                }
              });
            }
          }
        });

        if (fetchAllLocations) {
          if (!trackProgress.completed) {
            requests++;

            const isAuthenticated: boolean = await checkAuthStatus();
            if (!isAuthenticated) {
              return;
            }
            const promiseAgencyCodes = API.graphql(
              graphqlOperation(
                queries.landingPageAgencyCode,
                {
                  admin_person_id: personId
                }
              ),
              {
                Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
              }
            ) as Promise<{ data: LandingPageAgencyCodeQuery }>;

            promiseAgencyCodes.then((res) => {
              if (res.data) {
                return res.data.landingPageAgencyCode;
              } else {
                handleGraphQLErrors(res);
              }
            })
            .then((res) => {
              if (res?.statusCode === 200) {
                return res.body as AgencyCodes | null;
              } else {
                throw new Error('Something went wrong - 30');
              }
            })
            .then((result: AgencyCodes | null) => {
              if (result !== null) {
                if (result.hasOwnProperty('error')) {
                  throw new Error('Something went wrong - 31');
                } else {
                  const contacts: Contacts = agencyContacts;
                  trackProgress.locations.forEach((trackerLoc: TrackProgressLocation) => {
                    const agencyCodeArray: AgencyCode[] = [];
                    result.agency_codes.forEach((agencyCode: AgencyCode) => {
                      if (trackerLoc.location_id === agencyCode.agency_id && trackerLoc.agencyCodes && !trackerLoc.agencyCodes.completed) {
                        const newAgencyCode: AgencyCode = {
                          agency_id: agencyCode.agency_id,
                          agency_code: agencyCode.agency_code,
                          agency_name: agencyCode.agency_name,
                          agency_city: agencyCode.agency_city,
                          agency_state: agencyCode.agency_state,
                          agency_zip: agencyCode.agency_zip.substr(0,5),
                          dba: ''
                        };
                        agencyCodeArray.push(newAgencyCode);
                      }
                    });

                    contacts.locations.forEach((loc: ContactLocation) => {
                      if (loc.agency_id === trackerLoc.location_id) {
                        loc.agencyCodes = agencyCodeArray;
                      }
                    });

                    setAgencyContacts(contacts);

                    trackerLoc.agencyCodes.completed = true;
                  });

                  const newTracker: TrackProgress = JSON.parse(JSON.stringify(trackProgress));
                  setDataFetchProgress(newTracker);
                }
              } else {
                throw new Error('Something went wrong - 32');
              }   
            })
            .catch((err) => {
              if (err?.message) {
                console.error(err.message, err); 
              } else {
                handleGraphQLErrors(err);
              }
              window.location.href = "error";
            })
            .finally(() => {
              requests--;
            });
          }
        }
      }
    }

    checkContinueFetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueFetch]);

  useEffect(() => {
    if (updateIcon > 0) {
      const list: NodeListOf<Element> = document.querySelectorAll('li.pending:last-child > svg > g > g[stroke="Hotpink"]');
      list.forEach((item: any) => {
        item.setAttribute('stroke', 'hsl(354,73%,51%)');
      });
      if (list.length === 0 && updateIcon < 2) {
        setUpdateIcon(updateIcon => updateIcon + 1);
      } else {
        setUpdateIcon(0);
      }
    }
  }, [updateIcon]);

  useEffect(() => {
    function generateContactTypes() {
      const contactTypes: ContactTypeKeyPair[] = [];

      ContactData.contacttype.forEach((ct) => {
        const ctkp: ContactTypeKeyPair = {
          key: ct.contacttype.toString(),
          value: ct.description
        }
        contactTypes.push(ctkp);
      });

      contactTypes.sort((a, b) => {
        if (a.value === b.value) {
          return 0;
        } else {
          return (a.value.toLowerCase() < b.value.toLowerCase()) ? -1 : 1;
        }
      });

      setContactTypeList(contactTypes);
    }

    generateContactTypes();

    // check for saved selectedContactType
    // const filterContactType: string | null = decrypt(localStorage.getItem('FilterContactType'));
    // if (filterContactType) {
    //   setSelectedContactType(filterContactType);
    // }
  }, []);

  useEffect(() => {
    if (locationList.length) {
      // check for saved selectedLocation
      const filterLocation: string | null = decrypt(localStorage.getItem('FilterLocation'));
      if (filterLocation) {
        // prior location was saved to session. Now we retrieve from session and re-assign back to selectedLocation
        setSelectedLocation(filterLocation);
      } else {
        // setting the first location as the default
        setSelectedLocation(locationList[0].key);
      }
    }
  }, [locationList]);

  useEffect(() => {
    async function getAgencyAdminAgencyLocations() {
      if (isAdmin) {
        // Begin fetching all locations and corresponding address info
        const trackProgress: TrackProgress = { completed: false, locations: [] };
        setDataFetchProgress(trackProgress);

        const isAuthenticated: boolean = await checkAuthStatus();
        if (!isAuthenticated) {
          return;
        }
        const promiseLocations = API.graphql(
          graphqlOperation(
            queries.getagencyadminAgencyLocations,
            {
              admin_person_id: personId
            }
          ),
          {
            Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
          }
        ) as Promise<{ data: GetagencyadminAgencyLocationsQuery }>;

        promiseLocations.then((res) => {
          if (res.data) {
            return res.data.getagencyadminAgencyLocations;
          } else {
            handleGraphQLErrors(res);
          }
        })
        .then((res) => {
          if (res?.statusCode === 200 || res?.statusCode === 400) {
            return res.body as Locations;
          } else {
            return null;
          }
        })
        .then((result: Locations | null) => {
          if (result !== null && result.agency_locations !== null) {
            result.agency_locations.forEach((loc: Location) => {
              const newLoc: TrackProgressLocation = {
                location_id: loc.agency_id,
                completed: true,
                persons: { completed: false },
                agencyCodes: { completed: false }
              };
              trackProgress.locations.push(newLoc);
    
              const newLocation: ContactLocation = {
                agency_id: loc.agency_id,
                agency_name: loc.agency_name,
                agency_addressline: loc.agency_addressline,
                agency_city: loc.agency_city,
                agency_state: loc.agency_state,
                agency_zip: loc.agency_zip.substr(0,5),
                persons: [],
                agencyCodes: []
              };
    
              const contacts: Contacts = agencyContacts;
              contacts.locations.push(newLocation);
              setAgencyContacts(contacts);
            });
    
            const newTracker: TrackProgress = JSON.parse(JSON.stringify(trackProgress));
            setDataFetchProgress(newTracker);
    
            return result;
          } else if (result?.status === "error") {
            setShowLoadingSpinner(false);
            setShowAlertModal(true);
          } else {
            throw new Error('Something went wrong - 33');
          }
        })
        .catch((err) => {
          setAgencyContacts({
            locations: []
          });
          if (err?.message) {
            console.error(err.message, err); 
          } else {
            handleGraphQLErrors(err);
          }
          window.location.href = "error";
          return undefined;
        });
      }
    }

    getAgencyAdminAgencyLocations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId, locationId]);

  useEffect(() => {
    if (dataFetchProgress.completed) {
      console.log('Processing data');
      console.log('unfiltered agency contacts', agencyContacts);

      // filter locations based on selectedLocation
      const filteredLocations: ContactLocation[] = agencyContacts.locations.map((loc: ContactLocation) => {
        const persons: ContactPerson[] = [];

        loc.persons.forEach((person) => {
          const newPerson: ContactPerson = {
            person_id: person.person_id,
            gain_contact_id: person.gain_contact_id,
            prdcr_first_name: person.prdcr_first_name,
            prdcr_last_name: person.prdcr_last_name,
            is_active: person.is_active,
            login_id: person.login_id,
            preferred_email: person.preferred_email,
            pending_status: person.pending_status,
            agency_code_map: person.agency_code_map,
            name: person.name,
            primary_phone_number: person.primary_phone_number,
            contact_type: person.contact_type,
          };
          persons.push(newPerson);
        });

        const location: ContactLocation = {
          agency_id: loc.agency_id,
          agency_name: loc.agency_name,
          agency_addressline: loc.agency_addressline,
          agency_city: loc.agency_city,
          agency_state: loc.agency_state,
          agency_zip: loc.agency_zip,
          persons: persons,
          agencyCodes: loc.agencyCodes
        }

        return location;
      }).filter((loc: ContactLocation) => selectedLocation === loc.agency_id);
      const filteredAgencyContacts: Contacts = { locations: filteredLocations };

      // list of all contacts for the selected location - used for pending status
      const newPersonIdsForSelectedLoc: string[] = [];
      filteredLocations.forEach((loc: ContactLocation) => {
        loc.persons.forEach((person: ContactPerson) => {
          newPersonIdsForSelectedLoc.push(person.person_id);
        });
        
        // Determine if "no contacts" message should be displayed
        if (loc.persons.length) {
          setShowNoContactsMessage(false);
        } else {
          setShowNoContactsMessage(true);
        }
      });
      setPersonIdsForSelectedLoc(newPersonIdsForSelectedLoc);

      // filter persons bases on selectedContactType
      filteredAgencyContacts.locations.forEach((loc: ContactLocation, index: number) => {
        const personsFilterResult: ContactPerson[] = loc.persons.filter((person: ContactPerson) => selectedContactType === 'all' || person?.contact_type?.includes(selectedContactType));
        filteredAgencyContacts.locations[index].persons = personsFilterResult;
      });

      // filter persons based on search terms
      filteredAgencyContacts.locations.forEach((loc: ContactLocation) => {
        const persons = loc.persons.filter((person: ContactPerson) => {
          let containsAllSearchTerms: boolean = true;
          searchTerms.forEach((searchTerm: string) => {
            let containsSearchTerm: boolean = false;

            let lastNameCommaFirstName = '';
            let lastNameFirstName = '';
            let firstNameLastName = '';
            if (person.name) {
              lastNameCommaFirstName = person.name;
              let commaIndex: number = person.name.indexOf(',');
              lastNameFirstName = person.name.substr(0, commaIndex) + person.name.substr(commaIndex + 1, person.name.length);
              firstNameLastName = person.name.substr(commaIndex + 2, person.name.length) + ' ' + person.name.substr(0, commaIndex);
            }

            if (person.name && (lastNameCommaFirstName.toLowerCase().includes(searchTerm.toLowerCase()) || lastNameFirstName.toLowerCase().includes(searchTerm.toLowerCase())  || firstNameLastName.toLowerCase().includes(searchTerm.toLowerCase()))) {
              containsSearchTerm = true;
            };
            if (person.login_id && person.login_id.toLowerCase().includes(searchTerm.toLowerCase())) {
              containsSearchTerm = true;
            };
            if (person.preferred_email && person.preferred_email.toLowerCase().includes(searchTerm.toLowerCase())) {
              containsSearchTerm = true;
            };
            if (!containsSearchTerm) {
              containsAllSearchTerms = false;
            }
          });

          if (containsAllSearchTerms) {
            return true;
          } else {
            return false;
          }
        });
        loc.persons = persons;
      });

      console.log('filtered agency contacts', filteredAgencyContacts);

      // Next, we prepare raw table data which will contain all the filtered data, but formated for display in the table.
      // The raw table data will then be sliced later for pagination.
      const data: string[][] = [];
      const foundLocation = filteredAgencyContacts.locations.find((loc) => {
        return loc.agency_id === selectedLocation;
      });
      if (typeof foundLocation !== 'undefined') {
        foundLocation.persons.forEach((person) => {
          data.push([
            person.name,
            person?.contact_type?.length ? person?.contact_type.join(',') : '-',
            person.login_id,
            person.preferred_email,
            person.person_id,
          ]);
        });
      }

      // sort raw table data by "name" column at index 0
      data.sort((a, b) => {
        return a[sorting.columnIndex].toLowerCase()
          .localeCompare(b[sorting.columnIndex].toLowerCase()) * (sorting.order === "asc" ? 1 : -1);
      });

      // remove any duplicate persons
      const uniqueArray = Array.from(new Set(data.map((row) => JSON.stringify(row))), (row) => JSON.parse(row));

      console.log('all table data', uniqueArray);

      setRawTableData(uniqueArray);
      setTotalItems(uniqueArray.length);
      if (uniqueArray.length === 0) {
        console.log('No Items to display');
        setShowLoadingSpinner(false);
      }
      console.log('Processing complete');
    } else {
      // check tracking object to ensure all items have finished processing
      let complete: boolean = true;

      if (dataFetchProgress.locations.length) {
        const filteredLocations = dataFetchProgress.locations.filter((loc: TrackProgressLocation) => loc.completed === false);
        if (filteredLocations.length) {
          complete = false;
          return;
        }

        const filterLocation: string | null = decrypt(localStorage.getItem('FilterLocation'));
        if (selectedLocation === '') {
          // populate the locationList with locations for display in the Change Location modal
          const locArray: LocationKeyPair[] = [];
          agencyContacts.locations.forEach((loc: ContactLocation) => {
            const lkp: LocationKeyPair = {
              key: loc.agency_id,
              value: `${loc.agency_name}, ${loc.agency_addressline}, ${loc.agency_city}, ${loc.agency_state}`,
            };
            locArray.push(lkp);
          });
          locArray.sort((a, b) => {
            if (a.value === b.value) {
              return 0;
            } else {
              return (a.value.toLowerCase() < b.value.toLowerCase()) ? -1 : 1;
            }
          });
          setLocationList(locArray);
          complete = false;
        } else {
          if (filterLocation === null) {
            complete = false;
          } else {
            dataFetchProgress.locations.forEach((location: TrackProgressLocation) => {
              if (location.location_id === selectedLocation) {
                if (location.persons && location.persons.completed === false) {
                  complete = false;
                  return;
                }
              }
              if (fetchAllLocations) {
                if (location.agencyCodes && location.agencyCodes.completed === false) {
                  complete = false;
                  return;
                }
              }
            });
          }
        }
      } else {
        complete = false;
      }

      if (complete) {
        const tracker: TrackProgress = dataFetchProgress;
        tracker.completed = true;
        const newTracker: TrackProgress = JSON.parse(JSON.stringify(tracker));
        setFetchAllLocations(false);
        setDataFetchProgress(newTracker);
        console.log('Data fetch complete');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetchProgress, searchTerms, selectedLocation, sorting, selectedContactType]);

  useEffect(() => {
    if (dataFetchProgress.completed && rawTableData.length > 0 && retrievePendingData) {
      // get initial pending status data
      setRetrievePendingStatusData(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetchProgress, retrievePendingData, rawTableData]);

  useEffect(() => {
    if (totalItems > 0) {
      // slice the raw table data to get 10 rows for pagination
      const range = rawTableData.slice(getStartIndex(), getEndIndex());
      setTableData(range);
    } else {
      setTableData([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex, rawTableData, totalItems]);

  useEffect(() => {
    if (tableData.length > 0) {
      console.log('paginated table data', tableData);
      setUpdatePendingStatus(true);
    }
  }, [tableData]);

  useEffect(() => {
    let update = false;
    if (updatePendingStatus) {
      if (tableData.length > 0) {
        tableData.forEach((row: string[], index: number) => {
          const personId = row[4];

          const kabobMenu: Element = document.querySelector(`article > section > div > article > div:nth-child(${index + 1}) > section > section:last-child > ul > li > button > svg`) as Element;
          const ul: Element = document.querySelector(`article > section > div > article > div:nth-child(${index + 1}) > section > section:last-child > ul`) as Element;
          const button: Element = document.querySelector(`article > section > div > article > div:nth-child(${index + 1}) > section > section:last-child > ul > li:first-child > button`) as Element;
          const rowSection: Element = document.querySelector(`article > section > div > article > div:nth-child(${index + 1}) > section`) as Element;
          const section: Element = document.querySelector(`article > section > div > article > div:nth-child(${index + 1}) > section > section:first-child`) as Element;

          if (kabobMenu && ul && rowSection && section && button) {
            if (pendingPersons.includes(personId)) {
              const clockJSX: JSX.Element = <SAIcon icon={SAIcons.clock} size={SAIconSize.small} colorVariant={SAUX360Theme.colors.red600}></SAIcon>;
              const match: PendingPerson[] = pendingData.pending_persons.filter((person: PendingPerson) => {
                let matchFound: boolean = false;
                if (person.person_id === personId) {
                  matchFound = true;
                }
                return matchFound;
              });
  
              if (match.length > 0) {
                const status: string = match[0].pending_status;
                const pendingJSX: JSX.Element = <li>{status}</li>;
                button.setAttribute('tabindex','-1');
                kabobMenu.classList.add('pending');
                rowSection.classList.add('pending');
                const ulElement = document.createElement('ul');
                if (status === 'Pending Termination') {
                  ulElement.className = 'red';
                } else {
                  ulElement.className = 'blue';
                }
                ReactDOM.render(pendingJSX, ulElement);
                if (section.childNodes.length > 1) {
                  section.childNodes[1].remove();
                }
                if (section.childNodes.length === 1) {
                  section.appendChild(ulElement);
                }
                if (ul.childNodes.length === 1) {
                  const liElement: HTMLLIElement = document.createElement('li');
                  liElement.className = 'pending';
                  ReactDOM.render(clockJSX, liElement);
                  ul.appendChild(liElement);
                  update = true;
                }
              }
            } else {
              button.removeAttribute('tabindex');
              kabobMenu.classList.remove('pending');              
              rowSection.classList.remove('pending');
              if (section.childNodes.length > 1) {
                section.childNodes[1].remove();
              }
              if (ul.childNodes.length > 1) {
                ul.childNodes[1].remove();
              }
            }
          }
        });

        if (update) {
          setUpdateIcon(updateIcon => updateIcon + 1);
        }
      }
      setUpdatePendingStatus(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingPersons, updatePendingStatus]);

  useEffect(() => {
    function updatePosition() {
      if (document.documentElement.clientWidth <= mobileWidth) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  }, []);

  useEffect(() => {
    if (!document.getElementById('modal')) {
      const modalRoot: HTMLDivElement = document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      document.body.append(modalRoot);
    }
  }, []);

  useEffect(() => {
    if (displayContactTypesModal || displayChangeLocationModal || displayFilterModal || displayAddContactModal || displayTerminateContactModal || displayAgencyOperationsModal || showModalSpinner) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [displayContactTypesModal, displayChangeLocationModal, displayFilterModal, displayAddContactModal, displayTerminateContactModal, displayAgencyOperationsModal, showModalSpinner]);

  useEffect(() => {
    if (selectedContactType === 'all') {
      setLabelContactTypes('All Contact Types');
    } else {
      const found = contactTypeList.find((ct) => ct.key === selectedContactType);
      if (typeof found !== 'undefined') {
        setLabelContactTypes(found.value);
      } else {
        setLabelContactTypes('all');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContactType]);

  useEffect(() => {
    if (selectedLocation !== '') {
      const found = locationList.find((loc) => loc.key === selectedLocation);
      if (typeof found !== 'undefined') {
        setLabelLocations(found.value);
        const filterLocation: string | null = decrypt(localStorage.getItem('FilterLocation'));
        if (locationList.length === 1 && filterLocation === null) {
          changeLocation(locationList[0].key);
        }
        if (locationList.length > 1 && filterLocation === null) {
          setDisplayChangeLocationModal(true);
        }
        if (locationList.length > 0 && filterLocation) {
          changeLocation(filterLocation);
        }
      } else {
        setSelectedLocation('');
        setLabelLocations('');
      }
      setSorting({ columnIndex: 0, order: "asc"})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  useEffect(() => {
    setPendingPersons(getPendingPersons());
    setUpdatePendingStatus(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pendingData]);

  useEffect(() => {
    function handleScroll() {
      setReferenceElement(null);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <SASnackbar
        open={showSnackbar}
        variant="snackbar-success"
        position="top-end"
        autoHideDuration={5000}
        title={snackbarTitle}
        subtitle={snackbarSubtitle}
        offset={[-25, 170]}
        onClose={onSnackbarClose}
      />
      {showLoadingSpinner && !displayChangeLocationModal && <SpinnerContainer><SASpinner variant="circular-continuous" /></SpinnerContainer>}
      {showLoadingSpinner && displayChangeLocationModal &&
        <ModalChangeLocation
          selectedLocation={selectedLocation}
          locations={locationList}
          onClickSubmit={handleClickChangeLocationModalSubmit}
        />
      }
      {!showLoadingSpinner && (
        <AgencyContactsCard title="Agency Contacts" variant="standard" jsxElement={headerButtons} removePadding constants={constants} isEmployee={isEmployee}>
          {!showNoContactsMessage &&
            <>
              <Container>
                <SearchContainer>
                  <SASearch
                    fullWidth
                    values={searchTerms}
                    id="agencycontactssearchbar"
                    placeholder="Search by name, login id, or email address"
                    onSearch={handleSearch}
                    variant="search-terms"
                  />
                </SearchContainer>
                <AddContactContainer>
                  <SAButton fullWidthUnder={mobileWidth} label="Add Contact" onClick={handleClickAddContact} variant="primary-medium" startIcon={iconAddContact} />
                  <SAButton fullWidthUnder={mobileWidth} label="Bulk Terminate" onClick={handleClickAgencyContactsTerminate} variant="secondary-medium-outline" startIcon={iconPerson} />
                </AddContactContainer>
              </Container>
              <Table columns={columns} data={tableData} onRowClick={handleRowClick} onActionMenuClick={handleActionMenuClick} variant="table-to-listview-adjss" />
              <SAPopover
                data={popoverData}
                offset={[-10, 0]}
                onClose={(e: MouseEvent, index: number | null) => handleClose(e, index)}
                placement="bottom-start"
                referenceElement={referenceElement}
                variant="popover-menu-adjss"
              />
              <PaginationContainer>
                {!!totalItems &&
                  <div className="pagedetails">
                    {getPageRangeLabel()}
                  </div>
                }
                <SAPagination
                  currentPageIndex={currentPageIndex}
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  onChange={handlePaginationChange}
                  className="pagination"
                />
              </PaginationContainer>
            </>
          }
          {showNoContactsMessage &&
            <NoContactsMessage>
              The selected Location does not have any active agency code(s) or contacts. Select a different Location or contact <a href="mailto:Agency.Operations@stateauto.com">Agency.Operations@stateauto.com</a> for assistance.
            </NoContactsMessage>
          }
          {displayContactTypesModal &&
            <ModalContactTypes
              selectedContactType={selectedContactType}
              contactTypes={contactTypeList}
              onClickCancel={handleClickContactTypesModalCancel}
              onClickSubmit={handleClickContactTypesModalSubmit}
            />
          }
          {displayChangeLocationModal &&
            <ModalChangeLocation
              selectedLocation={selectedLocation}
              locations={locationList}
              onClickCancel={handleClickChangeLocationModalCancel}
              onClickSubmit={handleClickChangeLocationModalSubmit}
            />
          }
          {displayFilterModal &&
            <ModalFilter
              selectedContactType={selectedContactType}
              selectedLocation={selectedLocation}
              contactTypes={contactTypeList}
              hideContactTypes={showNoContactsMessage}
              locations={locationList}
              onClickCancel={handleClickFilterModalCancel}
              onClickApply={handleClickFilterModalApply}
            />
          }
          {displayAddContactModal &&
            <ModalAddContact 
              onClickCancel={handleClickAddContactModalCancel} 
              onClickContinue={handleClickAddContactModalContinue} 
              showEmailAlreadyUsed={(data: LicenseApplicationRequested) => {
                setDisplayAddContactModal(false);
                setDisplayEmailAlreadyUsedModal(data);
              }} 
            />
          }
          {displayEmailAlreadyUsedModal && 
            <ModalEmailAlreadyUsedForLP
              adminName={displayEmailAlreadyUsedModal.adminName}
              email={displayEmailAlreadyUsedModal.email}
              requestedOn={displayEmailAlreadyUsedModal.requestedOn}
              onClickClose={() => setDisplayEmailAlreadyUsedModal(null)}
            />
          }
          {displayTerminateContactModal &&
            <ModalTerminateContact onClickCancel={handleClickTerminateContactModalCancel} onClickConfirm={handleClickTerminateContactModalConfirm} />
          }
          {displayAgencyOperationsModal &&
            <ModalContactAgencyOperations
              title="Contact Cannot be Terminated"
              text={agencyOpsModalText}
              requiredContactTypes={requiredContactTypes}
              terminableResponse={terminableResponse}
              onClickClose={handleAgencyOpsModalClose}
            />
          }
          {showAlertModal && (
            <ModalAlert
              title="Account Inaccessible"
              text={
                <span>
                  Your account is inaccessible. Reach out to{" "}
                  <a href="mailto:Agency.Operations@stateauto.com">Agency.Operations@stateauto.com</a> for updates.
                </span>
              }
              onClickClose={onClickClose}
            />
          )}
          {showModalSpinner && <ModalSpinner />}
        </AgencyContactsCard>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import {
  SACard,
  SAText,
  SAButton,
  SAAlert,
  SASnackbar,
  SAIcon,
  SAIcons,
  SASpinner,
  SAPopover,
  SARadioGroup,
  SARadio,
  SAInput,
  SAUXTheme,
} from "@saux/design-system-react";
import { emailValidation } from "../../utils";
import styled from "styled-components";
import ModalSpinner from "../modal/ModalSpinner/ModalSpinner";
import ModalAlert from "../modal/ModalTerminateContactsAlert/ModalTerminateContactsAlert";
import validator from "validator";
import { decrypt, encrypt } from "../../utils/crypto";
import { constants } from "../../utils/constants";
import { Constants } from "../../interfaces/interfaces";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AgencyCodesInput from "../common/AgencyCodesInput";
import { useAgencyCodes } from "./hooks/useAgencyCodes";
import * as queries from "../../graphql/queries";
import env from '../../env_config';
import handleGraphQLErrors from "../../utils/handleGraphQLErrors";
import { API, graphqlOperation } from "aws-amplify";
import { checkAuthStatus } from "../../utils/utils";
import { GetRosterReportDetailsQueryVariables, GetRosterReportDetailsQuery } from "../../API";
import { simpleGTMDataLayer } from "../../utils/GTMHelpers";

type Position =
  | "auto"
  | "top-start"
  | "top"
  | "top-end"
  | "right"
  | "bottom-start"
  | "bottom"
  | "bottom-end"
  | "left";
type Offset = [number, number];
type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
  isEmployee?: boolean;
};
type ContactListingForm = {
  email: string;
  agencyCodes: string[];
  contacts: "Active" | "AllContacts"
}

const mobileWidth = 600;
const desktopPosition: Position = "auto";
const mobilePosition: Position = "top-end";
const desktopOffset: Offset = [0, 10];
const mobileOffset: Offset = [0, 10];

const CardHeader = styled.div`
  padding-bottom: 40px;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 30px;

  .closemodal {
    display: none;
  }

  button {
    margin-left: 15px;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

const CardSubTitle = styled.div`
  margin-top: 4px;
  span {
    line-height: 1.5;
  }
`;

const CardBody = styled.div`
  label {
    z-index: 0;
  }
`;

const CardFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      margin-top: 3rem;
      display: flex;
      justify-content: flex-end;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }
    `;
  }};
`;

const AgencyCodesLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;

  span:first-child > span {
    color: #fa5252;
    font-size: 14px;
  }
`;

const AgencyCodeHelp = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  span:first-child {
    font-size: 14px;
    color: rgb(151, 151, 151);
  }
`;

const EmailLabel = styled.div`
  margin-bottom: 12px;
  span:last-child {
    color: #fa5252;
    font-size: 14px;
  }
`;

const ContactsLabel = styled.div`
  margin-bottom: 2px;
  span:last-child {
    color: #fa5252;
    font-size: 14px;
  }
`;
const ContactInputField = styled.div`
  margin-bottom: 32px;
`;
const EmailInputField = styled.div`
  max-width: 420px;
  margin-bottom: 32px;
`;

const ErrorMessage = styled.div`
  margin-top: 8px;
  padding-left: 15px;
`;

const ContactListingCard = styled(SACard).attrs(
  ({ constants, isEmployee }: StyleProps) => ({
    constants,
    isEmployee,
  })
)`
  ${({ theme, constants, isEmployee }: StyleProps) => {
    const con: Constants = constants as Constants;
    let marginTop: number = isEmployee
      ? con.headerHeight +
        con.dockedAgentInfoHeight +
        con.dockedWarningHeight +
        con.verticalPageOffset
      : con.headerHeight + con.verticalPageOffset;
    let marginTopMobile: number = isEmployee
      ? con.headerHeightMobile +
        con.dockedAgentInfoHeight +
        con.dockedWarningHeight +
        con.verticalPageOffset
      : con.headerHeightMobile + con.verticalPageOffset;

    return `
      margin-top: ${marginTop}px;
      margin-bottom: 60px;
      margin-left: ${theme?.size.spacer.large};
      margin-right: ${theme?.size.spacer.large};

      @media only screen and (max-width: ${mobileWidth}px) {
        margin-top: ${marginTopMobile}px;
        margin-left: ${theme?.size.spacer.medium};
        margin-right: ${theme?.size.spacer.medium};
      }
    `;
  }};
`;

const Popover = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      color: hsla(220, 38%, 97%, 1);
      font-size: ${theme?.font.primary.size.smallTEMP};
      font-weight: ${theme?.font.primary.weight.normal};
      line-height: 16px;
      background-color: hsla(213, 30%, 35%, 1);
      border: 1px solid hsla(213, 30%, 35%, 1);
      box-sizing: border-box;
      box-shadow: 0px 0px 5px rgba(0, 32, 56, 0.22);
      border-radius: 4px;
      padding: 15px;
      width: 360px;
    `;
  }};
`;

const HelpContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const HelpTextIconButton = styled(SAButton)`
  padding: 0;

  &:hover {
    background-color: transparent;
  }
`;

const HelpIcon = styled.div`
  display: flex;
  padding-right: 10px;
  align-items: flex-start;
`;

const HelpText = styled.div`
  display: flex;
  align-items: flex-start;
`;

const HelpTextContent = styled.div``;

const SpinnerContainer = styled.div`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`;

export default function ContactListingReport() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    reset,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm<ContactListingForm>();
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showModalSpinner, setShowModalSpinner] = useState<boolean>(false);
  const [gtm, setGTM] = useState<boolean>(false);
  const email = decrypt(localStorage.getItem("preferred_email"));
  const personId = decrypt(localStorage.getItem("personId"));
  const auth_userId = decrypt(localStorage.getItem("auth_userid"));
  const isEmployee: boolean = decrypt(localStorage.getItem("loginType")) === "employeeID" ? true : false;
  const isAdmin: boolean = decrypt(localStorage.getItem("isAdmin")) === "true" ? true : false;
  const { isLoading, agencyCodes } = useAgencyCodes({ personId, isAdmin });

  function onClickClose(): void {
    window.location.href = env.saconnect;
  }

  const isValidEmail = (email: string) => {
    return validator.isEmail(email as string);
  };

  const onSubmit: SubmitHandler<ContactListingForm> = async (data) => {
    setShowModalSpinner(true);
    const isEmailValid = isEmployee ? await emailValidation(data.email) : true;
    
    setShowModalSpinner(false);
   
    if (isEmailValid && isValid) {
      setShowModalSpinner(true);
      generateReport({
        agency_codes: data.agencyCodes,
        is_active: data.contacts,
        email_address: data.email,
        is_actual: isEmployee ? "0" : "1",
        user_id: auth_userId
      });
      simpleGTMDataLayer({
        event: 'contactListingReport',
        event_action: 'Button Click',
        event_category: window.location.pathname,
        event_label: 'Contact Listing: generate report'
      });
    } else {
      setError("email", { type: "manual", message: "Invalid email address" });
      setShowModalSpinner(false);
    }
  };

  function closePopover(
    event: React.MouseEvent<HTMLElement> | MouseEvent,
    index: number | null
  ) {
    setReferenceElement(null);
  }

  function togglePopover(event: React.MouseEvent<HTMLElement>) {
    if (referenceElement !== event.currentTarget) {
      setReferenceElement(event.currentTarget);
    } else {
      setReferenceElement(null);
    }
  }

  function openPopover(event: React.MouseEvent<HTMLElement>) {
    setReferenceElement(event.currentTarget);
  }

  function handleKeyDown(e: any) {
    if (e.key === 'Tab') {
      setReferenceElement(null);
    }
  }
  
  function onSnackbarClose() {
    setShowSnackbar(false);
  }

  function setDefaultFormValues() {
    if (!isEmployee && email) {
      setValue("email", email);
    }
    setValue("contacts", "Active")
  }

  async function generateReport(reportVariable: GetRosterReportDetailsQueryVariables) {
    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseAgencyCodes = API.graphql(
      graphqlOperation(queries.getRosterReportDetails, reportVariable),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem("auth_accesstoken"))}`,
      }
    ) as Promise<{ data: GetRosterReportDetailsQuery }>;

    promiseAgencyCodes
      .then((res) => {
        if (res.data) {
          return res.data.getRosterReportDetails;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        reset();
        setDefaultFormValues();
        setShowModalSpinner(false);
        if (res?.statusCode === 200) {
          setShowSnackbar(true);
        } else {
          throw new Error("Something went wrong - 30");
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err);
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });
  }

  useEffect(() => {
    function handleScroll() {
      setReferenceElement(null);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!gtm) {    
      simpleGTMDataLayer({
        event: 'pageview',
        page: {
          path: '/contact-listing',
          title: 'ADJSS: Contact Listing Report'
        }
      });
      setGTM(true);
    }
  }, [gtm]);

  useEffect(() => {
    // set default focus
    const element = document.querySelector("input") as HTMLElement;
    if (element) {
      element.focus();
    }
  }, [agencyCodes]);


  useEffect(() => {
    setDefaultFormValues();
    // adding modal to body
    if (!document.getElementById("modal")) {
      const modalRoot: HTMLDivElement = document.createElement("div");
      modalRoot.setAttribute("id", "modal");
      document.body.append(modalRoot);
    }
  }, []);

  useEffect(() => {
    if (showModalSpinner) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [showModalSpinner]);

  if (!isAdmin) {
    return (
      <ContactListingCard
        constants={constants}
        isEmployee={isEmployee}
        variant="minimal"
      >
        <SAAlert severity="warning" title="Permission denied">
          Look up an Agency Admin or Principal to proceed.
        </SAAlert>
      </ContactListingCard>
    );
  }

  if (isLoading) {
    return (
      <SpinnerContainer>
        <SASpinner variant="circular-continuous" />
      </SpinnerContainer>
    );
  }

  if (!isLoading && agencyCodes && agencyCodes.length === 0) {
    return (
      <ModalAlert
        title="Account Inaccessible"
        text={
          <span>
            Your account is inaccessible. Reach out to{" "}
            <a href="mailto:Agency.Operations@stateauto.com">Agency.Operations@stateauto.com</a> for updates.
          </span>
        }
        onClickClose={onClickClose}
      />
    );
  }

  const displayReportForm = !isLoading && isAdmin && agencyCodes && agencyCodes.length > 0;

  return (
    <>
      {displayReportForm && (
        <ContactListingCard constants={constants} isEmployee={isEmployee} variant="minimal">
          <form id="agencycodesform" onSubmit={handleSubmit(onSubmit)}>
            <CardHeader>
              <CardTitle>
                <SAText type="heading-2" text="Contact Listing Report" />
              </CardTitle>
              <CardSubTitle>
                {isEmployee ? (
                  <SAText type="standard">
                    Enter the email address where this report will be sent.
                    Please allow 1 hour for report generation.
                  </SAText>
                ) : (
                  <SAText type="standard">
                    This report will be emailed to your email address:{" "}
                    <strong>{`${email}`}</strong> once submitted. Please allow 1
                    hour for report generation.
                  </SAText>
                )}
              </CardSubTitle>
            </CardHeader>
            <CardBody>
              {isEmployee && (
                <>
                  <EmailLabel>
                    <SAText
                      type="standard"
                      weight="bold"
                      text="Email Address"
                    />
                    <span> *</span>
                  </EmailLabel>
                  <EmailInputField>
                    <SAInput
                      {...register("email", {
                        required: "Email Address is required",
                        maxLength: {
                          value: 100,
                          message: "Email Address cannot exceed 100 characters",
                        },
                        validate: {
                          emailPattern: (value) =>
                            isValidEmail(value) ||
                            "Enter valid email address format",
                        },
                      })}
                      disabled={!isEmployee}
                      id="email"
                      name="email"
                      hideLabel
                      fullWidth
                      hint={errors.email?.message as string | undefined}
                      type="text"
                      error={errors.email as boolean | undefined}
                    />
                  </EmailInputField>
                </>
              )}
              <ContactsLabel>
                <SAText type="standard" weight="bold" text="Which Contacts?" />
                <span> *</span>
              </ContactsLabel>
              <ContactInputField>
                <Controller
                  control={control}
                  name="contacts"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <SARadioGroup
                      value={value}
                      onChange={onChange}
                      id="contacts"
                      variant="standard"
                      direction="horizontal"
                    >
                      <SARadio
                        value="Active"
                        label="Active"
                        variant="standard"
                      />
                      <SARadio
                        value="AllContacts"
                        label="All Contacts"
                        variant="standard"
                      />
                    </SARadioGroup>
                  )}
                />
                {errors.contacts && (
                  <ErrorMessage>
                    <SAText
                      type="small"
                      colorVariant="alert"
                      text="Which Contacts is required."
                    />
                  </ErrorMessage>
                )}
              </ContactInputField>
              <AgencyCodesLabel>
                <SAText type="standard" weight="bold">
                  Contacts are included based on their Agency Code association.{" "}
                  <span> *</span>
                </SAText>
                <AgencyCodeHelp>
                  <SAText
                    type="standard"
                    text=" Choose which Agency Codes to include:"
                  />
                  <HelpTextIconButton
                    fullWidth={false}
                    variant="link-small"
                    label="Help Text"
                    onClick={togglePopover}
                    onKeyDown={handleKeyDown}
                  >
                    <SAIcon
                      className="help-icon-svg"
                      icon={SAIcons.information}
                      size="20px"
                      colorVariant="primary"
                      onMouseOver={(e: any) => openPopover(e)} 
                      onMouseLeave={closePopover}
                    />
                  </HelpTextIconButton>
                </AgencyCodeHelp>
              </AgencyCodesLabel>
              <Controller
                control={control}
                name="agencyCodes"
                defaultValue={
                  agencyCodes.length === 1 ? [agencyCodes[0].agency_code] : []
                }
                rules={{
                  validate: (value) => (value ? value.length > 0 : false),
                }}
                render={({ field: { onChange, value } }) => (
                  <AgencyCodesInput
                    onChange={(value) => onChange(value)}
                    values={value ? value : []}
                    agencyCodes={agencyCodes}
                    resetSearchFilter={isSubmitSuccessful}
                    error={errors.agencyCodes ? true : false}
                  />
                )}
              />
              {errors.agencyCodes && (
                <ErrorMessage>
                  <SAText
                    type="small"
                    colorVariant="alert"
                    text="At least one agency code is required."
                  />
                </ErrorMessage>
              )}
            </CardBody>
            <CardFooter>
              <SAButton
                fullWidthUnder={mobileWidth}
                label="Submit"
                type="submit"
                variant="primary-large"
                textTransform="uppercase"
              />
            </CardFooter>
          </form>
        </ContactListingCard>
      )}
      <SASnackbar
        open={showSnackbar}
        variant="snackbar-success"
        position="top-end"
        autoHideDuration={5000}
        title="Report Submitted"
        subtitle="Report generation started"
        offset={[-25, 170]}
        onClose={onSnackbarClose}
      />
      <SAPopover
        onClose={(e: MouseEvent, index: number | null) =>
          closePopover(e, index)
        }
        offset={window.innerWidth <= mobileWidth ? mobileOffset : desktopOffset}
        placement={
          document.documentElement.clientWidth <= mobileWidth
            ? mobilePosition
            : desktopPosition
        }
        referenceElement={referenceElement}
        variant={"popover-minimal-nopadding"}
      >
        <Popover>
          <HelpContainer>
            <HelpIcon>
              <SAIcon
                icon={SAIcons.information}
                size="20px"
                colorVariant="hsla(220, 38%, 97%, 1)"
              />
            </HelpIcon>
            <HelpText>
              <HelpTextContent>
                <div>
                  Your contacts will display in the report if they share one or
                  more of the selected agency codes. The report listing will
                  display each person’s associated agency codes.
                </div>
              </HelpTextContent>
            </HelpText>
          </HelpContainer>
        </Popover>
      </SAPopover>
      {showModalSpinner && <ModalSpinner />}
    </>
  );
}

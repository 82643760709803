import React, {useEffect, useRef, useState} from 'react';
import { SAButton, SAModal, SAText } from '@saux/design-system-react';
import {
  ButtonWrapper,
  ModalBody,
  SARemoveModalBody,
  StyledModalText,
  StyledRemoveModalText,
  RemovelModalText,
  RemovelModalUpperText,
  RemoveModalButtonWrapper,
  RemoveModalCanNotRemoveButtonWrapper,
  RemovelModalUpperTextBold,
  StyledRemoveModalTextDescription,
  List,
  TextArea,
  StyledRemoveContactModalText
} from '../../AgencyContactDetails/AgencyContactDetailsStyles/AgencyContactDetailsStyles';
import styled from "styled-components";

export type ContactTypeRemoveProps = {
  title?: any;
  type: string;
  action: string;
  agency_codes?: [];
  onClickClose: Function;
  onClickSave: Function;
}

const Note = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &:first-child {
      font-weight: 700;
    }
  }
`;

const StyledAgencyCodes = styled.div`
  :last-child {
    padding-botton: 20px;
  }
`;

const StyledCloseButton = styled(SAButton)`
  margin-top: 30px;
`;

export default function ModalContactTypeRemove({ title, type, action, agency_codes, onClickClose, onClickSave }: ContactTypeRemoveProps) {
  const [reason, setReason] = useState<string>('');
  let hasPurview = useRef<boolean>(false);
  let primaryMailRecipient = useRef<boolean>(false);

  let ct = type;
  let isSuccess: boolean = false;
  if (action === 'success') {
    isSuccess = true;
  }

  if (title === 'Primary Mail Recipient cannot be removed because they are protected') {
    primaryMailRecipient.current = true;
  }

  if (title.includes('partial purview')) {
    hasPurview.current = false;
  } else {
    hasPurview.current = true;
  }

  if (type === 'Agency/Office Manager') {
    ct = 'Agency Manager';
  }

  const modalOnClickHandler = (event: any) => {
    event.stopPropagation();
  }

  const getAgencyCodes = () => {
    return agency_codes?.map((item: string, i: number) => {
      return (
        <StyledAgencyCodes key={i}>
          <span>{item}</span>
        </StyledAgencyCodes>
      )
    })
  }

  const renderModal = () => (
    <ModalBody>
      {isSuccess &&
        <SARemoveModalBody variant="simple" title={`Remove Contact Type`}>
          {!primaryMailRecipient.current && (
            <>
              <StyledRemoveModalTextDescription>
                <RemovelModalUpperText
                  type="paragraph"
                  text={`Are you sure you want to remove contact type `}
                />
                <RemovelModalUpperTextBold
                  type="paragraph"
                  text={` ${ct}?`}
                />
              </StyledRemoveModalTextDescription>
              <StyledRemoveModalText>
                <RemovelModalText
                  type="small"
                  text={`Note`}
                />
                <List>
                  {type === 'Licensed Producer' &&
                    <li>
                      <RemovelModalText
                        type="small"
                        text={`State Auto appointments will be terminated since you are removing Licensed Producer.`}
                      />
                    </li>
                  }
                  <li>
                    <RemovelModalText
                      type="small"
                      text={`Removing a contact type does not terminate the agency individual.`}
                    />
                  </li>
                </List>
              </StyledRemoveModalText>
              {type === 'Licensed Producer' &&
                <TextArea>
                  <label>Reason</label>
                  <textarea
                    autoFocus
                    id="reason"
                    name="reason"
                    onFocus={(e) => {
                      if (!e.target.parentElement?.children[0].className.includes('focused')) {
                        e.target.parentElement?.children[0].classList.add('focused');
                      }
                    }}
                    onBlur={(e)=> {
                      if (e.target.parentElement?.children[0].className.includes('focused')) {
                        e.target.parentElement?.children[0].classList.remove('focused');
                      }
                    }}
                    onChange={(event: any) => {
                      const { value } = event.target;
                      setReason(value.substr(0, 255));
                    }}
                  />
                </TextArea>
              }
            </>
          )}
          <RemoveModalButtonWrapper>
            <SAButton
              label="NO"
              onClick={(e) => onClickClose(e, 'close')}
              variant="link-large"
              color="text"
            />
            <SAButton
              label="YES"
              variant="primary-large"
              onClick={(e) => {
                if (type === 'Licensed Producer') {
                  onClickSave(e, type, reason);
                } else {
                  onClickSave(e, type);
                }
              }}
            />
          </RemoveModalButtonWrapper>
        </SARemoveModalBody>
      }
      {!isSuccess &&
        <SARemoveModalBody variant="simple" title={`Contact Type Cannot be Removed`}>
          {!hasPurview.current && (
            <>
              <StyledRemoveContactModalText>
                <RemovelModalUpperText type="standard">
                  You do not have the permission to remove contact type. Contact Agency Operations at <a href="mailto:Agency.Operations@stateauto.com">Agency.Operations@stateauto.com</a> for assistance.
                </RemovelModalUpperText>
              </StyledRemoveContactModalText>
              <Note><span><b>{type}</b></span></Note>
              {getAgencyCodes()}
            </>
          )}
          {hasPurview.current && (
            <>
              <StyledRemoveContactModalText>
                <RemovelModalUpperText type="standard">
                  At least one contact must have <b>{type}</b> contact type.
                </RemovelModalUpperText>
              </StyledRemoveContactModalText>
              <StyledRemoveContactModalText>
                <RemovelModalUpperText type="standard">
                  Add this contact type to another person before removing.
                </RemovelModalUpperText>
              </StyledRemoveContactModalText>
              <Note><span><b>{type}</b></span></Note>
              {getAgencyCodes()}
            </>
          )}
          <RemoveModalCanNotRemoveButtonWrapper>
              <StyledCloseButton
                label="CLOSE"
                onClick={(e: Event) => onClickClose(e, 'close')}
                variant="primary"
                color="text"
              />
          </RemoveModalCanNotRemoveButtonWrapper>
        </SARemoveModalBody>
      }
    </ModalBody>
  )

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modalconfirmedit') as HTMLElement;
      if (modal) {
        const element = modal.querySelector('button.sa-button') as HTMLElement;
        if (element) {
          element.focus();
        }
      }
    }

    function focusModalConfirmEdit(e: any) {
      const modal = document.querySelector('#modalconfirmedit') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          const element = modal.querySelector('button.sa-button') as HTMLElement;
          if (element) {
            element.focus();
          }
        }
      }
    }

    focusDefault();
    document.addEventListener('focusin', focusModalConfirmEdit);
    document.addEventListener('focusout', focusModalConfirmEdit);

    return () => {
      document.removeEventListener('focusin', focusModalConfirmEdit);
      document.removeEventListener('focusout', focusModalConfirmEdit);
    }
  }, []);

  return (
    <SAModal id="modalconfirmedit" onClickHandler={(event: React.MouseEvent) => modalOnClickHandler(event)}>
      <div>{renderModal()}</div>
    </SAModal>
  );
};

import React, { useEffect } from "react";
import {
  SACard,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
  SASeparator,
  SAUXTheme
} from '@saux/design-system-react';
import ContactData from '../../Model/ContactModel';
import { ContactDataContactType } from '../../../interfaces/interfaces';
import styled from 'styled-components';

const mobileWidth = 600;

type LearnMoreProps = {
  onClickClose: Function;
};

type StyleProps = {
  error?: boolean;
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const LearnMore = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 700px;
      height: 544px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 45px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  button:last-child {
    display: flex;

    &:focus {
      outline: auto;
    } 
  }
`;

const ModalBody = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      overflow: auto;
      padding-right: ${theme?.size.spacer.large};

      @media only screen and (max-width: ${mobileWidth}px) {
        padding-right: ${theme?.size.spacer.medium};
      }

      div {
        &:first-child {
          h4 {
            padding-top: 0;
          }
        }
      }

      h4 {
        padding-top: calc(${theme?.size.spacer.medium} + 5px);
        padding-bottom: ${theme?.size.spacer.medium};
      }

      div {
        &.separator {
          padding-top: calc(${theme?.size.spacer.medium} + 5px);
        }
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 92px;
        border: none;
      }
    
      &::-webkit-scrollbar-track {
          background: #F0EFEF;
          border-radius: 7px;
      }
    
      &::-webkit-scrollbar-thumb {
          background: #8A8A8A;
          border-radius: 7px;
          box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      }
    `;
  }};
`;

const Panel = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      background-color: hsl(220,38%,97%);
      padding: ${theme?.size.spacer.medium};
      font-size: ${theme?.font.primary.size.smallTEMP};
    `;
  }};
`;

export default function ModalLearnMore({ onClickClose }: LearnMoreProps) {
  function handleClickAddContactModal(event: React.MouseEvent) {
    event.stopPropagation();

    // get the card element inside the modal
    const cardElement = document.querySelector('#modal > div > article > section');

    // close modal when clicking outside of the card element
    if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
      onClickClose(event);
    }
  }

  function handleClickLearnMoreModalClose(event: React.MouseEvent) {
    event.stopPropagation();
    onClickClose(event);
  }

  const sort = (a: ContactDataContactType, b: ContactDataContactType) => {
    if (a.description === b.description) {
      return 0;
    } else {
      return (a.description.toLowerCase() < b.description.toLowerCase()) ? -1 : 1;
    }
  }

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modallearnmore') as HTMLElement;
      if (modal) {
        const button = modal.querySelector('button') as HTMLElement;
        if (button) {
          button.focus();
        }
      }
    }

    function focusModalLearnMore(e: any) {
      const modal = document.querySelector('#modallearnmore') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          const element = modal.querySelector('button') as HTMLElement;
          if (element) {  
            element.focus();
          }
        }
      }
    }

    focusDefault();
    document.addEventListener('focusin', focusModalLearnMore);
    document.addEventListener('focusout', focusModalLearnMore);

    return () => {
      document.removeEventListener('focusin', focusModalLearnMore)
      document.removeEventListener('focusout', focusModalLearnMore);
    }
  }, []);

  useEffect(() =>  {
    const element = document.querySelector('#modalbody');
    element?.setAttribute('tabindex', '0');
  }, []);

  return (
    <Modal id="modallearnmore" onClickHandler={handleClickAddContactModal}>
      <LearnMore variant="minimal">
        <ModalHeader>
          <ModalTitle>
            <SAText type="heading-2" text="Contact Types" />
            <SAIcon className="closemodal" icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" onClick={handleClickLearnMoreModalClose} />
          </ModalTitle>
        </ModalHeader>
        <ModalBody id="modalbody">
          {ContactData.contacttype.sort(sort).map((ct: ContactDataContactType, index: number) => {
            return (
              <div key={`container_${ct.contacttype}`}>
                <SAText type="heading-4" text={ct.description} />
                <Panel>
                  <p>{ct.definition}</p>
                </Panel>
                {(index + 1) < ContactData.contacttype.length &&
                  <SASeparator horizontal />
                }
              </div>
            );
          })}
        </ModalBody>
      </LearnMore>
    </Modal>
  );
}

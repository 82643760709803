import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import {
  SACard,
  SAText,
  SAButton,
  SAAccordion,
  SACheckbox,
  SATag,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAPopover,
  SASearch,
  SAUXTheme
} from '@saux/design-system-react';
import { NewContact, NewContactContactType, NewContactAgencyCode, AgencyCode, Constants, AppGenPayloadAdd } from '../../interfaces/interfaces';
import styled from 'styled-components';
import ModalApplicationGenerated from '../../components/modal/ModalApplicationGenerated/ModalApplicationGenerated';
import ContactDetailsSideCard from '../ContactDetailsSideCard/ContactDetailsSideCard';
import ModalCancelAlert from '../../components/modal/ModalCancelAlert/ModalCancelAlert';
import { encrypt, decrypt } from '../../utils/crypto';
import { constants } from '../../utils/constants';
import { LicenseApplicationRequested } from '../Model/LicenseApplicationRequested';
import ModalEmailAlreadyUsedForLP from '../modal/ModalEmailAlreadyUsedForLP/ModalEmailAlreadyUsedForLP';

const mobileWidth = 600;

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
  isEmployee?: boolean;
};

type Position = 'top-start' | 'top' | 'top-end' | 'right' | 'bottom-start' | 'bottom' | 'bottom-end' | 'left';

type Offset = [number, number];

const Container = styled.div.attrs(({ constants, isEmployee }: StyleProps) => ({
  constants,
  isEmployee
}))`
  ${({ theme, constants, isEmployee }: StyleProps) => {
    const con: Constants = constants as Constants;
    const marginTop: number = isEmployee ? con.headerHeight + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeight + con.dockedStepperHeight + con.verticalPageOffset;
    const marginTopMobile: number = isEmployee ? con.headerHeightMobile + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeightMobile + con.dockedStepperHeight + con.verticalPageOffset;

    return `
      display: flex;
      flex-direction: row;
      max-width: 1200px;
      margin: ${marginTop}px ${theme?.size.spacer.medium} 60px ${theme?.size.spacer.medium};
    
      @media only screen and (max-width: ${mobileWidth}px) {
        margin: ${marginTopMobile}px ${theme?.size.spacer.medium} 0 ${theme?.size.spacer.medium};
      }
    
      article:first-child {
        flex-grow: 1;
      }
    
      article:last-child {
        margin-left: ${theme?.size.spacer.large};
        width: 300px;
        flex-shrink: 0;
      }
    `;
  }};
`;

const CardHeader = styled.div`
  padding-bottom: 45px;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 30px;

  .closemodal {
    display: none;
  }

  button {
    margin-left: 15px;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

const CardSubTitle = styled.div``;

const CardBody = styled.div``;

const CardFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 60px;

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
      }

      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      div {
        &:last-child {
          display: flex;
          flex-direction: row;

          button {
            margin-left: ${theme?.size.spacer.medium};

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              font-weight: ${theme?.font.primary.weight.bold};
            }
          }

          @media only screen and (max-width: ${mobileWidth}px) {
            button {
              &:first-child {
                display: none;
              }
            }
          }
        }

        @media only screen and (max-width: ${mobileWidth}px) {
          flex-grow: 1;
        }
      }
    `;
  }};
`;

const CancelButton = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: none;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: ${theme?.size.spacer.medium} ${theme?.size.spacer.medium};
      }
    `;
  }};
`;

const AccordionContent = styled.div``;

const ContinueButton = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      justify-content: flex-end;
      margin: calc(${theme?.size.spacer.medium} + 5px) 0;
    `;
  }};
`;

const AgencyCodesContainer = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      border: 1px solid #D8DFEC;

      span {
        &.heading {
          display: flex;
          padding: ${theme?.size.spacer.small} calc(${theme?.size.spacer.medium} + 5px);
          margin: ${theme?.size.spacer.medium} ${theme?.size.spacer.small} 0 ${theme?.size.spacer.small};
          font-weight: ${theme?.font.primary.weight.bold};
          background-color: hsl(220,38%,97%);

          @media only screen and (max-width: ${mobileWidth}px) {
            margin: 0;
          }
        }
      }
    `;
  }};
`;

const AgencyCodesCheckboxes = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      margin: ${theme?.size.spacer.small};
      max-height: 270px;
      overflow-y: auto;

      @media only screen and (max-width: ${mobileWidth}px) {
        margin: ${theme?.size.spacer.small} 0 ${theme?.size.spacer.small} 0;
      }

      .saCheckboxWrapper {
        display: flex;
        align-items: center;
        margin: 10px 0;
        height: 40px;
        padding: 0 15px;
        color: ${theme?.colors.text};
        z-index: 0;

        &.hidden {
          display: none;
        }

        input {
          &:hover {
            cursor: pointer;
          }
        }

        label {
          pointer-events: none;
        }

        input {
          pointer-events: none;
        }

        label {
          &:hover {
            cursor: pointer;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 92px;
        border: none;
      }
    
      &::-webkit-scrollbar-track {
          background: #F0EFEF;
          border-radius: 7px;
      }
    
      &::-webkit-scrollbar-thumb {
          background: #8A8A8A;
          border-radius: 7px;
          box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      }
    `;
  }};
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: ${mobileWidth}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Search = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;

      .saInputWrapper {
        margin: 0 0 ${theme?.size.spacer.medium} 0;
      }

      .textLikeInput {
        padding-top: 0;
        width: initial;
        font-size: ${theme?.font.primary.size.medium};
      }

      ul {
        align-items: center;
        span {
          button {
            margin-bottom: 0;
          }
        }
        button {
          margin-bottom: 5px;
        }
      }
    `;
  }};
`;

const SelectAllButton = styled(SAButton)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-shrink: 0;
      margin: 0 0 ${theme?.size.spacer.medium} ${theme?.size.spacer.medium};

      @media only screen and (max-width: ${mobileWidth}px) {
        margin: 0 0 ${theme?.size.spacer.medium} 0;
      }
    `;
  }};
`;

const Popover = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      color: hsla(220, 38%, 97%, 1);
      font-size: ${theme?.font.primary.size.smallTEMP};
      font-weight: ${theme?.font.primary.weight.normal};
      line-height: 16px;
      background-color: hsla(213, 30%, 35%, 1);
      border: 1px solid hsla(213, 30%, 35%, 1);
      box-sizing: border-box;
      box-shadow: 0px 0px 5px rgba(0, 32, 56, 0.22);
      border-radius: 4px;
      padding: 15px;
      width: 360px;
    `;
  }};
`;

const HelpContainer = styled.div`
  display: flex;
  flex-directino: column;
`;

const HelpIcon = styled.div`
  display: flex;
  padding-right: 10px;
  align-items: flex-start;
`;

const HelpText = styled.div`
  display: flex;
  align-items: flex-start;
`;

export default function AgencyCodes(props: any) {
  const adminPersonId = decrypt(localStorage.getItem('personId'));
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [formData, setFormData] = useState<any>(null);
  const onSubmit = (data: any) => setFormData(data);
  const [displayEmailAlreadyUsedModal, setDisplayEmailAlreadyUsedModal] = useState<LicenseApplicationRequested | null>(null);
  const history = useHistory();
  
  // Agency Admin - 3
  // Agency Staff - 7
  // Agency Manager - 10
  // Primary Claim Contact - 13
  // Primary Mail Recipient - 14
  // Accounting Manager - 36
  // Licensed Producer - 37
  const agencyBasedContactTypes: number[] = [3, 7, 10,  13, 14, 36, 37];

  const [newContact, setNewContact] = useState<NewContact>(JSON.parse(decrypt(localStorage.getItem('NewContact'))));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [displayedContactTypes, setDisplayedContactTypes] = useState<number[]>(getDisplayedContactTypes());
  const [completed, setCompleted] = useState<number[]>([]);
  const [disabled, setDisabled] = useState<number[]>([]);
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeAgencyCodes, setActiveAgencyCodes] = useState<AgencyCode[]>(getActiveAgencyCodes());
  const [mobile, setMobile] = useState<boolean>(true);
  const [showContinueButtons, setShowContinueButtons] = useState<number[]>([]);
  const [expanded, setExpanded] = useState<number | Boolean>(false);
  const [searchTerms, setSearchTerms] = useState<string[][]>([]);
  const [path, setPath] = useState<string>('');
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const desktopPosition: Position = 'right';
  const mobilePosition: Position = 'top-end';
  const desktopOffset: Offset = [0, 10];
  const mobileOffset: Offset = [0, 10];
  const [position, setPosition] = useState<Position>(
    document.documentElement.clientWidth <= mobileWidth ? mobilePosition : desktopPosition,
  );
  const [offset, setOffset] = useState<Offset>(
    window.innerWidth <= mobileWidth ? mobileOffset : desktopOffset,
  );
  const [disabledCheckbox, setDisabledCheckbox] = useState<boolean>(false);
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;
  const [selectAll, setSelectAll] = useState<boolean>(true);

  // App Generated Modal
  const [payload, setPayload] = useState<AppGenPayloadAdd | null>(null);
  const [showAppGenModalSpinner, setShowAppGenModalSpinner] = useState<boolean>(false);

  // Modals
  const [displayApplicationGeneratedModal, setDisplayApplicationGeneratedModal] = useState<boolean>(false);
  const [displayCancelAlert, setDisplayCancelAlert] = useState<boolean>(false);

  function sortByAgencyCode(a: AgencyCode, b: AgencyCode) {
    if (a.agency_code === b.agency_code) {
      return 0;
    } else {
      return (a.agency_code < b.agency_code) ? -1 : 1;
    }
  }

  function getActiveAgencyCodes() {
    const agencyCodesArray: AgencyCode[] = JSON.parse(decrypt(localStorage.getItem('AddContactAgencyCodes')));
    agencyCodesArray.sort(sortByAgencyCode);
    return agencyCodesArray;
  }
  
  function handleClickNext(event : React.MouseEvent) {
    // if all panels completed then save selections to session and navigate to next page
    if (displayedContactTypes.length === completed.length) {
      // save selections to session
      const data: any = getValues('checkboxes');
      const ncAgencyCodeArray: NewContactAgencyCode[] = [];
      displayedContactTypes.forEach((ct: number) => {
        
        activeAgencyCodes.forEach((agencyCode: AgencyCode) => {
          const checked: boolean = activeAgencyCodes.length === 1 ? true : data[`contacttype_${ct.toString()}`][`agencycode_${agencyCode.agency_code}`];
          if (checked) {
            const ncAgencyCode: NewContactAgencyCode = {
              contacttype: ct,
              producer_number: agencyCode.agency_code
            };
            ncAgencyCodeArray.push(ncAgencyCode);
          }
        });
      });

      // Reset primary agency code selection if the saved primary agency code is not among the selected agency codes
      let priorSelectionFound: boolean = false;
      ncAgencyCodeArray.forEach((obj) => {
        if (obj.producer_number === newContact.contactDetails.primaryAgencyCode) {
          priorSelectionFound = true;
        }
      });
      if (!priorSelectionFound) {
        newContact.contactDetails.primaryAgencyCode = '';
      }

      newContact.contactDetails.agencyCodes = ncAgencyCodeArray;

      setNewContact(newContact);
      localStorage.setItem('NewContact', encrypt(JSON.stringify(newContact)));

      // Check if only Licensed Producer is selected
      if (newContact.contactDetails.contactTypes.length === 1 && newContact.contactDetails.contactTypes[0].contacttype === 37) {
        const agencyCodes: string[] = [];
        newContact.contactDetails.agencyCodes.forEach((ct: NewContactAgencyCode) => {
          if (ct.contacttype === 37) {
            agencyCodes.push(ct.producer_number);
          }
        });

        const appGenPayload: AppGenPayloadAdd = {
          agency_admin_person_id: Number(adminPersonId),
          producer_first_name: newContact.contactDetails.generalDetails.firstName,
          producer_last_name: newContact.contactDetails.generalDetails.lastName,
          agency_codes: agencyCodes.join(','),
          new_contact: 'yes',
          producer_email: newContact.contactDetails.email
        };

        setPayload(appGenPayload);
        setDisplayApplicationGeneratedModal(!displayApplicationGeneratedModal);
      } else {
        history.push({
          pathname: '/primary-agency-code'
        });
      }
    }
  }

  function getDisplayedContactTypes () {
    const displayed: number[] = [];
    newContact.contactDetails.contactTypes.forEach((ct: NewContactContactType) => {
      if (agencyBasedContactTypes.includes(ct.contacttype)) {
        displayed.push(ct.contacttype);
      }
    });
    return displayed;
  }

  const showComplete = (contactType: number) => {
    if (completed.includes(contactType)) {
      if (mobile) {
        return <SAIcon key={`complete_${contactType.toString()}`} icon={SAIcons.success} size={`calc(${SAIconSize.small} + 5px)`} colorVariant="dark" />;
      } else {
        return <SATag key={`complete_${contactType.toString()}`} variant="standard-outline" label="Completed" />;
      }
    }
  }

  function openPopover(event: React.MouseEvent<HTMLElement>) {
    setReferenceElement(event.currentTarget);
  }

  function closePopover(event: React.MouseEvent<HTMLElement> | MouseEvent, index: number | null) {
    setReferenceElement(null);
  }

  function togglePopover(event: React.MouseEvent<HTMLElement>) {
    if (referenceElement === null) {
      setReferenceElement(event.currentTarget.firstChild as HTMLElement);
    } else {
      setReferenceElement(null);
    }
  }

  function handleKeyDown(e: any) {
    if (e.key === 'Tab') {
      setReferenceElement(null);
    }
  }

  const selectAllDeselectAllButtonState = (contactType: number) => {
    let selectAllButtonState = false;
    activeAgencyCodes.forEach((agencyCode: AgencyCode) => {
      const element = document.getElementById(`contacttype_${contactType.toString()}_agencycode_${agencyCode.agency_code}`);
      if (element !== null) {
        const parentElement = element.parentElement as HTMLElement;
        const parentOfParent = parentElement.parentElement as HTMLElement;
        if (!parentOfParent.classList.contains('hidden')) {
          const checked: boolean = getValues(`checkboxes.contacttype_${contactType.toString()}.agencycode_${agencyCode.agency_code}`);
          if (!checked) {
            selectAllButtonState = true;
          }
        }
      }
    });
    return selectAllButtonState;
  }

  const handleChange = (contactType: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>, isExpanded: boolean) => {
    setExpanded(isExpanded ? contactType : false);
    setSelectAll(selectAllDeselectAllButtonState(contactType));
  };

  function handleClickApplicationGeneratedModalCancel(event: React.MouseEvent) {
    setDisplayApplicationGeneratedModal(!displayApplicationGeneratedModal);
  }

  function handleClickSendEmail() {
    setShowAppGenModalSpinner(true);
  }

  function handleEmailSent() {
    setShowAppGenModalSpinner(false);
  }

  function handleClickApplicationGeneratedModalClose(event: React.MouseEvent) {
    setDisplayApplicationGeneratedModal(!displayApplicationGeneratedModal);
    setPath('/agency-contacts');
  }

  function handleClickEmailAlreadyExistsClose(event: React.MouseEvent) {
    setDisplayEmailAlreadyUsedModal(null);
    setPath('/agency-contacts');
  }

  function handleShowEmailAlreadyExists(data: LicenseApplicationRequested) {
    setDisplayApplicationGeneratedModal(!displayApplicationGeneratedModal);
    setDisplayEmailAlreadyUsedModal(data);
  }

  function handleClickCancel(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleClickCancelAlertModalLeave(event: React.MouseEvent) {
    history.push({
      pathname: `/agency-contacts`
    });
  }

  function handleClickCancelAlertModalStay(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleClickPrevious(event : React.MouseEvent) {
    history.push({
      pathname: '/general-details'
    });
  }

  function handleClickContinue(contactType: number) {
    // clear search terms for current panel
    handleSearch([], contactType);

    // set current panel to completed
    const newCompleted: number[] = [];
    if (!completed.includes(contactType)) {
      completed.forEach((contactType: number) => {
        newCompleted.push(contactType);
      });
      newCompleted.push(contactType);
      setCompleted(newCompleted);
      if (displayedContactTypes.length === newCompleted.length) {
        setExpanded(false);
        return;
      }
    }

    // expand the next panel that is not completed
    if (displayedContactTypes.length !== completed.length) {
      if (typeof expanded === 'number') {
        // get index of current expanded panel
        const activeIndex: number = displayedContactTypes.indexOf(expanded);
        let done: boolean = false;
        displayedContactTypes.forEach((contactType: number, index: number) => {
          if (done) {
            return;
          }
          if (index > activeIndex) {
            if (!completed.includes(contactType)) {
              // enable panel if disabled
              if (disabled.includes(contactType)) {
                const newDisabled: number[] = [];
                disabled.forEach((disabledContactType: number, disabledIndex: number) => {
                  if (contactType !== disabledContactType) {
                    newDisabled.push(disabledContactType);
                  }
                });
                setDisabled(newDisabled);
              }

              // expand panel
              setSelectAll(selectAllDeselectAllButtonState(contactType));
              setExpanded(contactType);
              done = true;
            }
          }
        });
        if (!done) {
          displayedContactTypes.forEach((contactType: number, index: number) => {
            if (done) {
              return;
            }
            if (index < activeIndex) {
              if (!completed.includes(contactType)) {
                // expand panel
                setSelectAll(selectAllDeselectAllButtonState(contactType));
                setExpanded(contactType);
                done = true;
              }
            }
          });
        }
      }
    } else {
      setExpanded(false);
    }
  }

  function handleSearch(search: Array<string> | string, contactType: number) {
    // The callback function you provide in the onSearch prop will receive the current array
    // of search terms when a new search term is added or removed from the array.
    if (typeof search !== 'string') {
      const elements: HTMLElement[] = [];
      activeAgencyCodes.forEach((agencyCode: AgencyCode) => {
        const element: HTMLElement | null = document.querySelector(`label[for="contacttype_${contactType.toString()}_agencycode_${agencyCode.agency_code}"]`);
        if (element !== null) {
          elements.push(element);
        }
      });

      const filteredElements = elements.filter((element: HTMLElement) => {
        let containsAllSearchTerms: boolean = true;
        search.forEach((searchTerm) => {
          let containsSearchTerm: boolean = false;
          if (element.innerText.toLowerCase().includes(searchTerm.toLowerCase())) {
            containsSearchTerm = true;
          };
          if (!containsSearchTerm) {
            containsAllSearchTerms = false;
          }
        });

        if (containsAllSearchTerms) {
          return true;
        } else {
          return false;
        }
      });

      elements.forEach((element: HTMLElement, index: number) => {
        const parentElement = element.parentElement as HTMLElement;
        if (filteredElements.includes(element)) {
          parentElement.classList.remove('hidden');
        } else {
          parentElement.classList.add('hidden');
        }
      });

      // save search terms
      const newSearchTerms: string[][] = [];
      displayedContactTypes.forEach((ct: number) => {
        newSearchTerms[ct] = searchTerms[ct] ? searchTerms[ct] : [];
        if (ct === contactType) {
          newSearchTerms[contactType] = search;
        }
      });
      setSearchTerms(newSearchTerms);

      handleSubmit(onSubmit)();
    }
  }

  function handleClickSelectAll(event: React.MouseEvent, action: string, contactType: number) {
    activeAgencyCodes.forEach((agencyCode: AgencyCode) => {
      const element = document.getElementById(`contacttype_${contactType.toString()}_agencycode_${agencyCode.agency_code}`);
      if (element !== null) {
        const parentElement = element.parentElement as HTMLElement;
        const parentOfParent = parentElement.parentElement as HTMLElement;
        if (!parentOfParent.classList.contains('hidden')) {
          const checked: boolean = getValues(`checkboxes.contacttype_${contactType.toString()}.agencycode_${agencyCode.agency_code}`);
          if (action === 'select') {
            if (!checked) {
              element.click();
            }
          } else {
            if (checked) {
              element.click();
            }
          }
        }
      }
    });
    setSelectAll(!selectAll);
    handleSubmit(onSubmit)();
  }

  const accordion = () => {
    const accordions = newContact.contactDetails.contactTypes.map((ct: NewContactContactType) => {
      if (agencyBasedContactTypes.includes(ct.contacttype)) {
        return (
          <SAAccordion
            key={`accordion_${ct.contacttype}`}
            id={`accordion_${ct.contacttype}`}
            expanded={expanded === ct.contacttype}
            title={ct.description}
            textTransform="uppercase"
            onChange={handleChange(ct.contacttype)}
            buttons={showComplete(ct.contacttype)}
            variant="standard"
            disabled={disabled.includes(ct.contacttype)}
          >
            {accordionContent(ct.contacttype)}
          </SAAccordion>
        );
      } else {
        return false;
      }
    });
    return accordions;
  };

  const accordionContent = (contactType: number) => {
    return (
      <AccordionContent>
        <SearchContainer>
          <Search>
            <SASearch
              values={searchTerms[contactType]}
              fullWidth
              id={`contacttype_${contactType}_searchbar`}
              placeholder="Search by agency code, agency name, city, state, or zip"
              onSearch={(search: Array<string> | string) => handleSearch(search, contactType)}
              variant="search-terms"
            />
          </Search>
          {selectAll ? (
            <SelectAllButton
              label="Select All"
              onClick={(event: React.MouseEvent) => handleClickSelectAll(event, 'select', contactType)}
              textTransform="uppercase"
              variant="link-medium"
              color="accentBlue"
            />
          ) : (
            <SelectAllButton
              label="Deselect All"
              onClick={(event: React.MouseEvent) => handleClickSelectAll(event, 'deselect', contactType)}
              textTransform="uppercase"
              variant="link-medium"
              color="accentBlue"
            />
          )}
        </SearchContainer>
        <AgencyCodesContainer>
          <span className="heading">Agency Codes</span>
          <AgencyCodesCheckboxes>
            {agencyCodes(contactType)}
          </AgencyCodesCheckboxes>
        </AgencyCodesContainer>
        <ContinueButton>
          {showContinueButtons.includes(contactType) &&
            <SAButton
              key={`button_continue_${contactType}`}
              label="Continue"
              onClick={(e) => handleClickContinue(contactType)}
              textTransform="uppercase"
              variant="secondary-medium"
              fullWidthUnder={mobileWidth}
            />
          }
        </ContinueButton>
      </AccordionContent>
    );
  };

  const agencyCodes = (contactType: number) => {
    return (
      <>
        {activeAgencyCodes.map((agencyCode: AgencyCode) => 
          <SACheckbox
            {...register(`checkboxes.contacttype_${contactType.toString()}.agencycode_${agencyCode.agency_code}`)}
            key={`contacttype_${contactType.toString()}_agencycode_${agencyCode.agency_code}`}
            id={`contacttype_${contactType.toString()}_agencycode_${agencyCode.agency_code}`}
            label={`${agencyCode.agency_code} - ${agencyCode.dba !== '' ? agencyCode.dba : agencyCode.agency_name}, ${agencyCode.agency_city}, ${agencyCode.agency_state} ${agencyCode.agency_zip.substr(0, 5)}`}
            type="checkbox"
            defaultChecked={newContact.contactDetails.agencyCodes.includes({ contacttype: contactType, producer_number: agencyCode.agency_code}) ? true : false}
            disabled={disabledCheckbox}
            onChange={(e: any) => {
              setValue(`checkboxes.contacttype_${contactType.toString()}.agencycode_${agencyCode.agency_code}`, e.target.checked);
              handleSubmit(onSubmit)();
            }}
            onKeyDown={(e: any) => {
              if (e.key === ' ') {
                e.preventDefault();
                const element: HTMLElement | null = document.querySelector(`input[id="contacttype_${contactType.toString()}_agencycode_${agencyCode.agency_code}"]`);
                if (element !== null) {
                  element.click();
                }
              }
            }}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    if (!document.getElementById('modal')) {
      const modalRoot: HTMLDivElement = document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      document.body.append(modalRoot);
    }
  }, []);

  useEffect(() => {
    if (displayCancelAlert || displayApplicationGeneratedModal) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [displayCancelAlert, displayApplicationGeneratedModal]);

  useEffect(() => {
    if (displayedContactTypes.length === completed.length) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  useEffect(() => {
    function updatePosition() {
      if (window.innerWidth <= mobileWidth) {
        setMobile(true);
        setPosition(mobilePosition);
        setOffset(mobileOffset);
      } else {
        setMobile(false);
        setPosition(desktopPosition);
        setOffset(desktopOffset);
      }
    }
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // restore prior state when returning to Agency Code page

    // remove any prior contact types which are no longer selected on the contact type page
    const newAgencyCodes: NewContactAgencyCode[] = [];
    newContact.contactDetails.agencyCodes.forEach((ac: NewContactAgencyCode) => {
      if (displayedContactTypes.includes(ac.contacttype)) {
        newAgencyCodes.push(ac);
      }
    });
    newContact.contactDetails.agencyCodes = newAgencyCodes;

    if (newContact.contactDetails.agencyCodes.length) {
      // let start by setting all displayed contact types to be disabled and enable them as needed
      const newDisabled: number[] = [];
      displayedContactTypes.forEach((ct: number) => {
        newDisabled.push(ct);
      });

      const newShowContinueButtons: number[] = [];
      const newCompleted: number[] = [];

      displayedContactTypes.forEach((ct: number) => {
        activeAgencyCodes.forEach((ac: AgencyCode) => {
          newContact.contactDetails.agencyCodes.forEach((ncAc: NewContactAgencyCode) => {
            if (ct === ncAc.contacttype && ac.agency_code === ncAc.producer_number) {
              // restore prior selections
              const element: HTMLElement | null = document.querySelector(`input[id="contacttype_${ncAc.contacttype.toString()}_agencycode_${ncAc.producer_number}"]:not([disabled])`);
              if (element !== null) {
                element.click();
              }
            }

            // enable prior contact type panels
            if (newDisabled.includes(ncAc.contacttype)) {
              const index: number = newDisabled.indexOf(ncAc.contacttype);
              newDisabled.splice(index, 1);
            }

            // display continue button
            if (!newShowContinueButtons.includes(ncAc.contacttype)) {
              newShowContinueButtons.push(ncAc.contacttype);
            }

            // set panel to completed
            if (!newCompleted.includes(ncAc.contacttype)) {
              newCompleted.push(ncAc.contacttype);
            }
          });
        });
      });

      setShowContinueButtons(newShowContinueButtons);
      setCompleted(newCompleted);

      // enable first of remaining disabled panels
      if (newDisabled.length) {
        newDisabled.splice(0, 1);
      }
      setDisabled(newDisabled);

      // set default expanded panel
      let newExpanded: number | boolean = false;
      displayedContactTypes.forEach((ct: number) => {
        if (!newDisabled.includes(ct) && !newCompleted.includes(ct) && typeof newExpanded === 'boolean') {
          newExpanded = ct;
        }
      });
      setExpanded(newExpanded);
    } else {
      // enable default panel
      const newDisabled: number[] = [];
      displayedContactTypes.forEach((contactType: number) => {
        if (!completed.includes(contactType)) {
          newDisabled.push(contactType);
        }
      });
      newDisabled.splice(0, 1);
      setDisabled(newDisabled);

      // set default expanded panel
      let newExpanded: number | boolean = false;
      displayedContactTypes.forEach((ct: number) => {
        if (!newDisabled.includes(ct) && !completed.includes(ct)) {
          newExpanded = ct;
          return;
        }
      });
      setExpanded(newExpanded);
    }

    // if there is only one active agency code per content type available we default it to be selected and show continue button
    if (activeAgencyCodes.length === 1) {
      const newShowContinueButtons: number[] = [];
      displayedContactTypes.forEach((ct: number) => {
        newShowContinueButtons.push(ct);
        activeAgencyCodes.forEach((ac: AgencyCode) => {
          const element: HTMLElement | null = document.querySelector(`input[id="contacttype_${ct.toString()}_agencycode_${ac.agency_code}"]`);
          if (element !== null) {
            element.click();
            setDisabledCheckbox(true);
          }
        });
      });
      setShowContinueButtons(newShowContinueButtons);
      handleSubmit(onSubmit)();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleClickCheckboxContainer(contactType: number, agencyCode: AgencyCode) {
      const element: HTMLElement | null = document.querySelector(`input[id="contacttype_${contactType}_agencycode_${agencyCode.agency_code}"]:not([disabled])`);
      if (element !== null) {
        element.click();
        setSelectAll(selectAllDeselectAllButtonState(contactType));
      }
    }

    displayedContactTypes.forEach((contactType: number) => {
      activeAgencyCodes.forEach((agencyCode: AgencyCode) => {
        const element: HTMLElement | null = document.querySelector(`input[id="contacttype_${contactType}_agencycode_${agencyCode.agency_code}"]`);
        if (element !== null) {
          const parentElement = element.parentElement?.parentElement as HTMLElement;
          parentElement.onclick = (e: Event) => {
            handleClickCheckboxContainer(contactType, agencyCode);
          };
        }
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set default focus
    const element = document.querySelector('input[type=checkbox]') as HTMLElement;
    if (element) {
      setTimeout(() => {
        element.focus();
      });
    }
  }, []);

  useEffect(() => {
    function validateForm(data: any) {
      const newShowContinueButtons: number[] = [];
      showContinueButtons.forEach((ct: number) => {
        newShowContinueButtons.push(ct);
      });

      const newCompleted: number[] = [];
      completed.forEach((ct: number) => {
        newCompleted.push(ct);
      });
  
      displayedContactTypes.forEach((contactType: number) => {
        if (!disabled.includes(contactType)) {
          let panelValid: boolean = false;
          activeAgencyCodes.forEach((agencyCode: AgencyCode) => {
            const element: any | null = document.querySelector(`input[id="contacttype_${contactType.toString()}_agencycode_${agencyCode.agency_code}"]`);
            if (element !== null && element.checked) {
              panelValid = true;
            }
          });

          if (panelValid) {
            if (!newShowContinueButtons.includes(contactType)) {
              newShowContinueButtons.push(contactType);
              setShowContinueButtons(newShowContinueButtons);
            }
          } else {
            // hide continue button for the panel
            if (newShowContinueButtons.includes(contactType)) {
              const index = newShowContinueButtons.indexOf(contactType);
              newShowContinueButtons.splice(index, 1);
              setShowContinueButtons(newShowContinueButtons);
            }
  
            // remove completed status for the panel
            if (newCompleted.includes(contactType)) {
              const index = newCompleted.indexOf(contactType);
              newCompleted.splice(index, 1);
              setCompleted(newCompleted);
            }
          }
        }
      });
    }

    if (formData !== null) {
      validateForm(formData);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (!displayApplicationGeneratedModal && path !== '') {
      history.push({
        pathname: path
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayApplicationGeneratedModal, path]);

  useEffect(() => {
    function handleScroll() {
      setReferenceElement(null);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Container constants={constants} isEmployee={isEmployee}>
        <SACard variant="minimal">
          <form id="agencycodesform" onSubmit={handleSubmit(onSubmit)}>
            <CardHeader>
              <CardTitle>
                <SAText type="heading-2" text="Agency Codes" />
                <SAButton
                  fullWidth={false}
                  variant="link-small"
                  label="Help Text"
                  onClick={togglePopover}
                  onKeyDown={handleKeyDown}
                >
                  <SAIcon icon={SAIcons.information} size="20px" colorVariant="primary" onMouseOver={openPopover} onMouseLeave={closePopover} />
                </SAButton>
              </CardTitle>
              <CardSubTitle>
                <SAText type="standard" text="Select all the associated agency codes." />
              </CardSubTitle>
            </CardHeader>
            <CardBody>
              {accordion()}
            </CardBody>
            <CardFooter>
              <div>
                <SAButton fullWidthUnder={mobileWidth} label="Previous" onClick={handleClickPrevious} variant="link-large" color="text" textTransform="uppercase" />
              </div>
              <div>
                <SAButton label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
                <SAButton fullWidthUnder={mobileWidth} label="Next" onClick={handleClickNext} variant="primary-large" textTransform="uppercase" disabled={nextButtonDisabled} />
              </div>
            </CardFooter>
          </form>
        </SACard>
        <ContactDetailsSideCard />
      </Container>
      <CancelButton>
        <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
      </CancelButton>
      <SAPopover
        onClose={(e: MouseEvent, index: number | null) => closePopover(e, index)}
        offset={offset}
        placement={position}
        referenceElement={referenceElement}
        variant={mobile ? 'popover-minimal-nopadding' : 'popover-menu-no-click'}
      >
        <Popover>
          <HelpContainer>
            <HelpIcon>
              <SAIcon icon={SAIcons.information} size="20px" colorVariant="hsla(220, 38%, 97%, 1)" />
            </HelpIcon>
            <HelpText>
              Agency code assignment provides the individual with access to policy and account information associated with the selected agency codes. This also helps State Auto know who to contact by agency code. (ex. Primary Claim Contact)
            </HelpText>
          </HelpContainer>
        </Popover>
      </SAPopover>
      {displayApplicationGeneratedModal &&
        <ModalApplicationGenerated
          workflow="add contact"
          type="add"
          onClickCancel={handleClickApplicationGeneratedModalCancel}
          onClickSendEmail={handleClickSendEmail}
          onEmailSent={handleEmailSent}
          onClickClose={handleClickApplicationGeneratedModalClose}
          displaySpinner={showAppGenModalSpinner}
          payload={payload}
          showLPAlreadyRequested={handleShowEmailAlreadyExists}
        />
      }
      {displayEmailAlreadyUsedModal &&
        <ModalEmailAlreadyUsedForLP
          adminName={displayEmailAlreadyUsedModal.adminName}
          email={newContact.contactDetails.email}
          requestedOn={displayEmailAlreadyUsedModal.requestedOn}
          onClickClose={handleClickEmailAlreadyExistsClose}
        />
      }
      {displayCancelAlert &&
        <ModalCancelAlert onClickLeave={handleClickCancelAlertModalLeave} onClickStay={handleClickCancelAlertModalStay} />
      }
    </>
  );
}

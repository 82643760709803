import React, {useEffect, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAInput,
  SAPopover,
  SAText,
  SAUXTheme,
  SASnackbar,
  SASpinner,
  SAUX360Theme
} from '@saux/design-system-react';
import env from '../../../env_config';
import validator from 'validator';
import styled from 'styled-components';
import normalizePhoneNumber, { checkAuthStatus } from '../../../utils/utils';
import ConfirmEditModal from '../../ConfirmEditModal';
import {
  StyledCancelButton,
  StyledInput,
  ProfileSpinnerContainer,
  DisabledContinueButton,
  Popover,
  HelpContainer,
  StyledError,
  styles
} from '../AgencyContactDetailsStyles/AgencyContactDetailsStyles';
import { emailValidation } from '../../../utils';
import { encrypt, decrypt } from '../../../utils/crypto';
import Select from 'react-select';
import {useHistory} from "react-router-dom";
import ModalPendingEdits from '../../modal/ModalPendingEdits/ModalPendingEdits';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { UpdatePendingPersonGeneralDetailsMutation } from '../../../API';
import handleGraphQLErrors from '../../../utils/handleGraphQLErrors';

type StyleProps = {
  display?: string;
  err?: boolean;
  error?: boolean;
  theme?: SAUXTheme;
  className?: string;
};

const mobileWidth = 600;

type Person = {
  email_address: string | null;
  first_name: string;
  last_name: string;
  primary_agency_code: string | null;
  primary_phone: string | null;
};

const Container = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;

      .textLikeInput {
        font-size: 16px;
      }

      .textLikeInputWrapper {
        label {
          z-index: 0;
        }

        legend {
          span {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      .saInputLabel {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      @media only screen and (max-width: 600px) {
        margin: 0;
        max-width: 100%;
        width: 100%;
      }
    `;
  }};
`;

const StyledPrimaryAgencyCodeError = styled.span`
  ${({theme}: StyleProps) => {
  return `
        color: ${theme?.color.alert.background};
        display: block;
        font-size: 12px;
        padding: 5px 0 0 15px;
        top: -12px;
        position: relative;
        width: 210px;
    `;
}};
`;

const StyledLabel: any = styled.div.attrs(({err, className}: StyleProps) => ({
  err,
  className,
}))`
  ${({err, theme, className}: StyleProps) => {
  let color = '#';
  if (className === 'focused') {
    color = err ? `${theme?.color.alert.background}` : `${theme?.colors.primary}`;
  } else {
    color = err ? `${theme?.color.alert.background}` : '#666666';
  }

  return `
      color: ${color};
      background-color: white;
      font-size: 12px;
      font-weight: ${theme?.font.primary.weight.normal};
      left: 0;
      padding-top: inherit;
      padding-left: 5px;
      padding-right: 5px;
      position: relative;
      top: -48px;
      transform: translate(calc(15px - 5px), -8px);
      visibility: visible;
      width: 125px;

      &.focused {
        color: ${color};
      }
    `;
}};
`;

const StyledSAButton = styled(SAButton)`
  display: flex;
`;

const Header = styled(Container)`
    ${({ theme }: StyleProps) => {
        return `
        display: flex;
          justify-content: space-between;
          flex-direction: row;
          color: ${theme?.colors.accentBlue};
          @media only screen and (max-width: 600px) {
          margin: 0;
        }
       `;
    }};
`;

const StyledHeadings = styled(SAText)`
  font-weight: 700;
  font-size: 14px;
`;

const StyledHeader = styled(SAText)`
    ${({ theme }: StyleProps) => {
    return `
        color: ${theme?.colors.primary};
    `;
}};
`;

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
    
    div:first-child {
      padding-top: 0px;
    }
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
`;

const HelpTextIconButton = styled(SAButton)`
  padding: 10px;

  &:hover {
    background-color: transparent;
  }
`;

const ContentEditLine2 = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
    padding: 10px 0px 10px 0px;
    :first-child {
      padding-top: 0px;
    }
  }
`;

const ViewWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div:first-child {
      padding-top: 0px;
    }
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
`;

const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 33%;
    @media only screen and (max-width: 600px) {
      width: 100%;
      padding: 10px 0px 10px 0px;
    }
`;

const ContentEdit = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 48%;
    @media only screen and (max-width: 600px) {
      width: 100%;
      padding: 10px 0px 10px 0px;
    }
`;

const StyledSelect = styled(Select)`
  font-size: 16px;
  font-weight: normal;
  color: #041e41;
  width: 100%;

  .Select__control {
    background-color: #fff;
    border-radius: 4px;
    border: ${(props: any) => props.error ? "1px solid #cf021a" : "1px solid #ccc"};
    color: #333;
    cursor: default;
    border-spacing: 0;
    border-collapse: separate;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-height: 400px;

    &.Select__menuList {
      div:first-child {
        padding-top: 10px;
      }
  }
    
    :hover {
      border: ${(props: any) => props.error ? "1px solid #cf021a" : "1px solid #ccc"};
    }

    &.Select__control--is-focused {
      border: ${(props: any) => props.error ? "2px solid #cf021a" : "2px solid #0174C6"};
      box-shadow: none;
    }
  }
`;

const PrimaryAgencyCode = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
`;

const SAInfoIcon = styled(SAIcon)`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const Footer = styled(Container)`
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 30px;
    width: calc(100% - 20px);
    @media (max-width: 768px) {
      flex-direction: column;
    }
`;

const FooterButtons = styled(Container)`
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 30px;
  width: calc(100% - 20px);
    @media (max-width: 768px) {
      flex-direction: row;
    }
`;

const DisabledInputText = styled(SAInput)`
  .textLikeInput {
    pointer-events: none;
    background-color: rgba(216, 223, 236, 0.25);
  }
  .textLikeInputWrapper label {
    color: #979797;
  }
`;

const HelpIcon = styled.div`
  display: flex;
  padding-right: 10px;
  align-items: flex-start;
`;

const HelpText = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Note = styled.div`
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

type Position = 'top-start' | 'top' | 'top-end' | 'right' | 'bottom-start' | 'bottom' | 'bottom-end' | 'left';
type Offset = [number, number];

export default function FullWidthGrid(props: any) {
  const history = useHistory();
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;
  const isAdmin: boolean = decrypt(localStorage.getItem('isAdmin')) === 'true' ? true : false;
  const [personData, setPersonData] = useState<Person | null>(null);
  const [agency, setAgency] = useState<any[]>([]);
  const [agencyId, setAgencyId] = useState<string>('');
  const [allAgency, setAllAgency] = useState<any[]>([]);
  const [primaryAgencyCodeOptionsList, setPrimaryAgencyCodeOptionsList] = useState<any[]>([]);
  const [primaryAgencyCodeSaveButton, setPrimaryAgencyCodeSaveButton] = useState<boolean>(false);
  const [primaryAgencyCodeLabelClassName, setPrimaryAgencyCodeLabelClassName] = useState<string>('');
  const [show, setShow] = useState<any[]>([]);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarTitle, setSnackbarTitle] = useState<string>('');
  const [snackbarSubtitle, setSnackbarSubtitle] = useState<string>('');
  const [pendingEdits, setPendingEdits] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const [crossHierarchy, setCrossHierarchy] = useState<boolean>(props.crossHierarchy);
  let generalDetails = JSON.parse(decrypt(localStorage.getItem('general_details')) as any);
  const primaryAgencyCode = useRef(generalDetails?.primary_agency_code ? generalDetails?.primary_agency_code : '');
  const primaryAgencyId = useRef()
  localStorage.setItem('agency_code', encrypt(generalDetails?.primary_agency_code));

  // Popover Help Text
  const [mobile, setMobile] = useState<boolean>(true);
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const rememberRefElem = useRef<any>(null);
  const desktopPosition: Position = 'right';
  const mobilePosition: Position = 'top-end';
  const desktopOffset: Offset = [0, 10];
  const mobileOffset: Offset = [0, 10];
  const [position, setPosition] = useState<Position>(
    document.documentElement.clientWidth <= mobileWidth ? mobilePosition : desktopPosition,
  );
  const [offset, setOffset] = useState<Offset>(
    window.innerWidth <= mobileWidth ? mobileOffset : desktopOffset,
  );
  const [disabledCheckbox, setDisabledCheckbox] = useState<boolean>(false);

  // Spinner
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [spinnerStatus, setSpinnerStatus] = useState<string>('hide');
  let delay: ReturnType<typeof setTimeout>;

  const spinner = (
    <ProfileSpinnerContainer display={spinnerStatus}>
      <SASpinner variant="circular-continuous" delay={0} color={SAUX360Theme.colors.blueGray600} size="20px" />
    </ProfileSpinnerContainer>
  );

  const editState = props?.openEdit;
  const changeEditState = props?.change;
  const changeAgencyCode = props?.changeAgencyCode;

  type GeneralDetailsForm = {
    first_name: string;
    last_name: string;
    email_address: string;
    primary_phone: string;
    primary_agency_code: string;
  };

  const personId = decrypt(localStorage.getItem('selectedPersonId'));
  const formMethod = useForm<GeneralDetailsForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const startIcon = (
    <SAIcon
      icon={SAIcons.pencil}
      size={SAIconSize.small}
      colorVariant='secondary'
    />
  );

  const isValidEmail = (email: string) => {
    return validator.isEmail(email as string) ? true : false;
  }

  const isValidEmailDomain = (email: string) => {
    return emailValidation(email).then(
      response => {
        return response ? true : false;
      }
    );
  }

  function onSnackbarClose() {
    setShowSnackbar(false);
  }

  function handlePendingEditsClose() {
    if (!isAdmin) {
      const portalUrl = env['saconnect'];
      window.location.href = portalUrl;
    } else {
      history.push({
        pathname: `/agency-contacts`,
      });
    }
  }

  const findPrimaryAgencyCode = (arr: any) => {
    const isValid: boolean = arr.some(
      (agency: any) => agency.value === primaryAgencyCode.current
    );
    return isValid;
  }

  function openPopover(event: React.MouseEvent<HTMLElement>) {
    setReferenceElement(event.currentTarget);
  }

  function closePopover(event: React.MouseEvent<HTMLElement> | MouseEvent, index: number | null) {
    setReferenceElement(null);
  }

  function togglePopover(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    if (rememberRefElem.current === null) {
      setReferenceElement(event.currentTarget.firstChild as HTMLElement);
      rememberRefElem.current = event.currentTarget.firstChild;
    } else {
      setReferenceElement(null);
      rememberRefElem.current = null;
    }
  }

  function handleKeyDown(e: any, privilege: string) {
    if (e.key === 'Tab') {
      e.stopPropagation();
      setReferenceElement(null);
      rememberRefElem.current = null;
    }
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      if (rememberRefElem.current === null) {
        setReferenceElement(e.currentTarget.firstChild as HTMLElement);
        rememberRefElem.current = e.currentTarget.firstChild;
      } else {
        setReferenceElement(null);
        rememberRefElem.current = null;
      }
    }
  }

  useEffect(() => {
    if (generalDetails && generalDetails.email_address) {
      localStorage.setItem('selectedEmail', encrypt(generalDetails.email_address));
    }
  }, [generalDetails]);

  useEffect(() => {
    function updatePosition() {
      if (window.innerWidth <= mobileWidth) {
        setMobile(true);
        setPosition(mobilePosition);
        setOffset(mobileOffset);
      } else {
        setMobile(false);
        setPosition(desktopPosition);
        setOffset(desktopOffset);
      }
    }
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  }, []);

    useEffect(() => {
      if (generalDetails) {
        setPersonData(generalDetails);
        primaryAgencyCode.current = generalDetails.primary_agency_code ? generalDetails.primary_agency_code : '';
        localStorage.setItem('primaryAgencyCodeSaveButton', encrypt('false'));
      }

      const agency_map = props?.agencyCodeMap;
      const sortedAgencyCodesArray = agency_map.sort(function (a: any, b: any) {
        if (Number(a.agency_code) < Number(b.agency_code)) {
          return -1;
        }
        if (Number(a.agency_code) > Number(b.agency_code)) {
          return 1;
        }
        return 0;
      });

      const agency_code: any[] = [];
      const all_agency: any[] = [];
      const all_agency_options_list: any[] = [];

      if (generalDetails && sortedAgencyCodesArray) {
        sortedAgencyCodesArray?.forEach((i: any) => {
          if (primaryAgencyCode.current === i.agency_code || i.is_agency_admin_authorized === '1') {
            all_agency_options_list.push({
              'label': i.agency_code + ' - ' + i.agency_code_name + ', ' + i.city + ', ' + i.state_code + ' ' + i.zip.substr(0,5),
              'value': i.agency_code
            });
          }
          all_agency.push({
            'label': i.agency_code + ' - ' + i.agency_code_name + ', ' + i.city + ', ' + i.state_code + ' ' + i.zip.substr(0,5),
            'value': i.agency_code,
            'agencyId': i.agency_id,
            'authorized': i.is_agency_admin_authorized,
            'authorized_to_edit': i.is_agency_admin_authorized_to_update
          })
          if (i.is_prim_producer_nbr == '1') {
            agency_code.push({
              'agency_code': i.agency_code,
              'agency_code_name': i.agency_code_name,
              'agencyId': i.agency_id,
              'authorized': i.is_agency_admin_authorized,
              'authorized_to_edit': i.is_agency_admin_authorized_to_update
            })
            setAgencyId(i.agency_id);
          }
        });
        setAgency(agency_code);
        setAllAgency(all_agency);
        setPrimaryAgencyCodeOptionsList(all_agency_options_list);
        getAgencyAddress();
        const validPrimaryAgencyCode = findPrimaryAgencyCode(all_agency);
        if (!validPrimaryAgencyCode) {
          primaryAgencyCode.current = '';
        }
        if (primaryAgencyCode.current === '') {
          setPrimaryAgencyCodeSaveButton(!primaryAgencyCodeSaveButton);
          localStorage.setItem('primaryAgencyCodeSaveButton', encrypt('true'));
          handleEdit('edit');
        }
      }

    }, [props?.agencyCodeMap, props?.personId]);

    const getAgencyAddress = () => {
      const agencyCode = decrypt(localStorage.getItem('agency_code'));
      if (agencyCode) {
        const agencyAddr = props?.agencyCodeMap.filter((i: any) => {
          return  i.agency_code === agencyCode;
        });

        const data = {
          address_line1: agencyAddr[0]?.address_line1,
          address_line2: agencyAddr[0]?.address_line2,
          city: agencyAddr[0]?.city,
          county: agencyAddr[0]?.county,
          state: agencyAddr[0]?.state,
          state_cd: agencyAddr[0]?.state_code,
          zip: agencyAddr[0]?.zip
        }
        localStorage.setItem('agency_address', encrypt(JSON.stringify(data)));
      }
    }

    useEffect(() => {
      if (allAgency != null && allAgency.length > 0) {
        const allAgencyFilter = allAgency.filter((agency: any ) => {
          if (primaryAgencyCode.current !== '') {
            if (agency.value === primaryAgencyCode.current) {
              setAgencyId(agency.agencyId);
            }
            return agency.value === primaryAgencyCode.current;
          }
        });
        localStorage.setItem('allAgencyCodes', encrypt(JSON.stringify(allAgency)));
        setShow(allAgencyFilter);
      }
    }, [allAgency]);

    useEffect(() => {
      if (displayModal) {
        document.body.classList.add("noscroll");
      } else {
        document.body.classList.remove("noscroll");
      }
    }, [displayModal]);

    const handleEdit = (action: string) => {
      if (action === 'cancel') {
        localStorage.setItem('primaryAgencyCodeSaveButton', encrypt('false'));
        setPrimaryAgencyCodeSaveButton(false);
        changeEditState('default');
      }
      if (action === 'cancelPrimaryAgencyCode') {
          localStorage.setItem('primaryAgencyCodeSaveButton', encrypt('false'));
          setPrimaryAgencyCodeSaveButton(false);
          if (props?.isAdmin) {
            history.push({
              pathname: '/agency-contacts'
            });
          } else {
            const portalUrl = env['saconnect'];
            window.location.href = portalUrl;
          }
          changeEditState('default');
      }
      if (action === 'edit') {
        formMethod.reset();
        formMethod.setValue('primary_agency_code', primaryAgencyCode.current);
        setTimeout(() => {
          formMethod.trigger();
        }, 1000);
        if (editState !== 'general' && editState !== 'default') {
            setDisplayModal(true);
            localStorage.setItem('incoming', encrypt('general'));
          } else {
            changeEditState('general');
          }

      }
      if (action === 'discard') {
        const inComing = decrypt(localStorage.getItem('incoming'));
        if (inComing !== undefined) {
          changeEditState(inComing);
        }
      }
    }

    const {
      control,
      register,
      trigger,
      setValue,
      formState: { errors }, clearErrors
    } = formMethod;

    const confirmModal = (event: React.MouseEvent, action: string) => {
      event.stopPropagation();
      if (action === 'close') {
        setDisplayModal(!displayModal);
      }
      if (action === 'discard') {
        handleEdit('discard');
        setDisplayModal(!displayModal);
      }
    }

    const sendData = async (data: any) => {
      const isAuthenticated: boolean = await checkAuthStatus();
      if (!isAuthenticated) {
        return;
      }
      const promiseGeneralDetails = API.graphql(
        graphqlOperation(
          mutations.updatePendingPersonGeneralDetails,
          {
            body: data,
            person_id: personId
          }
        ),
        {
          Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
        }
      ) as Promise<{ data: UpdatePendingPersonGeneralDetailsMutation }>;

      promiseGeneralDetails.then((res) => {
        if (res.data) {
          return res.data.updatePendingPersonGeneralDetails;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200) {
          getAgencyAddress();
          localStorage.setItem('agency_code', encrypt(data?.primary_agency_code));
          setSnackbarTitle('Changes Saved');
          setSnackbarSubtitle('All your changes have been saved');
          setShowSnackbar(true);
          clearTimeout(delay);
          setShowSpinner(false);
          setSpinnerStatus('hide');
          if (data?.primary_agency_code) {
            setPrimaryAgencyCodeSaveButton(false);
            changeAgencyCode(true);
          }
          handleEdit('cancel');
        } else {
          clearTimeout(delay);
          setShowSpinner(false);
          setSpinnerStatus('hide');
          setPendingEdits(true);
        }
      })
      .catch((err) => {
        clearTimeout(delay);
        setShowSpinner(false);
        setSpinnerStatus('hide');
        if (err?.message) {
          console.error(err.message, err); 
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });
    }

    const updatePersonData = (data: any) => {
      setShowSpinner(true);
      setSpinnerStatus('hide');

      delay = setTimeout(() => {
        setSpinnerStatus('display');
      }, 1000);

      const rst = decrypt(localStorage.getItem('general_details'));
      let newPersonData;
      const phone = data.primary_phone.replace(/[^0-9]/g, '');
      if (rst) {
        newPersonData = JSON.parse(rst);
        newPersonData.primary_agency_code = data.primary_agency_code;
        newPersonData.email_address = data.email_address;
        newPersonData.primary_phone = phone;
        localStorage.setItem('general_details', encrypt(JSON.stringify(newPersonData)));
        generalDetails = newPersonData;
        if (generalDetails) {
          setPersonData(generalDetails);
        }
      }
      if (data.primary_agency_code) {
        const primaryAgencyCodeFilter = allAgency.filter(item => item.value === data.primary_agency_code);
        setShow(primaryAgencyCodeFilter);
        primaryAgencyCode.current = primaryAgencyCodeFilter[0].value;
      }

      allAgency.filter((agency: any ) => {
        if (primaryAgencyCode.current !== '') {
          if (agency.value === primaryAgencyCode.current) {
            primaryAgencyId.current = agency.agencyId;
          }
        }
      });

      data.updated_by = decrypt(localStorage.getItem('personId'));
      sendData(data);
    };

  const handleChange = (value: string) => {
    if (value) {
      setInputValue(value);
    }
  };

    const saveData = () => {
      if (!isEmployee) {
        formMethod.trigger().then(value => {
          if (value) {
            let data: any = {};
            data = {...formMethod.getValues()};
            data.primary_phone = data.primary_phone.replace(/[^0-9]/g, '');
            data.primary_agency_code = data.primary_agency_code ? data.primary_agency_code : primaryAgencyCode.current;
            updatePersonData(data);
          }
        });
      }
    }

    return (
      <div>
        <SASnackbar
          open={showSnackbar}
          variant="snackbar-success"
          position="top-end"
          autoHideDuration={5000}
          title={snackbarTitle}
          subtitle={snackbarSubtitle}
          offset={[-25, 170]}
          onClose={onSnackbarClose}
        />
        {editState !== 'general' ? (
          <Container>
            <Header>
              <StyledHeader type="heading-3" text="General Details" />
              {!crossHierarchy &&
                <StyledSAButton
                  id="general-details-edit"
                  color="secondary"
                  label="Edit"
                  textTransform="none"
                  startIcon={startIcon}
                  variant="primary-link-medium"
                  onClick={() => handleEdit("edit")}
                />
              }
            </Header>
            {crossHierarchy &&
              <Note>
                Changes in this section are disabled.  Contact Agency Operations for assistance at <a href="mailto:Agency.Operations@stateauto.com">Agency.Operations@stateauto.com</a>
              </Note>
            }
            <ViewWrapper>              
              <Content>
                <div><StyledHeadings type="small" text="Name" /></div>
                <div>
                  <SAText type="standard" text={generalDetails?.first_name + ' '} />
                  <SAText type="standard" text={generalDetails?.last_name} />
                </div>
              </Content>
              <Content>
                <div>
                  <StyledHeadings type="small" text="Email Address" />
                </div>
                <div>
                  <SAText type="standard" text={generalDetails?.email_address ? generalDetails?.email_address : "-"} />
                </div>
              </Content>
              <Content>
                <div><StyledHeadings type="small" text="Primary Phone" /> </div>
                <div>
                  <SAText type="standard" text={generalDetails?.primary_phone ? `${normalizePhoneNumber( generalDetails?.primary_phone)}` : '-'} />
                </div>
              </Content>
            </ViewWrapper>
            <Footer>
              <div> <StyledHeadings type="small" text="Primary Agency Code" /> </div>
              <div>
                <SAText
                  type="standard"
                  text={(show[0] == undefined) ? " - " : show[0].label}
                />
              </div>
            </Footer>
          </Container>
        ) : (
          <Container>
            <Header>
              <StyledHeader type="heading-3" text="Edit General Details" />
            </Header>
            {crossHierarchy && isAdmin &&
              <Note>
                Primary Agency Code is required for every contact.  Unfortunately, we are unable to process changes for Primary Agency Code, Phone Number or Email Address.  Contact <a href="mailto:Agency.Operations@stateauto.com">Agency.Operations@stateauto.com</a> for assistance.
              </Note>
            }
            {crossHierarchy && !isAdmin &&
              <Note>
                Primary Agency Code is required but we are unable to process the change.  Contact your Agency Admin to work with Agency Operations for a resolution.
              </Note>
            }
            <SectionWrapper>
              <ContentEdit>
                <DisabledInputText
                  readOnly
                  {...register("first_name", { required: "Please enter value" })}
                  id="first_name"
                  name="first_name"
                  error={Object.prototype.hasOwnProperty.call(
                    errors,
                    "first_name"
                  )}
                  hint={errors.first_name?.message as string | undefined}
                  label="First Name"
                  type="text"
                  defaultValue={personData?.first_name}
                  fullWidth
                />
              </ContentEdit>
              <ContentEdit>
                <DisabledInputText
                  readOnly
                  {...register("last_name", { required: "Please enter value" })}
                  id="last_name"
                  name="last_name"
                  error={Object.prototype.hasOwnProperty.call(
                    errors,
                    "last_name"
                  )}
                  hint={errors.last_name?.message as string | undefined}
                  label="Last Name"
                  defaultValue={personData?.last_name}
                  type="text"
                  fullWidth
                />
              </ContentEdit>
            </SectionWrapper>
            <SectionWrapper>
              <ContentEdit>
                <StyledInput
                  {...register("primary_phone",
                    {
                      required: 'Primary Phone is required',
                      minLength: { value: 14, message: 'Primary Phone must be 10 digits' }
                    }
                  )}
                  id="primary_phone"
                  name="primary_phone"
                  error={Object.prototype.hasOwnProperty.call(
                    errors,
                    "primary_phone"
                  )}
                  hint={errors.primary_phone?.message as string | undefined}
                  label="Primary Phone"
                  type="tel"
                  inputMode="numeric"
                  autoComplete="none"
                  defaultValue={normalizePhoneNumber(personData?.primary_phone!)}
                  onChange={(e: any) => {
                    const { value } = e.target;
                    clearErrors('primary_phone');
                    setValue('primary_phone', normalizePhoneNumber(value));
                  }}
                  fullWidth
                />
              </ContentEdit>
              <ContentEdit>
                  <StyledInput
                    {...register("email_address", {
                      required: 'Email Address is required',
                      maxLength: {value: 100, message: 'Email Address cannot exceed 100 characters'},
                      validate: {
                        emailPattern: (value) => isValidEmail(value) || 'Enter valid email address format',
                        emailDomain: (value) => isValidEmailDomain(value) || 'Invalid email address',
                      },
                    })}
                    id="email_address"
                    name="email_address"
                    error={Object.prototype.hasOwnProperty.call(errors, "email_address")}
                    hint={errors.email_address?.message as string | undefined}
                    label="Email Address"
                    type="text"
                    autoComplete="none"
                    defaultValue={generalDetails?.email_address}
                    fullWidth
                  />
                  <StyledError>{errors?.email_address?.message === '' ? 'Invalid email address' : ''}</StyledError>
              </ContentEdit>
            </SectionWrapper>
            <SectionWrapper>
              <ContentEditLine2>
                  <PrimaryAgencyCode>
                    <Controller
                      rules={{
                        required: "Please select a Primary Agency Code"
                      }}
                      control={control}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <StyledSelect
                          classNamePrefix="Select"
                          inputRef={ref}
                          value={primaryAgencyCodeOptionsList.find((c) => c.value === value)}
                          name={name}
                          styles={styles}
                          options={primaryAgencyCodeOptionsList}
                          autoComplete="none"
                          error={errors?.primary_agency_code}
                          defaultValue={primaryAgencyCodeOptionsList.find((c) => c.value === primaryAgencyCode)}
                          onChange={(selectedOption: any) => {
                            clearErrors('primary_agency_code');
                            onChange(selectedOption.value);
                            formMethod.trigger('primary_agency_code');
                          }}
                          onFocus={() => {
                            setPrimaryAgencyCodeLabelClassName('focused');
                          }}
                          onBlur={() => {
                            setPrimaryAgencyCodeLabelClassName('');
                            clearErrors('primary_agency_code');
                            formMethod.trigger('primary_agency_code');
                          }}
                          placeholder="Select a Primary Agency Code"
                        />
                      )}
                      name="primary_agency_code"
                    />
                    <HelpTextIconButton
                      fullWidth={false}
                      variant="link-small"
                      label="Help Text"
                      onClick={togglePopover}
                      onKeyDown={handleKeyDown}
                    >
                      <SAInfoIcon icon={SAIcons.information} size="20px" colorVariant="primary" onMouseOver={openPopover} onMouseLeave={closePopover} />
                    </HelpTextIconButton>
                  </PrimaryAgencyCode>
                <StyledLabel
                  err={errors.primary_agency_code}
                  className={primaryAgencyCodeLabelClassName}
                >Primary Agency Code</StyledLabel>
                <StyledPrimaryAgencyCodeError>{errors?.primary_agency_code?.message}</StyledPrimaryAgencyCodeError>
              </ContentEditLine2>
            </SectionWrapper>
            <FooterButtons>
              <StyledCancelButton
                label="Cancel"
                onClick={() => handleEdit(primaryAgencyCodeSaveButton ? 'cancelPrimaryAgencyCode' : 'cancel')}
                variant="link-large"
                color="text"
              />
              {crossHierarchy &&
                <SAButton disabled fullWidthUnder={mobileWidth} label="Save Changes" type="submit" variant="primary" textTransform="uppercase" />
              }
              {!crossHierarchy && !showSpinner &&
                <SAButton fullWidthUnder={mobileWidth} label="Save Changes" type="submit" variant="primary" textTransform="uppercase" onClick={saveData} disabled={isEmployee} />
              }
              {!crossHierarchy && showSpinner &&
                <DisabledContinueButton endIcon={spinner} disabled fullWidthUnder={mobileWidth} label="Save Changes" type="submit" variant="primary" textTransform="uppercase" />
              }
            </FooterButtons>
          </Container>
        )}
        <SAPopover
          onClose={(e: MouseEvent, index: number | null) => closePopover(e, index)}
          offset={offset}
          placement={position}
          referenceElement={referenceElement}
          variant={mobile ? 'popover-minimal-nopadding' : 'popover-menu-no-click'}
        >
          <Popover>
            <HelpContainer>
              <HelpIcon>
                <SAIcon icon={SAIcons.information} size="20px" colorVariant="hsla(220, 38%, 97%, 1)" />
              </HelpIcon>
              <HelpText>
                Primary Agency Code is the agency code / location where the individual works and the default code for quoting new business. This is sometimes called Primary Workplace Code.
              </HelpText>
            </HelpContainer>
          </Popover>
        </SAPopover>
        {displayModal && (
          <ConfirmEditModal confirmModal={confirmModal} />
        )}
        {pendingEdits &&
            <ModalPendingEdits isAdmin={isAdmin} onClickClose={handlePendingEditsClose} />
        }
      </div>
    )
}

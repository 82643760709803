import React, {useEffect, useState} from "react";
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  SAButton,
  SACard,
  SACheckbox,
  SAModal,
  SAText,
  SASpinner,
  SAUX360Theme,
  SAUXTheme,
  SAAccordion,
  SASearch,
  SAIcon,
  SAIcons,
  SAPopover,
  SAIconSize,
  SATag,
  SAAlert,
  SAAlertTitle,
  SAStack
} from "@saux/design-system-react";
import {
  AgencyLocations,
  ProfileSpinnerContainer
} from "../../AgencyContactDetails/AgencyContactDetailsStyles/AgencyContactDetailsStyles";
import {useHistory} from "react-router-dom";
import {
  AppGenPayloadAdd,
  Constants
} from "../../../interfaces/interfaces";
import {decrypt, encrypt} from "../../../utils/crypto";
import {constants} from "../../../utils/constants";
import ModalCancelAlert from "../ModalCancelAlert/ModalCancelAlert";
import ModalPendingEdits from '../../modal/ModalPendingEdits/ModalPendingEdits';
import env from "../../../env_config";
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { UpdatePendingPersonContactTypeMutation } from "../../../API";
import handleGraphQLErrors from "../../../utils/handleGraphQLErrors";
import ModalCrossHierarchyWarning from "../ModalCrossHierarchyWarning/ModalCrossHierarchyWarning";
import simpleGTMDataLayer from "../../../utils/GTMHelpers/simpleGTMDataLayer";
import { checkAuthStatus } from "../../../utils/utils";
import checkIfLicenseProducerRequested from "../../../utils/checkIfLicenseProducerRequested";

type ContactTypeResponse = {
  status: string;
  message: string;
}

type ContactTypeRequest = {
  contact_type: number;
  contact_type_desc: string;
  agency_cd: string[];
  updated_by: string;
}
type SavedAgencyCodes = {
  contact_type: number;
  agency_label: string;
}

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
  isEmployee?: boolean;
};

type NewContactTypes = {
  contact_type_desc: string;
  id: number;
}

type NewContactAgencyCode = {
  id: number;
  agency_code: string;
}

type AgencyCode = {
  agencyId: string;
  label: string;
  value: string;
  authorized: string;
  authorized_to_edit: string;
}

type AgencyCodes = {
  agency_code: string;
  agency_id: string;
  agency_code_name: string;
  city: string;
  state_code: string;
  zip: string;
  is_agency_admin_authorized_to_update: string;
}

type Position = 'top-start' | 'top' | 'top-end' | 'right' | 'bottom-start' | 'bottom' | 'bottom-end' | 'left';

type Offset = [number, number];

const mobileWidth = 600;

const Container = styled.div.attrs(({ constants, isEmployee }: StyleProps) => ({
  constants,
  isEmployee
  }))`
    ${({ theme, constants, isEmployee }: StyleProps) => {
    const con: Constants = constants as Constants;
    const marginTop: number = isEmployee ? con.headerHeight + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeight + con.dockedStepperHeight + con.verticalPageOffset;
    const marginTopMobile: number = isEmployee ? con.headerHeightMobile + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeightMobile + con.dockedStepperHeight + con.verticalPageOffset;

    return `
      display: flex;
      flex-direction: row;
      max-width: 700px;
      max-height: 800px;
      margin: auto;
    
      @media only screen and (max-width: ${mobileWidth}px) {
        margin: ${marginTopMobile}px ${theme?.size.spacer.medium} 0 ${theme?.size.spacer.medium};
      }
    
      article:first-child {
        flex-grow: 1;
      }
    
      article:last-child {
        width: 700px;
        flex-shrink: 0;
      }
    `;
  }};
`;

const AccordionContent = styled.div``;

const Modal = styled(SAModal).attrs(({display}) => ({
  display,
}))`
  ${({display}) => {
    return `
    display: ${ display ? display : 'flex'};
    top: 50%;
    left: 50%;
    justify-content: center;
    align-items: center;
    overflow: auto;
    `;
  }}
`;

const AgencyLocationWrapper = styled.div`
  padding-top: 10px;
  margin-left: 10px;
`;

const AlertMsg = styled(SAAlert)`
  margin-top: 15px;
`;

const ContinueButton = styled.div`
  ${({ theme }: StyleProps) => {
  return `
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      margin-bottom: 1px;
    `;
}};
`;

const AgencyCodesContainer = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      border: 1px solid #D8DFEC;
      height: 280px;

      span {
        &.heading {
          display: flex;
          padding: ${theme?.size.spacer.small} calc(${theme?.size.spacer.medium} + 5px);
          margin: ${theme?.size.spacer.medium} ${theme?.size.spacer.small} 0 ${theme?.size.spacer.small};
          font-weight: ${theme?.font.primary.weight.bold};
          background-color: hsl(220,38%,97%);

          @media only screen and (max-width: ${mobileWidth}px) {
            margin: 0;
          }
        }
      }
    `;
  }};
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: ${mobileWidth}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Search = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;

      .saInputWrapper {
        margin: 0 0 ${theme?.size.spacer.medium} 0;
      }

      .textLikeInput {
        padding-top: 0;
        width: initial;
        font-size: ${theme?.font.primary.size.medium};
      }

      ul {
        align-items: center;
        span {
          button {
            margin-bottom: 0;
          }
        }
        button {
          margin-bottom: 5px;
        }
      }
    `;
  }};
`;

const AgencyCodesCheckboxes = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      margin: ${theme?.size.spacer.small};
      max-height: 270px;
      overflow-y: auto;

      @media only screen and (max-width: ${mobileWidth}px) {
        margin: ${theme?.size.spacer.small} 0 ${theme?.size.spacer.small} 0;
      }

      .saCheckboxWrapper {
        display: flex;
        align-items: center;
        margin: 10px 0;
        height: 40px;
        padding: 0 15px;
        color: ${theme?.colors.text};
        z-index: 0;

        span {
          display: flex;
          flex-shrink: 0;
        }

        &.hidden {
          display: none;
        }

        input {
          &:hover {
            cursor: pointer;
          }
        }

        label {
          &:hover {
            cursor: pointer;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 92px;
        border: none;
      }
    
      &::-webkit-scrollbar-track {
          background: #F0EFEF;
          border-radius: 7px;
      }
    
      &::-webkit-scrollbar-thumb {
          background: #8A8A8A;
          border-radius: 7px;
          box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      }
    `;
  }};
`;

const SelectAllButton = styled(SAButton)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-shrink: 0;
      margin: 0 0 ${theme?.size.spacer.medium} ${theme?.size.spacer.medium};

      @media only screen and (max-width: ${mobileWidth}px) {
        margin: 0 0 ${theme?.size.spacer.medium} 0;
      }
    `;
  }};
`;

const Popover = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      color: hsla(220, 38%, 97%, 1);
      font-size: ${theme?.font.primary.size.smallTEMP};
      font-weight: ${theme?.font.primary.weight.normal};
      line-height: 16px;
      background-color: hsla(213, 30%, 35%, 1);
      border: 1px solid hsla(213, 30%, 35%, 1);
      box-sizing: border-box;
      box-shadow: 0px 0px 5px rgba(0, 32, 56, 0.22);
      border-radius: 4px;
      padding: 15px;
      width: 360px;
    `;
  }};
`;

const HelpContainer = styled.div`
  display: flex;
  flex-directino: column;
`;

const HelpIcon = styled.div`
  display: flex;
  padding-right: 10px;
  align-items: flex-start;
`;

const HelpText = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CardHeader = styled.div`
  padding-bottom: 45px;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 30px;

  .closemodal {
    display: none;
  }

  button {
    margin-left: 15px;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

const CardSubTitle = styled.div``;

const CardBody = styled.div`
  height: 490px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    height: 92px;
    border: none;
  }

  &::-webkit-scrollbar-track {
    background: #F0EFEF;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #8A8A8A;
    border-radius: 7px;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }
`;

const CardFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-top: 28px;
      border-top: 1px solid #D9DFEB;

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
      }

      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      div {
        &:last-child {
          display: flex;
          flex-direction: row;

          button {
            margin-left: ${theme?.size.spacer.medium};

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              font-weight: ${theme?.font.primary.weight.bold};
            }
          }

          @media only screen and (max-width: ${mobileWidth}px) {
            button {
              &:first-child {
                display: none;
              }
            }
          }
        }

        @media only screen and (max-width: ${mobileWidth}px) {
          flex-grow: 1;
        }
      }
    `;
  }};
`;

const CancelButton = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: none;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: ${theme?.size.spacer.medium} ${theme?.size.spacer.medium};
      }
    `;
  }};
`;

export const DisabledSaveButton = styled(SAButton)`
  ${({theme}: StyleProps) => {
    return `
      color: ${theme?.colors.white};
      background-color: ${theme?.colors.green};

      &:hover {
        background-color: ${theme?.colors.green};
      }
    `;
  }};
`;

const DisabledSaveChangesButton = styled(SAButton)`
  ${({ theme }: StyleProps) => {
    return `
      color: ${theme?.colors.white};
      background-color: ${theme?.colors.green};

      &:hover {
        background-color: ${theme?.colors.green};
      }
    `;
  }};
`;

// export default function ModalAddNewContactTypeAgencyCodes = ({ addContactTypeModal }: ContactTypeAgencyCodeModalProps) => {
export default function ModalAddNewContactTypeAgencyCodes(props: any) {
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [formData, setFormData] = useState<any>(null);
  const onSubmit = (data: any) => setFormData(data);
  const history = useHistory();

  // Agency Admin - 3
  // Agency Staff - 7
  // Agency Manager - 10
  // Primary Claim Contact - 13
  // Primary Mail Recipient - 14
  // Accounting Manager - 36
  // Licensed Producer - 37
  const agencyBasedContactTypes: number[] = [3, 7, 10,  13, 14, 36, 37];
  const [completed, setCompleted] = useState<number[]>([]);
  const [disabled, setDisabled] = useState<number[]>([]);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
  const [mobile, setMobile] = useState<boolean>(true);
  const [showContinueButtons, setShowContinueButtons] = useState<number[]>([]);
  const [expanded, setExpanded] = useState<number | Boolean>(false);
  const [searchTerms, setSearchTerms] = useState<string[][]>([]);
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const [addingLicensedProducer, setAddingLicensedProducer] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [saveContactTypes, setSaveContactTypes] = useState<ContactTypeRequest[]>([]);
  const [saveAgencyCodes, setSaveAgencyCodes] = useState<SavedAgencyCodes[]>([]);
  const [modalSaveError, setModalSaveError] = useState<boolean>(false);
  const [pendingEdits, setPendingEdits] = useState<boolean>(false);

  // loading spinner
  const [showModalSpinner, setShowModalSpinner] = useState<boolean>(false);

  // SnackBar
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarTitle, setSnackbarTitle] = useState<string>('');
  const [snackbarSubtitle, setSnackbarSubtitle] = useState<string>('');

  const [selectAll, setSelectAll] = useState<boolean>(true);
  const activeAgencyCodes: AgencyCode[] = getActiveAgencyCodes();
  const displayedContactTypes: number[] = getDisplayedContactTypes();
  const changedBy = decrypt(localStorage.getItem('personId'));
  const personId = decrypt(localStorage.getItem('selectedPersonId'));
  const contactTypes = props?.contactTypes;
  const desktopPosition: Position = 'right';
  const mobilePosition: Position = 'top-end';
  const desktopOffset: Offset = [0, 10];
  const mobileOffset: Offset = [0, 10];
  const [position, setPosition] = useState<Position>(
    document.documentElement.clientWidth <= mobileWidth ? mobilePosition : desktopPosition,
  );
  const [offset, setOffset] = useState<Offset>(
    window.innerWidth <= mobileWidth ? mobileOffset : desktopOffset,
  );
  const [disabledCheckbox, setDisabledCheckbox] = useState<boolean>(false);
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;
  const isAdmin: boolean = decrypt(localStorage.getItem('isAdmin')) === 'true' ? true : false;
  const loggedInPersonId = decrypt(localStorage.getItem('personId'));
  const firstName = decrypt(localStorage.getItem('selectedFirstName'));
  const lastName = decrypt(localStorage.getItem('selectedLastName'));
  const email = decrypt(localStorage.getItem('selectedEmail'));
  const adminFirstName = decrypt(localStorage.getItem('loggedInUserFirstName'));
  const adminLastName = decrypt(localStorage.getItem('loggedInUserLastName'));

  const modalOnClickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
  }

  // Modals
  const [displayCancelAlert, setDisplayCancelAlert] = useState<boolean>(false);
  const [displayCrossHierarchyWarningModal, setDisplayCrossHierarchyWarningModal] = useState<boolean>(false);

  function getDisplayedContactTypes () {
    const displayed: any[] = [];
    props?.newContactTypes.forEach((ct: NewContactTypes) => {
      if (agencyBasedContactTypes.includes(ct.id)) {
        displayed.push(ct.id);
      }
    });
    return displayed;
  }

  function getActiveAgencyCodes() {
    const agencyCodesArray: AgencyCode[] = JSON.parse(decrypt(localStorage.getItem('allAgencyCodes')));
    const sortedAgencyCodesArray = agencyCodesArray.sort(function (a: any, b: any) {
      if (Number(a.value) < Number(b.value)) {
        return -1;
      }
      if (Number(a.value) > Number(b.value)) {
        return 1;
      }
      return 0;
    });

    return sortedAgencyCodesArray.filter(agencyCode => agencyCode.authorized_to_edit === '1');
  }

  // Spinner
  let delay: ReturnType<typeof setTimeout>;
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [spinnerStatus, setSpinnerStatus] = useState<string>('hide');

  const spinner = (
    <ProfileSpinnerContainer display={spinnerStatus}>
      <SASpinner variant="circular-continuous" delay={0} color={SAUX360Theme.colors.blueGray600} size="20px" />
    </ProfileSpinnerContainer>
  );

  const handleClickClose = (event: any) => {
    event.stopPropagation();
  }

  const handleSave = (event: any) => {
    event.stopPropagation();
  }

  useEffect(() => {
    if (displayedContactTypes.length === completed.length) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  useEffect(() => {
    function updatePosition() {
      if (window.innerWidth <= mobileWidth) {
        setMobile(true);
        setPosition(mobilePosition);
        setOffset(mobileOffset);
      } else {
        setMobile(false);
        setPosition(desktopPosition);
        setOffset(desktopOffset);
      }
    }
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => {
      window.removeEventListener('resize', updatePosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showComplete = (contactType: number) => {
    if (completed.includes(contactType)) {
      if (mobile) {
        return <SAIcon key={`complete_${contactType.toString()}`} icon={SAIcons.success} size={`calc(${SAIconSize.small} + 5px)`} colorVariant="dark" />;
      } else {
        return <SATag id={`complete_${contactType.toString()}`} key={`complete_${contactType.toString()}`} variant="standard-outline" label="Completed" />;
      }
    }
  }

  function openPopover(event: React.MouseEvent<HTMLElement>) {
    setReferenceElement(event.currentTarget);
  }

  function closePopover(event: React.MouseEvent<HTMLElement> | MouseEvent, index: number | null) {
    setReferenceElement(null);
  }

  function togglePopover(event: React.MouseEvent<HTMLElement>) {
    if (referenceElement === null) {
      setReferenceElement(event.currentTarget.firstChild as HTMLElement);
    } else {
      setReferenceElement(null);
    }
  }

  function handleKeyDown(e: any) {
    if (e.key === 'Tab') {
      setReferenceElement(null);
    }
  }

  const handleChange = (contactType: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>, isExpanded: boolean) => {
    setExpanded(isExpanded ? contactType : false);
    setSelectAll(selectAllDeselectAllButtonState(contactType.toString()));
  };
  
  function handleClickCloseCrossHierarchyWarningModal() {
    setDisplayCrossHierarchyWarningModal(!displayCrossHierarchyWarningModal);
  }

  function handleClickCancel(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleClickCancelAlertModalLeave(event: React.MouseEvent) {
    props?.closeAlertModal(false);
    setDisplayCancelAlert(!displayCancelAlert);
    localStorage.setItem('edit_state', encrypt('default'));
  }

  function handleClickCloseModal(event: React.MouseEvent) {
    props?.closeAlertModal(false);
    props?.closeModal(false);
    localStorage.setItem('edit_state', encrypt('default'));
  }

  function handleClickCancelAlertModalStay(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleClickContinue(contactType: number) {
    // clear search terms for current panel
    handleSearch([], contactType);

    // set current panel to completed
    const newCompleted: number[] = [];
    if (displayedContactTypes.includes(contactType)) {
      if (completed.length < 1) {
        newCompleted.push(contactType);
      } else {
        completed.forEach((ct: number) => {
          if (ct === null || ct !== contactType) {
            if (!newCompleted.includes(contactType)) {
              newCompleted.push(contactType);
            }
          };
        });
      }

      if (displayedContactTypes.length === newCompleted.length) {
        setCompleted(newCompleted);
        setExpanded(false);
        return;
      }
    }

    // expand the next panel that is not completed
    if (displayedContactTypes.length !== completed.length) {
      if (typeof expanded === 'number') {
        // get index of current expanded panel
        const activeIndex: number = displayedContactTypes.indexOf(expanded);
        let done: boolean = false;
        displayedContactTypes.forEach((contactType: number, index: number) => {
          if (done) {
            return;
          }
          if (index > activeIndex) {
            if (!completed.includes(contactType)) {
              // enable panel if disabled
              if (disabled.includes(contactType)) {
                const newDisabled: number[] = [];
                disabled.forEach((disabledContactType: number, disabledIndex: number) => {
                  if (contactType !== disabledContactType) {
                    newDisabled.push(disabledContactType);
                  }
                });
                setDisabled(newDisabled);
              }

              if (!newCompleted.includes(expanded)) {
                newCompleted.push(expanded);
              }
              if (newCompleted) {
                setCompleted([...completed, ...newCompleted]);
              }
              setSelectAll(selectAllDeselectAllButtonState(contactType.toString()));
              setExpanded(contactType);
              done = true;
            }
          }
        });
        if (!done) {
          displayedContactTypes.forEach((contactType: number, index: number) => {
            if (done) {
              return;
            }
            if (index === activeIndex) {
              if (!completed.includes(contactType)) {
                // expand panel
                if (!newCompleted.includes(contactType)) {
                  newCompleted.push(contactType);
                }
                // newCompleted.push(contactType);
                if (newCompleted) {
                  setCompleted([...completed, ...newCompleted]);
                }
                // setCompleted([...completed, ...newCompleted]);
                setExpanded(false);
                done = true;
              }
            }
          });
        }
      }
    } else {
      setExpanded(false);
    }
  }

  function handleSearch(search: Array<string> | string, contactType: number) {
    // The callback function you provide in the onSearch prop will receive the current array
    // of search terms when a new search term is added or removed from the array.
    if (typeof search !== 'string') {
      const elements: HTMLElement[] = [];
      activeAgencyCodes.forEach((agencyCode: AgencyCode) => {
        const element: HTMLElement | null = document.querySelector(`label[for="contacttype_${contactType.toString()}_agencycode_${agencyCode.value}"]`);
        if (element !== null) {
          elements.push(element);
        }
      });

      const filteredElements = elements.filter((element: HTMLElement) => {
        let containsAllSearchTerms: boolean = true;
        search.forEach((searchTerm) => {
          let containsSearchTerm: boolean = false;
          if (element.innerText.toLowerCase().includes(searchTerm.toLowerCase())) {
            containsSearchTerm = true;
          };
          if (!containsSearchTerm) {
            containsAllSearchTerms = false;
          }
        });

        if (containsAllSearchTerms) {
          return true;
        } else {
          return false;
        }
      });

      elements.forEach((element: HTMLElement, index: number) => {
        const parentElement = element.parentElement as HTMLElement;
        if (filteredElements.includes(element)) {
          parentElement.classList.remove('hidden');
        } else {
          parentElement.classList.add('hidden');
        }
      });

      // save search terms
      const newSearchTerms: string[][] = [];
      displayedContactTypes.forEach((ct: number) => {
        newSearchTerms[ct] = searchTerms[ct] ? searchTerms[ct] : [];
        if (ct === contactType) {
          newSearchTerms[contactType] = search;
        }
      });
      setSearchTerms(newSearchTerms);

      handleSubmit(onSubmit)();
    }
  }

  async function sendData(data: ContactTypeRequest[]) {
    let saveError: boolean = false;
    const fetchDataByContactType = async (newContactType: ContactTypeRequest) => {
      const isAuthenticated: boolean = await checkAuthStatus();
      if (!isAuthenticated) {
        return null;
      }
      return (
        API.graphql(
          graphqlOperation(
            mutations.updatePendingPersonContactType,
            {
              body: newContactType,
              person_id: personId
            }
          ),
          {
            Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
          }
        ) as Promise<{ data: UpdatePendingPersonContactTypeMutation }>
      )
      .then((res) => {
        if (res.data) {
          return res.data.updatePendingPersonContactType;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200) {
          const contactTypedSaved: string = 'complete_' + newContactType.contact_type.toString();
          let completedContactType = document.getElementById(contactTypedSaved);
          if (completedContactType !== null) {
            completedContactType.style.color = 'rgb(77, 132, 11)';
            completedContactType.style.border = '1px solid rgb(77, 132, 11)';
            completedContactType.innerText = 'Saved';
          }
          props.saveError(false);
          return res.body as ContactTypeResponse;
        } else {
          saveError = true;
          const contactTypedSaved: string = 'complete_' + newContactType.contact_type.toString();
          let completedContactType = document.getElementById(contactTypedSaved);
          if (completedContactType !== null) {
            completedContactType.style.color = 'hsl(353,98%,41%)';
            completedContactType.style.border = '1px solid hsl(353,98%,41%)';
            completedContactType.innerText = 'Error';
          }
          clearTimeout(delay);
          setShowSpinner(false);
          setShowModalSpinner(false);
          setSpinnerStatus('hide');
          setModalSaveError(true);
          props.saveError(true);
          return res?.body as ContactTypeResponse;
        }
      })
      .catch((err) => {
        props.suppressToastMessage(true);
        if (err?.message) {
          console.error(err.message, err); 
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
        return null;
      });
    }

    const arrayOfPromises: ContactTypeResponse[] = [];

    setShowSpinner(false);
    delay = setTimeout(() => {
      setShowSpinner(true);
    }, 1000);

    for (const newContactType of data) {
      const response = await fetchDataByContactType(newContactType);
      if (response !== null) {
        arrayOfPromises.push(response);
      }
    }

    // mark Licensed Producer as Saved
    const contactTypeLicensedProducer: string = 'complete_37';
    let completedContactType = document.getElementById(contactTypeLicensedProducer);
    if (completedContactType !== null) {
      completedContactType.style.color = 'rgb(77, 132, 11)';
      completedContactType.style.border = '1px solid rgb(77, 132, 11)';
      completedContactType.innerText = 'Saved';
    }

    if (arrayOfPromises.length === data.length) {
      let isValid = true;
      arrayOfPromises.forEach((response: ContactTypeResponse) => {
        if (response && response?.status !== 'success') {
          isValid = false;
        }
      });

      if (isValid && !saveError) {
        if (addingLicensedProducer) {
          const data = await checkIfLicenseProducerRequested({
            adminName: `${adminFirstName} ${adminLastName}`,
            adminPersonId: loggedInPersonId,
            selectedPersonId: personId,
          });
          clearTimeout(delay);
          setShowSpinner(false);
          setShowModalSpinner(false);
          setSpinnerStatus("hide");
          setSelectAll(true);
          props?.closeModal(false);
          if (data && data.is_requested === "True") {
            props.showAlreadyRequestedLP({ adminName: data.admin_name, requestedOn: data.created });
          } else {
            props.showAppGenModal(true);
          }
        } else {
          clearTimeout(delay);
          setShowSpinner(false);
          setShowModalSpinner(false);
          setSpinnerStatus("hide");
          setSelectAll(true);
          props?.closeModal(false);
        }
      } else {
        if (addingLicensedProducer) {
          const data = await checkIfLicenseProducerRequested({
            adminName: `${adminFirstName} ${adminLastName}`,
            adminPersonId: loggedInPersonId,
            selectedPersonId: personId,
          });
          clearTimeout(delay);
          setShowSpinner(false);
          setShowModalSpinner(false);
          setSpinnerStatus("hide");
          setSelectAll(true);
          setModalSaveError(true);
          props.saveError(true);
          if (data && data.is_requested === "True") {
            props.showAlreadyRequestedLP({ adminName: data.admin_name, requestedOn: data.created });
          } else {
            props.showAppGenModal(true);
          }
        } else {
          clearTimeout(delay);
          setShowSpinner(false);
          setShowModalSpinner(false);
          setSpinnerStatus("hide");
          setSelectAll(true);
          setModalSaveError(true);
          props.saveError(true);
        }
      }
    }
  }

  async function handleClickSaveChanges(event : React.MouseEvent) {
    if (displayedContactTypes.length === 1 && displayedContactTypes.includes(37)) {
      const data = await checkIfLicenseProducerRequested({
        adminName: `${adminFirstName} ${adminLastName}`,
        adminPersonId: loggedInPersonId,
        selectedPersonId: personId
      });
      if (data) {
        props.licensedProducerOnly(true);
        props.closeModal(false);
        props.showAlreadyRequestedLP({adminName: data.admin_name, requestedOn: data.created});
        return;
      }
    }
    
    if (displayedContactTypes.length === completed.length && !isEmployee) {
      setIsCompleted(true);
      const data: any = getValues('checkboxes');
      const ctArray: ContactTypeRequest[] = [];
      const acArray: SavedAgencyCodes[] = [];
      displayedContactTypes.forEach((ct: number) => {
        const agencyCodeArray: string[] = [];
        let ct_desc: string = '';
        contactTypes.map((typ: any) => {
          if (typ.id === ct.toString()) {
            ct_desc = typ.name === 'Agency Manager' ? 'Agency/Office Manager' : typ.name;
          }
        });
        activeAgencyCodes.forEach((agencyCode: AgencyCode) => {
          const checked: boolean = activeAgencyCodes.length === 1 ? true : data[`contacttype_${ct.toString()}`][`agencycode_${agencyCode.value}`];
          if (checked) {
            let ac = {
              contact_type: ct,
              agency_label: agencyCode.label
            }
            acArray.push(ac);
            agencyCodeArray.push(agencyCode.value);
          }
        });
        setSaveAgencyCodes(acArray);
        const dt: ContactTypeRequest = {
          contact_type: ct,
          contact_type_desc: ct_desc,
          agency_cd: agencyCodeArray,
          updated_by: changedBy
        }

        ctArray.push(dt);
      });

      if (displayedContactTypes.length === 1 && displayedContactTypes.includes(37)) {
        // if displayed contact types only is Licensed Producer
        generateAppGenPayload(ctArray);
        setAddingLicensedProducer(true);
        props.licensedProducerOnly(true);
        props.suppressToastMessage(true);
        props.showAppGenModal(true);
        props.closeModal(false);
      } else if (displayedContactTypes.length > 1 && displayedContactTypes.includes(37)) {
        // if displayed contact types includes Licensed Producer and other contact types

        // remove Licensed Producer contact type to prevent it from being added
        const contactTypes: ContactTypeRequest[] = [];
        ctArray.forEach((ct: ContactTypeRequest) => {
          if (ct.contact_type !== 37) {
            contactTypes.push(ct);
          }
        });

        generateAppGenPayload(ctArray);
        setAddingLicensedProducer(true);
        props.licensedProducerOnly(false);
        props.suppressToastMessage(true);
        setSaveContactTypes(contactTypes);
      } else {
        setAddingLicensedProducer(false);
        props.licensedProducerOnly(false);
        props.suppressToastMessage(false);
        setSaveContactTypes(ctArray);
      }
    }
  }

  function generateAppGenPayload(ctArray: ContactTypeRequest[]) {
    let agencyCodes: string[] = [];

    // get assinged agency codes for the Licensed Producer contact type
    ctArray.forEach((ct: ContactTypeRequest) => {
      if (ct.contact_type === 37) {
        agencyCodes = ct.agency_cd;
      }
    });

    const appGenPayload: AppGenPayloadAdd = {
      agency_admin_person_id: Number(loggedInPersonId),
      producer_first_name: firstName,
      producer_last_name: lastName,
      agency_codes: agencyCodes.join(','),
      new_contact: 'no',
      producer_email: email
    };

    props.appGenPayload(appGenPayload);
  }

  function handleClickSelectAll(event: React.MouseEvent, action: string, contactType: number) {
    activeAgencyCodes.forEach((agencyCode: AgencyCode) => {
      const element = document.getElementById(`contacttype_${contactType.toString()}_agencycode_${agencyCode.value}`);
      if (element !== null) {
        const parentElement = element.parentElement as HTMLElement;
        const parentOfParent = parentElement.parentElement as HTMLElement;
        if (!parentOfParent.classList.contains('hidden')) {
          const checked: boolean = getValues(`checkboxes.contacttype_${contactType.toString()}.agencycode_${agencyCode.value}`);
          if (action === 'select') {
            if (!checked) {
              element.click();
            }
          } else {
            if (checked) {
              element.click();
            }
          }
        }
      }
    });
    setSelectAll(!selectAll);
    handleSubmit(onSubmit)();
  }

  const accordion = () => {
    const accordions = props?.newContactTypes.map((ct: NewContactTypes) => {
      if (agencyBasedContactTypes.includes(ct.id)) {
        return (
          <SAAccordion
            key={`accordion_${ct.id}`}
            id={`accordion_${ct.id}`}
            expanded={expanded === ct.id}
            title={ct.contact_type_desc}
            textTransform="uppercase"
            onChange={handleChange(ct.id)}
            buttons={showComplete(ct.id)}
            variant="standard"
            disabled={disabled.includes(ct.id) ? true : false}
            onKeyDown={(e: any) => {
              if (e.key === ' ') {
                e.preventDefault();
                const element: HTMLElement | null = document.querySelector(`input[id="accordion_${ct.id.toString()}]`);
                if (element !== null) {
                  element.click();
                }
              }
            }}
          >
            {accordionContent(ct.id, isCompleted)}
          </SAAccordion>
        );
      } else {
        return false;
      }
    });
    return accordions;
  };

  const accordionContent = (contactType: number, completed: boolean) => {
    return (
      <AccordionContent>
        {!completed &&
          <SearchContainer>
              <Search>
                  <SASearch
                      values={searchTerms[contactType]}
                      fullWidth
                      id={`contacttype_${contactType}_searchbar`}
                      placeholder="Search by agency code, agency name, city, state, or zip"
                      onSearch={(search: Array<string> | string) => handleSearch(search, contactType)}
                      variant="search-terms"
                  />
              </Search>
            {!selectAll ? (
              <SelectAllButton
                label="Deselect All"
                onClick={(event: React.MouseEvent) => handleClickSelectAll(event, 'deselect', contactType)}
                textTransform="uppercase"
                variant="link-medium"
                color="accentBlue"
              />
            ) : (
              <SelectAllButton
                label="Select All"
                onClick={(event: React.MouseEvent) => handleClickSelectAll(event, 'select', contactType)}
                textTransform="uppercase"
                variant="link-medium"
                color="accentBlue"
              />
            )}
          </SearchContainer>
        }
        <AgencyCodesContainer>
          <span className="heading">Agency Codes</span>
          <AgencyCodesCheckboxes>
            {!isCompleted &&
              agencyCodes(contactType, isCompleted)
            }
            {isCompleted &&
              viewOnlyAgencyCodes(contactType, isCompleted)
            }
          </AgencyCodesCheckboxes>
        </AgencyCodesContainer>
        <ContinueButton>
          {!isCompleted && showContinueButtons.includes(contactType) &&
            <SAButton
                key={`button_continue_${contactType}`}
                label="Continue"
                onClick={(e) => handleClickContinue(contactType)}
                textTransform="uppercase"
                variant="secondary-medium"
                fullWidthUnder={mobileWidth}
            />
          }
        </ContinueButton>
      </AccordionContent>
    );
  };

  const viewOnlyAgencyCodes = (contactType: number, completed: boolean) => {
    if (completed) {
      return saveAgencyCodes.map((item: SavedAgencyCodes, index: number) => {
        if (item.contact_type === contactType) {
          return (
            <AgencyLocationWrapper key={index}>
              <AgencyLocations type="standard" text={item.agency_label}/>
            </AgencyLocationWrapper>
          )
        }
      })
    }
  }

  const agencyCodes = (contactType: number, completed: boolean) => {
    return (
      <>
        {!completed && activeAgencyCodes.map((agencyCode: AgencyCode) =>
          <SACheckbox
            {...register(`checkboxes.contacttype_${contactType.toString()}.agencycode_${agencyCode.value}`)}
            key={`contacttype_${contactType.toString()}_agencycode_${agencyCode.value}`}
            id={`contacttype_${contactType.toString()}_agencycode_${agencyCode.value}`}
            name={contactType.toString()}
            label={agencyCode.label}
            type="checkbox"
            defaultChecked={false}
            disabled={disabledCheckbox}
            onChange={(e: any) => {
              setValue(`checkboxes.contacttype_${contactType.toString()}.agencycode_${agencyCode.value}`, e.target.checked);
              setSelectAll(selectAllDeselectAllButtonState(contactType.toString()));
              handleSubmit(onSubmit)();
            }}
            onKeyDown={(e: any) => {
              if (e.key === ' ') {
                e.preventDefault();
                const element: HTMLElement | null = document.querySelector(`input[id="contacttype_${contactType.toString()}_agencycode_${agencyCode.value}"]`);
                if (element !== null) {
                  element.click();
                }
              }
            }}
            onClick={(event: any) => {
              const gen_details = JSON.parse(decrypt(localStorage.getItem('general_details')));
              const primaryAgencyCode = gen_details?.primary_agency_code ? gen_details.primary_agency_code : '';
              const agencyCodeMap = JSON.parse(decrypt(localStorage.getItem('agency_code_map')));
              let primaryAgencyCodeNationalNumber = '';
              let currentAgencyCodeNationalNumber = '';
              agencyCodeMap.forEach((agency: any) => {
                if (primaryAgencyCode === agency.agency_code) {
                  primaryAgencyCodeNationalNumber = agency?.national_number;
                }
                if (event.target.id.substr(event.target.id.lastIndexOf('_') + 1) === agency.agency_code) {
                  currentAgencyCodeNationalNumber = agency?.national_number;
                }
              });
              if (primaryAgencyCodeNationalNumber !== currentAgencyCodeNationalNumber) {
                setDisplayCrossHierarchyWarningModal(true);
                event.target.checked = false;
                simpleGTMDataLayer({
                  event: 'crossHierarchyModalEvent',
                  event_action: 'Add Contact Type',
                  event_category: window.location.pathname,
                  event_label: 'Profile Page'
                });
              }     
            }}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    if (displayedContactTypes.length) {
      // let start by setting all displayed contact types to be disabled and enable them as needed
      const newDisabled: number[] = [];
      displayedContactTypes.forEach((ct: number) => {
        newDisabled.push(ct);
      });
      const newShowContinueButtons: number[] = [];
      const newCompleted: number[] = [];
      displayedContactTypes.forEach((ct: number) => {
        activeAgencyCodes.forEach((ac: AgencyCode) => {
          props?.newContactTypes.forEach((ncAc: NewContactTypes) => {
            // display continue button
            if (!newShowContinueButtons.includes(ncAc.id)) {
              newShowContinueButtons.push(ncAc.id);
            }
          });
        });
      });
      setShowContinueButtons(newShowContinueButtons);
      setCompleted(newCompleted);

      // enable first of remaining disabled panels
      if (newDisabled.length) {
        newDisabled.splice(0, 1);
      }
      setDisabled(newDisabled);

      // set default expanded panel
      let newExpanded: number | boolean = false;
      displayedContactTypes.forEach((ct: number) => {
        if (!newDisabled.includes(ct) && !newCompleted.includes(ct) && typeof newExpanded === 'boolean') {
          newExpanded = ct;
        }
      });
      setExpanded(newExpanded);
    } else {
      // enable default panel
      const newDisabled: number[] = [];
      displayedContactTypes.forEach((contactType: number) => {
        if (!completed.includes(contactType)) {
          newDisabled.push(contactType);
        }
      });
      newDisabled.splice(0, 1);
      setDisabled(newDisabled);

      // set default expanded panel
      let newExpanded: number | boolean = false;
      displayedContactTypes.forEach((ct: number) => {
        if (!newDisabled.includes(ct) && !completed.includes(ct)) {
          newExpanded = ct;
          return;
        }
      });
      setExpanded(newExpanded);
    }

    // if there is only one active agency code per content type available we default it to be selected and show continue button
    if (activeAgencyCodes.length === 1) {
      const newShowContinueButtons: number[] = [];
      displayedContactTypes.forEach((ct: number) => {
        newShowContinueButtons.push(ct);
        activeAgencyCodes.forEach((ac: AgencyCode) => {
          const element: HTMLElement | null = document.querySelector(`input[id="contacttype_${ct.toString()}_agencycode_${ac.value}"]`);
          if (element !== null) {
            element.click();
            setDisabledCheckbox(true);
          }
        });
      });
      setShowContinueButtons(newShowContinueButtons);
      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function validateForm(data: any) {
      const newShowContinueButtons: number[] = [];
      showContinueButtons.forEach((ct: number) => {
        newShowContinueButtons.push(ct);
      });
      const newCompleted: number[] = [];
      completed.forEach((ct: number) => {
        newCompleted.push(ct);
      });

      displayedContactTypes.forEach((contactType: number) => {
        if (!disabled.includes(contactType)) {
          let panelValid: boolean = false;
          activeAgencyCodes.forEach((agencyCode: AgencyCode) => {
            const element: any | null = document.querySelector(`input[id="contacttype_${contactType.toString()}_agencycode_${agencyCode.value}"]`);
            if (element !== null && element.checked) {
              panelValid = true;
            }
          });

          if (panelValid) {
            if (!newShowContinueButtons.includes(contactType)) {
              newShowContinueButtons.push(contactType);
              setShowContinueButtons(newShowContinueButtons);
            }
          } else {
            // hide continue button for the panel
            if (newShowContinueButtons.includes(contactType)) {
              const index = newShowContinueButtons.indexOf(contactType);
              newShowContinueButtons.splice(index, 1);
              setShowContinueButtons(newShowContinueButtons);
            }

            // remove completed status for the panel
            if (newCompleted.includes(contactType)) {
              const index = newCompleted.indexOf(contactType);
              newCompleted.splice(index, 1);
              setCompleted(newCompleted);
            }
          }
        }
      });
    }
    formData !== null ? validateForm(formData) : setShowContinueButtons([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    // set default focus
    const element = document.querySelector('input[type=checkbox]') as HTMLElement;
    if (element) {
      setTimeout(() => {
        element.focus();
      });
    }
  }, []);

  const selectAllDeselectAllButtonState = (ct: string) => {
    let isChecked = false;
    const element = document.getElementsByName(ct);
    if (element !== null) {
      element.forEach((item: any) => {
        if (!item.checked) {
          isChecked = true;
        }
      })
    }
    return isChecked;
  }

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modalconfirmedit') as HTMLElement;
      if (modal) {
        const element = modal.querySelector('button.sa-button') as HTMLElement;
        if (element) {
          element.focus();
        }
      }
    }

    function focusModalConfirmEdit(e: any) {
      const modal = document.querySelector('#modalconfirmedit') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          const element = modal.querySelector('button.sa-button') as HTMLElement;
          if (element) {
            element.focus();
          }
        }
      }
    }

    focusDefault();
    document.addEventListener('focusin', focusModalConfirmEdit);
    document.addEventListener('focusout', focusModalConfirmEdit);

    return () => {
      document.removeEventListener('focusin', focusModalConfirmEdit);
      document.removeEventListener('focusout', focusModalConfirmEdit);
    }
  }, []);

  useEffect(() => {
    if (saveContactTypes && saveContactTypes.length) {
      sendData(saveContactTypes);
    }
  }, [saveContactTypes]);

  function handlePendingEditsClose() {
    if (!isAdmin) {
      const portalUrl = env['saconnect'];
      window.location.href = portalUrl;
    } else {
      history.push({
        pathname: `/agency-contacts`,
      });
    }
  }

  return (
    <>
      <Modal id="modalconfirmedit" onClickHandler={(event: React.MouseEvent) => modalOnClickHandler(event)} display={displayCancelAlert || displayCrossHierarchyWarningModal ? 'none' : 'flex'}>
        <Container constants={constants} isEmployee={isEmployee}>
          <SACard variant="minimal">
            <form id="agencycodesform" onSubmit={handleSubmit(onSubmit)}>
              <CardHeader>
                <CardTitle>
                  <SAText type="heading-2" text="Add Agency Code(s)" />
                  <SAButton
                    fullWidth={false}
                    variant="link-small"
                    label="Help Text"
                    onClick={togglePopover}
                    onKeyDown={handleKeyDown}
                  >
                    <SAIcon icon={SAIcons.information} size="20px" colorVariant="primary" onMouseOver={openPopover} onMouseLeave={closePopover} />
                  </SAButton>
                </CardTitle>
                <CardSubTitle>
                  <SAText type="standard" text="Select all the associated agency codes." />
                </CardSubTitle>
                {modalSaveError &&
                  <AlertMsg severity="error">
                      <SAStack spacing="small">
                          <div>
                              <SAAlertTitle>Contact types could not be saved</SAAlertTitle>
                              Please try again at a later time.
                          </div>
                      </SAStack>
                  </AlertMsg>
                }
              </CardHeader>
              <CardBody>
                {accordion()}
              </CardBody>
              <CardFooter>
                <div>
                  {modalSaveError ? (
                    <SAButton fullWidthUnder={mobileWidth} label="Close" onClick={handleClickCloseModal} variant="primary-large" textTransform="uppercase" />
                  ) : (
                    <SAButton label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
                  )}
                  {!showSpinner && !modalSaveError &&
                    <SAButton fullWidthUnder={mobileWidth} label="Save Changes" onClick={handleClickSaveChanges} variant="primary-large" textTransform="uppercase" disabled={saveButtonDisabled || isEmployee} />
                  }
                  {showSpinner && !modalSaveError &&
                    <DisabledSaveChangesButton endIcon={spinner} fullWidthUnder={mobileWidth} label="Save Changes" variant="primary-large" textTransform="uppercase" disabled />
                  }
                </div>
              </CardFooter>
            </form>
          </SACard>
        </Container>
        <CancelButton>
          <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
        </CancelButton>
        <SAPopover
          onClose={(e: MouseEvent, index: number | null) => closePopover(e, index)}
          offset={offset}
          placement={position}
          referenceElement={referenceElement}
          variant={mobile ? 'popover-minimal-nopadding' : 'popover-menu-no-click'}
        >
          <Popover>
            <HelpContainer>
              <HelpIcon>
                <SAIcon icon={SAIcons.information} size="20px" colorVariant="hsla(220, 38%, 97%, 1)" />
              </HelpIcon>
              <HelpText>
                Agency code assignment provides the individual with access to policy and account information associated with the selected agency codes. This also helps State Auto know who to contact by agency code. (ex. Primary Claim Contact)
              </HelpText>
            </HelpContainer>
          </Popover>
        </SAPopover>
      </Modal>
      {displayCancelAlert &&
        <ModalCancelAlert title="Changes will not be saved" onClickLeave={handleClickCancelAlertModalLeave} onClickStay={handleClickCancelAlertModalStay} />
      }
      {displayCrossHierarchyWarningModal &&
        <ModalCrossHierarchyWarning
          onClickClose={handleClickCloseCrossHierarchyWarningModal}
         />
      }
      {pendingEdits &&
        <ModalPendingEdits isAdmin={isAdmin} onClickClose={handlePendingEditsClose} />
      }
    </>
  );
 };

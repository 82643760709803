import { useState, useEffect } from "react";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import handleGraphQLErrors from "../../../utils/handleGraphQLErrors";
import { API, graphqlOperation } from "aws-amplify";
import { checkAuthStatus } from "../../../utils/utils";
import { encrypt, decrypt } from "../../../utils/crypto";
import { LandingPageAgencyCodeQuery } from "../../../API";
import { AgencyCode, AgencyCodes } from "../../../interfaces/interfaces";

type AgencyCodesParam = {
  personId: string;
  isAdmin: boolean;
};

export function useAgencyCodes({ personId, isAdmin }: AgencyCodesParam) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agencyCodes, setAgencyCodes] = useState<AgencyCode[] | null>(null);

  function sortByAgencyCode(a: AgencyCode, b: AgencyCode) {
    if (a.agency_code === b.agency_code) {
      return 0;
    } else {
      return (a.agency_code < b.agency_code) ? -1 : 1;
    }
  }

  async function fetchAgencyCodes() {
    setIsLoading(true);
    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseAgencyCodes = API.graphql(
      graphqlOperation(queries.landingPageAgencyCode, {
        admin_person_id: personId,
      }),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem("auth_accesstoken"))}`,
      }
    ) as Promise<{ data: LandingPageAgencyCodeQuery }>;

    promiseAgencyCodes
      .then((res) => {
        if (res.data) {
          return res.data.landingPageAgencyCode;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        setIsLoading(false);
        if (res?.statusCode === 200) {
          return res.body as AgencyCodes | null;
        } else {
          throw new Error("Something went wrong - 30");
        }
      })
      .then((result: AgencyCodes | null) => {
        if (result !== null) {
          if (result.hasOwnProperty("error")) {
            throw new Error("Something went wrong - 31");
          } else {
            result.agency_codes.sort(sortByAgencyCode);
            setAgencyCodes(result.agency_codes || []);
          }
        } else {
          throw new Error("Something went wrong - 32");
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err);
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });
  }

  useEffect(() => {
    if (isAdmin) {
      fetchAgencyCodes();
    }
  }, []);

  return {
    isLoading,
    agencyCodes,
  };
}

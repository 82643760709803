import React from "react";
import { SAButton } from "@saux/design-system-react";
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 98px;
`;

const Text = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 18px;
`;

const Button = styled(SAButton)`
  width: 100px;
`;

export default function ErrorPage() {
  function handleClick() {
    window.location.href = "/";
  }

  return (
    <Container>
      <Text>Oops, an error occurred.  Please try again and if the error persists contact Customer Service at 833-SAHelps (833-724-3577).</Text>
      <Button variant="primary" label="Close" textTransform="uppercase" fullWidth={false} onClick={handleClick}/>
    </Container>
  );
}

import React, {useEffect, useRef, useState} from 'react';
import { useHistory } from "react-router-dom"
import GeneralDetails from '../AgencyContactDetails/GeneralDetails/GeneralDetails';
import ContactType from '../AgencyContactDetails/ContactType/ContactType';
import ResidentialAddress from '../AgencyContactDetails/ResidentialAddress/ResidentialAddress';
import BusinessAddress from '../AgencyContactDetails/BusinessAddress/BusinessAddress';
import MailingAddress from '../AgencyContactDetails/MailingAddress/MailingAddress';
import Privileges from '../AgencyContactDetails/Privileges/Privileges';
import { SACard, SAText, SAButton, SAUXTheme, SASpinner } from '@saux/design-system-react';
import { Constants, Terminable } from '../../interfaces/interfaces';
import ModalSpinner from '../modal/ModalSpinner/ModalSpinner';
import './AgencyContactDetails.css';
import env from '../../env_config';
import styled from 'styled-components';
import ModalTerminateContact from '../modal/ModalTerminateContact/ModalTerminateContact';
import ModalAlert from '../modal/ModalTerminateContactsAlert/ModalTerminateContactsAlert';
import ModalContactAgencyOperations from '../modal/ModalContactAgencyOperations/ModalContactAgencyOperations';
import ConfirmEditModal from '../ConfirmEditModal';
import { encrypt, decrypt } from '../../utils/crypto';
import simpleGTMDataLayer from "../../utils/GTMHelpers/simpleGTMDataLayer";
import { constants } from '../../utils/constants';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { AdminPersonDetailsIsTerminableQuery, AdminPersonDetailsQuery, UpdatePendingPersonsTerminateMutation } from '../../API';
import handleGraphQLErrors from '../../utils/handleGraphQLErrors';
import ModalCrossHierarchyWarning from '../modal/ModalCrossHierarchyWarning/ModalCrossHierarchyWarning';
import { checkAuthStatus } from '../../utils/utils';

const mobileWidth = 600;

type PersonData = {
  business_address: [];
  general_details: [];
  mailing_address: [];
  person_id: string;
  privileges: [];
  residential_address: [];
}

type AgencyCodeMap = {
  agency_code_map: [];
}

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
  isEmployee?: boolean;
  hasAgencyCode?: boolean;
};

const Container = styled.div.attrs(({ constants, isEmployee }: StyleProps) => ({
  constants,
  isEmployee
}))`
  ${({ theme, constants, isEmployee }: StyleProps) => {
    const con: Constants = constants as Constants;
    const marginTop: number = isEmployee ? con.headerHeight + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeight + con.dockedStepperHeight + con.verticalPageOffset;
    const marginTopMobile: number = isEmployee ? con.headerHeightMobile + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeightMobile + con.dockedStepperHeight + con.verticalPageOffset;

    return `
      margin-top: ${marginTop}px;
    
      @media only screen and (max-width: ${mobileWidth}px) {
        margin-top: ${marginTopMobile}px;
      }
    `;
  }};
`;

const StyledSACard = styled(SACard)`
  padding: 30px;
  section{
    padding: 0px;
  }
  header{
    height: 0px;
  }

  @media only screen and (max-width: 600px) {
    padding: 15px;
  }
`;

const DisplayComponent = styled.div.attrs(({ hasAgencyCode }: StyleProps) => ({
  hasAgencyCode,
}))`
  ${({ hasAgencyCode }: StyleProps) => {
  const display = hasAgencyCode  ? "visible" : "hidden";

  return `
      visibility: ${display}
    `;
}};
`;

const SACardWrapper = styled.div`
  max-width:  1200px;
  margin: 0 15px 3rem 15px;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`;

export default function AgencyContactData(props: any) {
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  // Router Params
  const history = useHistory();
  const loggedInPersonId = decrypt(localStorage.getItem('personId'));
  const personId = decrypt(localStorage.getItem('selectedPersonId'));
  const loginId = decrypt(localStorage.getItem('selectedLoginId'));
  const firstName = decrypt(localStorage.getItem('selectedFirstName'));
  const lastName = decrypt(localStorage.getItem('selectedLastName'));
  const isAdmin = decrypt(localStorage.getItem('isAdmin')) === 'true' ? true : false;
  const [agencyCodeMap, setAgencyCodeMap] = useState<AgencyCodeMap | null>(null);
  const [personData, setPersonData] = useState<PersonData | null>(null);
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;
  const [isExpanded, setIsExanded] = useState<boolean>(false);
  const generalDetails = JSON.parse(decrypt(localStorage.getItem('general_details')) as any);
  const [busAddress, setBusAddress] = useState<any>({});
  const [mailAddress, setMailAddress] = useState<any>({});
  const [resAddress, setResAddress] = useState<any>({});
  const [hasAgencyCode, setHasAgencyCode] = useState<boolean>(true);
  const [gtm, setGTM] = useState<boolean>(false);
  const [crossHierarchy, setCrossHierarchy] = useState<boolean>(false);

  // Loading Spinner
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(true);

  // Modal - Terminate Contact
  const [displayTerminateContactModal, setDisplayTerminateContactModal] = useState<boolean>(false);
  const [terminableResponse, setTerminableResponse] = useState<Terminable | null>(null);

  // Modal - Agency Operations
  const [displayAgencyOperationsModal, setDisplayAgencyOperationsModal] = useState<boolean>(false);
  const [requiredContactTypes, setRequiredContactTypes] = useState<boolean>(false);
  const [agencyOpsModalText, setAgencyOpsModalText] = useState<string>('');

  //Modal - Cross Hierarchy
  const [displayCrossHierarchyWarningModal, setDisplayCrossHierarchyWarningModal] = useState<boolean>(false);

  // loading spinner
  const [showModalSpinner, setShowModalSpinner] = useState<boolean>(false);

  const [openEdit, setOpenEdit] = useState('default');
  const editState = decrypt(localStorage.getItem('edit_state'));

  if (editState !== undefined) {
    localStorage.setItem('edit_state', encrypt(openEdit));
  } else {
    setOpenEdit(editState);
  }
  const adminPersonId =  decrypt(localStorage.getItem('personId'));
  const showAlertModal = agencyCodeMap && (agencyCodeMap as any).length === 0;

  function changeEditState(edit: string) {
    const isNotEdit = ['general', 'mailing', 'business', 'residential'].includes(edit);
    setIsExanded(isNotEdit);
    setOpenEdit(edit);
    localStorage.setItem('edit_state', encrypt(edit));
  }

  function onClickClose(): void {
    window.location.href = env.saconnect;
  }

  function changeAgencyCode(code: boolean) {
    if (code) {
      setHasAgencyCode(code);
    }
  }

  function handleAgencyOpsModalClose(event: React.MouseEvent<Element, MouseEvent>) {
    const isEditMode = decrypt(localStorage.getItem('primaryAgencyCodeSaveButton'));
    if (isEditMode === 'true') {
      const element: HTMLElement | null = document.getElementById('general-details-edit');
      if (element) {
        element.click();
      }
    }
    setDisplayAgencyOperationsModal(!displayAgencyOperationsModal);
  }

  function handleClickCloseCrossHierarchyWarningModal() {
    setDisplayCrossHierarchyWarningModal(!displayCrossHierarchyWarningModal);
  }

  function handleClickTerminateContactModalCancel(event: React.MouseEvent) {
    setDisplayTerminateContactModal(!displayTerminateContactModal);
  }

  async function handleClickTerminateContactModalConfirm(additionalComments: string) {
    // make API call to send terminate contact email
    const body = {
      agency_admin_person_id: loggedInPersonId?.toString(),
      comments: additionalComments.trim()
    }

    setShowModalSpinner(true);
    setDisplayTerminateContactModal(!displayTerminateContactModal);

    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseTerminateContactEmail = API.graphql(
      graphqlOperation(
        mutations.updatePendingPersonsTerminate,
        {
          body: body,
          person_id: personId
        }
      ),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
      }
    ) as Promise<{ data: UpdatePendingPersonsTerminateMutation }>;
    
    promiseTerminateContactEmail.then((res) => {
      if (res.data) {
        return res.data.updatePendingPersonsTerminate;
      } else {
        handleGraphQLErrors(res);
      }
    })
    .then((res) => {
      if (res?.statusCode === 200) {
        localStorage.setItem('contactTerminated', encrypt('true'));
        history.push({
          pathname: `/agency-contacts`,
        });
      } else {
        throw new Error('Something went wrong - 01');
      }
    })
    .catch((err) => {
      if (err?.message) {
        console.error(err.message, err); 
      } else {
        handleGraphQLErrors(err);
      }
      window.location.href = "error";
    })
    .finally(() => {
      setShowModalSpinner(false);
    });
  }

  function goToPortal() {
    const portalUrl = env['saconnect'];
    window.location.href = portalUrl;
  }

  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const editState = decrypt(localStorage.getItem('edit_state'));
    if (editState === 'default') {
      simpleGTMDataLayer({
        event: 'terminateContact',
        event_action: 'Button Click',
        event_category: window.location.pathname,
        event_label: 'Profile: Terminate Contact'
      });
      handleTerminateContact();
    } else {
      setDisplayModal(true);
    }
  }

  const confirmModal = (event: React.MouseEvent, action: string) => {
    event.stopPropagation();
    if (action === 'close') {
      setDisplayModal(!displayModal);
    }
    if (action === 'discard') {
      setDisplayModal(!displayModal);
      changeEditState('default');
      handleTerminateContact();
    }
  }

  const findPrimaryAgencyCode = (arr: any, code: string) => {
      const isValid: boolean = arr.some((agency: any) => agency.agency_code === code);
      return isValid;
  }

  async function handleTerminateContact() {
    // Terminate Contact

    // if logged in user's assigned agency codes match contact's assigned agency codes, and contact's contact types doesn't include principal then show terminate contact modal

    setShowModalSpinner(true);

    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseTerminable = API.graphql(
      graphqlOperation(
        queries.adminPersonDetailsIsTerminable,
        { 
          admin_person_id: loggedInPersonId,
          person_id: personId
        }
      ),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
      }
    ) as Promise<{ data: AdminPersonDetailsIsTerminableQuery }>;

    promiseTerminable.then((res) => {
      if (res.data) {
        return res.data.adminPersonDetailsIsTerminable;
      } else {
        handleGraphQLErrors(res);
      }
    })
    .then((res) => {
      setShowModalSpinner(false);
      if (res?.statusCode === 200) {
        const response = res.body as Terminable;
        if (response.status === 'success') {
          // you have permission to terminate
          setDisplayTerminateContactModal(!displayTerminateContactModal);
        }
      } else if (res?.statusCode === 400) {
        const response = res.body as Terminable;
        if (response.status === 'failure') {
          switch (response.reason) {
            case 'permissions':
              // Do not have adequate permission to terminate
              setAgencyOpsModalText('You do not have permission to terminate. Contact Agency Operations for assistance.');
              setRequiredContactTypes(false);
              setDisplayAgencyOperationsModal(!displayAgencyOperationsModal);
              break;
            case 'principal':
              // Principal cannot be terminated
              setAgencyOpsModalText('Principal cannot be terminated. Contact Agency Operations for assistance.');
              setRequiredContactTypes(false);
              setDisplayAgencyOperationsModal(!displayAgencyOperationsModal);
              break;
            case 'contact types':
              // you have permission to terminate, but are attempting to remove the last required contact type of an agency
              setAgencyOpsModalText('');
              setRequiredContactTypes(true);
              setTerminableResponse(response);
              setDisplayAgencyOperationsModal(!displayAgencyOperationsModal);
              break;
            case 'self terminate':
              // admin cannot terminate self
              setAgencyOpsModalText('You cannot self-terminate. For assistance, contact another Agency Admin or reach out to Agency Operations.');
              setRequiredContactTypes(false);
              setDisplayAgencyOperationsModal(!displayAgencyOperationsModal);
              break;
          }
        }
      } else {
        throw new Error('Something went wrong - 02');
      }
    })
    .catch((err) => {
      if (err?.message) {
        console.error(err.message, err); 
      } else {
        handleGraphQLErrors(err);
      }
      window.location.href = "error";
    })
    .finally(() => {
      setShowModalSpinner(false);
    });
  }

  function handleUpdateBusinessAddress(businessAddressObj: any) {
    if (personData?.business_address && businessAddressObj) {
      personData.business_address = businessAddressObj;
    }
  }

  useEffect(() => {
    if (!gtm) {
      simpleGTMDataLayer({
        event: 'pageview',
        page: {
          path: '/agency-contact-profile',
          title: 'ADJSS: Profile'
        }
      });
      setGTM(true);
    }
  }, []);

  useEffect(() => {
    if (!document.getElementById('modal')) {
      const modalRoot: HTMLDivElement = document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      document.body.append(modalRoot);
    }
  }, []);

  useEffect(() => {
    if (displayTerminateContactModal || displayAgencyOperationsModal || displayModal || displayCrossHierarchyWarningModal) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [displayTerminateContactModal, displayAgencyOperationsModal, displayModal, displayCrossHierarchyWarningModal]);

  useEffect(() => {
    async function initial() {
      const isAuthenticated: boolean = await checkAuthStatus();
      if (!isAuthenticated) {
        return;
      }
      const promiseAdminPersonDetails = API.graphql(
        graphqlOperation(
          queries.adminPersonDetails,
          {
            admin_person_id: adminPersonId,
            person_id: personId
          }
        ),
        {
          Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
        }
      ) as Promise<{ data: AdminPersonDetailsQuery }>;

      promiseAdminPersonDetails.then((res) => {
        if (res.data) {
          return res.data.adminPersonDetails;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200) {
          return res.body;
        } else {
          throw new Error('Something went wrong - 03');
        }
      })
      .then((result) => {
        if (result !== undefined) {
          if (result?.is_cross_hierarchy_exists === '1') {
            setCrossHierarchy(true);
            simpleGTMDataLayer({
              event: 'crossHierarchyFlagEvent',
              event_action: 'View Page',
              event_category: window.location.pathname,
              event_label: 'Profile Page'
            });
          }
          setAgencyCodeMap(result?.agency_code_map as AgencyCodeMap | null);
          localStorage.setItem('agency_code_map', encrypt(JSON.stringify(result?.agency_code_map)));
          localStorage.removeItem('general_details');
          localStorage.setItem('general_details', encrypt(JSON.stringify(result?.general_details)));
          localStorage.removeItem('bus_address');
          localStorage.setItem('bus_address', encrypt(JSON.stringify(result?.business_address)));
          localStorage.removeItem('res_address');
          localStorage.setItem('res_address', encrypt(JSON.stringify(result?.residential_address)));
          localStorage.removeItem('mail_address');
          localStorage.setItem('mail_address', encrypt(JSON.stringify(result?.mailing_address)));
          localStorage.removeItem('privileges');
          localStorage.setItem('privileges', encrypt(JSON.stringify(result?.privileges)));
          setPersonData(result as PersonData | null);
          if (result?.business_address) {
            setBusAddress(result.business_address);
          }
          if (result?.mailing_address) {
            setMailAddress(result.mailing_address);
          }
          if (result?.residential_address) {
            setResAddress(result.residential_address);
          }
          setShowLoadingSpinner(false);
          const isValid: boolean = findPrimaryAgencyCode(
            result?.agency_code_map,
            result?.general_details?.primary_agency_code ? result.general_details.primary_agency_code : ''
          );
          if (isValid !== undefined) {
            setHasAgencyCode(isValid);
          }
        } else {
          throw new Error('Something went wrong - 04');
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err); 
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });
    }

    initial();
  }, []);

  useEffect(() => {
    if (personData?.business_address) {
      setBusAddress(JSON.parse(JSON.stringify(personData?.business_address)));
    }
  }, [personData?.business_address]);

  useEffect(() => {
    if (personData?.mailing_address) {
      setMailAddress(JSON.parse(JSON.stringify(personData?.mailing_address)));
    }
  }, [personData?.mailing_address]);

  useEffect(() => {
    if (personData?.residential_address) {
      setResAddress(JSON.parse(JSON.stringify(personData?.residential_address)));
    }
  }, [personData?.residential_address]);

  return (
    <>
      {showLoadingSpinner && <SpinnerContainer><SASpinner variant="circular-continuous" /></SpinnerContainer>}
      {!showLoadingSpinner &&
        <Container id="agency" constants={constants} isEmployee={isEmployee}>
          <SACardWrapper>
          <div className="personInfo">
            <div>
              <SAText
                type="heading-2"
                text={
                  firstName + " " + lastName
                }
              />
              <SAText type="standard" text={`Login ID: ${loginId ? loginId : '-'}`} />
            </div>
            {isAdmin &&
              <div className="terminateButton">
                <SAButton
                  fullWidth={false}
                  label="Terminate Contact"
                  onClick={handleClick}
                  textTransform="uppercase"
                  variant="secondary-small-outline"
                />
              </div>
            }
            {!isAdmin &&
              <div>
                <SAButton
                  fullWidth={false}
                  label="Go Back to SAConnect"
                  onClick={goToPortal}
                  textTransform="uppercase"
                  variant="secondary-small-outline"
                />
              </div>
            }
          </div>
          </SACardWrapper>
          {!showAlertModal &&
            <SACardWrapper>
            <StyledSACard variant="simple">
              <GeneralDetails crossHierarchy={crossHierarchy} isAdmin={isAdmin} agencyCodeMap={agencyCodeMap} personId={personData?.person_id} openEdit={openEdit} changeAgencyCode={changeAgencyCode} change={changeEditState} />
              <DisplayComponent hasAgencyCode={hasAgencyCode}>
                {isAdmin &&
                  <>
                      <hr/>
                      <ContactType crossHierarchy={crossHierarchy} displayCrossHierarchyModal={setDisplayCrossHierarchyWarningModal} agencyCodeMap={agencyCodeMap} isExpanded={isExpanded} openEdit={openEdit}
                                   change={changeEditState}/>
                  </>
                }
                  <hr />
                  <BusinessAddress businessAddress={busAddress} onChangeBusinessAddress={handleUpdateBusinessAddress} openEdit={openEdit} change={changeEditState} />
                  <hr />
                  <MailingAddress businessAddress={busAddress} mailAddress={mailAddress} openEdit={openEdit} change={changeEditState} />
                  <hr />
                  <ResidentialAddress residentialAddress={resAddress} openEdit={openEdit} change={changeEditState} />
                {isAdmin &&
                  <>
                  <hr />
                  <Privileges privilegeData={personData?.privileges} openEdit={openEdit} change={changeEditState} />
                  </>
                }
              </DisplayComponent>
            </StyledSACard>
            </SACardWrapper>
          }
        </Container>
      }
      {displayTerminateContactModal &&
        <ModalTerminateContact onClickCancel={handleClickTerminateContactModalCancel} onClickConfirm={handleClickTerminateContactModalConfirm} />
      }
      {displayAgencyOperationsModal &&
        <ModalContactAgencyOperations
          title="Contact Cannot be Terminated"
          text={agencyOpsModalText}
          requiredContactTypes={requiredContactTypes}
          terminableResponse={terminableResponse}
          onClickClose={handleAgencyOpsModalClose}
        />
      }
      {displayCrossHierarchyWarningModal &&
        <ModalCrossHierarchyWarning
          onClickClose={handleClickCloseCrossHierarchyWarningModal}
         />
      }
      {displayModal && (
        <ConfirmEditModal confirmModal={confirmModal} />
      )}
      {showModalSpinner && <ModalSpinner />}
      {showAlertModal && 
        <ModalAlert 
          title='Account Inaccessible' 
          text={
            <span>
              Your account is inaccessible. Reach out to{" "}
              <a href="mailto:Agency.Operations@stateauto.com">Agency.Operations@stateauto.com</a> for updates.
            </span>
          }
          onClickClose={onClickClose}/>
      }
    </>
  );
}

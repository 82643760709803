import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useHistory } from "react-router-dom";
import { AppContextProps } from '../../../interfaces/interfaces';
import ConfirmEditModal from '../../ConfirmEditModal';
import {
  SAButton,
  SACheckbox,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAPopover,
  SASnackbar,
  SASpinner,
  SAUX360Theme,
  SAUXTheme
} from '@saux/design-system-react';
import env from '../../../env_config';
import {
  Container,
  StyledHeader,
  StyledSAButton,
  Header,
  SectionWrapper,
  PrivilegesContent,
  PrivilegesStyledText,
  FooterButtons,
  StyledHeaderText,
  PrivilegesSectionWrapper,
  PrivilegesViewWrapper,
  StyledCancelButton,
  ProfileSpinnerContainer,
  DisabledContinueButton
} from '../AgencyContactDetailsStyles/AgencyContactDetailsStyles';
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import ReactDOM from "react-dom";
import { encrypt, decrypt } from '../../../utils/crypto';
import ModalPendingEdits from "../../modal/ModalPendingEdits/ModalPendingEdits";
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { UpdatePendingPersonPrivilegesMutation } from '../../../API';
import handleGraphQLErrors from '../../../utils/handleGraphQLErrors';
import { checkAuthStatus } from '../../../utils/utils';

type StyleProps = {
  theme?: SAUXTheme;
};

type Position = 'top-start' | 'top' | 'top-end' | 'right' | 'bottom-start' | 'bottom' | 'bottom-end' | 'left';
type Offset = [number, number];

const mobileWidth = 600;

const Popover = styled.div`
  ${({ theme }: StyleProps) => {
  return `
      display: flex;
      flex-direction: column;
      color: hsla(220, 38%, 97%, 1);
      font-size: ${theme?.font.primary.size.smallTEMP};
      font-weight: ${theme?.font.primary.weight.normal};
      line-height: 16px;
      background-color: hsla(213, 30%, 35%, 1);
      border: 1px solid hsla(213, 30%, 35%, 1);
      box-sizing: border-box;
      box-shadow: 0px 0px 5px rgba(0, 32, 56, 0.22);
      border-radius: 4px;
      padding: 15px;
      width: 360px;
    `;
}};
`;

const HelpContainer = styled.div`
  display: flex;
  flex-directino: column;
`;

const HelpIcon = styled.div`
  display: flex;
  padding-right: 10px;
  align-items: flex-start;
`;

const HelpText = styled.div`
  display: flex;
  align-items: flex-start;
`;

export default function FullWidthGrid(props: any) {
  let desc = [
    "No Privileges"
  ];

  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;
  const isAdmin: boolean = decrypt(localStorage.getItem('isAdmin')) === 'true' ? true : false;
  const default_desc: string[] = ["Commercial Lines", "Personal Lines", "View Agency Level Reports"];
  const [privileges, setPrivileges] = useState(desc);
  const [isEdit, setIsEdit] = useState(false);
  const [agencyCode, setAgencyCode] = useState<string | null>('');
  const [editPrivileges, setEditPrivileges] = useState<any[]>([]);
  const [agencyCodes, setAgencyCodes] = useState<any[]>([]);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarTitle, setSnackbarTitle] = useState<string>('');
  const [snackbarSubtitle, setSnackbarSubtitle] = useState<string>('');
  const location = useLocation<AppContextProps>();
  const personId = decrypt(localStorage.getItem('selectedPersonId'));
  const changedBy = decrypt(localStorage.getItem('personId'));
  const loginId = decrypt(localStorage.getItem('selectedLoginId'));
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [pendingEdits, setPendingEdits] = useState<boolean>(false);
  const history = useHistory();

  // Popover Help Text
  const desktopPosition: Position = 'right';
  const mobilePosition: Position = 'top-end';
  const desktopOffset: Offset = [0, 10];
  const mobileOffset: Offset = [0, 10];
  const [mobile, setMobile] = useState<boolean>(true)
  const [popover, setPopover] = useState<string | null>(null);
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const rememberRefElem = useRef<any>(null);
  const [position, setPosition] = useState<Position>(
    document.documentElement.clientWidth <= mobileWidth ? mobilePosition : desktopPosition,
  );
  const [offset, setOffset] = useState<Offset>(
    window.innerWidth <= mobileWidth ? mobileOffset : desktopOffset,
  );

  // Spinner
  let delay: ReturnType<typeof setTimeout>;
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [spinnerStatus, setSpinnerStatus] = useState<string>('hide');

  const spinner = (
    <ProfileSpinnerContainer display={spinnerStatus}>
      <SASpinner variant="circular-continuous" delay={0} color={SAUX360Theme.colors.blueGray600} size="20px" />
    </ProfileSpinnerContainer>
  );

  useEffect(() => { localStorage.removeItem('privileges'); }, []);
  const editState = props?.openEdit;
  const changeEditState = props?.change;
  const themeContext = useContext(ThemeContext);
  let savedPrivileges: any[] = [];

  const formMethod = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethod;

  const startIcon = (
    <SAIcon
        icon={SAIcons.pencil}
        size={SAIconSize.small}
        colorVariant="secondary"
    />
  );

  function openPopover(event: React.MouseEvent<HTMLElement>, privilege: string) {
    event.stopPropagation();
    setPopover(privilege);
    setReferenceElement(event.currentTarget);
  }

  function closePopover(event: React.MouseEvent<HTMLElement> | MouseEvent, index: number | null) {
    setReferenceElement(null);
  }

  function togglePopover(event: React.MouseEvent<HTMLElement>, privilege: string) {
    setPopover(privilege);
    event.stopPropagation();
    if (rememberRefElem.current === null) {
      setReferenceElement(event.currentTarget.firstChild as HTMLElement);
      rememberRefElem.current = event.currentTarget.firstChild;
    } else {
      setReferenceElement(null);
      rememberRefElem.current = null;
    }
  }

  function handleKeyDown(e: any, privilege: string) {
    setPopover(privilege);
    if (e.key === 'Tab') {
      e.stopPropagation();
      setReferenceElement(null);
      rememberRefElem.current = null;
    }
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      if (rememberRefElem.current === null) {
        setReferenceElement(e.currentTarget.firstChild as HTMLElement);
        rememberRefElem.current = e.currentTarget.firstChild;
      } else {
        setReferenceElement(null);
        rememberRefElem.current = null;
      }
    }
  }

  function updateHelpText() {
    if (window.innerWidth <= mobileWidth) {
      setMobile(true);
      setPosition(mobilePosition);
      setOffset(mobileOffset);
    } else {
      setMobile(false);
      setPosition(desktopPosition);
      setOffset(desktopOffset);
    }
    window.addEventListener('resize', updateHelpText);
  }

  function handlePendingEditsClose() {
    if (!isAdmin) {
      const portalUrl = env['saconnect'];
      window.location.href = portalUrl;
    } else {
      history.push({
        pathname: `/agency-contacts`,
      });
    }
  }

  useEffect(() => {
    let a: any = [];
    let allPrivileges = props?.privilegeData;
    if (allPrivileges) {
      allPrivileges?.forEach((i: any) => {
        a = [i];
      });
      setAgencyCode(decrypt(localStorage.getItem('agency_code')));
      setAgencyCodes(props?.privilegeData);
      let s = default_desc.filter(x => !allPrivileges?.includes(x));
      let edit: any = [];
      let privileges: any = [];

      s.map(x => {
          let k = {value: x, check: false}
          edit.push(k);
      });

      allPrivileges?.map((y: any) => {
          let k = {value: y, check: true};
          edit.push(k);
          privileges.push(y);
      });

      const sortedPrivileges = edit.sort(function (a: any, b: any) {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });

      setEditPrivileges(sortedPrivileges);
      localStorage.setItem('privileges', encrypt(JSON.stringify(sortedPrivileges)));
      if (privileges.length < 1) {
        setPrivileges(desc);
      } else {
        setPrivileges(privileges);
      }
    }
  }, [props?.privilegeData]);

  useEffect(() => {
    default_desc.forEach((privilege: string, index: number) => {
      const element: HTMLElement | null = document.querySelector(`label[for="privileges_${index}"]`);
      if (element) {
        const jsxElement: JSX.Element = (
          <SAButton
            fullWidth={false}
            variant="link-small"
            label="Help Text"
            onClick={(e: any) => togglePopover(e, privilege)}
            onKeyDown={(e: any) => handleKeyDown(e, privilege)}
          >
            <SAIcon icon={SAIcons.information} size="20px" colorVariant="primary" onMouseOver={(e: any) => openPopover(e, privilege)} onMouseLeave={closePopover} />
          </SAButton>
        );
        const parentElement = element.parentElement as HTMLElement;
        if (parentElement.children.length > 2) {
          parentElement.lastChild?.remove();
        }
        const divElement = document.createElement('div');
        divElement.classList.add('helpicon');
        ReactDOM.render(jsxElement, divElement);
        parentElement.appendChild(divElement);
      }
    });
  }, [mobile, editPrivileges]);

  useEffect(() => {
    if (displayModal) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [displayModal]);

  const confirmModal = (event: React.MouseEvent, action: string) => {
    event.stopPropagation();
    if (action === 'close') {
      setDisplayModal(!displayModal);
    }
    if (action === 'discard') {
      handleEdit('discard');
      setDisplayModal(!displayModal);
    }
  }

  async function sendData(data: any) {
    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promisePrivileges = API.graphql(
      graphqlOperation(
        mutations.updatePendingPersonPrivileges,
        {
          body: data,
          person_id: personId
        }
      ),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
      }
    ) as Promise<{ data: UpdatePendingPersonPrivilegesMutation }>;

    promisePrivileges.then((res) => {
      if (res.data) {
        return res.data.updatePendingPersonPrivileges;
      } else {
        handleGraphQLErrors(res);
      }
    })
    .then((res) => {
      if (res?.statusCode === 200 || res?.statusCode === 400) {
        return res.body;
      } else {
        throw new Error('Something went wrong - 21');
      }
    })
    .then((result) => {
      // TODO if state being used add result to state
      if (result !== undefined && result !== null) {
        if (result.status === 'success') {
          setSnackbarTitle('Changes Saved')
          setSnackbarSubtitle('All your changes have been saved');
          setShowSnackbar(true);

          let newPrivileges: string[] = [];
          editPrivileges.filter((item: any, key: number) => {
            if (item.check) {
              newPrivileges.push(item.value);
            }
          });
          if (newPrivileges.length === 0) {
            newPrivileges.push('No Privileges');
          }

          setPrivileges(newPrivileges);
          savedPrivileges = newPrivileges;
          setEditPrivileges(savedPrivileges);
          localStorage.setItem('privileges', encrypt(JSON.stringify(editPrivileges)));
          clearTimeout(delay);
          setShowSpinner(false);
          setSpinnerStatus('hide');
          handleEdit('cancel');
        }
        if (result.status === 'error') {
          clearTimeout(delay);
          setShowSpinner(false);
          setSpinnerStatus('hide');
          setPendingEdits(true);
        }
      }
    })
    .catch((err) => {
      clearTimeout(delay);
      setShowSpinner(false);
      setSpinnerStatus('hide');
      if (err?.message) {
        console.error(err.message, err); 
      } else {
        handleGraphQLErrors(err);
      }
      window.location.href = "error";
     });
  }

  const handleEdit = (action: string) => {
    if (action === 'cancel') {
      changeEditState('default');
      setEditPrivileges(savedPrivileges);
    }
    if (action === 'edit') {
      updateHelpText();
      if (editState !== 'privileges' && editState !== 'default') {
        setDisplayModal(true);
        localStorage.setItem('incoming', encrypt('privileges'));
      } else {
        setEditPrivileges(savedPrivileges);
        formMethod.reset();
        changeEditState('privileges');
      }
    }
    if (action === 'discard') {
      const inComing = decrypt(localStorage.getItem('incoming'));
      if (inComing !== undefined) {
        setEditPrivileges(savedPrivileges);
        formMethod.reset();
        changeEditState(inComing);
      }
    }
  }

  function savePrivileges() {
    if (!isEmployee) {
      setShowSpinner(true);
      setSpinnerStatus('hide');

      delay = setTimeout(() => {
        setSpinnerStatus('display');
      }, 1000);

      let personPrivileges: any = [];
      let newPrivileges: any[] = [];
      editPrivileges.filter((item: any, key: number) => {
        if (item.check) {
          newPrivileges.push(item.value);
        }
      });

      let privilegesByAgencyCode = { "updated_by": changedBy, "user_privileges": newPrivileges};
      personPrivileges.push(privilegesByAgencyCode);
      sendData(personPrivileges[0])
    }
  }

  function checkValue(e: any) {
    if (e.target) {
      let index = editPrivileges.findIndex(obj => obj.value === e.target.name);
      editPrivileges[index].check = e.target.checked;
      setEditPrivileges(editPrivileges);
    }
  }

  function onSnackbarClose() {
    setShowSnackbar(false);
  }

  const privilegesCheckboxes = () => {
    if (editPrivileges.length < 1) {
      savedPrivileges = JSON.parse(decrypt(localStorage.getItem('privileges')) as any);
      if (savedPrivileges !== null && savedPrivileges.length > 0) {
        setEditPrivileges(savedPrivileges);
      }
    }

    return (
      editPrivileges?.map((el, idx) => {
        if (el?.value !== 'Make Sweep Payments') {
          return (
            <SACheckbox
              {...register(`privileges_${idx.toString()}`)}
              id={`privileges_${idx.toString()}`}
              key={idx.toString()}
              name={el?.value}
              label={el?.value}
              type="checkbox"
              checked={el?.check}
              onChange={e => checkValue(e)}
            />
          )
        }
      })
    )
  }

  return (
    <div>
      <SASnackbar
        open={showSnackbar}
        variant="snackbar-success"
        position="top-end"
        autoHideDuration={5000}
        title={snackbarTitle}
        subtitle={snackbarSubtitle}
        offset={[-25, 170]}
        onClose={onSnackbarClose}
      />
      {editState !== 'privileges' ? (
          <>
            <Container>
              <Header>
                <StyledHeader type="heading-3" text="Privileges" />
                <StyledSAButton
                  color="secondary"
                  label="Edit"
                  textTransform="none"
                  startIcon={startIcon}
                  variant="primary-link-medium"
                  onClick={() => handleEdit("edit")}
                />
              </Header>
              <PrivilegesViewWrapper>
                {privileges?.map((el, idx) => (
                  <PrivilegesContent key={idx}>
                    {el !== 'No Privileges' && el !== "Make Sweep Payments" && (<SAIcon icon={SAIcons.checkmark} size={SAIconSize.medium} colorVariant={themeContext.colors.accentBlue} />)}
                    <PrivilegesStyledText type="standard" text={(el === "") ? "No Privileges" : (el !== "Make Sweep Payments" ? el : '')} />
                  </PrivilegesContent>
                ))}
              </PrivilegesViewWrapper>
            </Container>
          </>
        ) :
        <Container>
          <Header>
            <StyledHeader type="heading-3" text="Edit Privileges" />
          </Header>
          <StyledHeaderText type="heading-4" text="Select all that apply" />
          <form onSubmit={handleSubmit(savePrivileges)}>
            <PrivilegesSectionWrapper>
              {privilegesCheckboxes()}
            </PrivilegesSectionWrapper>
            <SectionWrapper>
              <FooterButtons>
                <StyledCancelButton
                  label="Cancel"
                  onClick={() => handleEdit('cancel')}
                  variant="link-large"
                  color="text"
                />
                {!showSpinner &&
                  <SAButton fullWidthUnder={mobileWidth} label="Save Changes" type="submit" variant="primary" textTransform="uppercase" disabled={isEmployee} />
                }
                {showSpinner &&
                  <DisabledContinueButton endIcon={spinner} disabled fullWidthUnder={mobileWidth} label="Save Changes" type="submit" variant="primary" textTransform="uppercase" />
                }
              </FooterButtons>
            </SectionWrapper>
          </form>
        </Container>
      }
      <SAPopover
        onClose={(e: MouseEvent, index: number | null) => closePopover(e, index)}
        offset={offset}
        placement={position}
        referenceElement={referenceElement}
        variant={mobile ? 'popover-minimal-nopadding' : 'popover-menu-no-click'}
      >
        <Popover>
          <HelpContainer>
            <HelpIcon>
              <SAIcon icon={SAIcons.information} size="20px" colorVariant="hsla(220, 38%, 97%, 1)" />
            </HelpIcon>
            <HelpText>
              {popover === 'Personal Lines' &&
              <>Provides access to Personal Lines quoting, submission, endorsement and cancellation transactions.  Personal Lines includes the following lines of business: Homeowners, Personal Auto, Umbrella, and Dwelling Fire.</>
              }
              {popover === 'Commercial Lines' &&
              <>Provides access to Commercial Lines quoting, submission, endorsement and cancellation transactions. Commercial Lines includes the following lines of business: Businessowners', Business Auto, Commercial Package, Commercial Umbrella, Farm & Ranch, Farm Auto, Farm Umbrella, and Workers' Compensation.</>
              }
              {popover === 'View Agency Level Reports' &&
              <>Allows the user access to all agency reporting including commission reports.</>
              }
            </HelpText>
          </HelpContainer>
        </Popover>
      </SAPopover>
      {displayModal && (
        <ConfirmEditModal confirmModal={confirmModal} />
      )}
      {pendingEdits &&
          <ModalPendingEdits isAdmin={isAdmin} onClickClose={handlePendingEditsClose} />
      }
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import {
  SACard,
  SAText,
  SAButton,
  SAInput,
  SAPopover,
  SAIcon,
  SAIcons,
  SAIconSize,
  SASpinner,
  SAUXTheme,
  SAUX360Theme
} from '@saux/design-system-react';
import { NewContact, NewContactContactType, NewContactGeneralDetails, Position, Offset, Constants } from '../../interfaces/interfaces';
import normalizePhoneNumber, { checkAuthStatus } from '../../utils/utils';
import styled from 'styled-components';
import ContactDetailsSideCard from '../ContactDetailsSideCard/ContactDetailsSideCard';
import ModalCancelAlert from '../../components/modal/ModalCancelAlert/ModalCancelAlert';
import { encrypt, decrypt } from '../../utils/crypto';
import { constants } from '../../utils/constants';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { PersonLookupLoginIdExistsQuery } from '../../API';
import handleGraphQLErrors from '../../utils/handleGraphQLErrors';

const mobileWidth = 600;

type StyleProps = {
  error?: boolean;
  theme?: SAUXTheme;
  constants?: Constants;
  isEmployee?: boolean;
};

const Container = styled.div.attrs(({ constants, isEmployee }: StyleProps) => ({
  constants,
  isEmployee
}))`
  ${({ theme, constants, isEmployee }: StyleProps) => {
    const con: Constants = constants as Constants;
    const marginTop: number = isEmployee ? con.headerHeight + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeight + con.dockedStepperHeight + con.verticalPageOffset;
    const marginTopMobile: number = isEmployee ? con.headerHeightMobile + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeightMobile + con.dockedStepperHeight + con.verticalPageOffset;

    return `
      display: flex;
      flex-direction: row;
      max-width: 1200px;
      margin: ${marginTop}px ${theme?.size.spacer.medium} 60px ${theme?.size.spacer.medium};
    
      @media only screen and (max-width: ${mobileWidth}px) {
        margin: ${marginTopMobile}px ${theme?.size.spacer.medium} 0 ${theme?.size.spacer.medium};
      }
    
      article:first-child {
        flex-grow: 1;
      }
    
      article:last-child {
        margin-left: ${theme?.size.spacer.large};
        width: 300px;
        flex-shrink: 0;
      }

      .textLikeInputWrapper {
        label {
          z-index: 0;
        }

        legend {
          span {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      .saInputLabel {
        font-weight: ${theme?.font.primary.weight.normal};
      }
    `;
  }};
`;

const CardHeader = styled.div`
  padding-bottom: 45px;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  .closemodal {
    display: none;
  }

  @media only screen and (max-width: ${mobileWidth}px) {    
    button {
      display: none;
    }
  }
`;

const CardBody = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      div {
        &.saInputWrapper {
          width: 50%;
          display: block;
          margin: 0 ${theme?.size.spacer.medium} ${theme?.size.spacer.large} 0;
          padding-right: ${theme?.size.spacer.medium};

          @media only screen and (max-width: ${mobileWidth}px) {
            margin: 0 0 ${theme?.size.spacer.large} 0;
            padding-right: 0;
            width: 100%;
          }
        }

        &.textLikeInput {
          display: flex;
          font-size: ${theme?.font.primary.size.medium};

          input {
            display: flex;
            flex-grow: 1;
          }
        }
      }
    `;
  }};
`;

const CardFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 60px;

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
      }

      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      div {
        &:last-child {
          display: flex;
          flex-direction: row;

          button {
            margin-left: ${theme?.size.spacer.medium};

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              font-weight: ${theme?.font.primary.weight.bold};
            }
          }

          @media only screen and (max-width: ${mobileWidth}px) {
            button {
              &:first-child {
                display: none;
              }
            }
          }
        }

        @media only screen and (max-width: ${mobileWidth}px) {
          flex-grow: 1;
        }
      }
    `;
  }};
`;

const CancelButton = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: none;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: ${theme?.size.spacer.medium} ${theme?.size.spacer.medium};
      }
    `;
  }};
`;

const Span = styled.span`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: flex-start;
    
      @media only screen and (max-width: ${mobileWidth}px) {
        align-items: initial;
        flex-direction: column;
      }
    
      div {
        display: flex;
        flex-direction: row;
        flex-grow: 1;

        &.saInputWrapper {
          display: flex;
          flex-direction: column;

          div {
            &.textLikeInput {
              width: 100%;
            }
          }
        }
    
        &:first-child {
          div {
            &.saInputWrapper {
              padding-right: 0;
            }
          }
        }

        &:last-child {
          div {
            &.saInputWrapper {
              margin: 0 0 ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
              padding-right: 0;

              @media only screen and (max-width: ${mobileWidth}px) {
                margin: 0 0 ${theme?.size.spacer.large} 0;
              }
            }
          }
        }
      }
    `;
  }};
`;

const Popover = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F5F7FA;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 32, 56, 0.22);
  border-radius: 3px;
  padding: 10px;
  width: 200px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: flex-start;
      padding-top: 10px;

      &:first-child {
        padding-top: 0;
      }

      svg {
        display: flex;
        flex-shrink: 0;
        margin-right: 5px;
        margin-top: 5px;
        height: 4px;
        width: 20px;

        &.alert {
          margin-top: 0px;
          height: 12px;
          width: 20px;
        }
      }

      span {
        display: flex;
      }
    }
  }
`;

const Red = styled.span`
  ${({ theme }: StyleProps) => {
    return `
      small {
        color: ${theme?.colors.red};
        font-weight: ${theme?.font.primary.weight.bold};
      }
    `;
  }};
`;

const SpinnerContainer = styled.div`
  margin-left: 10px;
  svg:last-child {
    margin-left: 0;
  }
`;

const DisabledNextButton = styled(SAButton)`
  ${({ theme }: StyleProps) => {
    return `
      color: ${theme?.colors.white};
      background-color: ${theme?.colors.green};

      &:hover {
        background-color: ${theme?.colors.green};
      }
    `;
  }};
`;

export default function GeneralDetails(props: any) {
  const { register, handleSubmit, setValue, setError, formState: { errors }, clearErrors } = useForm();
  const onSubmit = (data: any) => validateForm(data);
  const history = useHistory();
  const newContact: NewContact = JSON.parse(decrypt(localStorage.getItem('NewContact')));
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const desktopPosition: Position = 'right';
  const mobilePosition: Position = 'top-end';
  const desktopOffset: Offset = [0, 10];
  const mobileOffset: Offset = [0, 10];
  const [position, setPosition] = useState<Position>(
    document.documentElement.clientWidth <= mobileWidth ? mobilePosition : desktopPosition,
  );
  const [offset, setOffset] = useState<Offset>(
    window.innerWidth <= mobileWidth ? mobileOffset : desktopOffset,
  );
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;
  const rememberRefElem = useRef<any>(null);

  // Spinner
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const spinner = (
    <SpinnerContainer>
      <SASpinner variant="circular-continuous" delay={0} color={SAUX360Theme.colors.blueGray600} size="20px" />
    </SpinnerContainer>
  );

  // Modal
  const [displayCancelAlert, setDisplayCancelAlert] = useState<boolean>(false);

  function closePopover(event: React.MouseEvent<HTMLElement> | MouseEvent, index: number | null) {
    setReferenceElement(null);
    rememberRefElem.current = null;
  }

  function togglePopover(event: React.MouseEvent<HTMLElement>) {
    if (rememberRefElem.current === null) {
      setReferenceElement(event.currentTarget as HTMLElement);
      rememberRefElem.current = event.currentTarget;
    } else {
      setReferenceElement(null);
      rememberRefElem.current = null;
    }
  }

  function getTenDigitPhone(phone: string) {
    // eslint-disable-next-line no-useless-escape
    return phone.replace(/\s/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\-/g, '');
  }

  async function validateForm(data: any) {
    setShowSpinner(false);

    const delay = setTimeout(() => {
      setShowSpinner(true);
    }, 1000);

    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseLoginIDValidation = API.graphql(
      graphqlOperation(
        queries.personLookupLoginIdExists,
        {
          login_id: data.loginId
        }
      ),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
      }
    ) as Promise<{ data: PersonLookupLoginIdExistsQuery }>;

    promiseLoginIDValidation.then((res) => {
      if (res.data) {
        return res.data.personLookupLoginIdExists;
      } else {
        handleGraphQLErrors(res);
      }
    })
    .then((res) => {
      if (res?.statusCode === 200) {
        return res.body as { login_id: string; exists: 'YES' | 'NO' } | null | undefined;
      } else {
        clearTimeout(delay);
        return null;
      }
    })
    .then((res) => {
      if (res) {
        if (res.exists === 'NO') {
          return res;
        } else if (res.exists === 'YES') {
          setError('loginId', { type: 'manual', message: 'Login ID already in use' });
          setShowSpinner(false);
          clearTimeout(delay);
          return null;
        } else {
          clearTimeout(delay);
          return null;
        }
      } else {
        clearTimeout(delay);
        return null;
      }
    })
    .then((res) => {
      if (res) {
        const generalDetails: NewContactGeneralDetails = {
          loginId: data.loginId,
          firstName: data.firstName,
          lastName: data.lastName,
          primaryPhone: getTenDigitPhone(data.primaryPhone)
        }
        newContact.contactDetails.generalDetails = generalDetails;
        localStorage.setItem('NewContact', encrypt(JSON.stringify(newContact)));
    
        const workflow = getSteps();
  
        clearTimeout(delay);
    
        if (workflow.includes('Agency Code')) {
          history.push({
            pathname: '/agency-codes'
          });
        } else {
          history.push({
            pathname: '/primary-agency-code'
          });
        }
      }
    })
    .catch((err) => {
      if (err?.message) {
        console.error(err.message, err); 
      } else {
        handleGraphQLErrors(err);
      }
      window.location.href = "error";
    });
  }

  function getSteps() {
    // return steps for the workflow
    // const contact: NewContact = JSON.parse(decrypt(localStorage.getItem('NewContact')));
    if (newContact !== null) {
      const assignmentLevels: string[] = [];
      newContact.contactDetails.contactTypes.forEach((ct: NewContactContactType) => {
        switch (ct.contacttype) {
          case 3:
          case 7:
          case 10:
          case 13:
          case 14:
          case 36:
          case 37:
            // Agency Admin - 3
            // Agency Staff - 7
            // Agency Manager - 10
            // Primary Claim Contact - 13
            // Primary Mail Recipient - 14
            // Accounting Manager - 36
            // Licensed Producer - 37
            if (!assignmentLevels.includes('Agency Code')) {
              assignmentLevels.push('Agency Code');
            }
            break;
        }
      });

      const workflow: string [] = ['Contact Type', 'General Details', 'Agency Code', 'Primary Agency Code', 'User Privileges'];

      let pagesToSuppress: string[] = ['Agency Code'];

      pagesToSuppress = pagesToSuppress.filter((page: string) => !assignmentLevels.includes(page));

      pagesToSuppress.forEach((page: string) => {
        const index: number = workflow.indexOf(page);
        workflow.splice(index, 1);
      });

      return workflow;
    } else {
      return [];
    }
  }

  function handleClickCancel(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleClickCancelAlertModalLeave(event: React.MouseEvent) {
    history.push({
      pathname: `/agency-contacts`
    });
  }

  function handleClickCancelAlertModalStay(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleClickPrevious(event : React.MouseEvent) {
    history.push({
      pathname: '/contact-types'
    });
  }

  function checkForSpaces(value: string) {
    // value should not contain spaces
    if (value.includes(' ')) {
      return false;
    } else {
      return true;
    }
  }

  function checkForSALogin(value: string) {
    // Value must not match State Auto employ login ID format.
    // Value should not start with 3 alpha and end with 4 numeric characters
    // if the length is 7
    if (value.length === 7) {
      let startMatch: boolean = false;
      let endMatch: boolean = false;
      let regex: RegExp = /[A-Za-z]{3}/;

      if (value.slice(0, 3).match(regex)) {
        startMatch = true;
      }
      regex = /[0-9]{4}/;

      if (value.slice(3, 7).match(regex)) {
        endMatch = true;
      }
      
      if (startMatch && endMatch) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  function checkForMaxNumSpecialChars(value: string) {
    // value cannot contain more than 3 special characters:
    const specialChars: string = '~`!@#$%^&*()_-+={[}]|\\:;"\'<,>.?/';

    let specialCharOccurrences: number = 0;
    value.split('').forEach((char: string) => {
      if (specialChars.includes(char)) {
        specialCharOccurrences = specialCharOccurrences + 1;
      }
      if (specialCharOccurrences > 3) {
        return;
      }
    });

    if (specialCharOccurrences > 3) {
      return false;
    } else {
      return true;
    }
  }

  function getDot(size: string, color: string) {
    const renderSize = size;
    return (
      <svg
        width={renderSize}
        height={renderSize}
        viewBox="0 0 4 4"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <circle cx="2" cy="2" r="2" fill={color} />
      </svg>
    );
  }

  useEffect(() => {
    if (!document.getElementById('modal')) {
      const modalRoot: HTMLDivElement = document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      document.body.append(modalRoot);
    }
  }, []);

  useEffect(() => {
    if (displayCancelAlert) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [displayCancelAlert]);

  useEffect(() => {
    function updatePosition() {
      if (window.innerWidth <= mobileWidth) {
        setPosition(mobilePosition);
        setOffset(mobileOffset);
      } else {
        setPosition(desktopPosition);
        setOffset(desktopOffset);
      }
    }
    window.addEventListener('resize', updatePosition);
    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleScroll() {
      setReferenceElement(null);
      rememberRefElem.current = null;
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    function handleKeydown(event: any) {
      if (event.key === 'Tab') {
        setReferenceElement(null);
        rememberRefElem.current = null;
      } else if (event.key === ' ' || event.key === 'Enter') {
        event.preventDefault();   
        togglePopover(event);
      }
    }

    const helpTextIcon = document.querySelector('button.icon-button');
    helpTextIcon?.addEventListener('keydown', handleKeydown);

    return () => {
      const helpTextIcon = document.querySelector('button.icon-button');
      helpTextIcon?.removeEventListener('keydown', handleKeydown);
    }
  }, []);

  return (
    <>
      <Container constants={constants} isEmployee={isEmployee}>
        <SACard variant="minimal">
          <CardHeader>
            <CardTitle>
              <SAText type="heading-2" text="General Details" />
            </CardTitle>
          </CardHeader>
          <CardBody>
            <form id="generaldetailsform" onSubmit={handleSubmit(onSubmit)} autoComplete="none">
              <SAInput
                {...register('loginId', {
                  required: 'Login ID is required',
                  minLength: { value: 6, message: 'Login ID must be at least 6 characters' },
                  maxLength: { value: 20, message: 'Login ID cannot exceed 20 characters' },
                  validate: {
                    noWhiteSpaces: (value) => checkForSpaces(value) || 'Login ID cannot contain spaces',
                    noSALogins: (value) => checkForSALogin(value) || 'Login ID cannot begin with 3 alpha and end with 4 numerics',
                    maxNumSpecialChars: (value) => checkForMaxNumSpecialChars(value) || 'Login ID cannot contain more than 3 special characters',
                  },
                  // eslint-disable-next-line no-useless-escape
                  pattern: { value: /^[A-Za-z0-9 \@\_\-\.]+$/, message: 'Login ID cannot contain invalid characters' },
                })}
                autoFocus
                hint={errors.loginId?.message as string | undefined}
                error={errors.loginId as boolean | undefined}
                id="loginid"
                name="loginId"
                label="Login ID"
                type="text"
                // onClick={(event: any) => openPopover(event)}
                // onFocus={(event: any) => openPopover(event)}
                onBlur={(event: any) => {
                  setReferenceElement(null);
                  rememberRefElem.current = null;
                }}
                onClickIcon={togglePopover}
                defaultValue={newContact.contactDetails.generalDetails.loginId}
                icon={SAIcons.information}
                iconSize="20px"
                iconColor="primary"
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
              <Span>
                <SAInput
                  {...register('firstName', {
                    required: 'First Name is required',
                    maxLength: {value: 50, message: 'First Name cannot exceed 50 characters'},
                  })}
                  hint={errors.firstName?.message as string | undefined}
                  error={errors.firstName as boolean | undefined}
                  id="firstname"
                  name="firstName"
                  label="First Name"
                  type="text"
                  defaultValue={newContact.contactDetails.generalDetails.firstName}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
                <SAInput
                  {...register('lastName', {
                    required: 'Last Name is required',
                    maxLength: {value: 50, message: 'Last Name cannot exceed 50 characters'},
                  })}
                  hint={errors.lastName?.message as string | undefined}
                  error={errors.lastName as boolean | undefined}
                  id="lastname"
                  name="lastName"
                  label="Last Name"
                  type="text"
                  defaultValue={newContact.contactDetails.generalDetails.lastName}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
              </Span>
              <SAInput
                {...register('primaryPhone', {
                  required: 'Primary Phone is required',
                  minLength: { value: 14, message: 'Primary Phone must be 10 digits' }
                })}
                hint={errors.primaryPhone?.message as string | undefined}
                error={errors.primaryPhone as boolean | undefined}
                id="primaryphone"
                name="primaryPhone"
                label="Primary Phone"
                type="tel"
                inputMode="numeric"
                onChange={(event: any) => {
                  const { value } = event.target;
                  clearErrors('primaryPhone');
                  setValue('primaryPhone', normalizePhoneNumber(value));
                }}
                defaultValue={normalizePhoneNumber(newContact.contactDetails.generalDetails.primaryPhone)}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
            </form>
          </CardBody>
          <CardFooter>
            <div>
              <SAButton fullWidthUnder={mobileWidth} label="Previous" onClick={handleClickPrevious} variant="link-large" color="text" textTransform="uppercase" />
            </div>
            <div>
              <SAButton label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
              {!showSpinner &&
                <SAButton fullWidthUnder={mobileWidth} label="Next" type="submit" variant="primary-large" textTransform="uppercase" onClick={handleSubmit(onSubmit)} />
              }
              {showSpinner &&
                <DisabledNextButton endIcon={spinner} disabled fullWidthUnder={mobileWidth} label="Next" type="submit" variant="primary-large" textTransform="uppercase" />
              }
            </div>
          </CardFooter>
        </SACard>
        <ContactDetailsSideCard />
      </Container>
      <CancelButton>
        <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
      </CancelButton>
      <SAPopover
        onClose={(e: MouseEvent, index: number | null) => closePopover(e, index)}
        offset={offset}
        placement={position}
        referenceElement={referenceElement}
        variant="popover-minimal-nopadding"
      >
        <Popover>
          <ul>
            <li>
              {errors.loginId?.type as any === 'minLength' &&
                <>
                  <SAIcon icon={SAIcons.alert} size={SAIconSize.small} colorVariant="alert" className="alert" />
                  <Red>
                    <SAText type="small" text="Minimum 6 characters" />
                  </Red>
                </>
              }
              {!(errors.loginId?.type as any === 'minLength') &&
                <>
                  {/* <SAIcon icon={SAIcons.checkmark} size={SAIconSize.small} colorVariant="secondary" className="checkmark" /> */}
                  {getDot('4px', SAUX360Theme.colors.text)}
                  <span>
                    <SAText type="small" text="Minimum 6 characters" />
                  </span>
                </>
              }
            </li>
            <li>
              {errors.loginId?.type as any === 'maxNumSpecialChars' &&
                <>
                  <SAIcon icon={SAIcons.alert} size={SAIconSize.small} colorVariant="alert" className="alert" />
                  <Red>
                    <SAText type="small" text="Maximum 3 special characters allowed" />
                  </Red>
                </>
              }
              {!(errors.loginId?.type as any === 'maxNumSpecialChars') &&
                <>
                  {/* <SAIcon icon={SAIcons.checkmark} size={SAIconSize.small} colorVariant="secondary" className="checkmark" /> */}
                  {getDot('4px', SAUX360Theme.colors.text)}
                  <span>
                    <SAText type="small" text="Maximum 3 special characters allowed" />
                  </span>
                </>
              }
            </li>
            <li>
              {errors.loginId?.type as any === 'maxLength' &&
                <>
                  <SAIcon icon={SAIcons.alert} size={SAIconSize.small} colorVariant="alert" className="alert" />
                  <Red>
                    <SAText type="small" text="Maximum 20 characters allowed" />
                  </Red>
                </>
              }
              {!(errors.loginId?.type as any === 'maxLength') &&
                <>
                  {/* <SAIcon icon={SAIcons.checkmark} size={SAIconSize.small} colorVariant="secondary" className="checkmark" /> */}
                  {getDot('4px', SAUX360Theme.colors.text)}
                  <span>
                    <SAText type="small" text="Maximum 20 characters allowed" />
                  </span>
                </>
              }
            </li>
            <li>
              {errors.loginId?.type as any === 'noWhiteSpaces' &&
                <>
                  <SAIcon icon={SAIcons.alert} size={SAIconSize.small} colorVariant="alert" className="alert" />
                  <Red>
                    <SAText type="small" text="Cannot contain spaces" />
                  </Red>
                </>
              }
              {!(errors.loginId?.type as any === 'noWhiteSpaces') &&
                <>
                  {/* <SAIcon icon={SAIcons.checkmark} size={SAIconSize.small} colorVariant="secondary" className="checkmark" /> */}
                  {getDot('4px', SAUX360Theme.colors.text)}
                  <span>
                    <SAText type="small" text="Cannot contain spaces" />
                  </span>
                </>
              }
            </li>
            <li>
              {errors.loginId?.type as any === 'noSALogins' &&
                <>
                  <SAIcon icon={SAIcons.alert} size={SAIconSize.small} colorVariant="alert" className="alert" />
                  <Red>
                    <SAText type="small" text="Cannot begin with 3 alpha and end with 4 numerics" />
                  </Red>
                </>
              }
              {!(errors.loginId?.type as any === 'noSALogins') &&
                <>
                  {/* <SAIcon icon={SAIcons.checkmark} size={SAIconSize.small} colorVariant="secondary" className="checkmark" /> */}
                  {getDot('4px', SAUX360Theme.colors.text)}
                  <span>
                    <SAText type="small" text="Cannot begin with 3 alpha and end with 4 numerics" />
                  </span>
                </>
              }
            </li>
            <li>
              {errors.loginId?.type as any === 'pattern' &&
                <>
                  <SAIcon icon={SAIcons.alert} size={SAIconSize.small} colorVariant="alert" className="alert" />
                  <Red>
                    <SAText type="small" text="Special characters allowed - period, dash, underscore and at sign" />
                  </Red>
                </>
              }
              {!(errors.loginId?.type as any === 'pattern') &&
                <>
                  {/* <SAIcon icon={SAIcons.checkmark} size={SAIconSize.small} colorVariant="secondary" className="checkmark" /> */}
                  {getDot('4px', SAUX360Theme.colors.text)}
                  <span>
                    <SAText type="small" text="Special characters allowed - period, dash, underscore and at sign" />
                  </span>
                </>
              }
            </li>
          </ul>
        </Popover>
      </SAPopover>
      {displayCancelAlert &&
        <ModalCancelAlert onClickLeave={handleClickCancelAlertModalLeave} onClickStay={handleClickCancelAlertModalStay} />
      }
    </>
  );
}

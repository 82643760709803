import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import {
  SACard,
  SAText,
  SAButton,
  SASearch,
  SARadioGroup,
  SARadio,
  SAIcon,
  SAIcons,
  SAPopover,
  SAUXTheme
} from '@saux/design-system-react';
import { NewContact, NewContactContactType, NewContactAgencyCode, AgencyCode, Constants } from '../../interfaces/interfaces';
import styled from 'styled-components';
import ContactDetailsSideCard from '../ContactDetailsSideCard/ContactDetailsSideCard';
import ModalCancelAlert from '../../components/modal/ModalCancelAlert/ModalCancelAlert';
import { encrypt, decrypt } from '../../utils/crypto';
import { constants } from '../../utils/constants';

const mobileWidth = 600;

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
  isEmployee?: boolean;
};

type Position = 'top-start' | 'top' | 'top-end' | 'right' | 'bottom-start' | 'bottom' | 'bottom-end' | 'left';

type Offset = [number, number];

const Container = styled.div.attrs(({ constants, isEmployee }: StyleProps) => ({
  constants,
  isEmployee
}))`
  ${({ theme, constants, isEmployee }: StyleProps) => {
    const con: Constants = constants as Constants;
    const marginTop: number = isEmployee ? con.headerHeight + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeight + con.dockedStepperHeight + con.verticalPageOffset;
    const marginTopMobile: number = isEmployee ? con.headerHeightMobile + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeightMobile + con.dockedStepperHeight + con.verticalPageOffset;

    return `
      display: flex;
      flex-direction: row;
      max-width: 1200px;
      margin: ${marginTop}px ${theme?.size.spacer.medium} 60px ${theme?.size.spacer.medium};
    
      @media only screen and (max-width: ${mobileWidth}px) {
        margin: ${marginTopMobile}px ${theme?.size.spacer.medium} 0 ${theme?.size.spacer.medium};
      }
    
      article:first-child {
        flex-grow: 1;
      }
    
      article:last-child {
        margin-left: ${theme?.size.spacer.large};
        width: 300px;
        flex-shrink: 0;
      }
    `;
  }};
`;

const CardHeader = styled.div`
  padding-bottom: 45px;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 30px;

  .closemodal {
    display: none;
  }

  button {
    margin-left: 15px;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

const CardSubTitle = styled.div``;

const CardBody = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      div {
        &.saInputWrapper {
          margin: 0 0 ${theme?.size.spacer.medium} 0;

          @media only screen and (max-width: ${mobileWidth}px) {
            margin: 0 0 ${theme?.size.spacer.large} 0;
            padding-right: 0;
          }
        }
      }

      legend {
        display: none;
      }

      fieldset {
        label {
          z-index: 0;
        }
      }
    `;
  }};
`;

const CardFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 60px;

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
      }

      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      div {
        &:last-child {
          display: flex;
          flex-direction: row;

          button {
            margin-left: ${theme?.size.spacer.medium};

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              font-weight: ${theme?.font.primary.weight.bold};
            }
          }

          @media only screen and (max-width: ${mobileWidth}px) {
            button {
              &:first-child {
                display: none;
              }
            }
          }
        }

        @media only screen and (max-width: ${mobileWidth}px) {
          flex-grow: 1;
        }
      }
    `;
  }};
`;

const CancelButton = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: none;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: ${theme?.size.spacer.medium} ${theme?.size.spacer.medium};
      }
    `;
  }};
`;

const RadioGroup = styled(SARadioGroup)`
  ${({ theme }: StyleProps) => {
    return `
      margin-top: ${theme?.size.spacer.medium};
      border: rgb(217,223,235) solid 1px;
      padding: ${theme?.size.spacer.medium};
      max-height: 270px;
      overflow-y: auto;

      label {
        color: ${theme?.colors.text};
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 92px;
        border: none;
      }
    
      &::-webkit-scrollbar-track {
          background: #F0EFEF;
          border-radius: 7px;
      }
    
      &::-webkit-scrollbar-thumb {
          background: #8A8A8A;
          border-radius: 7px;
          box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      }
    `;
  }};
`;

const CardValidation = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      background-color: #FDEAE7;
      padding: ${theme?.size.spacer.large};
      margin-top: 60px;
      margin-left: -${theme?.size.spacer.large};
      margin-right: -${theme?.size.spacer.large};
      margin-bottom: 0;

      p {
        color: ${theme?.color.alert.background};
        font-weight: ${theme?.font.primary.weight.bold};
      }
    `;
  }};
`;

const Popover = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      color: hsla(220, 38%, 97%, 1);
      font-size: ${theme?.font.primary.size.smallTEMP};
      font-weight: ${theme?.font.primary.weight.normal};
      line-height: 16px;
      background-color: hsla(213, 30%, 35%, 1);
      border: 1px solid hsla(213, 30%, 35%, 1);
      box-sizing: border-box;
      box-shadow: 0px 0px 5px rgba(0, 32, 56, 0.22);
      border-radius: 4px;
      padding: 15px;
      width: 360px;
    `;
  }};
`;

const HelpContainer = styled.div`
  display: flex;
  flex-directino: column;
`;

const HelpIcon = styled.div`
  display: flex;
  padding-right: 10px;
  align-items: flex-start;
`;

const HelpText = styled.div`
  display: flex;
  align-items: flex-start;
`;

const SearchContainer = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      .saInputWrapper {
        font-size: ${theme?.font.primary.size.medium};
      }

      ul {
        align-items: center;
        span {
          button {
            margin-bottom: 0;
          }
        }
        button {
          margin-bottom: 5px;
        }
      }
    `;
  }};
`;
export default function PrimaryAgencyCode(props: any) {
  const { handleSubmit, control, setError, formState: { errors } } = useForm();
  const [formData, setFormData] = useState<any>(null);
  const onSubmit = (data: any) => setFormData(data);
  const history = useHistory();
  const [newContact, setNewContact] = useState<NewContact>(JSON.parse(decrypt(localStorage.getItem('NewContact'))));
  const [activeAgencyCodes, setActiveAgencyCodes] = useState<AgencyCode[]>(getActiveAgencyCodes());
  const [mobile, setMobile] = useState<boolean>(true);
  const [defaultValue, setDefaultValue] = useState<string | undefined>(getDefaultValue());
  const [filteredAgencyCodes, setFilteredAgencyCodes] = useState<AgencyCode[]>(activeAgencyCodes);
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const desktopPosition: Position = 'right';
  const mobilePosition: Position = 'top-end';
  const desktopOffset: Offset = [0, 10];
  const mobileOffset: Offset = [0, 10];
  const [position, setPosition] = useState<Position>(
    document.documentElement.clientWidth <= mobileWidth ? mobilePosition : desktopPosition,
  );
  const [offset, setOffset] = useState<Offset>(
    window.innerWidth <= mobileWidth ? mobileOffset : desktopOffset,
  );
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;

  // Modal
  const [displayCancelAlert, setDisplayCancelAlert] = useState<boolean>(false);

  function getDefaultValue() {
    if (newContact.contactDetails.primaryAgencyCode !== '') {
      return newContact.contactDetails.primaryAgencyCode;
    } else if (activeAgencyCodes.length === 1) {
      return activeAgencyCodes[0].agency_code;
    } else {
      return undefined;
    }
  }

  function sortByAgencyCode(a: AgencyCode, b: AgencyCode) {
    if (a.agency_code === b.agency_code) {
      return 0;
    } else {
      return (a.agency_code < b.agency_code) ? -1 : 1;
    }
  }

  function getActiveAgencyCodes() {
    const agencyCodesArray: AgencyCode[] = JSON.parse(decrypt(localStorage.getItem('AddContactAgencyCodes')));
    const filteredAgencies = agencyCodesArray.filter((agency: AgencyCode) => {
      let includeAgency: boolean = false;
      newContact.contactDetails.agencyCodes.forEach((agencyCode: NewContactAgencyCode) => {
        if (agency.agency_code.toString() === agencyCode.producer_number.toString()) {
          includeAgency = true;
        }
      });
      return includeAgency;
    });
    filteredAgencies.sort(sortByAgencyCode);
    return filteredAgencies;
  }

  function getSteps() {
    // return steps for the workflow
    if (newContact !== null) {
      const assignmentLevels: string[] = [];
      newContact.contactDetails.contactTypes.forEach((ct: NewContactContactType) => {
        switch (ct.contacttype) {
          case 3:
          case 7:
          case 10:
          case 13:
          case 14:
          case 36:
          case 37:
            // Agency Admin - 3
            // Agency Staff - 7
            // Agency Manager - 10
            // Primary Claim Contact - 13
            // Primary Mail Recipient - 14
            // Accounting Manager - 36
            // Licensed Producer - 37
            if (!assignmentLevels.includes('Agency Code')) {
              assignmentLevels.push('Agency Code');
            }
            break;
        }
      });

      const workflow: string [] = ['Contact Type', 'General Details', 'Agency Code', 'Primary Agency Code', 'User Privileges'];

      let pagesToSuppress: string[] = ['Agency Code'];

      pagesToSuppress = pagesToSuppress.filter((page: string) => !assignmentLevels.includes(page));

      pagesToSuppress.forEach((page: string) => {
        const index: number = workflow.indexOf(page);
        workflow.splice(index, 1);
      });

      return workflow;
    } else {
      return [];
    }
  }

  function handleSearch(search: Array<string> | string) {
    // The callback function you provide in the onSearch prop will receive the current array
    // of search terms when a new search term is added or removed from the array.
    if (typeof search !== 'string') {
      const filteredResults = activeAgencyCodes.filter((ac) => {
        let containsAllSearchTerms: boolean = true;
        search.forEach((searchTerm) => {
          let containsSearchTerm: boolean = false;
          const label = `${ac.agency_code} - ${ac.dba !== '' ? ac.dba : ac.agency_name}, ${ac.agency_city}, ${ac.agency_state} ${ac.agency_zip.substr(0, 5)}`;
          if (label.toLowerCase().includes(searchTerm.toLowerCase())) {
            containsSearchTerm = true;
          };
          if (!containsSearchTerm) {
            containsAllSearchTerms = false;
          }
        });

        if (containsAllSearchTerms) {
          return true;
        } else {
          return false;
        }
      });        

      setFilteredAgencyCodes(filteredResults);
    }
  }

  function handleClickCancel(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleClickCancelAlertModalLeave(event: React.MouseEvent) {
    history.push({
      pathname: `/agency-contacts`
    });
  }

  function handleClickCancelAlertModalStay(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleClickPrevious(event : React.MouseEvent) {
    const workflow = getSteps();

    if (workflow.includes('Agency Code')) {
      history.push({
        pathname: '/agency-codes'
      });
    } else {
      history.push({
        pathname: '/general-details'
      });
    }
  }

  function openPopover(event: React.MouseEvent<HTMLElement>) {
    setReferenceElement(event.currentTarget);
  }

  function closePopover(event: React.MouseEvent<HTMLElement> | MouseEvent, index: number | null) {
    setReferenceElement(null);
  }

  function togglePopover(event: React.MouseEvent<HTMLElement>) {
    if (referenceElement === null) {
      setReferenceElement(event.currentTarget.firstChild as HTMLElement);
    } else {
      setReferenceElement(null);
    }
  }

  function handleKeyDown(e: any) {
    if (e.key === 'Tab') {
      setReferenceElement(null);
    }
  }

  useEffect(() => {
    function validateForm(data: { primaryAgencyCode : string }) {
      if (data.primaryAgencyCode !== undefined) {
        const newContact: NewContact = JSON.parse(decrypt(localStorage.getItem('NewContact')));
        newContact.contactDetails.primaryAgencyCode = data.primaryAgencyCode;
        localStorage.setItem('NewContact', encrypt(JSON.stringify(newContact)));
        
        history.push({
          pathname: '/user-privileges'
        });
      } else {
        setError('primaryAgencyCode', {
          type: 'manual',
          message: 'Primary Agency Code is required.'
        })
      }
    }

    if (formData !== null) {
      validateForm(formData);
    };
  }, [formData]);

  useEffect(() => {
    function focusDefault() {
      let radio = document.querySelector('label.checked') as HTMLElement;
      if (radio) {
        (radio?.firstChild as HTMLElement).focus();
      } else {
        radio = document.querySelector('input[type=radio]') as HTMLElement;
        if (radio) {
          radio.focus();
        }
      }
    }

    focusDefault();
  }, []);
  
  useEffect(() => {
    const newContact: NewContact = JSON.parse(decrypt(localStorage.getItem('NewContact')));
    if (newContact.contactDetails.primaryAgencyCode !== '') {
      const element: Element | null = document.querySelector(`input[id="agencyCode_${newContact.contactDetails.primaryAgencyCode}"]`);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  useEffect(() => {
    if (!document.getElementById('modal')) {
      const modalRoot: HTMLDivElement = document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      document.body.append(modalRoot);
    }
  }, []);

  useEffect(() => {
    if (displayCancelAlert) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [displayCancelAlert]);

  useEffect(() => {
    function updatePosition() {
      if (window.innerWidth <= mobileWidth) {
        setMobile(true);
        setPosition(mobilePosition);
        setOffset(mobileOffset);
      } else {
        setMobile(false);
        setPosition(desktopPosition);
        setOffset(desktopOffset);
      }
    }
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleScroll() {
      setReferenceElement(null);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Container constants={constants} isEmployee={isEmployee}>
        <SACard variant="minimal">
          <CardHeader>
            <CardTitle>
              <SAText type="heading-2" text="Primary Agency Code" />
              <SAButton
                  fullWidth={false}
                  variant="link-small"
                  label="Help Text"
                  onClick={togglePopover}
                  onKeyDown={handleKeyDown}
              >
                <SAIcon icon={SAIcons.information} size="20px" colorVariant="primary" onMouseOver={openPopover} onMouseLeave={closePopover} />
              </SAButton>
            </CardTitle>
            <CardSubTitle>
              <SAText type="standard" text="Select the primary agency code." />
            </CardSubTitle>
          </CardHeader>
          <CardBody>
            <SearchContainer>
              <SASearch
                fullWidth
                id="primaryagencycodesearchbar"
                placeholder="Search by agency code, agency name, city, state, or zip"
                onSearch={handleSearch}
                variant="search-terms"
              />
            </SearchContainer>
            <form id="primaryagencycodeform" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="primaryAgencyCode"
                defaultValue={defaultValue}
                render={({ field: { onChange, value }}) => (
                  <RadioGroup id="primaryAgencyCode" value={value} variant="standard" direction="vertical" onChange={onChange}>
                    {filteredAgencyCodes.map((ac: AgencyCode) => {
                      const label = `${ac.agency_code} - ${ac.dba !== '' ? ac.dba : ac.agency_name}, ${ac.agency_city}, ${ac.agency_state} ${ac.agency_zip.substr(0, 5)}`;
                      return (
                        <SARadio key={ac.agency_code} id={`agencyCode_${ac.agency_code}`} value={ac.agency_code} label={label} variant="standard" />
                      )
                    })}
                  </RadioGroup>
                )}
              />
            </form>
          </CardBody>
          {errors.primaryAgencyCode && 
            <CardValidation>
              <SAText type="paragraph" text={errors.primaryAgencyCode?.message as string | undefined} />
            </CardValidation>
          }
          <CardFooter>
            <div>
              <SAButton fullWidthUnder={mobileWidth} label="Previous" onClick={handleClickPrevious} variant="link-large" color="text" textTransform="uppercase" />
            </div>
            <div>
              <SAButton label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
              <SAButton fullWidthUnder={mobileWidth} label="Next" type="submit" variant="primary-large" textTransform="uppercase" onClick={handleSubmit(onSubmit)} />
            </div>
          </CardFooter>
        </SACard>
        <ContactDetailsSideCard />
      </Container>
      <CancelButton>
        <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
      </CancelButton>
      <SAPopover
        onClose={(e: MouseEvent, index: number | null) => closePopover(e, index)}
        offset={offset}
        placement={position}
        referenceElement={referenceElement}
        variant={mobile ? 'popover-minimal-nopadding' : 'popover-menu-no-click'}
      >
        <Popover>
          <HelpContainer>
            <HelpIcon>
              <SAIcon icon={SAIcons.information} size="20px" colorVariant="hsla(220, 38%, 97%, 1)" />
            </HelpIcon>
            <HelpText>
              Primary Agency Code is the agency code / location where the individual works and the default code for quoting new business. This is sometimes called Primary Workplace Code.
            </HelpText>
          </HelpContainer>
        </Popover>
      </SAPopover>
      {displayCancelAlert &&
        <ModalCancelAlert onClickLeave={handleClickCancelAlertModalLeave} onClickStay={handleClickCancelAlertModalStay} />
      }
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import {
  SACard,
  SAText,
  SAButton,
  SACheckbox,
  SAUXTheme,
  SABox
} from '@saux/design-system-react';
import styled from 'styled-components';
import ContactData from '../Model/ContactModel';
import {
  NewContact,
  NewContactContactType,
  ContactDataContactType,
  Constants
} from '../../interfaces/interfaces';
import ContactDetailsSideCard from '../ContactDetailsSideCard/ContactDetailsSideCard';
import ModalLearnMore from '../../components/modal/ModalLearnMore/ModalLearnMore';
import ModalCancelAlert from '../../components/modal/ModalCancelAlert/ModalCancelAlert';
import ModalContactAgencyOperations from '../modal/ModalContactAgencyOperations/ModalContactAgencyOperations';
import ModalAddLicensedOrUnlicensedContactType from '../modal/ModalAddLicensedOrUnlicensedContactType/ModalAddLicensedOrUnlicensedContactType';
import ModalLicensedProducerMarketFocus from '../modal/ModalLicensedProducerMarketFocus/ModalLicensedProducerMarketFocus';
import { encrypt, decrypt } from '../../utils/crypto';
import { constants } from '../../utils/constants';

const mobileWidth = 600;
const LICENSED_PRODUCER_CONTACT_TYPE = 37;

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
  isEmployee?: boolean;
};

const Container = styled.div.attrs(({ constants, isEmployee }: StyleProps) => ({
  constants,
  isEmployee
}))`
  ${({ theme, constants, isEmployee }: StyleProps) => {
    const con: Constants = constants as Constants;
    const marginTop: number = isEmployee ? con.headerHeight + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeight + con.dockedStepperHeight + con.verticalPageOffset;
    const marginTopMobile: number = isEmployee ? con.headerHeightMobile + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeightMobile + con.dockedStepperHeight + con.verticalPageOffset;

    return `
      display: flex;
      flex-direction: row;
      max-width: 1200px;
      margin: ${marginTop}px ${theme?.size.spacer.medium} 60px ${theme?.size.spacer.medium};
    
      @media only screen and (max-width: ${mobileWidth}px) {
        margin: ${marginTopMobile}px ${theme?.size.spacer.medium} ${theme?.size.spacer.medium} ${theme?.size.spacer.medium};
      }
    
      article:first-child {
        flex-grow: 1;
      }
    
      article:last-child {
        margin-left: ${theme?.size.spacer.large};
        width: 300px;
        flex-shrink: 0;
      }
    `;
  }};
`;

const CardHeader = styled.div`
  padding-bottom: 45px;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  
  .closemodal {
    display: none;
  }

  @media only screen and (max-width: ${mobileWidth}px) { 
    button {
      display: none;
    }
  }
`;

const CardSubTitle = styled.div`
  span {
    line-height: 1.5;
  }
`;

const CardBody = styled.div``;

const CardValidation = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      background-color: #FDEAE7;
      padding: ${theme?.size.spacer.large};
      margin-top: 60px;
      margin-left: -${theme?.size.spacer.large};
      margin-right: -${theme?.size.spacer.large};
      margin-bottom: 0;

      p {
        color: ${theme?.color.alert.background};
        font-weight: ${theme?.font.primary.weight.bold};
      }
    `;
  }};
`;

const CardFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-right: ${theme?.size.spacer.medium};

        &:last-child {
          margin-right: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
      }
    `;
  }};
`;

const CheckboxContainer = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 240px;
    
      @media only screen and (max-width: ${mobileWidth}px) {
        flex-wrap: nowrap;
        max-height: fit-content;
      }
    
      .saCheckboxWrapper {
        display: flex;
        align-items: center;
        margin: 10px 0;
        height: 40px;
        padding: 0 15px;
        z-index: 0;

        &:hover {
          background-color: rgba(216,223,236,0.25);
          cursor: pointer;
        }

        input {
          &:hover {
            cursor: pointer;
          }
        }

        label {
          pointer-events: none;
        }

        input {
          pointer-events: none;
        }

        label {
          &:hover {
            cursor: pointer;
          }
        }   
      }
    `;
  }};
`;

const LearnMoreContainer = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: none;

      @media only screen and (max-width: ${mobileWidth}px) {    
        display: flex;
        padding-top: ${theme?.size.spacer.small};
      }
    `;
  }};
`;

export default function ContactTypes(props: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm();
  const onSubmit = (data: any) => validateForm(data);
  const history = useHistory();
  const [contact, setContact] = useState<NewContact>(JSON.parse(decrypt(localStorage.getItem('NewContact'))));
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;

  // Modals
  const [displayLearnMoreModal, setDisplayLearnMoreModal] = useState<boolean>(false);
  const [displayCancelAlert, setDisplayCancelAlert] = useState<boolean>(false);
  const [displayContactAgencyOperationsModal, setDisplayContactAgencyOperationsModal] = useState<boolean>(false);
  const [displayLicensedOrUnlicensedModal, setDisplayLicensedOrUnlicensedModal] = useState<boolean>(false);
  const [displayLicensedProducerMarketFocusModal, setDisplayLicensedProducerMarketFocusModal] = useState<boolean>(false);

  function validateForm(data: any) {
    let atLeastOneContactTypeSelected: boolean = false;
    for (const key in data) {
      if (data[key]) {
        atLeastOneContactTypeSelected = true;
        break;
      }
    }

    if (atLeastOneContactTypeSelected) {
      setShowValidation(false);
      const newContact = contact;
      newContact.contactDetails.contactTypes.length = 0;
      ContactData.contacttype.forEach((ct: ContactDataContactType) => {
        const value: boolean = data['contacttype_' + ct.contacttype];
        if (value) {
          const newContactType: NewContactContactType = {
            contacttype: ct.contacttype,
            description: ct.description
          };
          newContact.contactDetails.contactTypes.push(newContactType);
        }
      });
      
      setContact(newContact);
      localStorage.setItem('NewContact', encrypt(JSON.stringify(contact)));
      
      const contactTypes = newContact.contactDetails.contactTypes;
      const hasLicenseProducer = contactTypes.some(({ contacttype }) => contacttype === LICENSED_PRODUCER_CONTACT_TYPE);

      // displayed LicenseOrUnlicense modal if it contains LicenseProducer and other contact types
      if (contactTypes.length > 1 && hasLicenseProducer) {
        setDisplayLicensedOrUnlicensedModal(true);
        return;
      }

      // displayed MarketFocus modal if it contains LicenseProducer contact type
      if (hasLicenseProducer) {
        setDisplayLicensedProducerMarketFocusModal(true);
        return;
      }

      history.push({
        pathname: '/general-details'
      });
    } else {
      setShowValidation(true);
    }
  }

  function handleClickCancel(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleClickCancelAlertModalLeave(event: React.MouseEvent) {
    history.push({
      pathname: `/agency-contacts`
    });
  }

  function handleClickCancelAlertModalStay(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleClickLearnMore(event: React.MouseEvent) {
    setDisplayLearnMoreModal(!displayLearnMoreModal);
  }

  function handleClickLearnMoreModalClose(event: React.MouseEvent) {
    setDisplayLearnMoreModal(!displayLearnMoreModal);
  }

  function handleClickCloseContactAgencyOperations(event: React.MouseEvent) {
    setDisplayContactAgencyOperationsModal(!displayContactAgencyOperationsModal);
  }

  function handleMarketFocusModalYes(event: React.MouseEvent) {
    const newContact = { ...contact };
    const newContactTypes = newContact.contactDetails.contactTypes
      .filter(type => type.contacttype !== LICENSED_PRODUCER_CONTACT_TYPE);
    newContactTypes.push({
      contacttype: 7,
      description:"Agency Staff"
    });
    newContact.contactDetails.contactTypes = newContactTypes;
    setContactAndContactTypeFormValues(newContact);
    setDisplayLicensedProducerMarketFocusModal(false);
  }

  function handleMarketFocusModalNo(event: React.MouseEvent) {
    setContactAndContactTypeFormValues(contact);
    setDisplayLicensedProducerMarketFocusModal(false);
    history.push({
      pathname: '/general-details'
    });
  }

  function handleMarketFocusModalClose(event: React.MouseEvent) {
    setDisplayLicensedProducerMarketFocusModal(false);
  }

  function handleAddLicensedOrUnlicensedModalClose(event: React.MouseEvent) {
    setDisplayLicensedOrUnlicensedModal(false);
  }
  
  function handleAddLicensedOrUnlicensedModalYes(event: React.MouseEvent) {
    const newContact = { ...contact };
    // selected license producer contact type only and deselected all others once the modal close
    const newContactTypes = newContact.contactDetails.contactTypes
      .filter(type => type.contacttype === LICENSED_PRODUCER_CONTACT_TYPE);
    newContact.contactDetails.contactTypes = newContactTypes;

    setContactAndContactTypeFormValues(newContact);
    setDisplayLicensedOrUnlicensedModal(false);
  }

  function handleAddLicensedOrUnlicensedModalNo(event: React.MouseEvent) {
    const newContact = { ...contact };
    // removed license producer contact type and selected all others types once the modal close
    const newContactTypes = newContact.contactDetails.contactTypes
      .filter(type => type.contacttype !== LICENSED_PRODUCER_CONTACT_TYPE);
    newContact.contactDetails.contactTypes = newContactTypes;

    setContactAndContactTypeFormValues(newContact);
    setDisplayLicensedOrUnlicensedModal(false);
  }

  function setContactAndContactTypeFormValues(newContact: NewContact) {
    setContact(newContact);
    localStorage.setItem('NewContact', encrypt(JSON.stringify(newContact)));
    ContactData.contacttype.forEach((cdct: ContactDataContactType) => {
      let checked: boolean = newContact.contactDetails.contactTypes.some(ct => ct.contacttype === cdct.contacttype);
      setValue(`contacttype_${cdct.contacttype}`, checked);
    });
  }

  const sort = (a: ContactDataContactType, b: ContactDataContactType) => {
    if (a.description === b.description) {
      return 0;
    } else {
      return (a.description.toLowerCase() < b.description.toLowerCase()) ? -1 : 1;
    }
  }

  const getContactTypes = () => {
    return (
      <>
        {ContactData.contacttype.sort(sort).map((cdct: ContactDataContactType) => {
          let checked: boolean = false;
          contact.contactDetails.contactTypes.forEach((ct: NewContactContactType) => {
            if (ct.contacttype === cdct.contacttype) {
              checked = true;
              return;
            }
          });

          return (
            <SACheckbox
              {...register(`contacttype_${cdct.contacttype}`)}
              key={cdct.contacttype}
              id={`checkbox_${cdct.contacttype}`}
              label={cdct.description}
              type="checkbox"
              defaultChecked={checked}
              onChange={(e: any) => {
                setValue(`contacttype_${cdct.contacttype}`, e.target.checked);
              }}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
                if (e.key === " ") {
                  e.preventDefault();
                  const element: HTMLElement | null = document.querySelector(`input[id="checkbox_${cdct.contacttype}"]`);
                  if (element !== null) {
                    setShowValidation(false);
                    if (cdct.contacttype === 6) {
                      // Principal
                      setDisplayContactAgencyOperationsModal(!displayContactAgencyOperationsModal);
                    } else {
                      element.click();
                    }
                  }
                }
              }}
            />
          );
        })}
      </>
    );
  };

  useEffect(() => {
    // initialize contact type selections
    ContactData.contacttype.sort(sort).forEach((cdct: ContactDataContactType) => {
      let checked: boolean = false;
      contact.contactDetails.contactTypes.forEach((ct: NewContactContactType) => {
        if (ct.contacttype === cdct.contacttype) {
          checked = true;
        }
        setValue(`contacttype_${cdct.contacttype}`, checked);
      });
    });
  }, []);

  useEffect(() => {
    if (!document.getElementById('modal')) {
      const modalRoot: HTMLDivElement = document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      document.body.append(modalRoot);
    }
  }, []);

  useEffect(() => {
    function handleClickCheckboxContainer(contactType: number) {
      const element: HTMLElement | null = document.querySelector(`input[id="checkbox_${contactType}"]`);
      if (element !== null) {
        setShowValidation(false);
        if (contactType === 6) {
          // Principal
          setDisplayContactAgencyOperationsModal(!displayContactAgencyOperationsModal);
        } else {
          element.click();
        }
      }
    }

    ContactData.contacttype.sort(sort).map((cdct: ContactDataContactType) => {
      const element: HTMLElement | null = document.querySelector(`input[id="checkbox_${cdct.contacttype}"]`);
      if (element !== null) {
        const parentElement = element.parentElement?.parentElement as HTMLElement;
        parentElement.onclick = (e: Event) => {
          handleClickCheckboxContainer(cdct.contacttype);
        };
      }
    });
  }, []);

  useEffect(() => {
    // set default focus
    const element = document.querySelector('input') as HTMLElement;
    if (element) {
      element.focus();
    }
  }, []);

  useEffect(() => {
    if (displayLearnMoreModal || displayCancelAlert || displayContactAgencyOperationsModal || displayLicensedOrUnlicensedModal || displayLicensedProducerMarketFocusModal) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [displayLearnMoreModal, displayCancelAlert, displayContactAgencyOperationsModal, displayLicensedOrUnlicensedModal, displayLicensedProducerMarketFocusModal]);

  return (
    <>
      <Container constants={constants} isEmployee={isEmployee}>
        <SACard variant="minimal">
          <CardHeader>
            <CardTitle>
              <SAText type="heading-2" text="Select Contact Type" />
              <SAButton
                label="Learn More"
                onClick={handleClickLearnMore}
                textTransform="uppercase"
                variant="primary-link-medium"
              />
            </CardTitle>
            <CardSubTitle>
              <SABox mt="medium">
                <SAText type="standard" >
                  All contact types provide system access. Licensed Producers will gain system access upon completion of the application onboarding process and once onboarded additional contact types may be added. Select either Licensed Producer or any combination of the other contact types that do not require a license.
                </SAText>
              </SABox>
            </CardSubTitle>
            <LearnMoreContainer>
              <SAButton
                label="Learn More"
                onClick={handleClickLearnMore}
                textTransform="uppercase"
                variant="primary-link-medium"
              />
            </LearnMoreContainer>
          </CardHeader>
          <CardBody>
            <form id="addcontactform" onSubmit={handleSubmit(onSubmit)}>
              <CheckboxContainer>
                {getContactTypes()}
              </CheckboxContainer>
            </form>
          </CardBody>
          {showValidation && 
            <CardValidation>
              <SAText type="paragraph" text="At least one contact type is required." />
            </CardValidation>
          }
          <CardFooter>
            <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
            <SAButton fullWidthUnder={mobileWidth} label="Next" type="submit" variant="primary-large" textTransform="uppercase" onClick={handleSubmit(onSubmit)} />
          </CardFooter>
        </SACard>
        <ContactDetailsSideCard />
      </Container>
      {displayLearnMoreModal &&
        <ModalLearnMore onClickClose={handleClickLearnMoreModalClose} />
      }
      {displayCancelAlert &&
        <ModalCancelAlert onClickLeave={handleClickCancelAlertModalLeave} onClickStay={handleClickCancelAlertModalStay} />
      }
      {displayContactAgencyOperationsModal &&
        <ModalContactAgencyOperations
          title="Contact Agency Operations"
          text="Contact Agency Operations to add a Principal."
          onClickClose={handleClickCloseContactAgencyOperations}
        />
      }
      {displayLicensedOrUnlicensedModal && 
        <ModalAddLicensedOrUnlicensedContactType 
          onClickYes={handleAddLicensedOrUnlicensedModalYes} 
          onClickNo={handleAddLicensedOrUnlicensedModalNo} 
          onClickClose={handleAddLicensedOrUnlicensedModalClose}
        />
      }
      {displayLicensedProducerMarketFocusModal &&
        <ModalLicensedProducerMarketFocus
          onClickYes={handleMarketFocusModalYes}
          onClickNo={handleMarketFocusModalNo}
          onClickClose={handleMarketFocusModalClose}
        />
      }
    </>
  );
}

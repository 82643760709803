import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import { GetNucleusUserExistQueryVariables, GetNucleusUserExistQuery } from "../API";
import { checkAuthStatus } from "./utils";
import { decrypt } from "./crypto";
import handleGraphQLErrors from "./handleGraphQLErrors";

async function isAgencyOpsInternalUser(userId: string) {
    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseAgencyCodes = API.graphql(
      graphqlOperation(queries.getNucleusUserExist, { user_id: userId} as GetNucleusUserExistQueryVariables),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem("auth_accesstoken"))}`,
      }
    ) as Promise<{ data: GetNucleusUserExistQuery }>;

    const result = await promiseAgencyCodes
      .then((res) => {
        if (res.data) {
          return res.data.getNucleusUserExist;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200 && res.body) {
          return res.body.is_nucleus_user === "True" ? true : false;
        } else {
          throw new Error("Something went wrong - 38");
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err);
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });

      return result;
  }
  
export default isAgencyOpsInternalUser;

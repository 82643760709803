import { useState } from "react";
import {
  SAButton,
  SAIcon,
  SAIcons,
  SATabBar,
  SAUXTheme,
} from "@saux/design-system-react";
import { HeadingTab, SATypography, SAHypeLinks, FlexBox } from "./AgencyAdminStyled";
import { ADJOverview } from "../AgencyAdminOverview/ADJOverview";
import ADJDownload from "../ADJDownload/ADJDownload";
import ADJCommissions from "../ADJCommissions/ADJCommissions";
import styled from "styled-components";
import { decrypt, encrypt } from "../../utils/crypto";
import { Constants } from "../../interfaces/interfaces";
import { constants } from '../../utils/constants';
import simpleGTMDataLayer from "../../utils/GTMHelpers/simpleGTMDataLayer";

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
};

const Container = styled.div.attrs(({ constants }: StyleProps) => ({
  constants,
}))`
  ${({ theme, constants }: StyleProps) => {
    const con: Constants = constants as Constants;

    return `
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: ${con?.headerHeight + con?.verticalPageOffset}px;
      margin-bottom: 60px;
      margin-left: ${theme?.size.spacer.medium};
      margin-right: ${theme?.size.spacer.medium};
    `;
  }};
`;

const TabBar = styled(SATabBar)`
  button {
    cursor: pointer;
  }
`;

const AgencyAdministration = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const itemLabels = ["Overview", "Commissions", "Download"];
  const loginType = decrypt(localStorage.getItem('loginType'));
  const isAdmin = decrypt(localStorage.getItem('isAdmin')) === 'true' ? true : false;

  const tabChange = (newActiveIndex: number) => {
    setActiveIndex(newActiveIndex);
  };

  simpleGTMDataLayer({
    event: 'pageview',
    page: {
      path: '/agency-administration',
      title: 'ADJSS: Agency Administration'
    }
  });

  function handleClickManageContacts() {
    if (loginType === 'personID') {
      window.open(window.location.origin + '/agency-contacts', '_blank');
    } else if (loginType === 'employeeID') {
      localStorage.setItem('navigatedFrom', encrypt('/agency-contacts'));
      window.open(window.location.origin + '/search-agency-contact', '_blank');
    }
  }

  function handleClickReports() {
    if (loginType === 'personID') {
      window.open(window.location.origin + '/contact-listing', '_blank');
    } else if (loginType === 'employeeID') {
      localStorage.setItem('navigatedFrom', encrypt('/contact-listing'));
      window.open(window.location.origin + '/search-agency-contact', '_blank');
    }
  }

  return (
    <>
      <Container constants={constants}>
        <HeadingTab>
          <SATypography>Agency Administration</SATypography>
          <FlexBox>
            <SAHypeLinks hide bold>
              MANAGE AGENCY
            </SAHypeLinks>
            {(loginType === 'employeeID' || isAdmin) &&
              <>
                <SAButton
                  endIcon={
                    <SAIcon
                      icon={SAIcons.redirect}
                      colorVariant="primary"
                      size='18px'
                    />
                  }
                  fullWidth={false}
                  label="Manage Contacts"
                  onClick={handleClickManageContacts}
                  textTransform="uppercase"
                  variant="link-small"
                />
                <SAButton
                  endIcon={
                    <SAIcon
                      icon={SAIcons.redirect}
                      colorVariant="primary"
                      size='18px'
                    />
                  }
                  fullWidth={false}
                  label="Reports"
                  onClick={handleClickReports}
                  textTransform="uppercase"
                  variant="link-small"
                />
              </>
            }
          </FlexBox>
        </HeadingTab>
        <TabBar
          activeIndex={activeIndex}
          itemLabels={itemLabels}
          onChange={tabChange}
        ></TabBar>
        {activeIndex === 0 && <ADJOverview />}
        {activeIndex === 1 && <ADJCommissions />}
        {activeIndex === 2 && <ADJDownload />}
      </Container>
    </>
  );
};

export default AgencyAdministration;

import {
  SACard,
  SAButton,
  SAIcon,
  SAIcons,
} from "@saux/design-system-react";
import {
  AgencyCard,
  AgencyCardHead,
  BlueCardButtonContainer,
  BlueCardRow,
  Card,
  ContactDetails,
  FlexBox,
  Heading,
  HiddenBox,
  SAHr,
  SAHypeLinks,
  SAReport,
} from "../AgencyAdministration/AgencyAdminStyled";
import styled from "styled-components";

const BlueSACard = styled(SACard)`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #3B3B3B;

  section {
    padding: 15px 10px 15px 40px;
  }
`;

interface IADJIconProps {
  color?: string;
  backgroundColor?: string;
  fontWeight?: number;
  hoverColor?: string;
  marinRight?: string;
  cursor?: string;
  margin?: string;
}

interface IADJIconButtonProps {
  padding?: string;
}

const ADJIcon = styled(SAIcon)<IADJIconProps>`
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
  margin: ${(props) => (props.margin ? props.margin : "initial")};
`;

const ADJIconButton = styled(SAButton)<IADJIconButtonProps>`
  padding: ${(props) => (props.padding ? props.padding : "auto")};
`;

const ADJButton = styled(SAButton)<IADJIconProps>`
  // color: ${(props) => (props.color ? props.color : "auto")};
  background-color: ${(props) => props.backgroundColor};
  // font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "auto")};
  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
  border: none;
`;

const Wrapper = styled.div``;

interface IButtonCardProps {
  title: string;
  cardText?: string;
  buttonLabel: string;
  handleClick: () => void;
}

export function ButtonCard(props: IButtonCardProps) {
  const { title, cardText, buttonLabel, handleClick } = props;
  return (
    <SACard variant="standard" title={title}>
      <Heading color="#3B3B3B" marginBottom="25px">{cardText}</Heading>
      <FlexBox justifyContent="space-between">
        <SAButton
          fullWidth={false}
          label={buttonLabel}
          onClick={handleClick}
          textTransform="uppercase"
          variant="secondary-small-outline"
        />
        <div></div>
      </FlexBox>
    </SACard>
  );
}

interface IContactCardProps {
  title: string;
  emailId: string;
  phoneNumber: string;
}

export const ContactCard = ({
  title,
  emailId,
  phoneNumber,
}: IContactCardProps) => {
  const draftMail = () => {
    window.location.href = `mailto:${emailId}`;
  };

  return (
    <SACard variant="standard" title={title}>
      <Heading marginBottom="13px" color="#666666">
        Please reach us at:
      </Heading>
      <FlexBox cursor="pointer">
        <ADJIcon
          icon={SAIcons.mail}
          size="24px"
          colorVariant="dark"
        />
        <ContactDetails onClick={draftMail}>{emailId}</ContactDetails>
      </FlexBox>
      <HiddenBox height="13px" />
      <FlexBox cursor="pointer">
        <ADJIcon
          icon={SAIcons.phone}
          size="24px"
          colorVariant="dark"
        />
        <ContactDetails>{phoneNumber}</ContactDetails>
      </FlexBox>
    </SACard>
  );
};

interface IBlueButtonCard {
  title: string;
  buttonLabel: string;
  handleClick: () => void;
  handleReportsClick: () => void;
  text: string;
}

export const BlueButtonCard = ({
  title,
  buttonLabel,
  handleClick,
  handleReportsClick,
  text,
}: IBlueButtonCard) => {
  return (
    <AgencyCard variant="minimal">
      <FlexBox flexDirection="column">
        <AgencyCardHead>{title}</AgencyCardHead>
        <FlexBox flexDirection="row" justifyContent="space-between">
          <BlueCardRow className="bluecardfullwidth">
            {text}
          </BlueCardRow>
          <BlueCardRow className="bluecardbutton">
            <div>
              <BlueCardButtonContainer>
                <ADJButton
                  fullWidth={true}
                  color="primary"
                  hoverColor="#CCEEFF"
                  backgroundColor="#E1F5FF"            
                  label={buttonLabel}
                  onClick={handleClick}
                  textTransform="uppercase"
                  variant="secondary-small-outline"
                />
              </BlueCardButtonContainer>
              <BlueCardButtonContainer>
                <ADJButton
                  fullWidth={true}
                  color="primary"
                  hoverColor="#CCEEFF"
                  backgroundColor="#E1F5FF"            
                  label={"REPORTS"}
                  onClick={handleReportsClick}
                  textTransform="uppercase"
                  variant="secondary-small-outline"
                />
              </BlueCardButtonContainer>
            </div>
          </BlueCardRow>
        </FlexBox>
      </FlexBox>
    </AgencyCard>
  );
};
interface ISubCardsProps {
  title: string;
  legacyReports: { [key: string]: string };
  routeLegacyReports: (element: string) => void;
}

export const SubCards = ({
  title,
  legacyReports,
  routeLegacyReports,
}: ISubCardsProps) => {
  return (
    <Card variant="standard" title={title}>
      {Object.keys(legacyReports).sort().map((key) => (
        <SAReport key={key}
          variant="sub"
          onSubVariantClick={(e: any) => routeLegacyReports(legacyReports[key])}
        >
          <SAHypeLinks>{key}</SAHypeLinks>
        </SAReport>
      ))}
    </Card>
  );
};

interface IPdfCardProps {
  title: string;
  subHeading: { [key: string]: string };
  downloadPdf: (element: string) => void;
}

export const PdfCard = ({ title, subHeading, downloadPdf }: IPdfCardProps) => {
  return (
    <>
      <HiddenBox height="20px" />
      <BlueSACard variant="sub">{title}</BlueSACard>
      {Object.keys(subHeading).sort().map((key) => (
        <Wrapper key={key}>
          <FlexBox justifyContent="space-between" alignItems="center" height="60px">
            <FlexBox justifyContent="space-between">
              <ADJIcon
                icon={SAIcons.documentPDF}
                size="20px"
                colorVariant="primary"
                margin="10px"
              />
              <Heading fontWeight="bold" lineHeight="16px">
                {key}
              </Heading>
            </FlexBox>
            <ADJIconButton
              fullWidth={false}
              label="Download"
              variant="link"
              onClick={() => downloadPdf(subHeading[key])}
              padding="10px"
            >
              <ADJIcon
                colorVariant="#65A100"
                cursor="pointer"
                icon={SAIcons.download}
                size="28px"
              />
            </ADJIconButton>
          </FlexBox>
          <SAHr borderTop="1px solid #bbb" borderRadius="3px 3px 0px 0px" />
        </Wrapper>
      ))}
    </>
  );
};

export const SpinnerContainer = styled.div`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`;
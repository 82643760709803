import React, { useState, useEffect } from "react";
import { SACard, SAText, SAButton, SAAlert, SATag, SAUXTheme, SABox } from "@saux/design-system-react";
import * as queries from "../../graphql/queries";
import handleGraphQLErrors from "../../utils/handleGraphQLErrors";
import { API, graphqlOperation } from "aws-amplify";
import ModalSpinner from "../modal/ModalSpinner/ModalSpinner";
import { constants } from "../../utils/constants";
import styled from "styled-components";
import env from '../../env_config';
import { useForm, Controller, SubmitHandler, useWatch } from "react-hook-form";
import { encrypt, decrypt } from "../../utils/crypto";
import { Constants, Person, TerminateContactsValidation } from "../../interfaces/interfaces";
import TerminateContactsTable from "./TerminateContactsTable";
import { useAgencyContacts } from "./hooks/useAgencyContacts";
import ModalTerminateContacts from "../modal/ModalTerminateContacts/ModalTerminateContacts";
import ModalTerminateContactsAlert from "../modal/ModalTerminateContactsAlert/ModalTerminateContactsAlert";
import ModalAlert from "../modal/ModalTerminateContactsAlert/ModalTerminateContactsAlert";
import { GetBulkValidateQuery, BulkValidationResponseContacts } from "../../API";
import ContactData from "../Model/ContactModel";
import { checkAuthStatus } from "../../utils/utils";
import { simpleGTMDataLayer } from "../../utils/GTMHelpers";

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
  isEmployee?: boolean;
  isAgencyOpsInternalUser?: boolean;
};
const mobileWidth = 600;
const MAX_CONTACTS_LIMIT = 25;
const TerminateAgencyContactsCard = styled.div.attrs(({ constants, isEmployee, isAgencyOpsInternalUser }: StyleProps) => ({
  constants,
  isEmployee,
  isAgencyOpsInternalUser
}))`
  ${({ theme, constants, isEmployee, isAgencyOpsInternalUser}: StyleProps) => {
    const con: Constants = constants as Constants;
    let marginTop: number = isEmployee 
    ? isAgencyOpsInternalUser ?  
        con.headerHeight +
        con.dockedMenuHeight +
        con.dockedAgentInfoHeight +
        con.verticalPageOffset 
      : 
        con.headerHeight +
        con.dockedMenuHeight +
        con.dockedAgentInfoHeight +
        con.dockedWarningHeight +
        con.verticalPageOffset
      : con.headerHeight + con.dockedMenuHeight + con.verticalPageOffset;
    
    let marginTopMobile: number = isEmployee
      ? con.headerHeightMobile +
        con.dockedMenuHeight +
        con.dockedAgentInfoHeight +
        con.dockedWarningHeight +
        con.verticalPageOffset
      : con.headerHeightMobile + con.dockedMenuHeight + con.verticalPageOffset;

    return `
        display: grid;
        gap: 20px;
        grid-template-columns: auto 340px;
        margin-top: ${marginTop}px;
        margin-bottom: 60px;
        margin-left: ${theme?.size.spacer.small};
        margin-right: ${theme?.size.spacer.small};
  
        @media only screen and (max-width: ${mobileWidth}px) {
          margin-top: ${marginTopMobile}px;
          margin-left: ${theme?.size.spacer.medium};
          margin-right: ${theme?.size.spacer.medium};
        }

        @media only screen and (max-width: 800px) {
          grid-template-columns: auto;
        }
      `;
  }};
`;

const CardContainer = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium} ${theme?.size.spacer.large} ${theme?.size.spacer.medium}
    `;
  }};
`;

const CardHeader = styled.div`
  padding-bottom: 2rem;
  margin-left: 1rem;
  margin-left: 1rem;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 30px;

  .closemodal {
    display: none;
  }

  button {
    margin-left: 15px;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

const CardSubTitle = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  span {
    line-height: 1.5;
  }
`;

const CardBody = styled.div``;

const CardFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      margin-top: 3rem;
      display: flex;
      justify-content: space-between;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }
    `;
  }};
`;

const CardValidation = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      background-color: #FDEAE7;
      padding: ${theme?.size.spacer.large};
      margin-top: 60px;
      margin-left: -${theme?.size.spacer.large};
      margin-right: -${theme?.size.spacer.large};
      margin-bottom: 0;

      p {
        color: ${theme?.color.alert.background};
        font-weight: ${theme?.font.primary.weight.bold};
      }
    `;
  }};
`;

const RightPanelContainer = styled.div`
  display: flex;
  max-height: 800px;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border: none;
  }

  &::-webkit-scrollbar-track {
    background: #f0efef;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #8a8a8a;
    border-radius: 7px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

export function TerminateAgencyContacts() {
  const personId = decrypt(localStorage.getItem("personId"));
  const isAdmin: boolean = decrypt(localStorage.getItem("isAdmin")) === "true" ? true : false;
  // Is Employee currently impersonating a contact
  const isEmployee: boolean = decrypt(localStorage.getItem("loginType")) === "employeeID" ? true : false;
  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    reset,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm();

  const { persons, isLoading, personMap } = useAgencyContacts({
    isAdmin,
    personId,
  });

  const [personsWithError, setPersonsWithError] = useState<Map<string, TerminateContactsValidation>>(new Map());
  const [displayTerminateModal, setDisplayTerminateModal] = useState(false);
  const [displayTerminateAlertModal, setDisplayTerminateAlertModal] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [gtm, setGTM] = useState<boolean>(false);

  const selectedContacts: string[] = useWatch({ control: control, name: "personsid", defaultValue: [] });

  const selectedContactsWithDetails = selectedContacts
    .map((personid: string) => personMap.get(personid))
    .filter((person: Person | undefined) => person !== undefined) as Person[];

  const onSubmit: SubmitHandler<any> = async (data) => {
    validateSelectedContacts();
  };

  function deselectContact(event: React.MouseEvent, person_id: string) {
    event.stopPropagation();
    setValue(
      "personsid",
      selectedContacts.filter((personId: string) => personId !== person_id)
    );
  }

  function onClickClose(): void {
    window.location.href = env.saconnect;
  }


  function getContactTypeDescription(contactTypeId: string) {
    const contactType = ContactData.contacttype.find((ct) => ct.contacttype.toString() === contactTypeId);
    return contactType?.description ?? "";
  }

  
  function buildContactsWithError(terminableResponse: BulkValidationResponseContacts[]) {
    const valdationMap = new Map<string, TerminateContactsValidation>();
    terminableResponse.forEach((validation) => {
      const terminate = validation as TerminateContactsValidation;
      terminate.required_contact_types = terminate.required_contact_types
        ?.map((contact) => {
          const agencyCodes = contact.agency_codes.sort((a, b) => {
            if (a === b) {
              return 0;
            } else {
              return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
            }
          });
          return {
            ...contact,
            contact_type_description: getContactTypeDescription(contact.contact_type_id),
            agency_codes: agencyCodes,
          };
        })
        .sort((a, b) => {
          const aDescription: string = a.contact_type_description;
          const bDescription: string = b.contact_type_description;
          if (aDescription === bDescription) {
            return 0;
          } else {
            return aDescription.toLowerCase() < bDescription.toLowerCase() ? -1 : 1;
          }
        });
      valdationMap.set(terminate.person_id, terminate);
    });
    return valdationMap;
  }

  async function validateSelectedContacts() {
    setIsValidating(true);

    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }

    const payload = selectedContactsWithDetails.map((person) => {
      return { person_id: person.person_id, contact_type: person.contact_type };
    });
    
    const promiseContactsValidation = API.graphql(
      graphqlOperation(queries.getBulkValidate, {
        admin_person_id: personId,
        person_ids: payload,
      }),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem("auth_accesstoken"))}`,
      }
    ) as Promise<{ data: GetBulkValidateQuery }>;

    promiseContactsValidation
      .then((res) => {
        if (res.data) {
          return res.data.getBulkValidate
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200) {
          return res.body?.is_nonterminable;
        } else {
          return null;
        }
      })
      .then((terminableResponse) => {
        if (terminableResponse) {
          const valdationMap = buildContactsWithError(terminableResponse as BulkValidationResponseContacts[]);
          setPersonsWithError(valdationMap);

          if (valdationMap.size === 0) {
            setDisplayTerminateModal(true);
          } else {
            window.scrollTo({ top: 0 });
          }
          console.log(personsWithError);
        }
        setIsValidating(false);
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err);
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });
  }

  useEffect(() => {
    if (!gtm) {
      simpleGTMDataLayer({
        event: 'pageview',
        page: {
          path: '/terminate-agency-contacts',
          title: 'ADJSS: Bulk Terminate Contacts'
        }
      });
      setGTM(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("edit_state", encrypt("default"));
    if (!document.getElementById("modal")) {
      const modalRoot: HTMLDivElement = document.createElement("div");
      modalRoot.setAttribute("id", "modal");
      document.body.append(modalRoot);
    }
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      // display continue editing modal
      if (value.personsid.length > 0) {
        localStorage.setItem("edit_state", encrypt("persons_terminate"));
      } else {
        localStorage.setItem("edit_state", encrypt("default"));
      }

      // display terminate alert modal if selected contacts exceeds max value
      if (value.personsid.length > MAX_CONTACTS_LIMIT) {
        setDisplayTerminateAlertModal(true);
      } else {
        setDisplayTerminateAlertModal(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (displayTerminateModal || displayTerminateAlertModal || isValidating) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [displayTerminateModal, displayTerminateAlertModal, isValidating]);

  if (!isLoading && persons && persons.length === 0) {
    return (
      <ModalAlert
        title="Account Inaccessible"
        text={
          <span>
            Your account is inaccessible. Reach out to{" "}
            <a href="mailto:Agency.Operations@stateauto.com">Agency.Operations@stateauto.com</a> for updates.
          </span>
        }
        onClickClose={onClickClose}
      />
    );
  }

  return (
    <>
      <TerminateAgencyContactsCard constants={constants} isEmployee={isEmployee}>
        <CardContainer variant="minimal" removePadding>
          <form id="person_bulk_terminate_form" onSubmit={handleSubmit(onSubmit)}>
            <CardHeader>
              <CardTitle>
                <SAText type="heading-2" text="Terminate Agency Contacts" />
              </CardTitle>
              <CardSubTitle>
                <SAText type="standard">Select the contacts you need to terminate.</SAText>
                <SAText type="standard">
                  <i>
                    Note: Please email Agency Operations at&ensp;
                    <a href="mailto:Agency.Operations@stateauto.com">Agency.Operations@stateauto.com</a> to terminate a
                    Principal.
                  </i>
                </SAText>
              </CardSubTitle>
            </CardHeader>
            <CardBody>
              {personsWithError.size > 0 &&
                selectedContacts.some((personId: string) => personsWithError.has(personId)) && (
                  <SABox ml="medium" mb="large" mr="medium">
                    <SAAlert severity="error">Bulk termination errors must be removed to continue. Click "SHOW ERRORS ONLY" for full list and details.</SAAlert>
                  </SABox>
                )}
              {!isLoading && (
                <Controller
                  control={control}
                  name="personsid"
                  defaultValue={[]}
                  rules={{
                    validate: (value) => (value ? value.length > 0 : false),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TerminateContactsTable
                      onChange={(value) => onChange(value)}
                      resetSearchFilter={isSubmitSuccessful}
                      values={value ? value : []}
                      personsData={persons || []}
                      personsWithError={personsWithError}
                    />
                  )}
                />
              )}
            </CardBody>
            {errors.personsid && (
              <CardValidation>
                <SAText type="paragraph" text="At least one contact is required." />
              </CardValidation>
            )}
            <CardFooter>
              <SAButton
                fullWidthUnder={mobileWidth}
                label="Deselect All"
                type="button"
                variant="link-medium"
                textTransform="uppercase"
                onClick={() => reset({ personsid: [] })}
              />
              <SAButton
                fullWidthUnder={mobileWidth}
                label="Continue"
                type="submit"
                variant="primary-medium"
                textTransform="uppercase"
              />
            </CardFooter>
          </form>
        </CardContainer>
        <CardContainer variant="minimal" removePadding>
          <SAText
            type="standard"
            weight="bold"
            text={`Selected contacts for termination ${
              selectedContacts.length > 0 ? `(${selectedContacts.length})` : ""
            }`}
          />
          <RightPanelContainer>
            {selectedContactsWithDetails.map((person: Person, index: number) => (
              <SATag
                key={person.person_id}
                variant={!personsWithError.has(person.person_id) ? "primary-small" : "alert-small"}
                label={`${person.prdcr_last_name}, ${person.prdcr_first_name} ${
                  person.login_id ? `(${person.login_id})` : ""
                }`}
                closeable
                onClose={(e) => deselectContact(e, person.person_id)}
              />
            ))}
          </RightPanelContainer>
        </CardContainer>
      </TerminateAgencyContactsCard>
      {isLoading && <ModalSpinner description="This page may take several minutes to load." />}
      {isValidating && <ModalSpinner />}
      {displayTerminateModal && (
        <ModalTerminateContacts
          contacts={selectedContactsWithDetails}
          onClickCancel={() => setDisplayTerminateModal(false)}
          onClickConfirm={() => console.log(getValues())}
        />
      )}
      {displayTerminateAlertModal && (
        <ModalTerminateContactsAlert
          title="Terminated Contact count exceeds 25"
          text="Only 25 contacts may be processed at one time. Your last selection has been removed. Once you submit the first 25, you may return and process more terminations."
          onClickClose={(e) => {
            deselectContact(e, selectedContacts[selectedContacts.length - 1]);
          }}
        />
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  SACard,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
  SARadioGroup,
  SARadio,
  SASeparator,
  SAUXTheme
} from '@saux/design-system-react';
import { ContactTypeKeyPair } from '../../../interfaces/interfaces';
import styled from 'styled-components';
import ModalLearnMore from '../ModalLearnMore/ModalLearnMore';

const mobileWidth = 600;

type ContactTypesProps = {
  contactTypes: ContactTypeKeyPair[];
  onClickCancel: Function;
  onClickSubmit: Function;
  selectedContactType?: string;
};

type StyleProps = {
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const ContactTypes = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 600px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 45px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
`;

const ModalBody = styled.div`
  legend {
    display: none;
  }
`;

const ModalFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
  }};
`;

const RadioGroup = styled(SARadioGroup)`
  ${({ theme }: StyleProps) => {
    return `
      border: rgb(217,223,235) solid 1px;
      padding: 15px;
      max-height: 270px;
      overflow-y: auto;

      label {
        color: ${theme?.colors.text};
      }
      
      &::-webkit-scrollbar {
        width: 7px;
        height: 92px;
        border: none;
      }
    
      &::-webkit-scrollbar-track {
          background: #F0EFEF;
          border-radius: 7px;
      }
    
      &::-webkit-scrollbar-thumb {
          background: #8A8A8A;
          border-radius: 7px;
          box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      }
    `;
  }};
`;

const ButtonGrouping = styled.div`
  display: flex;
  align-items: center;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    }
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      margin-left: 15px;
      display: flex;
    }
  }
`;

export default function ModalContactTypes({ contactTypes, onClickCancel, onClickSubmit, selectedContactType }: ContactTypesProps) {
  const [contactType, setContactType] = useState<string>(selectedContactType as string);
  const [displayLearnMoreModal, setDisplayLearnMoreModal] = useState<boolean>(false);

  function handleClickContentTypesModal(event: React.MouseEvent) {
    event.stopPropagation();

    // get the card element inside the modal
    const cardElement = document.querySelector('#modal > div > article > section');

    // close modal when clicking outside of the card element
    if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
      onClickCancel(event);
    }
  }

  function handleClickCancel(event: React.MouseEvent) {
    event.stopPropagation();
    onClickCancel(event);
  }

  function handleClickSubmit(event: React.MouseEvent) {
    event.stopPropagation();
    onClickSubmit(event, contactType);
  }

  function handleRadioChange(event: any) {
    setContactType(event.target.value);
  }

  function handleClickLearnMore(event: React.MouseEvent) {
    setDisplayLearnMoreModal(!displayLearnMoreModal);
  }

  function handleClickLearnMoreModalClose(event: React.MouseEvent) {
    setDisplayLearnMoreModal(!displayLearnMoreModal);
  }

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modalcontacttypes') as HTMLElement;
      if (modal) {
        const radio = modal.querySelector('label.checked') as HTMLElement;
        if (radio) {
          (radio?.firstChild as HTMLElement).focus();
        }
      } 
    }

    function focusModalContactTypes(e: any) {
      const modal = document.querySelector('#modalcontacttypes') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          const element = modal.querySelector('button') as HTMLElement;
          if (element) {  
            element.focus();
          }
        }
      }
    }

    focusDefault();
    document.addEventListener('focusin', focusModalContactTypes);
    document.addEventListener('focusout', focusModalContactTypes);

    return () => {
      document.removeEventListener('focusin', focusModalContactTypes);
      document.removeEventListener('focusout', focusModalContactTypes);
    }
  }, []);

  //TODO - update RadioGroup styled component to allow scrolling with max-height.
  return (
    <>
      {!displayLearnMoreModal &&
        <Modal id="modalcontacttypes" onClickHandler={handleClickContentTypesModal}>
          <ContactTypes variant="minimal">
            <ModalHeader>
              <ModalTitle>
                <SAText type="heading-2" text="Contact Types" />
                <ButtonGrouping>
                  <SAButton
                    label="Learn More"
                    onClick={handleClickLearnMore}
                    textTransform="uppercase"
                    variant="primary-link-medium"
                  />
                  <SAIcon className="closemodal" icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" onClick={handleClickCancel} />
                </ButtonGrouping>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <RadioGroup id="contacttypes" value={contactType} variant="standard" direction="vertical" onChange={handleRadioChange}>
                <SARadio key="all" value="all" label="All Contact Types" variant="standard" />
                <SASeparator horizontal />
                {contactTypes.map((ct) => {
                  return (
                    <SARadio key={ct.key} value={ct.key} label={ct.value} variant="standard" />
                  )
                })}
              </RadioGroup>
            </ModalBody>
            <ModalFooter>
              <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
              <SAButton fullWidthUnder={mobileWidth} label="Submit" onClick={handleClickSubmit} variant="primary-large" textTransform="uppercase" />
            </ModalFooter>
          </ContactTypes>
        </Modal>
      }
      {displayLearnMoreModal &&
        <ModalLearnMore onClickClose={handleClickLearnMoreModalClose} />
      }
    </>
  );
}

ModalContactTypes.defaultProps = {
  selectedContactType: 'all',
}

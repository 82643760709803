import { useState, useEffect } from "react";
import * as queries from "../../../graphql/queries";
import handleGraphQLErrors from "../../../utils/handleGraphQLErrors";
import { API, graphqlOperation } from "aws-amplify";
import { checkAuthStatus } from "../../../utils/utils";
import { decrypt } from "../../../utils/crypto";
import { GetBulkSelectQuery, agencyLocationsPersons as AgencyLocationsPersons  } from "../../../API";
import { Person } from "../../../interfaces/interfaces";

type AgencyParam = {
  personId: string;
  isAdmin: boolean;
};

export function useAgencyContacts({ personId, isAdmin }: AgencyParam) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [persons, setPersons] = useState<Person[] | null>(null);
  const [personMap, setPersonMap] = useState<Map<string, Person>>(new Map());


  function buildMapWithPerson(agencyLocationsPersons: AgencyLocationsPersons[]) {
    // Used map to remove duplicate contacts and key as person_id and combined all contact types from all locations
    const map = new Map<string, Person>();
    agencyLocationsPersons.forEach(location => {
      location?.persons?.forEach((contact) => {
        const person = contact as unknown as Person;
        const isPendingPerson = ['Pending Addition', 'Pending Updates', 'Pending Termination', 'Progress Updates'].includes(person?.pending_status ?? "");
        if (!isPendingPerson && person?.person_id) {
          if (map.has(person.person_id)) {
            const dublicatePerson = map.get(person.person_id);
            const distinctContactTypes =  person.contact_type?.filter(type => (dublicatePerson?.contact_type ?? []).indexOf(type) < 0);
            person.contact_type = dublicatePerson?.contact_type?.concat(distinctContactTypes ?? []);
          }
          map.set(person.person_id, person);
        }
      });
    });
    return map;
  }

  
  function distinctAndSortPersonData(map: Map<string, Person>) {
    const uniquePersonData = Array.from(map.values());
    uniquePersonData.sort((a, b) => {
      const nameA = `${a.prdcr_last_name}, ${a.prdcr_first_name}`;
      const nameB = `${b.prdcr_last_name}, ${b.prdcr_first_name}`;
      if (nameA === nameB) {
        return 0;
      } else {
        return nameA.toLowerCase() < nameB.toLowerCase() ? -1 : 1;
      }
    });
    return uniquePersonData;
  }

  async function fetchAgencyLandingPage() {
    setIsLoading(true);
    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }

    const promiseLocations = 
      API.graphql(
        graphqlOperation(queries.getBulkSelect, {
          admin_person_id: personId,
        }),
        {
          Authorization: `Bearer ${decrypt(localStorage.getItem("auth_accesstoken"))}`,
        }
      ) as Promise<{ data: GetBulkSelectQuery }>
    
    promiseLocations.then((res) => {
        if (res.data) {
          return res.data.getBulkSelect;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200) {
          return res.body?.agency_location_persons;
        } else {
          return null;
        }
      }).then((agencyLocationsPersons) => {
        if (agencyLocationsPersons) {   
          const map = buildMapWithPerson(agencyLocationsPersons as AgencyLocationsPersons[]);
          setPersonMap(map);
          const uniquePersonData = distinctAndSortPersonData(map);
          setPersons(uniquePersonData);
        }
        setIsLoading(false);
      }).catch((err) => {
        if (err?.message) {
          console.error(err.message, err);
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });

  }

  useEffect(() => {
    if (isAdmin) {
      fetchAgencyLandingPage();
    }
  }, []);

  return {
    isLoading,
    persons,
    personMap,
  };
}

import env from '../env_config';

export function encrypt(value: string) {
  if (env['session-encryption-enabled']) {
    const CryptoJS = require("crypto-js");
    const encrypted = CryptoJS.AES.encrypt(value, env['encryption-key']).toString();
    return encrypted;
  } else {
    return value;
  }
}

export function decrypt(value: string | null) {
  if (env['session-encryption-enabled']) {
    if (value !== null) {
      const CryptoJS = require('crypto-js');
      const decrypted = CryptoJS.AES.decrypt(value, env['encryption-key']).toString(CryptoJS.enc.Utf8);
      return decrypted;
    } else {
      return null;
    } 
  } else {
    return value;
  }
}
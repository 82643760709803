import styled from "styled-components";
import configuration from "../../env_config";
import { Card, HiddenBox } from "../AgencyAdministration/AgencyAdminStyled";
import { PdfCard } from "../common/commonComponents";

const SAHeading = styled.div`
  color: #3B3B3B;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ADJDownload = () => {
  const downloadPdf = (url: string) => {
    window.open(url, "_blank");
  };

  const downloadCodes: { [key: string]: string } = {
    "Commercial Lines Businessowners": configuration.CLBusinessOwner,
    "Commercial Lines Business Auto": configuration.CLBusinessAuto,
    "Commercial Lines GL Coverage": configuration.CLGLCoverage,
    "Commercial Lines GL Class Codes and Descriptions": configuration.CLGLClassCodeNDesc,
    "Commercial Lines Inland Marine Class Codes and Descriptions": configuration.CLIMClassCodeNDesc,
    "Commercial Lines Property Coverage": configuration.CLPropCoverage,
    "Commercial Lines Property SOI": configuration.CLPropSOI,
    "Commercial Lines Worker's Compensation": configuration.CLWorkerComp,
    "Personal Lines Auto": configuration.PLAuto,
    "Personal Lines Dwelling Fire": configuration.PLDwellingFire,
    "Personal Lines Homeowner": configuration.PLHomeowner,
    "Driver Download Attributes": configuration.DriverDownAttr,
    "NAIC": configuration.NAIC,
  };

  return (
    <>
      <HiddenBox height="20px" />
      <Card variant="standard" title="State Auto Vendor Download Codes" removePadding>
        <SAHeading>
          In order to Download with State Auto, unique download codes are
          required per Line of Business. The following links provide detailed
          information for these codes.
        </SAHeading>
        <PdfCard
          title="File Name"
          subHeading={downloadCodes}
          downloadPdf={downloadPdf}
        />
        <HiddenBox height="40px" />
        <SAHeading>
          If you have any question or problems, please call (833) SAHelps or
          (833) 724-3577 for support, or{" "}
          <a
            style={{ textDecoration: "none" }}
            href="mailto:SAHelps@StateAuto.com"
          >
            email
          </a>{" "}
          your Agency Interface Support Representative.
        </SAHeading>
        <HiddenBox height="20px" />
      </Card>
    </>
  );
};

export default ADJDownload;

export const constants = {
  headerHeight: 98,
  headerHeightMobile: 70,
  dockedMenuHeight: 32,
  dockedStepperHeight: 56,
  dockedWarningHeight: 20,
  dockedAgentInfoHeight: 65,
  disableBannerHeight: 65,
  verticalPageOffset: 50
};

export const PENDING_STATUS = [
  "Pending Addition",
  "Pending Updates",
  "Pending Termination",
];
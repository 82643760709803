import React, { useEffect, useState } from "react";
import {
  SACard,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
  SAUXTheme
} from '@saux/design-system-react';
import styled from 'styled-components';
import { ContactDataContactType, Terminable, TerminableContactType } from "../../../interfaces/interfaces";
import ContactData from '../../Model/ContactModel';

const mobileWidth = 600;

type ContactAgencyOperationsProps = {
  title: string;
  text: string;
  requiredContactTypes?: boolean;
  onClickClose: Function;
  terminableResponse: Terminable | null;
};

type StyleProps = {
  error?: boolean;
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const ContactAgencyOperations = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 600px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 45px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    } 
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      display: flex;
    }
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalFooter: any = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
  }};
`;

const Email = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &:first-child {
      font-weight: 700;
    }
  }
`;

const RequiredContactTypes = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &:first-child {
      font-weight: 700;
    }
  }
`;

const Text = styled(SAText)`
  font-size: 20px;
  line-height: 26px;
`;

const RequiredContactTypesText = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      font-size: ${theme?.font.primary.size.large};
      font-weight: ${theme?.font.primary.weight.normal};
      line-height: 26px;

      span {
        font-weight: ${theme?.font.primary.weight.bold};
      }
    `;
  }};
`;

export default function ModalContactAgencyOperations({ title, text, requiredContactTypes, terminableResponse, onClickClose }: ContactAgencyOperationsProps) {
  const [uniqueContactTypes, setUniqueContactTypes] = useState<string[]>([]);

  useEffect(() => {
    if (requiredContactTypes && terminableResponse !== null) {
      const all_contact_types: string[] = [];

      terminableResponse.required_contact_types?.forEach((contactType: TerminableContactType) => {
        ContactData.contacttype.forEach((ct: ContactDataContactType) => {
          if (contactType.contact_type_id === ct.contacttype.toString()) {
            all_contact_types.push(ct.description);
          }
        });
      })
      
      const unique_contact_type_descriptions: string[] = Array.from(new Set(all_contact_types.map((row) => JSON.stringify(row))), (row) => JSON.parse(row));

      unique_contact_type_descriptions.sort((a, b) => {
        if (a === b) {
          return 0;
        } else {
          return (a.toLowerCase() < b.toLowerCase()) ? -1 : 1;
        }
      });

      setUniqueContactTypes(unique_contact_type_descriptions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modalcontactagencyoperations') as HTMLElement;
      if (modal) {
        const element = modal.querySelector('button.sa-button') as HTMLElement;
        if (element) {
          element.focus();
        }
      } 
    }

    function focusModalContactAgencyOperations(e: any) {
      const modal = document.querySelector('#modalcontactagencyoperations') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          let element = null;
          if (document.documentElement.clientWidth <= mobileWidth) {
            element = modal.querySelector('button') as HTMLElement;
          } else {
            element = modal.querySelector('a') as HTMLElement;
          }
          if (element) {  
            element.focus();
          }
        }
      }
    }

    focusDefault();
    document.addEventListener('focusin', focusModalContactAgencyOperations);
    document.addEventListener('focusout', focusModalContactAgencyOperations);

    return () => {
      document.removeEventListener('focusin', focusModalContactAgencyOperations);
      document.removeEventListener('focusout', focusModalContactAgencyOperations);
    }
  }, []);
  
  function handleClickContactAgencyOperationsModal(event: React.MouseEvent) {
    event.stopPropagation();

    // get the card element inside the modal
    const cardElement = document.querySelector('#modal > div > article > section');

    // close modal when clicking outside of the card element
    if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
      onClickClose(event);
    }
  }

  function handleClickClose(event: React.MouseEvent) {
    event.stopPropagation();
    onClickClose(event);
  }

  function getDescription(contactTypeId: string) {
    let description: string = '';
    ContactData.contacttype.forEach((ct: ContactDataContactType) => {
      if (ct.contacttype.toString() === contactTypeId.toString()) {
        description = ct.description;
      }
    });
    return description;
  }

  const displayContactTypes = () =>  {
    return (terminableResponse as Terminable).required_contact_types?.sort(
      (a, b) => {
        const aDescription: string = getDescription(a.contact_type_id);
        const bDescription: string = getDescription(b.contact_type_id);
        if (aDescription === bDescription) {
          return 0;
        } else {
          return (aDescription.toLowerCase() < bDescription.toLowerCase()) ? -1 : 1;
        }
      }
    ).map((contactType: TerminableContactType) => {
      return ContactData.contacttype.map((ct: ContactDataContactType) => {
        if (contactType.contact_type_id === ct.contacttype.toString()) {
          return (
            <RequiredContactTypes key={`${contactType.contact_type_id}`}>
              <SAText type="standard" text={`${ct.description}`} />
              {contactType.agency_codes.sort(
                (a, b) => {
                  if (a === b) {
                    return 0;
                  } else {
                    return (a.toLowerCase() < b.toLowerCase()) ? -1 : 1;
                  }
                }
              ).map((agency_name: string) => {
                return (
                  <SAText key={agency_name} type="standard" text={`${agency_name}`} />
                );
              })}
            </RequiredContactTypes>
          );
        }
      });
    });
  };

  return (
    <Modal id="modalcontactagencyoperations" onClickHandler={handleClickContactAgencyOperationsModal}>
      <ContactAgencyOperations variant="minimal">
        <ModalHeader>
          <ModalTitle>
            <SAText type="heading-2" text={title} />
            <SAIcon className="closemodal" icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" onClick={handleClickClose} />
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          {requiredContactTypes &&
            <RequiredContactTypesText>
              At least 1 agency contact must have contact type: <span>{uniqueContactTypes.join(', ')}</span>. Add this contact type(s) to another person before terminating.
            </RequiredContactTypesText>
          }
          {!requiredContactTypes &&
            <>
              <Text type="standard" text={text} />
              <Email>
                <SAText type="standard" text="Email Address" />
                <span><a href="mailto:Agency.Operations@stateauto.com">Agency.Operations@stateauto.com</a></span>
              </Email>
            </>
          }
          {requiredContactTypes && terminableResponse &&
            displayContactTypes()
          }
        </ModalBody>
        <ModalFooter>
          <SAButton label="Close" onClick={handleClickClose} variant="large" color="secondary" textTransform="uppercase" />
        </ModalFooter>
      </ContactAgencyOperations>
    </Modal>
  );
}

ModalContactAgencyOperations.defaultProps = {
  requiredContactTypes: false,
  terminableResponse: null,
};

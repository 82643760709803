import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import {
  SACard,
  SAText,
  SAButton,
  SACheckbox,
  SAPopover,
  SAIcon,
  SAIcons,
  SAUXTheme
} from '@saux/design-system-react';
import { NewContact, NewContactContactType, NewContactAgencyCode, IndividualDetails, ContactDataContactType, AddContactAgencyCode, Constants, AgencyCodeAddress, AppGenPayloadAdd, AddContactAgencyCodeInfo } from '../../interfaces/interfaces';
import styled from 'styled-components';
import ModalApplicationGenerated from '../modal/ModalApplicationGenerated/ModalApplicationGenerated';
import ContactDetailsSideCard from '../ContactDetailsSideCard/ContactDetailsSideCard';
import ModalCancelAlert from '../modal/ModalCancelAlert/ModalCancelAlert';
import ContactData from "../Model/ContactModel";
import ModalSpinner from '../modal/ModalSpinner/ModalSpinner';
import ReactDOM from 'react-dom';
import { encrypt, decrypt } from '../../utils/crypto';
import ModalDuplicateLogin from '../modal/ModalDuplicateLogin/ModalDuplicateLogin';
import ModalCrossHierarchy from '../modal/ModalCrossHierarchy/ModalCrossHierarchy';
import { constants } from '../../utils/constants';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { GetAgencyCodeAddressQuery, PersonLookupLoginIdExistsQuery, PersonAddDetailsMutation, UpdatePersonAddressMutation, UpdatePersonPrivilegesMutation, CreateServiceNowRequestMutation } from '../../API';
import handleGraphQLErrors from '../../utils/handleGraphQLErrors';
import simpleGTMDataLayer from '../../utils/GTMHelpers/simpleGTMDataLayer';
import { checkAuthStatus } from '../../utils/utils';

const mobileWidth = 600;

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
  isEmployee?: boolean;
};

type Position = 'top-start' | 'top' | 'top-end' | 'right' | 'bottom-start' | 'bottom' | 'bottom-end' | 'left';

type Offset = [number, number];

const Container = styled.div.attrs(({ constants, isEmployee }: StyleProps) => ({
  constants,
  isEmployee
}))`
  ${({ theme, constants, isEmployee }: StyleProps) => {
    const con: Constants = constants as Constants;
    const marginTop: number = isEmployee ? con.headerHeight + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeight + con.dockedStepperHeight + con.verticalPageOffset;
    const marginTopMobile: number = isEmployee ? con.headerHeightMobile + con.dockedWarningHeight + con.dockedAgentInfoHeight + con.dockedStepperHeight + con.verticalPageOffset : con.headerHeightMobile + con.dockedStepperHeight + con.verticalPageOffset;

    return `
      display: flex;
      flex-direction: row;
      max-width: 1200px;
      margin: ${marginTop}px ${theme?.size.spacer.medium} 60px ${theme?.size.spacer.medium};
    
      @media only screen and (max-width: ${mobileWidth}px) {
        margin: ${marginTopMobile}px ${theme?.size.spacer.medium} 0 ${theme?.size.spacer.medium};
      }
    
      article:first-child {
        flex-grow: 1;
      }
    
      article:last-child {
        margin-left: ${theme?.size.spacer.large};
        width: 300px;
        flex-shrink: 0;
      }
    `;
  }};
`;

const CardHeader = styled.div`
  padding-bottom: 45px;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  .closemodal {
    display: none;
  }

  @media only screen and (max-width: ${mobileWidth}px) {    
    button {
      display: none;
    }
  }
`;

const CardSubTitle = styled.div``;

const CardBody = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      div {
        &.saInputWrapper {
          margin: 0 ${theme?.size.spacer.medium} ${theme?.size.spacer.large} 0;
          padding-right: ${theme?.size.spacer.medium};

          @media only screen and (max-width: ${mobileWidth}px) {
            margin: 0 0 ${theme?.size.spacer.large} 0;
            padding-right: 0;
          }
        }

        &.textLikeInput {
          width: 50%;
          
          @media only screen and (max-width: ${mobileWidth}px) {
            width: 100%;
          }
        }
      }
    `;
  }};
`;

const CardFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 60px;

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
      }

      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      div {
        &:last-child {
          display: flex;
          flex-direction: row;

          button {
            margin-left: ${theme?.size.spacer.medium};

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              font-weight: ${theme?.font.primary.weight.bold};
            }
          }

          @media only screen and (max-width: ${mobileWidth}px) {
            button {
              &:first-child {
                display: none;
              }
            }
          }
        }

        @media only screen and (max-width: ${mobileWidth}px) {
          flex-grow: 1;
        }
      }
    `;
  }};
`;

const CancelButton = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: none;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: ${theme?.size.spacer.medium} ${theme?.size.spacer.medium};
      }
    `;
  }};
`;

const CheckboxContainer = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-height: 360px;
    
      @media only screen and (max-width: ${mobileWidth}px) {
        flex-direction: column;
        flex-wrap: nowrap;
        max-height: fit-content;
      }
    
      div {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50%;

        &.helpicon {
          padding-top:0;
          padding-left: 10px;
          flex-basis: 0;

          button {
            padding: 0;
        
            &:hover {
              background-color: transparent;
            }
          }

          svg {
            g {
              path {
                fill: hsl(205, 99%, 39%);
              }
            }
          }
        }
      }

      .saCheckboxWrapper {
        display: flex;
        flex-grow: 0;
        align-items: center;
        margin: 10px 0;
        height: 40px;
        padding: 0 15px;
        z-index: 0;

        &:hover {
          background-color: rgba(216,223,236,0.25);
          cursor: pointer;

          @media only screen and (max-width: ${mobileWidth}px) {
            background-color: initial;
          }
        }

        input {
          &:hover {
            cursor: pointer;
          }
        }

        label {
          pointer-events: none;
        }

        input {
          pointer-events: none;
        }

        label {
          &:hover {
            cursor: pointer;
          }
        }
      }
    `;
  }};
`;

const Popover = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      color: hsla(220, 38%, 97%, 1);
      font-size: ${theme?.font.primary.size.smallTEMP};
      font-weight: ${theme?.font.primary.weight.normal};
      line-height: 16px;
      background-color: hsla(213, 30%, 35%, 1);
      border: 1px solid hsla(213, 30%, 35%, 1);
      box-sizing: border-box;
      box-shadow: 0px 0px 5px rgba(0, 32, 56, 0.22);
      border-radius: 4px;
      padding: 15px;
      width: 360px;
    `;
  }};
`;

const HelpContainer = styled.div`
  display: flex;
  flex-directino: column;
`;

const HelpIcon = styled.div`
  display: flex;
  padding-right: 10px;
  align-items: flex-start;
`;

const HelpText = styled.div`
  display: flex;
  align-items: flex-start;
`;

export default function UserPrivileges(props: any) {
  const adminPersonId = decrypt(localStorage.getItem('personId'));
  const { register, handleSubmit } = useForm();
  const onSubmit = (data: any) => validateForm(data);
  const history = useHistory();
  const [newContact, setNewContact] = useState<NewContact>(JSON.parse(decrypt(localStorage.getItem('NewContact'))));
  const [newContactBackup, setNewContactBackup] = useState<NewContact>();
  const availablePrivileges: string[] = ['Personal Lines', 'Commercial Lines', 'View Agency Level Reports'];
  const [path, setPath] = useState<string>('');
  const contactTypeModel: ContactDataContactType[] = [...ContactData.contacttype];
  const [mobile, setMobile] = useState<boolean>(true);
  contactTypeModel.sort((desc1, desc2) => {
    return desc1.description.localeCompare(desc2.description);
  });
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const rememberRefElem = useRef<any>(null);
  const desktopPosition: Position = 'right';
  const mobilePosition: Position = 'top-end';
  const desktopOffset: Offset = [0, 10];
  const mobileOffset: Offset = [0, 10];
  const [position, setPosition] = useState<Position>(
    document.documentElement.clientWidth <= mobileWidth ? mobilePosition : desktopPosition,
  );
  const [offset, setOffset] = useState<Offset>(
    window.innerWidth <= mobileWidth ? mobileOffset : desktopOffset,
  );
  const [popover, setPopover] = useState<string | null>(null);
  const personId = decrypt(localStorage.getItem('personId'));
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;
  const [userCreated, setUserCreated] = useState<boolean>(false);
  const [crossHierarchyTitle, setCrossHierarchyTitle] = useState<string>('');
  const [crossHierarchyText, setCrossHierarchyText] = useState<string>('');
  const [crossHierarchyPrimaryAgencyCode, setCrossHierarchyPrimaryAgencyCode] = useState<string>('');
  const [crossHierarchyAddedContactTypes, setCrossHierarchyAddedContactTypes] = useState<string>('');
  const [crossHierarchyAssociationsNotBeingAdded, setCrossHierarchyAssociationsNotBeingAdded] = useState<{ contacttype: number, producer_number: string }[]>([]);
  // const [associations, setAssociations] = useState<{ contact_type_id: number, contact_type_description: string, agency_codes: string[] }[]>([]);

  // App Generated Modal
  const [payload, setPayload] = useState<AppGenPayloadAdd | null>(null);
  const [appGenModalClosed, setAppGenModalClosed] = useState<boolean>(false);
  const [showAppGenModalSpinner, setShowAppGenModalSpinner] = useState<boolean>(false);

  // Modals
  const [displayApplicationGeneratedModal, setDisplayApplicationGeneratedModal] = useState<boolean>(false);
  const [displayDuplicateLoginModal, setDisplayDuplicateLoginModal] = useState<boolean>(false);
  const [displayCancelAlert, setDisplayCancelAlert] = useState<boolean>(false);
  const [displayCrossHierarchyModal, setDisplayCrossHierarchyModal] = useState<boolean>(false);

  // Modal Spinner
  const [showModalSpinner, setShowModalSpinner] = useState<boolean>(false);

  async function validateForm(data: any) {
    if (!isEmployee) {
      setShowModalSpinner(true);

      const isAuthenticated: boolean = await checkAuthStatus();
      if (!isAuthenticated) {
        return;
      }
      const promiseLoginIDValidation = API.graphql(
        graphqlOperation(
          queries.personLookupLoginIdExists,
          {
            login_id: newContact.contactDetails.generalDetails.loginId
          }
        ),
        {
          Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
        }
      ) as Promise<{ data: PersonLookupLoginIdExistsQuery }>;

      promiseLoginIDValidation.then((res) => {
        if (res.data) {
          return res.data.personLookupLoginIdExists;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200) {
          return res.body as { login_id: string; exists: 'YES' | 'NO' } | null | undefined;
        } else {
          return null;
        }
      })
      .then((res) => {
        if (res) {
          if (res.exists === 'NO') {
            // no duplicate found.
            setShowModalSpinner(false);
            return res;
          } else if (res.exists === 'YES') {
            // duplicate loginId found.  Show modal.
            setShowModalSpinner(false);
            setDisplayDuplicateLoginModal(true);
            return null;
          } else {
            // an unexpected response was returned
            return null;
          }
        } else {
          return null;
        }
      })
      .then((res) => {
        if (res) {
          const newPrivileges: string[] = [];
          availablePrivileges.forEach((privilege: string, index: number) => {
            const checked: boolean = data[`privilege_${index.toString()}`];
            if (checked) {
              newPrivileges.push(privilege);
            }
          });
          newContact.contactDetails.userPrivileges = newPrivileges;
          setNewContact(newContact);
          localStorage.setItem('NewContact', encrypt(JSON.stringify(newContact)));
  
          // Check if Licensed Producer is selected
          let licensedProducerSelected: boolean = false;
          newContact.contactDetails.contactTypes.forEach((ct: NewContactContactType) => {
            if (ct.contacttype === 37) {
              licensedProducerSelected = true;
            }
          });
  
          if (licensedProducerSelected && newContact.contactDetails.contactTypes.length > 1) {
            const agencyCodes: string[] = [];
            newContact.contactDetails.agencyCodes.forEach((ct: NewContactAgencyCode) => {
              if (ct.contacttype === 37) {
                agencyCodes.push(ct.producer_number);
              }
            });

            const appGenPayload: AppGenPayloadAdd = {
              agency_admin_person_id: Number(adminPersonId),
              producer_first_name: newContact.contactDetails.generalDetails.firstName,
              producer_last_name: newContact.contactDetails.generalDetails.lastName,
              agency_codes: agencyCodes.join(','),
              new_contact: 'no',
              producer_email: newContact.contactDetails.email
            };

            setPayload(appGenPayload);
            setDisplayApplicationGeneratedModal(!displayApplicationGeneratedModal);
          } else {
            setShowModalSpinner(true);
            continueSubmission();
          }
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err); 
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });
    }
  }

  function sortByContactType(a: NewContactAgencyCode, b: NewContactAgencyCode) {
    if (a.contacttype === b.contacttype) {
      return 0;
    } else {
      return (a.contacttype < b.contacttype) ? -1 : 1;
    }
  }

  function sortByContactTypeDescription(a: NewContactAgencyCode, b: NewContactAgencyCode) {
    const descA = ContactData.contacttype.find((item) => item.contacttype === a.contacttype)?.description as string;
    const descB = ContactData.contacttype.find((item) => item.contacttype === b.contacttype)?.description as string;
    if (descA === descB) {
      return 0;
    } else {
      return (descA < descB) ? -1 : 1;
    }
  }

  async function continueSubmission() {
    // Save new contact
    let contactTypes: string = '';
    newContact.contactDetails.contactTypes.forEach((contactType: NewContactContactType) => {
      // skip licensed producer contact type (37)
      if (contactType.contacttype !== 37) {
        const contactTypeDesc = contactType.contacttype === 10 ? 'Agency/Office Manager' : contactType.description;
        contactTypes = contactTypes === '' ? contactTypeDesc : contactTypes + ',' + contactTypeDesc;
      }
    });

    let association: { agency_code: string; contacttype: string; }[] = [];
    
    const contactAgencyCodes: AddContactAgencyCode[] = JSON.parse(decrypt(localStorage.getItem('AddContactAgencyCodes')));
    
    newContact.contactDetails.agencyCodes.forEach((assoc: NewContactAgencyCode) => {
      // skip contact type Licensed Producer (37)
      if (assoc.contacttype !== 37) {
        contactTypeModel.forEach((contactType: ContactDataContactType) => {
          if (contactType.contacttype === assoc.contacttype) {
            const contactTypeDesc = contactType.contacttype === 10 ? 'Agency/Office Manager' : contactType.description;
            association.push({ agency_code: assoc.producer_number, contacttype: contactTypeDesc });
          }
        });
      }
    });

    // remove any duplicate agency codes
    const uniqueAssociations: { agency_code: string; contacttype: string }[] = Array.from(new Set(association.map((assoc) => JSON.stringify(assoc))), (assoc) => JSON.parse(assoc));
    
    // sort unique agency code/contact type combo by agency code
    uniqueAssociations.sort((a, b) => {
      const agencyCodeA = a.agency_code;
      const agencyCodeB = b.agency_code;
      if (agencyCodeA === agencyCodeB) {
        return 0;
      } else {
        return (agencyCodeA < agencyCodeB) ? -1 : 1;
      }
    });

    // concatenate contact types per agency code
    let lastAgencyCode = '';
    let currentAgencyCode = '';
    const agencyCodeAssoc: { agency_code: string; contacttype: string }[] = [];

    let contactTypesArray: string[] = [];
    uniqueAssociations.forEach((assoc: { agency_code: string; contacttype: string }) => {
      currentAgencyCode = assoc.agency_code;
      if (lastAgencyCode === '') {
        lastAgencyCode = currentAgencyCode;
      }
      if (currentAgencyCode !== lastAgencyCode) {
        agencyCodeAssoc.push({ agency_code: lastAgencyCode, contacttype: contactTypesArray.join(',') });
        contactTypesArray.length = 0;
      }
      contactTypesArray.push(assoc.contacttype);
      lastAgencyCode = currentAgencyCode;
    });
    if (contactTypesArray.length) {
      agencyCodeAssoc.push({ agency_code: lastAgencyCode, contacttype: contactTypesArray.join(',') });
      contactTypesArray.length = 0;
    }
    
    const individualDetails: IndividualDetails = {
      first_name: newContact.contactDetails.generalDetails.firstName.substr(0, 50),
      last_name: newContact.contactDetails.generalDetails.lastName.substr(0, 50),
      nick_name: '',
      mid_name: '',
      prdcr_sffx: '',
      status: 'Active',
      dateofbirth: '',
      gender: '',
      ssn: '',
      contacttype: contactTypes.substr(0, 255),
      primaryWorkLocation: newContact.contactDetails.primaryAgencyCode.substr(0, 200),
      login_id: newContact.contactDetails.generalDetails.loginId,
      association: agencyCodeAssoc,
      additional_emails: [{
        email_category: 'Preferred_Email',
        email_address: newContact.contactDetails.email.substr(0, 100),
      }],
      pending_status: 'Pending Addition',
      updated_by: personId,
    };

    console.log(individualDetails);

    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseNewPerson = API.graphql(
      graphqlOperation(
        mutations.personAddDetails,
        {
          body: individualDetails
        }
      ),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
      }
    ) as Promise<{ data: PersonAddDetailsMutation }>;

    promiseNewPerson.then((res) => {
      if (res.data) {
        return res.data.personAddDetails;
      } else {
        handleGraphQLErrors(res);
      }
    })
    .then((res) => {
      if (res?.statusCode === 200 || res?.statusCode === 400) {
        return res.body as { status: string; message: string; data: { contact_id: string; status: string; message: string; } } | null | undefined;
      } else {
        throw new Error('Something went wrong - 44');
      }
    })
    .then(async (res) => {
      if (res) {
        console.log(res);
        if (res.status !== 'error') {
          const contactId: string = res.data.contact_id;
          
          const isAuthenticated: boolean = await checkAuthStatus();
          if (!isAuthenticated) {
            return;
          }
          const promiseAgencyCodeAddress = API.graphql(
            graphqlOperation(
              queries.getAgencyCodeAddress,
              {
                agency_code: newContact.contactDetails.primaryAgencyCode
              }
            ),
            {
              Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
            }
          ) as Promise<{ data: GetAgencyCodeAddressQuery }>;

          promiseAgencyCodeAddress.then((res) => {
            if (res.data) {
              return res.data.getAgencyCodeAddress;
            } else {
              handleGraphQLErrors(res);
            }
          })
          .then((res) => {
            if (res?.statusCode === 200) {
              return res.body as { agency_code_addresses: AgencyCodeAddress[] } | null | undefined;
            } else {
              throw new Error('Something went wrong - 45');
            }
          })
          .then(async (res) => {
            if (res) {
              const filter = res.agency_code_addresses.filter((address: AgencyCodeAddress) => address.agency_code === newContact.contactDetails.primaryAgencyCode);
              const primaryAgencyInfo = filter[0];

              const addressDetails: any = {
                contact_id: contactId,
                address_details: [
                  {
                    contact_id: contactId,
                    address_type: 'Business',
                    addressline1: primaryAgencyInfo.addressline1.trim(),
                    addressline2: primaryAgencyInfo.addressline2.trim(),
                    city: primaryAgencyInfo.city_name.trim(),
                    county: primaryAgencyInfo.county_name.trim(),
                    state_code: primaryAgencyInfo.state_code.trim(),
                    zip: primaryAgencyInfo.zip_code.trim(),
                    country_code: primaryAgencyInfo.country.trim(),
                    phone: newContact.contactDetails.generalDetails.primaryPhone,
                    fax: '',
                    mobile: '',
                    extn: '',
                    po_box: ''
                  }
                ],
                pending_status: 'Pending Addition',
                updated_by: personId,
              };

              const isAuthenticated: boolean = await checkAuthStatus();
              if (!isAuthenticated) {
                return;
              }
              const promiseAddress = API.graphql(
                graphqlOperation(
                  mutations.updatePersonAddress,
                  {
                    body: addressDetails,
                    person_id: contactId
                  }
                ),
                {
                  Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
                }
              ) as Promise<{ data: UpdatePersonAddressMutation }>;

              promiseAddress.then((res) => {
                if (res.data) {
                  return res.data.UpdatePersonAddress;
                } else {
                  handleGraphQLErrors(res);
                }
              })
              .then((res) => {
                if (res?.statusCode === 200) {
                  return res.body as { message: string, status: string } | null | undefined;
                } else {
                  throw new Error('Something went wrong - 46');
                }
              })
              .then(async (res) => {
                if (res) {
                  console.log(res);

                  if (newContact.contactDetails.userPrivileges.length > 0) {
                    const privileges: {
                      contact_id: number;
                      login_id: string;
                      user_privileges: {
                        agency_code: string;
                        user_privilege_code: string;
                      }[]
                      pending_status: string;
                      updated_by: string;
                    } = {
                      contact_id: Number(contactId),
                      login_id: newContact.contactDetails.generalDetails.loginId,
                      user_privileges: [],
                      pending_status: 'Pending Addition',
                      updated_by: personId,
                    };
                
                    const agencyCodes: string[] = [];
        
                    if (newContact.contactDetails.agencyCodes.length > 0) {
                      newContact.contactDetails.agencyCodes.forEach((agencyCode: NewContactAgencyCode) => {
                        // skip licensed producer (37)
                        if (agencyCode.contacttype !== 37) {
                          agencyCodes.push(agencyCode.producer_number);
                        }
                      });
                    }

                    if (newContact.contactDetails.primaryAgencyCode !== '') {
                      let includePrimaryAgency: boolean = false;
                      newContact.contactDetails.agencyCodes.forEach((agencyCode: NewContactAgencyCode) => {
                        // If any contact type, other than Licensed Producer (37), is associated with the primary agency code, then include the primary agency code.
                        if (agencyCode.contacttype !== 37 && agencyCode.producer_number === newContact.contactDetails.primaryAgencyCode) {
                          includePrimaryAgency = true;
                        }
                      });

                      if (includePrimaryAgency) {
                        agencyCodes.push(newContact.contactDetails.primaryAgencyCode.substr(0, 200));
                      }
                    }
        
                    // remove any duplicate agency codes
                    const uniqueAgencyCodes = Array.from(new Set(agencyCodes.map((code) => JSON.stringify(code))), (code) => JSON.parse(code));

                    uniqueAgencyCodes.forEach((agencyCode: string) => {
                      privileges.user_privileges.push(
                        {
                          agency_code: agencyCode,
                          user_privilege_code: newContact.contactDetails.userPrivileges.join(','),
                        }
                      );
                    });
        
                    console.log('Privileges', privileges);

                    const isAuthenticated: boolean = await checkAuthStatus();
                    if (!isAuthenticated) {
                      return;
                    }
                    const promisePrivileges = API.graphql(
                      graphqlOperation(
                        mutations.updatePersonPrivileges,
                        {
                          body: privileges,
                          person_id: contactId
                        }
                      ),
                      {
                        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
                      }
                    ) as Promise<{ data: UpdatePersonPrivilegesMutation }>;

                    promisePrivileges.then((res) => {
                      if (res.data) {
                        return res.data.UpdatePersonPrivileges;
                      } else {
                        handleGraphQLErrors(res);
                      }
                    })
                    .then((res) => {
                      if (res?.statusCode === 200) {
                        return res.body;
                      } else {
                        throw new Error('Something went wrong - 47');
                      }
                    })
                    .then((res) => {
                      if (res) {
                        console.log(res);
                        setShowModalSpinner(false);
                        setDisplayCrossHierarchyModal(false);
                        setShowAppGenModalSpinner(false);
                        setUserCreated(true);
                      } else {
                        throw new Error('Something went wrong - 48');
                      }
                    })
                    .catch((err) => {
                      setDisplayCrossHierarchyModal(false);
                      setShowAppGenModalSpinner(false);
                      setShowModalSpinner(false);
                      if (err?.message) {
                        console.error(err.message, err); 
                      } else {
                        handleGraphQLErrors(err);
                      }
                      window.location.href = "error";
                    });
                  } else {
                    setShowModalSpinner(false);
                    setDisplayCrossHierarchyModal(false);
                    setShowAppGenModalSpinner(false);
                    setUserCreated(true);
                  }
                } else {
                  throw new Error('Something went wrong - 49');
                }   
              })
              .catch((err) => {
                setShowAppGenModalSpinner(false);
                setShowModalSpinner(false);
                if (err?.message) {
                  console.error(err.message, err); 
                } else {
                  handleGraphQLErrors(err);
                }
                window.location.href = "error";
              });
            } else {
              throw new Error('No primary agency code address available');
            }
          })
          .catch((err) => {
            if (err?.message) {
              console.error(err.message, err); 
            } else {
              handleGraphQLErrors(err);
            }
            window.location.href = "error";
          });
        } else {
          if (res.message.includes('agency_code=') && res.message.includes(' is invalid')) {
            // handle cross-hierarchy

            // retrieve invalid agency code from error
            const indexStart: number = res.message.indexOf('agency_code=') + 12;
            const indexEnd: number = res.message.indexOf(' is invalid');
            const invalidAgencyCode: string = res.message.substr(indexStart, indexEnd - indexStart);
            
            // retrieve primary agency
            const primaryAgencyCode: string = newContact.contactDetails.primaryAgencyCode;

            console.log('invalid agency code', invalidAgencyCode);
            console.log('individualDetails', individualDetails);
            console.log('newContact object', newContact);
            console.log('primary agency code', primaryAgencyCode);
            console.log('AddContactAgencyCodes', JSON.parse(decrypt(localStorage.getItem('AddContactAgencyCodes'))))

            // retrieve list of agency codes for the same location as the primary agency code
            const addContactAgencyCodes: AddContactAgencyCodeInfo[] = JSON.parse(decrypt(localStorage.getItem('AddContactAgencyCodes')));

            let agency_id: string = '';
            const loc_agency_codes: AddContactAgencyCodeInfo[] = [];

            // get primary agency code's agency_id
            addContactAgencyCodes.forEach((agencyCode) => {
              if (agencyCode.agency_code === primaryAgencyCode) {
                agency_id = agencyCode.agency_id;
              }
            });

            // get all agency codes and info for the agency_id retrieved above
            addContactAgencyCodes.forEach((agencyCode) => {
              if (agencyCode.agency_id === agency_id) {
                loc_agency_codes.push(agencyCode);
              }
            });

            console.log('agency codes associated with the primary agency code location', loc_agency_codes);

            // make list of all agency codes not being submitted
            const agencyCodesNotBeingSubmitted: string[] = [];
            newContact.contactDetails.agencyCodes.forEach((agencyCode) => {
              // skip licensed producer
              if (agencyCode.contacttype !== 37) {
                const filterResult = loc_agency_codes.filter((locAgencyCode) => locAgencyCode.agency_code === agencyCode.producer_number);
                if (filterResult.length === 0 && !agencyCodesNotBeingSubmitted.includes(agencyCode.producer_number)) {
                  agencyCodesNotBeingSubmitted.push(agencyCode.producer_number);
                }
              }
            });
            console.log('agencyCodesNotBeingSubmitted', agencyCodesNotBeingSubmitted);

            // make an array of the contact type/agency code associations no being submitted
            const agencyCodeAssociationsNotBeingSubmitted: { contacttype: number, producer_number: string }[] = [];
            newContact.contactDetails.agencyCodes.forEach((agencyCode) => {
              // skip licensed producer
              if (agencyCode.contacttype !== 37) {
                if (agencyCodesNotBeingSubmitted.includes(agencyCode.producer_number)) {
                  agencyCodeAssociationsNotBeingSubmitted.push({ contacttype: agencyCode.contacttype, producer_number: agencyCode.producer_number });
                }
              }
            });
            console.log('agencyCodeAssociationsNotBeingSubmitted', agencyCodeAssociationsNotBeingSubmitted);

            // remove the contact type/agency code associations from the newContact object that are not being submitted
            const ncFilterResults = newContact.contactDetails.agencyCodes.filter((agencyCode) => {
              let matchFound = false;
              agencyCodeAssociationsNotBeingSubmitted.forEach((ac) => {
                if (ac.contacttype === agencyCode.contacttype && ac.producer_number === agencyCode.producer_number) {
                  matchFound = true;
                }
              });
              return matchFound ? false : true;
            });
            console.log('ncFilterResults', JSON.parse(JSON.stringify(ncFilterResults)));
            setNewContactBackup(JSON.parse(JSON.stringify(newContact)));
            const nc = newContact;
            nc.contactDetails.agencyCodes = JSON.parse(JSON.stringify(ncFilterResults));

            // loop through newContact.contactDetails.contactTypes and ensure that a contact type association for the primary agency code and each contact type exists in newContact.contactDetails.agencyCodes
            const ctAssociations: { contacttype: number, producer_number: string }[] = []
            const primaryContactTypes: string[] = [];
            newContact.contactDetails.contactTypes.forEach((contactType) => {
              // skip licensed producer
              if (contactType.contacttype !== 37) {
                const results = nc.contactDetails.agencyCodes.filter((agencyCode) => agencyCode.contacttype === contactType.contacttype && agencyCode.producer_number === primaryAgencyCode);
                if (results.length === 0) {
                  ctAssociations.push({ contacttype: contactType.contacttype, producer_number: primaryAgencyCode });
                  const ct = ContactData.contacttype.find((item) => item.contacttype === contactType.contacttype);
                  if (ct) {
                    primaryContactTypes.push(ct.description);
                  }
                } else {
                  const ct = ContactData.contacttype.find((item) => item.contacttype === results[0].contacttype);
                  if (ct) {
                    primaryContactTypes.push(ct.description);
                  }
                }
              }
            });
            ctAssociations.forEach((assoc) => {
              nc.contactDetails.agencyCodes.push(assoc);
            });
            
            // once newContact object is updated then display modal, if they click cancel revert newContact object back to the way it was
            setNewContact(JSON.parse(JSON.stringify(nc)));
            setCrossHierarchyTitle('Contact Add');
            setCrossHierarchyText(`${newContact.contactDetails.generalDetails.firstName} ${newContact.contactDetails.generalDetails.lastName} is being added for the below primary agency code.  The other agency codes will be added as soon as possible.  Thank you for your patience.`);
            setCrossHierarchyPrimaryAgencyCode(primaryAgencyCode);
            setCrossHierarchyAddedContactTypes(primaryContactTypes.join(', '));
            setCrossHierarchyAssociationsNotBeingAdded(agencyCodeAssociationsNotBeingSubmitted);
            setDisplayCrossHierarchyModal(true);
            setShowModalSpinner(false);
            // if they submit then the updated newContact object is used for submission and call an API to send email with the agency codes/contact types that could not be submitted.
            simpleGTMDataLayer({
              event: 'crossHierarchyErrorEvent',
              event_action: 'Submit',
              event_category: window.location.pathname,
              event_label: 'Add Contact Workflow'
            });
          } else {
            throw new Error(res.message);
          }
        }
      } else {
        throw new Error('Something went wrong - 50');
      }
    })
    .catch((err) => {
      setShowModalSpinner(false);
      if (err?.message) {
        console.error(err.message, err); 
      } else {
        handleGraphQLErrors(err);
      }
      window.location.href = "error";
    });
    simpleGTMDataLayer({
      event: 'addNewContactEvent',
      event_action: 'Submit',
      event_category: window.location.pathname,
      event_label: 'Add Contact Workflow: Final submit'
    });
  }

  function openPopover(event: React.MouseEvent<HTMLElement>, privilege: string) {
    event.stopPropagation();
    setPopover(privilege);
    setReferenceElement(event.currentTarget);
    rememberRefElem.current = event.currentTarget;
  }

  function closePopover(event: React.MouseEvent<HTMLElement> | MouseEvent, index: number | null) {
    setReferenceElement(null);
    rememberRefElem.current = null;
  }

  function togglePopover(event: React.MouseEvent<HTMLElement>, privilege: string) {
    setPopover(privilege);
    event.stopPropagation();
    if (rememberRefElem.current === null) {
      setReferenceElement(event.currentTarget.firstChild as HTMLElement);
      rememberRefElem.current = event.currentTarget.firstChild;
    } else {
      setReferenceElement(null);
      rememberRefElem.current = null;
    }
  }

  function handleKeyDown(e: any, privilege: string) {
    setPopover(privilege);
    if (e.key === 'Tab') {
      e.stopPropagation();
      setReferenceElement(null);
      rememberRefElem.current = null;
    }
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      if (rememberRefElem.current === null) {
        setReferenceElement(e.currentTarget.firstChild as HTMLElement);
        rememberRefElem.current = e.currentTarget.firstChild;
      } else {
        setReferenceElement(null);
        rememberRefElem.current = null;
      }
    }
  }

  function handleClickApplicationGeneratedModalCancel(event: React.MouseEvent) {
    setDisplayApplicationGeneratedModal(!displayApplicationGeneratedModal);
  }

  function handleClickSendEmail() {
    setShowAppGenModalSpinner(true);
  }

  function handleEmailSent() {    
    continueSubmission();
  }

  function handleClickApplicationGeneratedModalClose(event: React.MouseEvent) {
    setDisplayApplicationGeneratedModal(!displayApplicationGeneratedModal);
    setAppGenModalClosed(true);
  }

  function handleClickDuplicateLoginModalCancel() {
    setDisplayDuplicateLoginModal(!displayDuplicateLoginModal);
  }

  function handleClickDuplicateLoginModalConfirm(loginId: string) {
    setDisplayDuplicateLoginModal(!displayDuplicateLoginModal);
    newContact.contactDetails.generalDetails.loginId = loginId;
    const contact: NewContact = JSON.parse(JSON.stringify(newContact));
    setNewContact(contact);
    localStorage.setItem('NewContact', encrypt(JSON.stringify(newContact)));
  }

  function handleClickCancel(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleClickCancelAlertModalLeave(event: React.MouseEvent) {
    history.push({
      pathname: `/agency-contacts`
    });
  }

  function handleClickCancelAlertModalStay(event: React.MouseEvent) {
    setDisplayCancelAlert(!displayCancelAlert);
  }

  function handleCrossHierarchyClickCancel(event: React.MouseEvent) {
    setDisplayCrossHierarchyModal(false);
    setShowAppGenModalSpinner(false);
    setNewContact(JSON.parse(JSON.stringify(newContactBackup)));
    localStorage.setItem('NewContact', encrypt(JSON.stringify(newContactBackup)));
  }

  async function handleCrossHierarchyClickConfirm(event: React.MouseEvent) {    
    setShowModalSpinner(true);
    if (crossHierarchyAssociationsNotBeingAdded.length) {
      // prep Full Description for SNOW request
      let currentContactType: number;
      let prevContactType: number;
      const associations: string[] = [];
      let associationLine: string = '';
      const agencyCodes: string[] = [];
      crossHierarchyAssociationsNotBeingAdded.sort(sortByContactTypeDescription).forEach((assoc) => {
        if (currentContactType === undefined) {
          currentContactType = assoc.contacttype;
          const contactTypeDesc = ContactData.contacttype.find((item) => item.contacttype === assoc.contacttype)?.description;
          associationLine = `${contactTypeDesc} - ${assoc.producer_number}`;
        } else {
          currentContactType = assoc.contacttype;
        }
        if (prevContactType === undefined) {
          // do nothing
        } else if (prevContactType === currentContactType) {
          associationLine = `${associationLine}, ${assoc.producer_number}`;
        } else {
          associations.push(associationLine);
          associationLine = '';
          const contactTypeDesc = ContactData.contacttype.find((item) => item.contacttype === assoc.contacttype)?.description;
          associationLine = `${contactTypeDesc} - ${assoc.producer_number}`;
        }
        prevContactType = currentContactType;
      });
      if (associationLine !== '') {
        associations.push(associationLine);
      }

      const loggedInUserFirst = decrypt(localStorage.getItem('loggedInUserFirstName'));
      const loggedInUserLast = decrypt(localStorage.getItem('loggedInUserLastName'));

      const shortDescription: string = 'Cross-Hierarchy Add New Contact';
      const FullDescription: string = `Updates to Nucleus are needed within 5 business days.\nStep 1: Update as many codes as possible in Nucleus\nStep 2: Create a ticket for Duckcreek to process the remaining agency codes on the backend\nNew Contact ${newContact.contactDetails.generalDetails.firstName} ${newContact.contactDetails.generalDetails.lastName} is cross-hierarchy\nPrimary Agency Code ${crossHierarchyPrimaryAgencyCode}\nRequested by ${loggedInUserFirst} ${loggedInUserLast} ${adminPersonId} via Add Contact workflow\n${associations.join('\n')}
      `;
      console.log('SNOW request content', FullDescription);

      const payload = {
        u_short_description: shortDescription,
        u_description: FullDescription
      }

      const isAuthenticated: boolean = await checkAuthStatus();
      if (!isAuthenticated) {
        return;
      }
      // make call to new API to send SNOW request.  If successful then continueSubmission
      const promiseSnowRequestResponse = API.graphql(
        graphqlOperation(
          mutations.createServiceNowRequest,
          {
            body: JSON.stringify(payload)
          }
        ),
        {
          Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
        }
      ) as Promise<{ data: CreateServiceNowRequestMutation }>;

      promiseSnowRequestResponse.then((res) => {
        if (res.data) {
          return res.data.createServiceNowRequest;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200) {
          continueSubmission();
        } else {
          throw new Error('Something unexpected happened when attempting to send SNOW request');
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err); 
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });
    } else {
      continueSubmission();
    }
  }

  function handleClickPrevious(event : React.MouseEvent) {
    history.push({
      pathname: '/primary-agency-code'
    });
  }

  const getUserPrivileges = () => {
    return (
      <>
        {availablePrivileges.map((privilege: string, index: number) => {
          let checked: boolean = false;
          newContact.contactDetails.userPrivileges.forEach((p: string) => {
            if (p === privilege) {
              checked = true;
            }
          });

          return (
            <SACheckbox
              {...register(`privilege_${index.toString()}`)}
              key={index.toString()}
              id={`checkbox_${index.toString()}`}
              label={privilege}
              type="checkbox"
              defaultChecked={checked}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                } else if (e.key === ' ') {
                  e.preventDefault();
                  const element: HTMLElement | null = document.querySelector(`input[id="checkbox_${index.toString()}"]`);
                  if (element !== null) {
                    element.click();
                  }
                }
              }}
            />
          );
        })}
      </>
    );
  }

  useEffect(() => {
    let isLicensedProducer: boolean = false;
    newContact.contactDetails.contactTypes.forEach((ct: NewContactContactType) => {
      if (ct.contacttype === 37) {
        isLicensedProducer = true;
      }
    });

    if ((isLicensedProducer && userCreated && appGenModalClosed) || (!isLicensedProducer && userCreated)) {
      setShowModalSpinner(false);
      localStorage.removeItem('newContact');
      localStorage.setItem('contactAdded', encrypt('true'));
      history.push({
        pathname: '/agency-contacts'
      });
    }
  }, [userCreated, appGenModalClosed]);

  useEffect(() => {
    function handleClickCheckboxContainer(index: number) {
      const element: HTMLElement | null = document.querySelector(`input[id="checkbox_${index.toString()}"]`);
      if (element !== null) {
        element.click();
      }
    }

    availablePrivileges.forEach((privilege: string, index: number) => {
      const element: HTMLElement | null = document.querySelector(`input[id="checkbox_${index.toString()}"]`);
      if (element !== null) {
        const parentElement = element.parentElement?.parentElement as HTMLElement;
        parentElement.onclick = (e: Event) => {
          handleClickCheckboxContainer(index);
        };
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!document.getElementById('modal')) {
      const modalRoot: HTMLDivElement = document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      document.body.append(modalRoot);
    }
  }, []);

  useEffect(() => {
    function updatePosition() {
      if (window.innerWidth <= mobileWidth) {
        setMobile(true);
        setPosition(mobilePosition);
        setOffset(mobileOffset);
      } else {
        setMobile(false);
        setPosition(desktopPosition);
        setOffset(desktopOffset);
      }
    }
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => {
      
    };
  }, []);

  useEffect(() => {
    // set default focus
    const element = document.querySelector('input') as HTMLElement;
    if (element) {
      element.focus();
    }
  }, []);

  useEffect(() => {
    availablePrivileges.forEach((privilege: string, index: number) => {
      const element: HTMLElement | null = document.querySelector(`label[for="checkbox_${index}"]`);
      if (element !== null) {
        const jsxElement: JSX.Element = (
          <SAButton
            fullWidth={false}
            variant="link-small"
            label="Help Text"
            onClick={(e: any) => togglePopover(e, privilege)}
            onKeyDown={(e: any) => handleKeyDown(e, privilege)}
          >
            <SAIcon icon={SAIcons.information} size="20px" colorVariant="primary" onMouseOver={(e: any) => openPopover(e, privilege)} onMouseLeave={closePopover} />
          </SAButton>
        );
        const parentElement = element.parentElement as HTMLElement;
        if (parentElement.children.length > 2) {
          parentElement.lastChild?.remove();
        }
        const divElement = document.createElement('div');
        divElement.classList.add('helpicon');
        ReactDOM.render(jsxElement, divElement);
        parentElement.appendChild(divElement);
      }
    });
  }, [mobile]);

  useEffect(() => {
    if (displayCancelAlert || displayApplicationGeneratedModal) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [displayCancelAlert, displayApplicationGeneratedModal]);

  useEffect(() => {
    if (!displayApplicationGeneratedModal && path !== '') {
      history.push({
        pathname: path
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayApplicationGeneratedModal, path])

  useEffect(() => {
    if (displayApplicationGeneratedModal && displayCrossHierarchyModal) {
      const element = document.getElementById('modalapplicationgenerated');
      if (element) {
        element.classList.add('hidemodal');
      }
    }
    if (displayApplicationGeneratedModal && !displayCrossHierarchyModal) {
      const element = document.getElementById('modalapplicationgenerated');
      if (element) {
        element.classList.remove('hidemodal');
      }
    }
  }, [displayApplicationGeneratedModal, displayCrossHierarchyModal]);

  return (
    <>
      <Container constants={constants} isEmployee={isEmployee}>
        <SACard variant="minimal">
          <CardHeader>
            <CardTitle>
              <SAText type="heading-2" text="User Privileges" />
            </CardTitle>
            <CardSubTitle>
              <SAText type="standard" text="Select all the privileges allowed for the user." />
            </CardSubTitle>
          </CardHeader>
          <CardBody>
            <form id="userprivilegesform" onSubmit={handleSubmit(onSubmit)}>
              <CheckboxContainer>
                {getUserPrivileges()}
              </CheckboxContainer>
            </form>
          </CardBody>
          <CardFooter>
            <div>
              <SAButton fullWidthUnder={mobileWidth} label="Previous" onClick={handleClickPrevious} variant="link-large" color="text" textTransform="uppercase" />
            </div>
            <div>
              <SAButton label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
              <SAButton fullWidthUnder={mobileWidth} label="Submit" type="submit" variant="primary-large" textTransform="uppercase" disabled={isEmployee} onClick={handleSubmit(onSubmit)} />
            </div>
          </CardFooter>
        </SACard>
        <ContactDetailsSideCard newContactData={newContact} />
      </Container>
      <CancelButton>
        <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
      </CancelButton>
      <SAPopover
        onClose={(e: MouseEvent, index: number | null) => closePopover(e, index)}
        offset={offset}
        placement={position}
        referenceElement={referenceElement}
        variant={mobile ? 'popover-minimal-nopadding' : 'popover-menu-no-click'}
      >
        <Popover>
          <HelpContainer>
            <HelpIcon>
              <SAIcon icon={SAIcons.information} size="20px" colorVariant="hsla(220, 38%, 97%, 1)" />
            </HelpIcon>
            <HelpText>
              {popover === 'Personal Lines' &&
                <>Provides access to Personal Lines quoting, submission, endorsement and cancellation transactions.  Personal Lines includes the following lines of business: Homeowners, Personal Auto, Umbrella, and Dwelling Fire.</>
              }
              {popover === 'Commercial Lines' &&
                <>Provides access to Commercial Lines quoting, submission, endorsement and cancellation transactions. Commercial Lines includes the following lines of business: Businessowners', Business Auto, Commercial Package, Commercial Umbrella, Farm & Ranch, Farm Auto, Farm Umbrella, and Workers' Compensation.</>
              }
              {popover === 'View Agency Level Reports' &&
                <>Allows the user access to all agency reporting including commission reports.</>
              }
            </HelpText>
          </HelpContainer>
        </Popover>   
      </SAPopover>
      {displayApplicationGeneratedModal &&
        <ModalApplicationGenerated
          workflow="add contact"
          type="add"
          onClickCancel={handleClickApplicationGeneratedModalCancel}
          onClickSendEmail={handleClickSendEmail}
          onEmailSent={handleEmailSent}
          onClickClose={handleClickApplicationGeneratedModalClose}
          displaySpinner={showAppGenModalSpinner}
          payload={payload}
        />
      }
      {displayDuplicateLoginModal &&
        <ModalDuplicateLogin
          onClickCancel={handleClickDuplicateLoginModalCancel}
          onClickConfirm={handleClickDuplicateLoginModalConfirm}
        />
      }
      {displayCancelAlert &&
        <ModalCancelAlert onClickLeave={handleClickCancelAlertModalLeave} onClickStay={handleClickCancelAlertModalStay} />
      }
      {displayCrossHierarchyModal &&
        <ModalCrossHierarchy
          onClickCancel={handleCrossHierarchyClickCancel}
          onClickConfirm={handleCrossHierarchyClickConfirm}
          title={crossHierarchyTitle}
          text={crossHierarchyText}
          addedContactTypes={crossHierarchyAddedContactTypes}
          primaryAgencyCode={crossHierarchyPrimaryAgencyCode}
        />
      }
      {showModalSpinner && <ModalSpinner />}
    </>
  );
}

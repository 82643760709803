import React, { useState, useEffect } from "react";
import {
  SACard,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
  SAUXTheme,
  SASpinner,
  SAUX360Theme,
} from "@saux/design-system-react";
import * as queries from "../../../graphql/queries";
import handleGraphQLErrors from "../../../utils/handleGraphQLErrors";
import { API, graphqlOperation } from "aws-amplify";
import { decrypt } from "../../../utils/crypto";
import styled from "styled-components";
import { Person } from "../../../interfaces/interfaces";
import { useHistory } from "react-router-dom";
import { checkAuthStatus } from "../../../utils/utils";
import { GetBulkConfirmationQuery, GetBulkConfirmationQueryVariables } from "../../../API";
import { simpleGTMDataLayer } from "../../../utils/GTMHelpers";

const mobileWidth = 600;

type TerminateContactProps = {
  onClickCancel: Function;
  onClickConfirm: Function;
  contacts: Person[];
};

type StyleProps = {
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      top: 50%;
      left: 50%;
      justify-content: center;
      align-items: center;
      overflow: auto;

      .textLikeInputWrapper {
        legend {
          span {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    `;
  }};
`;

const TerminateContact = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 540px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  ${({ theme }: StyleProps) => {
    return `
    padding: ${theme?.size.spacer.large};
  `;
  }};
`;
const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    }
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      display: flex;
    }
  }
`;

const ModalSubTitle = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      margin-top: 12px;
      span {
        line-height: 1.3;
      }
    `;
  }};
`;

const ContactsContainer = styled.div`
  ${({ theme }: StyleProps) => {
    return `
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-height: 350px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      border: none;
    }
  
    &::-webkit-scrollbar-track {
        background: #F0EFEF;
        border-radius: 7px;
    }
  
    &::-webkit-scrollbar-thumb {
        background: #8A8A8A;
        border-radius: 7px;
        box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
  `;
  }};
`;

const ModalBody = styled.div`
  ${({ theme }: StyleProps) => {
    return `
    display: flex;
    flex-direction: column;
    gap: 22px;

    > span:first-child {
      padding-left: ${theme?.size.spacer.large};
    }
  `;
  }};
`;

const ModalFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: flex-end;
      padding: ${theme?.size.spacer.large};
      
      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
  }};
`;

const Panel = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      background-color: hsl(220,38%,97%);  // Faint Blue
      padding: calc(${theme?.size.spacer.small} + 5px) ${theme?.size.spacer.large};
    `;
  }};
`;

const ConfirmPanel = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      background-color: #F2FAE5;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      gap: 12px;
      margin: 0px -30px -30px -30px;
      padding: 16px 30px 16px 30px;
    `;
  }};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: ${mobileWidth}px) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
  }
`;

const ContactName = styled.span`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      font-size: ${theme?.font.primary.size.medium};
      font-weight: ${theme?.font.primary.weight.bold};
    `;
  }};
`;

const LoginID = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      padding-top: 10px;
      padding-right: 5px;

      @media only screen and (max-width: ${mobileWidth}px) {
        padding-right: 0;
      }

      div {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-size: ${theme?.font.primary.size.smallTEMP};
        font-weight: ${theme?.font.primary.weight.normal};

        &:first-child {
          font-weight: ${theme?.font.primary.weight.bold};
          margin-bottom: 4px;
        }
      }
    `;
  }};
`;

const EmailAddress = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      padding-top: 10px;
      padding-right: 5px;

      @media only screen and (max-width: ${mobileWidth}px) {
        padding-right: 0;
      }

      div {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-size: ${theme?.font.primary.size.smallTEMP};
        font-weight: ${theme?.font.primary.weight.normal};

        &:first-child {
          font-weight: ${theme?.font.primary.weight.bold};
          margin-bottom: 4px;
        }
      }
    `;
  }};
`;

const TerminationMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Buttons = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      align-self: end;

      button[type='button'] {
        font-weight: ${theme?.font.primary.weight.normal};
      }
    `;
  }};
`;

const DashboardIcon = styled.svg`
  ${({ theme }: StyleProps) => {
    return `
      margin-right: ${theme?.size.spacer.medium};
    `;
  }};
`;

const SpinnerContainer = styled.div`
  margin-left: 10px;
  svg:last-child {
    margin-left: 0;
  }
`;

export default function ModalTerminateContacts({ onClickCancel, contacts }: TerminateContactProps) {
  const personId = decrypt(localStorage.getItem("personId"));
  const isAgencyOpsInternalUser: boolean = decrypt(localStorage.getItem('isAgencyOpsInternalUser')) === 'true' ? true : false;
  const auth_userId = decrypt(localStorage.getItem("auth_userid"));
  const [displayConfirm, setDisplayConfirm] = useState<boolean>(true);
  const [isTerminating, setIsTerminating] = useState(false);
  const history = useHistory();

  const isEmployee: boolean = decrypt(localStorage.getItem("loginType")) === "employeeID" ? true : false;
  const successIcon = getSuccessIcon("40px", "hsl(82,100%,32%)");

  function handleClickTerminateContactsModal(event: React.MouseEvent) {
    event.stopPropagation();
    if (displayConfirm && !isTerminating) {
      // get the card element inside the modal
      const cardElement = document.querySelector("#modal > div > article > section");

      // close modal when clicking outside of the card element
      if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
        onClickCancel(event);
      }
    }
  }

  function handleClickTerminateContactModalCancel(event: React.MouseEvent) {
    event.stopPropagation();
    onClickCancel(event);
  }

  async function handleConfirmClick(event: React.MouseEvent) {
    event.stopPropagation();
    setIsTerminating(true);
    
    simpleGTMDataLayer({
      event: 'bulkTerminateContacts',
      event_action: 'Button Click',
      event_category: window.location.pathname,
      event_label: 'Bulk: Terminate Contacts'
    });

    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }

    const payload = contacts.map((person) => person.person_id);
    
    const promiseContactsConfirm = API.graphql(
      graphqlOperation(queries.getBulkConfirmation, {
        admin_person_id: personId,
        person_ids: payload,
        user_id: auth_userId,
        is_agencyops_internal_user: isAgencyOpsInternalUser ? "True" : "False"
      } as GetBulkConfirmationQueryVariables),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem("auth_accesstoken"))}`,
      }
    ) as Promise<{ data: GetBulkConfirmationQuery }>;

    promiseContactsConfirm
      .then((res) => {
        if (res.data) {
          return res.data.getBulkConfirmation;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200) {
          return res.body;
        } else {
          return null;
        }
      })
      .then((terminableResponse) => {
        if (terminableResponse) {
          setDisplayConfirm(false);
        }
        setIsTerminating(false);
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err);
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });
  }

  function getSuccessIcon(size: string, color: string) {
    const renderSize = size;
    return (
      <DashboardIcon
        width={renderSize}
        height={renderSize}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <rect width="40" height="40" rx="20" fill={color} />
        <path
          d="M10 20C10 20 16.318 27 16.8901 27C17.4621 27 30 13 30 13"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
        />
      </DashboardIcon>
    );
  }

  const spinner = (
    <SpinnerContainer>
      <SASpinner variant="circular-continuous" delay={0} color={SAUX360Theme.colors.blueGray600} size="20px" />
    </SpinnerContainer>
  );

  useEffect(() => {
    function focusModalTerminateContact(e: any) {
      const modal = document.querySelector("#modalterminatecontacts") as HTMLElement;
      const root = document.querySelector("#root") as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          let element = modal.querySelector('button.sa-button') as HTMLElement;
          if (element) {  
            element.focus();
          }
        }
      }
    }

    document.addEventListener("focusin", focusModalTerminateContact);
    document.addEventListener("focusout", focusModalTerminateContact);

    return () => {
      document.removeEventListener("focusin", focusModalTerminateContact);
      document.removeEventListener("focusout", focusModalTerminateContact);
    };
  }, []);

  function handleClickClose(event: React.MouseEvent): void {
    history.push({
      pathname: `/agency-contacts`,
    });
  }

  return (
    <Modal id="modalterminatecontacts" onClickHandler={handleClickTerminateContactsModal}>
      <TerminateContact variant="minimal" removePadding>
        <ModalHeader>
          <ModalTitle>
            <SAText type="heading-2" text="Remove Agency Contacts" />
            <SAIcon
              className="closemodal"
              icon={SAIcons.x}
              size={SAIconSize.medium}
              colorVariant="dark"
              onClick={handleClickTerminateContactModalCancel}
            />
          </ModalTitle>
          <ModalSubTitle>
            <SAText type="standard">
              Each contact will be removed from the agency. For Licensed Producer contacts appointments will be
              terminated for State Auto. Are you sure you want to <strong>completely remove</strong> these contacts from
              your agency?
            </SAText>
          </ModalSubTitle>
        </ModalHeader>
        <ModalBody>
          <SAText type="standard" weight="bold" text={`Selected Contacts for Termination (${contacts.length})`} />
          <ContactsContainer>
            {contacts
              .sort((a, b) => {
                const nameA = `${a.prdcr_last_name}, ${a.prdcr_first_name}`;
                const nameB = `${b.prdcr_last_name}, ${b.prdcr_first_name}`;
                if (nameA === nameB) {
                  return 0;
                } else {
                  return nameA.toLowerCase() < nameB.toLowerCase() ? -1 : 1;
                }
              })
              .map((person) => (
                <Panel key={`person-${person.person_id}`}>
                  <Row>
                    <ContactName>
                      {person.prdcr_last_name}, {person.prdcr_first_name}
                    </ContactName>
                  </Row>
                  <Row>
                    <Col>
                      <LoginID>
                        <div>Login ID:</div>
                        <div>{person.login_id}</div>
                      </LoginID>
                    </Col>
                    <Col>
                      <EmailAddress>
                        <div>Email Address:</div>
                        <div>{person.preferred_email}</div>
                      </EmailAddress>
                    </Col>
                  </Row>
                </Panel>
              ))}
          </ContactsContainer>
        </ModalBody>
        <ModalFooter>
          {displayConfirm && (
            <>
              <SAButton
                fullWidthUnder={mobileWidth}
                label="Cancel"
                onClick={handleClickTerminateContactModalCancel}
                variant="link-large"
                color="text"
                disabled={isTerminating}
                textTransform="uppercase"
              />
              <SAButton
                fullWidthUnder={mobileWidth}
                label="Confirm"
                type="submit"
                variant="primary-large"
                endIcon={isTerminating ? spinner : undefined}
                textTransform="uppercase"
                disabled={(isEmployee && !isAgencyOpsInternalUser) || isTerminating}
                onClick={handleConfirmClick}
              />
            </>
          )}
          {!displayConfirm && (
            <ConfirmPanel>
              <TerminationMessage>
                <>
                  {successIcon}
                  <SAText
                    type="standard"
                    text="Processing your request may take several minutes and then contacts will be Pending Termination until we update our systems."
                  />
                </>
              </TerminationMessage>
              <Buttons>
                <SAButton
                  label="Close"
                  onClick={handleClickClose}
                  variant="link-large"
                  color="text"
                  textTransform="uppercase"
                />
              </Buttons>
            </ConfirmPanel>
          )}
        </ModalFooter>
      </TerminateContact>
    </Modal>
  );
}

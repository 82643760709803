import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { DatafluxEmailAddressQuery } from "../API";
import handleGraphQLErrors from './handleGraphQLErrors';
import { decrypt } from './crypto';
import { checkAuthStatus } from './utils';

export default async function emailValidation(email: string) {
  const isAuthenticated: boolean = await checkAuthStatus();
  if (!isAuthenticated) {
    return;
  }
  return (API.graphql(
    graphqlOperation(
      queries.datafluxEmailAddress,
      {
        body: { email: email }
      }
    ),
    {
      Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
    }
  ) as Promise<{ data: DatafluxEmailAddressQuery }>)
  .then((res) => {
    if (res.data) {
      return res.data.datafluxEmailAddress;
    } else {
      handleGraphQLErrors(res);
    }
  })
  .then((res) => {
    if (res?.statusCode === 200) {
      return res.body;
    } else {
      throw new Error('Something went wrong - 52');
    }
  })
  .then((res) => {
    if (res) {
      return res.result === 'Ok';
    } else {
      throw new Error('Something went wrong - 53');
    }
  })
  .catch((err) => {
    if (err?.message) {
      console.error(err.message, err); 
    } else {
      handleGraphQLErrors(err);
    }
    window.location.href = "error";
  });
}

export default function formatZipcode(value: any) {
    // remove white spaces and only allow numeric values up to 9 digits
    if (value) {
        const newValue = value.replace(/\s/g, '').match(/\d{1,10}/g)?.join('').substr(0, 10) || '';

        if (newValue.length === 0) {
            return ''
        } else if (newValue.length >= 1 && newValue.length <= 5) {
            return newValue.substr(0, 5);
        } else if (newValue.length >= 5 && newValue.length <= 10) {
            return newValue.substr(0, 5) + '-' + newValue.substr(5, 4);
        } else {
            return newValue.substr(0, 5);
        }
    }
}
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  SACard,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
  SAInput,
  SASpinner,
  SAUXTheme,
  SAUX360Theme
} from '@saux/design-system-react';
import { NewContact } from '../../../interfaces/interfaces';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import styled from 'styled-components';
import { decrypt, encrypt } from '../../../utils/crypto';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import { DatafluxEmailAddressQuery } from "../../../API";
import handleGraphQLErrors from "../../../utils/handleGraphQLErrors";
import { checkAuthStatus } from "../../../utils/utils";
import checkIfLicenseProducerRequested from "../../../utils/checkIfLicenseProducerRequested";

const mobileWidth = 600;

type AddContactProps = {
  onClickCancel: Function;
  onClickContinue: Function;
  showEmailAlreadyUsed: Function;
};

type StyleProps = {
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      top: 50%;
      left: 50%;
      justify-content: center;
      align-items: center;
      overflow: auto;

      .saInputLabel {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      .textLikeInputWrapper {
        legend {
          span {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    `;
  }};
`;

const AddNewContact = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 450px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 45px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    } 
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      display: flex;
    }
  }
`;

const ModalSubTitle = styled.div``;

const ModalBody = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      .textLikeInput {
        font-size: ${theme?.font.primary.size.medium};
      }
    `;
  }};
`;

const ModalFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;
      
      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
  }};
`;

const SpinnerContainer = styled.div`
  margin-left: 10px;
  svg:last-child {
    margin-left: 0;
  }
`;

const DisabledContinueButton = styled(SAButton)`
  ${({ theme }: StyleProps) => {
    return `
      color: ${theme?.colors.white};
      background-color: ${theme?.colors.green};

      &:hover {
        background-color: ${theme?.colors.green};
      }
    `;
  }};
`;

export default function ModalAddContact({ onClickCancel, onClickContinue, showEmailAlreadyUsed }: AddContactProps) {
  const { register, handleSubmit, setError, formState: { errors } } = useForm();
  const onSubmit = (data: any) => validateForm(data);
  const history = useHistory();

  // Spinner
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const spinner = (
    <SpinnerContainer>
      <SASpinner variant="circular-continuous" delay={0} color={SAUX360Theme.colors.blueGray600} size="20px" />
    </SpinnerContainer>
  );

  function isValidEmail(email: string) {
    if (validator.isEmail(email as string)) {
      return true;
    } else {
      return false;
    }
  }

  async function validateForm(data: { email: string }) {
    setShowSpinner(false);

    const delay = setTimeout(() => {
      setShowSpinner(true);
    }, 1000);

    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }

    const checkIfApplicationReq = await checkIfLicenseProducerRequested({
      adminName: ``,
      adminPersonId: "",
      selectedPersonId: data.email,
    });

    if (checkIfApplicationReq?.is_requested === "True") { 
      const {admin_name, created} = checkIfApplicationReq;
      showEmailAlreadyUsed({email: data.email, adminName: admin_name, requestedOn: created});
      return;
    }

    const promiseEmailValidation = API.graphql(
      graphqlOperation(
        queries.datafluxEmailAddress,
        {
          body: { email: data.email }
        }
      ),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
      }
    ) as Promise<{ data: DatafluxEmailAddressQuery }>;

    promiseEmailValidation.then((res) => {
      if (res.data) {
        return res.data.datafluxEmailAddress;
      } else {
        handleGraphQLErrors(res);
      }
    })
    .then((res) => {
      if (res?.statusCode === 200) {
        return res.body;
      } else {
        throw new Error('Something went wrong - 37');
      }
    })
    .then((res) => {
      if (res) {
        if (res.result === 'Ok') {
          const newContact: NewContact = {
            contactDetails: {
              email: data.email,
              contactTypes: [],
              generalDetails: {
                loginId: '',
                firstName: '',
                lastName: '',
                primaryPhone: '',
              },
              locations: [],
              agencyCodes: [],
              primaryAgencyCode: '',
              userPrivileges: []
            }
          }
      
          localStorage.setItem('NewContact', encrypt(JSON.stringify(newContact)));
          handleClickAddContactModalContinue();
        } else if (res.result === 'Bad') {
          setError('email', { type: 'manual', message: 'Invalid email address' });
          setShowSpinner(false);
        } else {
          throw new Error(`Unexpected response: ${JSON.stringify(res)}`)
        }
      } else {
        throw new Error('Something went wrong - 38');
      }
    })
    .catch((err) => {
      if (err?.message) {
        console.error(err.message, err); 
      } else {
        handleGraphQLErrors(err);
      }
      window.location.href = "error";
    })
    .finally(() => {
      clearTimeout(delay);
    });
  }

  function handleClickAddContactModal(event: React.MouseEvent) {
    event.stopPropagation();

    // get the card element inside the modal
    const cardElement = document.querySelector('#modal > div > article > section');

    // close modal when clicking outside of the card element
    if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
      onClickCancel(event);
    }
  }

  function handleClickAddContactModalCancel(event: React.MouseEvent) {
    event.stopPropagation();
    onClickCancel(event);
  }

  function handleClickAddContactModalContinue() {
    onClickContinue();
  }

  useEffect(() => {
    function focusModalAddContact(e: any) {
      const modal = document.querySelector('#modaladdcontact') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          let element = null;
          if (document.documentElement.clientWidth <= mobileWidth) {
            element = modal.querySelector('button') as HTMLElement;
          } else {
            element = modal.querySelector('input') as HTMLElement;  
          }
          if (element) {  
            element.focus();
          }
        }
      }
    }

    document.addEventListener('focusin', focusModalAddContact);
    document.addEventListener('focusout', focusModalAddContact);

    return () => {
      document.removeEventListener('focusin', focusModalAddContact);
      document.removeEventListener('focusout', focusModalAddContact);
    }
  }, []);

  return (
    <Modal id="modaladdcontact" onClickHandler={handleClickAddContactModal}>
      <AddNewContact variant="minimal">
        <ModalHeader>
          <ModalTitle>
            <SAText type="heading-2" text="Add New Agency Contact" />
            <SAIcon className="closemodal" icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" onClick={handleClickAddContactModalCancel} />
          </ModalTitle>
          <ModalSubTitle>
            <SAText type="standard" text="Enter the contact email address below." />
          </ModalSubTitle>
        </ModalHeader>
        <ModalBody>
          <form id="addcontactform" onSubmit={handleSubmit(onSubmit)} autoComplete="none">
            <SAInput
              {...register("email", {
                required: 'Email Address is required',
                maxLength: {value: 100, message: 'Email Address cannot exceed 100 characters'},
                validate: {
                  emailPattern: (value) => isValidEmail(value) || 'Enter valid email address format'
                },
              })}
              autoFocus
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              hint={errors.email?.message as string | undefined}
              type="text"
              error={errors.email as boolean | undefined}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickAddContactModalCancel} variant="link-large" color="text" textTransform="uppercase" />
          {!showSpinner &&
            <SAButton fullWidthUnder={mobileWidth} label="Continue" type="submit" variant="primary-large" textTransform="uppercase" onClick={handleSubmit(onSubmit)} />
          }
          {showSpinner &&
            <DisabledContinueButton endIcon={spinner} disabled fullWidthUnder={mobileWidth} label="Continue" type="submit" variant="primary-large" textTransform="uppercase" />
          }
        </ModalFooter>
      </AddNewContact>
    </Modal>
  );
}

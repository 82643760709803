import React, { useState, useEffect } from "react";
import {
  SACard,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
  SAUXTheme
} from '@saux/design-system-react';
import { useForm } from 'react-hook-form';
import { decrypt } from '../../../utils/crypto';
import styled from 'styled-components';

const mobileWidth = 600;

type TerminateContactProps = {
  onClickCancel: Function;
  onClickConfirm: Function;
};

type StyleProps = {
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      top: 50%;
      left: 50%;
      justify-content: center;
      align-items: center;
      overflow: auto;

      .textLikeInputWrapper {
        legend {
          span {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    `;
  }};
`;

const TerminateContact = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 450px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 45px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    }
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      display: flex;
    }
  }
`;

const ModalSubTitle = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      span {
        color: ${theme?.colors.red};
      }
    `;
  }};
`;

const ModalBody = styled.div``;

const ModalFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;
      
      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
  }};
`;

const Panel = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      background-color: hsl(220,38%,97%);  // Faint Blue
      margin-left: -${theme?.size.spacer.large};
      margin-right: -${theme?.size.spacer.large};
      padding: calc(${theme?.size.spacer.medium} + 5px) ${theme?.size.spacer.large};
    `;
  }};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: ${mobileWidth}px) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
  }
`;

const ContactName = styled.span`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      font-size: ${theme?.font.primary.size.medium};
      font-weight: ${theme?.font.primary.weight.bold};
    `;
  }};
`;

const LoginID = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      padding-top: 10px;
      padding-right: 5px;

      @media only screen and (max-width: ${mobileWidth}px) {
        padding-right: 0;
      }

      div {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-size: ${theme?.font.primary.size.smallTEMP};
        font-weight: ${theme?.font.primary.weight.normal};

        &:first-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }
    `;
  }};
`;

const EmailAddress = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      padding-top: 10px;
      padding-right: 5px;

      @media only screen and (max-width: ${mobileWidth}px) {
        padding-right: 0;
      }

      div {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-size: ${theme?.font.primary.size.smallTEMP};
        font-weight: ${theme?.font.primary.weight.normal};

        &:first-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }
    `;
  }};
`;

const ContactType = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      padding-top: 10px;
      padding-left: 5px;

      @media only screen and (max-width: ${mobileWidth}px) {
        padding-left: 0;
      }

      div {
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-size: ${theme?.font.primary.size.smallTEMP};
        font-weight: ${theme?.font.primary.weight.normal};

        &:first-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }
    `;
  }};
`;

const TextArea = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      margin: 20px 0;

      label {
        background-color: ${theme?.colors.white};
        color: #666666;
        font-size: 12px;
        font-weight: ${theme?.font.primary.weight.normal};
        position: relative;
        top: 8px;
        left: 8px;
        padding-right: 5px;
        padding-left: 5px;

        &.focused {
          color: ${theme?.colors.blue};
        }
      }

      textarea {
        resize: none;
        width: 100%;
        height: 60px;
        padding: 10px;
        font-family: ${theme?.font.primary.family};
        font-size: ${theme?.font.primary.size.medium};
        font-weight: ${theme?.font.primary.weight.normal};
        border-radius: 4px;
        border: 1px solid #DEDEDE;

        &:focus {
          outline: 0;
          border: 2px solid ${theme?.colors.blue};
        }
      }
    `;
  }};
`;

export default function ModalAddContact({ onClickCancel, onClickConfirm }: TerminateContactProps) {
  const { register, handleSubmit, setValue } = useForm();
  const onSubmit = (data: any) => validateForm(data);
  const firstName = decrypt(localStorage.getItem('selectedFirstName'));
  const lastName = decrypt(localStorage.getItem('selectedLastName'));
  const contactTypes = decrypt(localStorage.getItem('selectedContactTypes'));
  const loginId = decrypt(localStorage.getItem('selectedLoginId')) === '' ? '-' : decrypt(localStorage.getItem('selectedLoginId'));
  const email = decrypt(localStorage.getItem('selectedEmail')) === 'null' ? '-' : decrypt(localStorage.getItem('selectedEmail'));
  const [warningText, setWarningText] = useState<string>(getDefaultWarningText());
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;

  function getDefaultWarningText() {
    return contactTypes?.includes('Licensed Producer') ? 'This contact will be removed from the agency and Producer NIPR appointments will be terminated.' : 'This contact will be removed from the agency.';
  }

  function validateForm(data: {additionalComments: string}) {
    if (!isEmployee) {
      onClickConfirm(data.additionalComments);
    }
  }

  function handleClickTerminateContactModal(event: React.MouseEvent) {
    event.stopPropagation();

    // get the card element inside the modal
    const cardElement = document.querySelector('#modal > div > article > section');

    // close modal when clicking outside of the card element
    if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
      onClickCancel(event);
    }
  }

  function handleClickTerminateContactModalCancel(event: React.MouseEvent) {
    event.stopPropagation();
    onClickCancel(event);
  }

  useEffect(() => {
    function focusModalTerminateContact(e: any) {
      const modal = document.querySelector('#modalterminatecontact') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          let element = null;
          if (document.documentElement.clientWidth <= mobileWidth) {
            element = modal.querySelector('button') as HTMLElement;
          } else {
            element = modal.querySelector('textarea') as HTMLElement;
          }
          if (element) {  
            element.focus();
          }
        }
      }
    }

    document.addEventListener('focusin', focusModalTerminateContact);
    document.addEventListener('focusout', focusModalTerminateContact);

    return () => {
      document.removeEventListener('focusin', focusModalTerminateContact);
      document.removeEventListener('focusout', focusModalTerminateContact);
    }
  }, []);

  return (
    <Modal id="modalterminatecontact" onClickHandler={handleClickTerminateContactModal}>
      <TerminateContact variant="minimal">
        <form id="addcontactform" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <ModalHeader>
            <ModalTitle>
              <SAText type="heading-2" text="Remove Agency Contact" />
              <SAIcon className="closemodal" icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" onClick={handleClickTerminateContactModalCancel} />
            </ModalTitle>
            <ModalSubTitle>
              <SAText type="standard" text={warningText} />
            </ModalSubTitle>
          </ModalHeader>
          <ModalBody>
            <Panel>
              <Row>
                <ContactName>
                  {lastName}, {firstName}
                </ContactName>
              </Row>
              <Row>
                <Col>
                  <LoginID>
                    <div>
                      Login ID:
                    </div>
                    <div>
                      {loginId}
                    </div>
                  </LoginID>
                  <EmailAddress>
                    <div>
                      Email Address:
                    </div>
                    <div>
                      {email}
                    </div>
                  </EmailAddress>
                </Col>
                <Col>
                  <ContactType>
                    <div>
                      Contact Type:
                    </div>
                    <div>
                      {contactTypes}
                    </div>
                  </ContactType>
                </Col>
              </Row>
            </Panel>
            <TextArea>
              <label>Additional Comments</label>
              <textarea
                {...register('additionalComments')}
                autoFocus
                id="additionalcomments"
                name="additionalComments"
                onFocus={(e) => {
                  if (!e.target.parentElement?.children[0].className.includes('focused')) {
                    e.target.parentElement?.children[0].classList.add('focused');
                  }
                }}
                onBlur={(e)=> {
                  if (e.target.parentElement?.children[0].className.includes('focused')) {
                    e.target.parentElement?.children[0].classList.remove('focused');
                  }
                }}
                onChange={(event: any) => {
                  const { value } = event.target;
                  setValue('additionalComments', value.substr(0, 255));
                }}
              />
            </TextArea>
            <SAText type="standard" text="Are you sure you want to completely remove this contact from your agency?" />
          </ModalBody>
          <ModalFooter>
            <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickTerminateContactModalCancel} variant="link-large" color="text" textTransform="uppercase" />
            <SAButton fullWidthUnder={mobileWidth} label="Confirm" type="submit" variant="primary-large" textTransform="uppercase" disabled={isEmployee} />
          </ModalFooter>
        </form>
      </TerminateContact>
    </Modal>
  );
}

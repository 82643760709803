import React, { useEffect } from 'react';
import { SAButton, SAModal, SAText } from '@saux/design-system-react';
import {
  ButtonWrapper,
  ModalBody,
  SARemoveModalBody,
  StyledModalText,
  StyledRemoveModalText,
  RemovelModalText,
  RemovelModalUpperText,
  RemoveModalButtonWrapper,
  RemoveModalCanNotRemoveButtonWrapper,
  RemovelModalUpperTextBold,
  StyledRemoveModalTextDescription
} from '../../AgencyContactDetails/AgencyContactDetailsStyles/AgencyContactDetailsStyles';

export type LastContactTypeProps = {
  title: string;
  text: string;
  onClickClose: Function;
}

export default function ModalLastContactType({ title, text, onClickClose }: LastContactTypeProps) {
  const modalOnClickHandler = (event: any) => {
    event.stopPropagation();
  }

  const renderModal = () => (
    <ModalBody>
      <SARemoveModalBody variant="simple" title={title}>
          <>
            <StyledModalText>
              <RemovelModalUpperText
                type="paragraph"
                text={text}/>
            </StyledModalText>
          </>
        <RemoveModalCanNotRemoveButtonWrapper>
            <SAButton
                label="CLOSE"
                onClick={(e) => onClickClose(e, 'close')}
                variant="primary"
                color="text"
            />
        </RemoveModalCanNotRemoveButtonWrapper>
      </SARemoveModalBody>
    </ModalBody>
  )

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modalconfirmedit') as HTMLElement;
      if (modal) {
        const element = modal.querySelector('button.sa-button') as HTMLElement;
        if (element) {
          element.focus();
        }
      }
    }

    function focusModalLastContactType(e: any) {
      const modal = document.querySelector('#modallastcontacttype') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          const element = modal.querySelector('button.sa-button') as HTMLElement;
          if (element) {
            element.focus();
          }
        }
      }
    }

    focusDefault();
    document.addEventListener('focusin', focusModalLastContactType);
    document.addEventListener('focusout', focusModalLastContactType);

    return () => {
      document.removeEventListener('focusin', focusModalLastContactType);
      document.removeEventListener('focusout', focusModalLastContactType);
    }
  }, []);

  return (
    <SAModal id="modallastcontacttype" onClickHandler={(event: React.MouseEvent) => modalOnClickHandler(event)}>
      <div>{renderModal()}</div>
    </SAModal>
  );
};

import React, { useEffect } from "react";
import {
  SACard,
  SAButton,
  SAModal,
  SAText,
  SAUXTheme
} from '@saux/design-system-react';
import styled from 'styled-components';

const mobileWidth = 600;

type CrossHierarchyProps = {
  title: string;
  text: string;
  primaryAgencyCode: string;
  addedContactTypes: string;
  onClickCancel: Function;
  onClickConfirm: Function;
};

type StyleProps = {
  error?: boolean;
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const CrossHierarchy = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 600px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 45px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    } 
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      display: flex;
    }
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalFooter: any = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
  }};
`;

const Text = styled(SAText)`
  font-size: 20px;
  line-height: 26px;
`;

const PrimaryAgency = styled(Text)`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const ContactTypes = styled(Text)`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const NoteHeading = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const Note = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export default function ModalCrossHierarchy({ title, text, primaryAgencyCode, addedContactTypes, onClickCancel, onClickConfirm }: CrossHierarchyProps) {
  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modalcrosshierarchy') as HTMLElement;
      if (modal) {
        const element = modal.querySelector('button.sa-button') as HTMLElement;
        if (element) {
          element.focus();
        }
      } 
    }

    function focusModalCrossHierarchy(e: any) {
      const modal = document.querySelector('#modalcrosshierarchy') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          let element = null;
          element = modal.querySelector('button') as HTMLElement;
          if (element) {  
            element.focus();
          }
        }
      }
    }

    focusDefault();
    document.addEventListener('focusin', focusModalCrossHierarchy);
    document.addEventListener('focusout', focusModalCrossHierarchy);

    return () => {
      document.removeEventListener('focusin', focusModalCrossHierarchy);
      document.removeEventListener('focusout', focusModalCrossHierarchy);
    }
  }, []);
  
  function handleClickCrossHierarchyModal(event: React.MouseEvent) {
    event.stopPropagation();
  }

  function handleClickCancel(event: React.MouseEvent) {
    event.stopPropagation();
    onClickCancel(event);
  }

  function handleClickConfirm(event: React.MouseEvent) {
    event.stopPropagation();
    onClickConfirm(event);
  }

  return (
    <Modal id="modalcrosshierarchy" onClickHandler={handleClickCrossHierarchyModal}>
      <CrossHierarchy variant="minimal">
        <ModalHeader>
          <ModalTitle>
            <SAText type="heading-2" text={title} />
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Text type="standard" text={text} />
          <PrimaryAgency type="standard" text={`Primary Agency Code: ${primaryAgencyCode}`} />
          <ContactTypes type="standard" text={addedContactTypes} />
          <NoteHeading>Note</NoteHeading>
          <Note>If you would like to work directly with Agency Operations, please contact them directly at <a href="mailto:Agency.Operations@stateauto.com">Agency.Operations@stateauto.com</a></Note>
        </ModalBody>
        <ModalFooter>
          <SAButton label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
          <SAButton label="Confirm" onClick={handleClickConfirm} variant="large" color="secondary" textTransform="uppercase" />
        </ModalFooter>
      </CrossHierarchy>
    </Modal>
  );
}

import React, { useEffect } from "react";
import { SACard, SAButton, SAIcon, SAIcons, SAIconSize, SAModal, SAText, SAUXTheme } from "@saux/design-system-react";
import styled from "styled-components";

const mobileWidth = 600;

type ApplicationAlertProps = {
  contactName: string;
  adminName: string;
  requestedOn: string;
  onClickClose: (event: React.MouseEvent) => void;
};

type StyleProps = {
  error?: boolean;
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const LicenceProducerApplication = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 600px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 36px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    }
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      display: flex;
    }
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ModalFooter: any = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
  }};
`;

export default function ModalLicenceProducerApplication({
  contactName,
  adminName,
  requestedOn,
  onClickClose,
}: ApplicationAlertProps) {

  const requestedDate = new Date(requestedOn);

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector("#modalLicenceProducerApplication") as HTMLElement;
      if (modal) {
        const element = modal.querySelector("button.sa-button") as HTMLElement;
        if (element) {
          element.focus();
        }
      }
    }

    function focusModalLicenceProducerApplication(e: any) {
      const modal = document.querySelector("#modalLicenceProducerApplication") as HTMLElement;
      const root = document.querySelector("#root") as HTMLElement;
      if (modal && root) {
        if (
          (e.type === "focusout" && root.contains(e.relatedTarget)) ||
          (e.type === "focusin" && root.contains(e.target))
        ) {
          let element = modal.querySelector("button.sa-button") as HTMLElement;
          if (element) {
            element.focus();
          }
        }
      }
    }

    focusDefault();
    document.addEventListener("focusin", focusModalLicenceProducerApplication);
    document.addEventListener("focusout", focusModalLicenceProducerApplication);

    return () => {
      document.removeEventListener("focusin", focusModalLicenceProducerApplication);
      document.removeEventListener("focusout", focusModalLicenceProducerApplication);
    };
  }, []);

  function handleClickAlertModal(event: React.MouseEvent) {
    event.stopPropagation();

    // get the card element inside the modal
    const cardElement = document.querySelector("#modal > div > article > section");

    // close modal when clicking outside of the card element
    if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
      onClickClose(event);
    }
  }

  function handleClickClose(event: React.MouseEvent) {
    event.stopPropagation();
    onClickClose(event);
  }

  return (
    <Modal id="modalLicenceProducerApplication" onClickHandler={handleClickAlertModal}>
      <LicenceProducerApplication variant="minimal">
        <ModalHeader>
          <ModalTitle>
            <SAText type="heading-2" text="Licensed Producer Application already sent" />
            <SAIcon
              className="closemodal"
              icon={SAIcons.x}
              size={SAIconSize.medium}
              colorVariant="dark"
              onClick={handleClickClose}
            />
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <SAText
            type="paragraph"
            size="400"
            text={`A Licensed Producer application has already been sent for this contact. It was sent by ${adminName} on ${requestedDate.toLocaleDateString()}.`}
          />
          <SAText
            type="paragraph"
            size="400"
            text={`Please check with ${contactName} to verify receipt of the application and if not, check spam. Once ${contactName} has submitted the application the onboarding process will only take a few days.`}
          />
        </ModalBody>
        <ModalFooter>
          <SAButton
            label="close"
            onClick={handleClickClose}
            variant="large"
            color="secondary"
            textTransform="uppercase"
          />
        </ModalFooter>
      </LicenceProducerApplication>
    </Modal>
  );
}

import React from "react";
import { SAModal, SASpinner, SAText } from "@saux/design-system-react";
import styled from "styled-components";
type ModalSpinnerProps = {
  description?: string;
};
const Modal = styled(SAModal)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

export default function ModalSpinner({ description }: ModalSpinnerProps) {
  function handleClickContactAgencyOperationsModal(event: React.MouseEvent) {
    event.stopPropagation();
  }

  return (
    <Modal onClickHandler={handleClickContactAgencyOperationsModal}>
      <SASpinner variant="circular-continuous" />
      {description && <SAText colorVariant="info-background" type="standard">{description}</SAText>}
    </Modal>
  );
}

import * as queries from '../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";
import { GetPersonRequestedQueryVariables, GetPersonRequestedQuery } from "../API";
import { decrypt } from "./crypto";
import handleGraphQLErrors from "./handleGraphQLErrors";

type ApplicationParams =  {
    adminName: string;
    adminPersonId: string;
    selectedPersonId: string;
}

export default async function checkIfLicenseProducerRequested({adminName, adminPersonId, selectedPersonId} : ApplicationParams) {

    const response = await (
      API.graphql(
        graphqlOperation(queries.getPersonRequested, {
          admin_name: adminName,
          person_id: selectedPersonId,
          admin_person_id: adminPersonId,
        } as GetPersonRequestedQueryVariables),
        {
          Authorization: `Bearer ${decrypt(localStorage.getItem("auth_accesstoken"))}`,
        }
      ) as Promise<{ data: GetPersonRequestedQuery }>
    )
      .then((res) => {
        if (res.data) {
          return res.data.getPersonRequested;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err);
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
        return null;
      });

    if (response && response.statusCode === 200) {
      const result = response.body;
      if (result?.is_requested === "True") {       
        return result;
      }
      return null;
    }
  }
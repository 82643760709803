import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
    SAButton,
    SAIcon,
    SAIcons,
    SAIconSize,
    SAText,
    SASnackbar,
    SASpinner,
    SAUX360Theme
} from '@saux/design-system-react';
import env from '../../../env_config';
import { useLocation } from 'react-router';
import { useHistory } from "react-router-dom";
import { AppContextProps } from '../../../interfaces/interfaces';
import statesJson from '../StateDropDown/states';
import {
    AddressLine,
    Container,
    Content,
    ContentEdit,
    ContentEditLine2,
    FooterButtons,
    Header,
    SectionWrapper,
    StyledError,
    StyledHeader,
    StyledHeaderLine,
    StyledHeaderLine2,
    StyledSAButton,
    StyledInput,
    StyledSelect,
    styles,
    StyledDiv,
    StyledLabel,
    StyledCancelButton,
    ProfileSpinnerContainer,
    DisabledContinueButton,
    HiddenStyledInput
} from '../AgencyContactDetailsStyles/AgencyContactDetailsStyles';
import ConfirmEditModal from '../../ConfirmEditModal';
import {formatZipcode, addressHasData, addressScrubber, emailValidation} from '../../../utils';
import { AddressTemplate } from '../AddressTemplate';
import { encrypt, decrypt } from '../../../utils/crypto';
import ModalPendingEdits from "../../modal/ModalPendingEdits/ModalPendingEdits";
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { UpdatePendingPersonResidentialAddressMutation } from '../../../API';
import handleGraphQLErrors from '../../../utils/handleGraphQLErrors';
import stateNameLookup from '../../../utils/stateNameLookup';
import { checkAuthStatus } from '../../../utils/utils';

const mobileWidth = 600;

type ResAddress = {
    address_line1: string;
    address_line2: string | "";
    city: string;
    state: string | "";
    state_cd: string | "";
    zip: string;
    county: string;
};

export default function FullWidthGrid(props: any) {
    const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;
    const isAdmin: boolean = decrypt(localStorage.getItem('isAdmin')) === 'true' ? true : false;
    const [resAddress, setResAddress] = useState<ResAddress | null>(null);
    const [displayModal, setDisplayModal] = useState<boolean>(false);
    const [hasAddress, setHasAddress] = useState<boolean>(false);
    const [state, setState] = useState('');
    const [stateName, setStateName] = useState();
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [snackbarTitle, setSnackbarTitle] = useState<string>('');
    const [snackbarSubtitle, setSnackbarSubtitle] = useState<string>('');
    const [stateLabelClassName, setStateLabelClassName] = useState<string>('');
    const [shrinkLabel, setShrinkLabel] = useState<string | null>(null);
    const [pendingEdits, setPendingEdits] = useState<boolean>(false);
    const [scrubbingStatus, setScrubbingStatus] = useState<string>('');
    const [saveType, setSaveType] = useState<string>('');
    const history = useHistory();

    // Spinner
    let delay: ReturnType<typeof setTimeout>;
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [spinnerStatus, setSpinnerStatus] = useState<string>('hide');

    const spinner = (
        <ProfileSpinnerContainer display={spinnerStatus}>
            <SASpinner variant="circular-continuous" delay={0} color={SAUX360Theme.colors.blueGray600} size="20px" />
        </ProfileSpinnerContainer>
    );

    const USStates: any[] = statesJson;
    const options = USStates.map((item: any) => ({
        label: item.value + " - " + item.name,
        value: item.value
    }));

    type ResAddressForm = {
      address_line1: string;
      address_line2: string;
      zip: string;
      city: string;
      state: string;
      state_cd: string;
      county: string;
    };
    const formMethod = useForm<ResAddress>({
        mode: "onBlur",
        reValidateMode: "onBlur",
        criteriaMode: "firstError",
        shouldFocusError: true,
    });

    const location = useLocation<AppContextProps>();
    const personId = decrypt(localStorage.getItem('selectedPersonId'));

    const editState = props?.openEdit;
    const changeEditState = props?.change;

    let mailData: any[] = [];
    const startIcon = (
        <SAIcon
            icon={SAIcons.pencil}
            size={SAIconSize.small}
            colorVariant="secondary"
        />
    );

    useEffect(() => {
        let resAddr = props.residentialAddress;
        if (!resAddr) {
            resAddr = AddressTemplate;
        }

        setHasAddress(addressHasData(resAddr));
        setResAddress(resAddr);
        setState(resAddr?.state_cd);
        setStateName(resAddr.state);
    }, [props.residentialAddress]);

    useEffect(() => {
        function addShrinkLabel(element: HTMLInputElement) {
            const parent = element.parentElement?.parentElement?.parentElement;
            if (element.value.trim() !== '' && !parent?.classList.contains('shrinkLabel')) {
                parent?.classList.add('shrinkLabel');
            }
        }

        if (!(editState !== 'residence')) {
            const elements: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="text"]');
            elements.forEach((element: HTMLInputElement) => {
                addShrinkLabel(element);
            });
        }
    }, [editState]);

    useEffect(() => {
        if (shrinkLabel !== null) {
            const element = document.querySelector(`input[id="${shrinkLabel}"]`) as HTMLInputElement;
            const parent = element.parentElement?.parentElement?.parentElement as HTMLElement;
            if (element.value.trim() !== '' && !parent.classList.contains('shrinkLabel')) {
                parent.classList.add('shrinkLabel');
            }
            setShrinkLabel(null);
        }
    }, [shrinkLabel]);

    useEffect(() => {
        if (displayModal) {
          document.body.classList.add("noscroll");
        } else {
          document.body.classList.remove("noscroll");
        }
      }, [displayModal]);

    useEffect(() => {
        formMethod.setValue('state_cd', state);
    }, [state]);

    useEffect(() => {
        if (scrubbingStatus === 'complete') {
            if (saveType === 'residential') {
                handleSubmit(handleOnSubmit)();
                setSaveType('');
            }
        }
    }, [scrubbingStatus]);

    const {
        control,
        register,
        reset,
        setValue,
        handleSubmit,
        formState: { errors }, clearErrors,
    } = formMethod;

    function handlePendingEditsClose() {
        if (!isAdmin) {
            const portalUrl = env['saconnect'];
            window.location.href = portalUrl;
        } else {
            history.push({
                pathname: `/agency-contacts`,
            });
        }
    }

    function onSnackbarClose() {
        setShowSnackbar(false);
    }

    function addressScrub() {
        const addressLine1 = formMethod.getValues('address_line1');
        const zip = formMethod.getValues('zip');

        if (addressLine1 && zip) {
            setScrubbingStatus('scrubbing');
            addressScrubber(addressLine1, zip).then((data) => {
                if (data) {
                    const addr: any = data?.AddressInformation;
                    if (addr && addr.ErrorDescription === '') {
                        const zipCode = addr?.ReturnedScrubbedAddress?.ScrubbedZipCode + addr?.ReturnedScrubbedAddress?.ScrubbedZipCodePlus4;
                        formMethod.setValue('address_line1', addr?.ReturnedScrubbedAddress?.ScrubbedAddress);
                        formMethod.setValue('city', addr?.ReturnedScrubbedAddress?.ScrubbedCity);
                        formMethod.setValue('state_cd', addr?.ReturnedScrubbedAddress?.ScrubbedState);
                        formMethod.setValue('county', addr?.ReturnedScrubbedAddress?.County);
                        formMethod.setValue('zip', formatZipcode(zipCode));
                        setState(addr?.ReturnedScrubbedAddress?.ScrubbedState);

                        const inputs: string[] = ['address_line1', 'zip', 'city', 'state_cd', 'county'];

                        inputs.forEach((input: any) => {
                            formMethod.clearErrors(input);
                            if (input !== 'state_cd') {
                                const element = document.querySelector(`input[id="${input}"]`) as HTMLInputElement;
                                const parent = element.parentElement?.parentElement?.parentElement as HTMLElement;
                                if (element.value.trim() !== '' && !parent.classList.contains('shrinkLabel')) {
                                    parent.classList.add('shrinkLabel');
                                }
                            }
                        });
                    }
                }
                setScrubbingStatus('complete');
            });
        }
    }

    function updateResAddress (data: any) {
        const residentialAddr = {
            address_line1: data.address_line1,
            address_line2: data.address_line2,
            city: data.city,
            county: data.county,
            state: data.state,
            state_cd: data.state_cd,
            zip: data.zip
        }
        setResAddress(residentialAddr);
        setHasAddress(true);
    }

    async function sendData(data: any) {
        setShowSpinner(true);
        setSpinnerStatus('hide');

        delay = setTimeout(() => {
            setSpinnerStatus('display');
        }, 1000);

        if (data.county === undefined) {
            data.county = '';
        }
        data.updated_by = decrypt(localStorage.getItem('personId'));

        const isAuthenticated: boolean = await checkAuthStatus();
        if (!isAuthenticated) {
          return;
        }
        const promiseResidentialAddress = API.graphql(
            graphqlOperation(
                mutations.updatePendingPersonResidentialAddress,
                {
                    body: data,
                    person_id: personId
                }
            ),
            {
              Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
            }
        ) as Promise<{ data: UpdatePendingPersonResidentialAddressMutation }>;

        promiseResidentialAddress.then((res) => {
            if (res.data) {
                return res.data.updatePendingPersonResidentialAddress;
            } else {
              handleGraphQLErrors(res);
            }
        })
        .then((res) => {
            if (res?.statusCode === 200 || res?.statusCode === 400) {
                return res.body;
            } else {
                throw new Error('Something went wrong - 22');
            }
        })
        .then((result) => {
            // TODO if state being used add result to state
            if (result !== undefined && result !== null) {
                if (result.status === 'success') {
                    updateResAddress(data);
                    setSnackbarTitle('Changes Saved');
                    setSnackbarSubtitle('All your changes have been saved');
                    setShowSnackbar(true);
                    clearTimeout(delay);
                    setShowSpinner(false);
                    setSpinnerStatus('hide');
                    handleEdit('cancel');
                }
                if (result.status === 'error') {
                    clearTimeout(delay);
                    setShowSpinner(false);
                    setSpinnerStatus('hide');
                    setPendingEdits(true);
                }
            } else {
                throw new Error('Something went wrong - 23');
            }
        })
        .catch((err) => {
            if (err?.message) {
                console.error(err.message, err); 
            } else {
                handleGraphQLErrors(err);
            }
            window.location.href = "error";
        });
    }

    const handleEdit = (action: string) => {
        if (action === 'cancel') {
            changeEditState('default');
        }
        if (action === 'edit') {
            formMethod.reset();
            if (state) {
                formMethod.setValue('state_cd', state);
            } else {
                formMethod.setValue('state_cd', '');
            }
            if (editState !== 'residence' && editState !== 'default') {
                setDisplayModal(true);
                localStorage.setItem('incoming', encrypt('residence'));
            } else {
                changeEditState('residence');
            }
        }
        if (action === 'discard') {
            const inComing = decrypt(localStorage.getItem('incoming'));
            if (inComing !== undefined) {
                changeEditState(inComing);
            }
        }
    }

  const confirmModal = (event: React.MouseEvent, action: string) => {
    event.stopPropagation();
    if (action === 'close') {
      setDisplayModal(!displayModal);
    }
    if (action === 'discard') {
        setDisplayModal(!displayModal);
        handleEdit('discard');
    }
  }

    const handleOnSubmit = (data: any) => {
        if (!isEmployee) {
            // wait for scrubbing to complete before saving
            if (scrubbingStatus === 'scrubbing') {
                setSaveType('residential');
            } else {
                setSaveType('');
                let addressDetails: any = {};
                formMethod.trigger().then(value => {
                    if (value) {
                        addressDetails = data;
                        addressDetails.zip = data?.zip.replace('-', '');
                        addressDetails.state = stateNameLookup(addressDetails?.state_cd);
                        setResAddress(addressDetails);
                        setState(addressDetails.state_cd);
                        setHasAddress(true);
                        localStorage.setItem('res_address', encrypt(JSON.stringify(addressDetails)));
                        sendData(addressDetails);
                    }
                });
            }
        }
    }

    return (
        <div>
            <SASnackbar
                open={showSnackbar}
                variant="snackbar-success"
                position="top-end"
                autoHideDuration={5000}
                title={snackbarTitle}
                subtitle={snackbarSubtitle}
                offset={[-25, 170]}
                onClose={onSnackbarClose}
            />
            {editState !== 'residence' ? (
                <>
                    <Container>
                        <Header>
                            <StyledHeader type="heading-3" text="Residential Address"/>
                            <StyledSAButton
                                color="secondary"
                                label="Edit"
                                variant="primary-link-medium"
                                textTransform="none"
                                startIcon={startIcon}
                                onClick={() => handleEdit("edit")}
                            />
                        </Header>
                        <SectionWrapper>
                            <Content>
                                <AddressLine>
                                    {!hasAddress ? (
                                        <SAText type="standard" text="No residential address entered" />
                                    ) : (
                                        <>
                                            <SAText type="standard"
                                                    text={(resAddress?.address_line1 == "") ? " " : resAddress?.address_line1 + ', '}/>
                                            <SAText type="standard"
                                                    text={(resAddress?.address_line2 == "") ? " " : resAddress?.address_line2 + ', '}/>
                                            <SAText type="standard"
                                                    text={(resAddress?.city == "") ? " " : resAddress?.city + ', '}/>
                                            <SAText type="standard"
                                                    text={(resAddress?.state_cd == "") ? " " : resAddress?.state_cd + ' '}/>
                                            <SAText type="standard"
                                                    text={resAddress?.zip ? `${formatZipcode(resAddress?.zip)}` : ''}/>
                                        </>
                                    )}
                                </AddressLine>
                            </Content>
                        </SectionWrapper>
                    </Container>
                </>
            ) : (
                <Container>
                    <>
                        <StyledHeaderLine>
                            <StyledHeader type="heading-3" text="Edit Residential Address"/>
                            <StyledHeaderLine2 type="standard" text="Enter Residential Address below:"/>
                        </StyledHeaderLine>
                        <form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onSubmit={handleSubmit(handleOnSubmit)} >
                        <SectionWrapper>
                            <ContentEdit>
                                <StyledInput
                                    {...register("address_line1", {required: "Street address is required"})}
                                    id="address_line1"
                                    name="address_line1"
                                    error={Object.prototype.hasOwnProperty.call(
                                        errors,
                                        "address_line1"
                                    )}
                                    hint={errors.address_line1?.message as string | undefined}
                                    label="Street Address 1"
                                    type="text"
                                    autoComplete="none"
                                    defaultValue={resAddress?.address_line1}
                                    onBlur={() => {
                                        clearErrors('address_line1');
                                        formMethod.trigger('address_line1');
                                        if (errors && !errors.address_line1) {
                                            addressScrub();
                                        }
                                        setShrinkLabel('address_line1');
                                    }}
                                    fullWidth
                                />
                            </ContentEdit>
                            <ContentEdit>
                                <StyledInput
                                    {...register("address_line2")}
                                    id="address_line2"
                                    name="address_line2"
                                    error={Object.prototype.hasOwnProperty.call(
                                        errors,
                                        "address_line2"
                                    )}
                                    hint="optional"
                                    label="Street Address 2"
                                    defaultValue={resAddress?.address_line2}
                                    type="text"
                                    autoComplete="none"
                                    fullWidth
                                    onBlur={() => {
                                        setShrinkLabel('address_line2');
                                    }}
                                />
                            </ContentEdit>
                        </SectionWrapper>
                        <SectionWrapper>
                            <ContentEditLine2>
                                <StyledInput
                                    {...register("zip", {
                                        required: 'Zip Code is required',
                                        minLength: {value: 5, message: "Zip Code requires 5 digits"},
                                        maxLength: {value: 10, message: "Zip Code cannot exceed 9 digits"}
                                    })}
                                    id="zip"
                                    name="zip"
                                    error={Object.prototype.hasOwnProperty.call(
                                        errors,
                                        "zip"
                                    )}
                                    hint={errors.zip?.message as string | undefined}
                                    label="Zip Code"
                                    type="text"
                                    defaultValue={formatZipcode(resAddress?.zip!)}
                                    onChange={(e: any) => {
                                        clearErrors('zip');
                                        const { value } = e.target;
                                        setValue('zip', formatZipcode(value));
                                    }}
                                    onBlur={(e: any) => {
                                        clearErrors('zip');
                                        const { value } = e.target;
                                        formMethod.trigger('zip');
                                        if (value.length < 10) {
                                            setValue('zip', value.substr(0, 5));
                                        }
                                        if (errors && !errors.zip) {
                                            addressScrub();
                                        }
                                        setShrinkLabel('zip');
                                    }}
                                    autoComplete="none"
                                    fullWidth
                                />
                            </ContentEditLine2>
                            <ContentEditLine2>
                                <StyledInput
                                    {...register("city", {required: "City is required"})}
                                    id="city"
                                    name="city"
                                    error={Object.prototype.hasOwnProperty.call(errors, "city")}
                                    hint={errors.city?.message as string | undefined}
                                    label="City"
                                    type="text"
                                    defaultValue={(resAddress?.city == null || resAddress?.city == undefined || resAddress?.city == "") ? "-" : resAddress?.city}
                                    autoComplete="none"
                                    fullWidth
                                    onBlur={() => {
                                        formMethod.trigger('city');
                                        setShrinkLabel('city');
                                    }}
                                />
                            </ContentEditLine2>
                            <ContentEditLine2>
                                <StyledDiv>
                                    <Controller
                                        rules={{
                                            required: "Please select a State"
                                        }}
                                        control={control}
                                        render={({ field: { onChange, value, name, ref } }) => (
                                            <StyledSelect
                                                classNamePrefix="Select"
                                                inputRef={ref}
                                                value={options.find((c) => c.value === value)}
                                                name={name}
                                                styles={styles}
                                                options={options}
                                                error={errors?.state_cd}
                                                onChange={(selectedOption: any) => {
                                                    clearErrors('state_cd');
                                                    onChange(selectedOption.value);
                                                    formMethod.trigger('state_cd');
                                                }}
                                                onFocus={() => {
                                                    setStateLabelClassName('focused');
                                                }}
                                                onBlur={() => {
                                                    setStateLabelClassName('');
                                                    clearErrors('state_cd');
                                                    formMethod.trigger('state_cd');
                                                }}
                                                placeholder="Select a State"
                                            />
                                        )}
                                        name="state_cd"
                                    />
                                    <StyledLabel
                                        err={errors.state_cd}
                                        className={stateLabelClassName}
                                    >State</StyledLabel>
                                </StyledDiv>
                                <StyledError>{errors?.state_cd?.message}</StyledError>
                            </ContentEditLine2>
                            <ContentEditLine2>
                                <HiddenStyledInput
                                    {...register("county")}
                                    id="county"
                                    name="county"
                                    type="text"
                                    defaultValue={resAddress?.county}
                                    autoComplete="none"
                                    fullWidth
                                />
                            </ContentEditLine2>
                        </SectionWrapper>
                        <FooterButtons>
                            <StyledCancelButton
                                label="Cancel"
                                onClick={() => handleEdit('cancel')}
                                variant="link-large"
                                color="text"
                            />
                            {!showSpinner &&
                                <SAButton fullWidthUnder={mobileWidth} label="Save Changes" type="submit" variant="primary" textTransform="uppercase" disabled={isEmployee} />
                            }
                            {showSpinner &&
                                <DisabledContinueButton endIcon={spinner} disabled fullWidthUnder={mobileWidth} label="Save Changes" type="submit" variant="primary" textTransform="uppercase" />
                            }
                        </FooterButtons>
                        </form>
                    </>
                </Container>
            )}
            {displayModal && (
                <ConfirmEditModal confirmModal={confirmModal} />
            )}
            {pendingEdits &&
              <ModalPendingEdits isAdmin={isAdmin} onClickClose={handlePendingEditsClose} />
            }
        </div>
    )
}

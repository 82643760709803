import { useState, useMemo, useEffect } from "react";
import { SAButton, SACheckbox, SASearch, SAUXTheme } from "@saux/design-system-react";
import styled from "styled-components";
import { AgencyCode, Constants } from "../../interfaces/interfaces";

type AgencyCodesInputProps = {
  onChange: (values: string[]) => void;
  values: string[];
  agencyCodes: AgencyCode[];
  resetSearchFilter?: boolean;
  error?: boolean;
};

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
  isEmployee?: boolean;
  error?: boolean;
};

const mobileWidth = 600;
const AgencyCodesContainer = styled.div.attrs(
  ({ error }: StyleProps) => ({
    error
  })
)`
  border: ${ props => props.error ?  '1px solid hsl(353,98%,41%)' : '1px solid #D8DFEC'}; 
  ${({ theme }: StyleProps) => {
    return `
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    
    span {
      &.heading {
        display: flex;
        padding: ${theme?.size.spacer.small} calc(${theme?.size.spacer.medium} + 5px);
        margin: ${theme?.size.spacer.medium} ${theme?.size.spacer.small} 0 ${theme?.size.spacer.small};
        font-weight: ${theme?.font.primary.weight.bold};
        background-color: hsl(220,38%,97%);

        @media only screen and (max-width: ${mobileWidth}px) {
          margin: 0;
        }
      }
    }
  `;
  }};
`;

const AgencyCodesCheckboxes = styled.div`
  ${({ theme }: StyleProps) => {
    return `
    margin: ${theme?.size.spacer.small};
    max-height: 270px;
    overflow-y: auto;

    @media only screen and (max-width: ${mobileWidth}px) {
      margin: ${theme?.size.spacer.small} 0 ${theme?.size.spacer.small} 0;
    }

    .saCheckboxWrapper {
      display: flex;
      align-items: center;
      margin: 10px 0;
      height: 40px;
      padding: 0 15px;
      color: ${theme?.colors.text};
      z-index: 0;

      &.hidden {
        display: none;
      }

      input {
        &:hover {
          cursor: pointer;
        }
      }

      // label {
      //   pointer-events: none;
      // }

      // input {
      //   pointer-events: none;
      // }

      label {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 92px;
      border: none;
    }
  
    &::-webkit-scrollbar-track {
        background: #F0EFEF;
        border-radius: 7px;
    }
  
    &::-webkit-scrollbar-thumb {
        background: #8A8A8A;
        border-radius: 7px;
        box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
  `;
  }};
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: ${mobileWidth}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Search = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;

      .saInputWrapper {
        margin: 0 0 ${theme?.size.spacer.medium} 0;
      }

      .textLikeInput {
        padding-top: 0;
        width: initial;
        font-size: ${theme?.font.primary.size.medium};
      }

      ul {
        align-items: center;
        span {
          button {
            margin-bottom: 0;
          }
        }
        button {
          margin-bottom: 5px;
        }
      }
    `;
  }};
`;

const SelectAllButton = styled(SAButton)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-shrink: 0;
      margin: 0 0 ${theme?.size.spacer.medium} ${theme?.size.spacer.medium};

      @media only screen and (max-width: ${mobileWidth}px) {
        margin: 0 0 ${theme?.size.spacer.medium} 0;
      }
    `;
  }};
`;

export default function AgencyCodesInput({ onChange, error, values, agencyCodes, resetSearchFilter }: AgencyCodesInputProps) {
  const [searchValues, setSearchValues] = useState<Array<string>>([]);

  const filteredAgencyCodes = useMemo(() => {
    return agencyCodes.filter(({ agency_name, agency_code, agency_zip, agency_city, agency_state }) => {
      return searchValues.every((term) =>
        `${agency_code}, ${agency_name}, ${agency_city}, ${agency_state} ${agency_zip.substr(0, 5)}`
          .toLowerCase()
          .includes(term.toLowerCase())
      );
    });
  }, [searchValues, agencyCodes]);

  function handleSearch(search: Array<string> | string) {
    // The callback function you provide in the onSearch prop will receive the current array
    // of search terms when a new search term is added or removed from the array.
    if (typeof search !== "string") {
      setSearchValues(search);
    }
  }

  function handleClickSelectAll(actionType: "select" | "deselect") {
    if (actionType === "select") {
      const newValues = filteredAgencyCodes
        .filter((agency) => !values.includes(agency.agency_code))
        .map((agency) => agency.agency_code) as string[];
      onChange([...values, ...newValues]);
    } else {
      onChange([]);
    }
  }

  const agencyCodeCheckboxes = () => {
    return (
      <>
        {filteredAgencyCodes.map((agencyCode: AgencyCode) => (
          <SACheckbox
            key={`input.agencycode_${agencyCode.agency_code}`}
            id={`input.agencycode_${agencyCode.agency_code}`}
            label={`${agencyCode.agency_code} - ${agencyCode.agency_name}, ${agencyCode.agency_city}, ${
              agencyCode.agency_state
            } ${agencyCode.agency_zip.substr(0, 5)}`}
            type="checkbox"
            onChange={(e) => {
              if (e.currentTarget.checked) {
                const newValues = [...values, e.currentTarget.value];
                onChange(newValues);
              } else {
                const newValues = values.filter((value) => value !== e.currentTarget.value);
                onChange([...newValues]);
              }
            }}
            value={agencyCode.agency_code}
            checked={values.includes(agencyCode.agency_code)}
            disabled={agencyCodes.length === 1}
          />
        ))}
      </>
    );
  };

  useEffect(() => {
    if (resetSearchFilter) {
      setSearchValues([]);
    }
  }, [resetSearchFilter]);

  return (
    <>
      <SearchContainer>
        <Search>
          <SASearch
            values={searchValues}
            fullWidth
            id={`searchbar`}
            placeholder="Search by agency code, agency name, city, state, or zip"
            variant="search-terms"
            onSearch={(search: Array<string> | string) => handleSearch(search)}
          />
        </Search>
        {values.length !== agencyCodes.length ? (
          <SelectAllButton
            label="Select All"
            textTransform="uppercase"
            variant="link-medium"
            color="accentBlue"
            onClick={() => handleClickSelectAll("select")}
          />
        ) : (
          <SelectAllButton
            disabled={agencyCodes.length === 1}
            label="Deselect All"
            textTransform="uppercase"
            variant="link-medium"
            color="accentBlue"
            onClick={() => handleClickSelectAll("deselect")}
          />
        )}
      </SearchContainer>
      <AgencyCodesContainer error={error}>
        <span className="heading">Agency Codes</span>
        <AgencyCodesCheckboxes>{agencyCodeCheckboxes()}</AgencyCodesCheckboxes>
      </AgencyCodesContainer>
    </>
  );
}

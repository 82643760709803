import configuration from "../../env_config";
import { Card, Heading, HiddenBox } from "../AgencyAdministration/AgencyAdminStyled";
import { PdfCard } from "../common/commonComponents";

const ADJCommissions = () => {
  const downloadPdf = (url: string) => {
    window.open(url, "_blank");
  };

  const personalLines: { [key: string]: string } = {
    "Legacy Commission Schedules": configuration.PLlegacyCommSched,
    "Connect Commission Schedules": configuration.PLconnectCommSched,
    "Contingent Commission Plan": configuration.PLCommPlan,
  };

  const commercialLines: { [key: string]: string } = {
    "Legacy Commission Schedules": configuration.CLlegacyCommSched,
    "Connect Commission Schedules": configuration.CLconnectCommSched,
    "Contingent Commission Plan": configuration.CLCommPlan,
  };

  const farmNRanch: { [key: string]: string } = {
    "Legacy and Connect Commission Schedule": configuration.FRlegacyCommSched,
    "Legacy and Connect Contingent Commission Plan ": configuration.FRLegNConnectCommPlan,
  };

  return (
    <>
      <HiddenBox height="20px" />
      <Card variant="standard" title="Commission Schedules" removePadding>
        <Heading fontWeight="bold" fontSize="19px" marginTop="20px">
          Personal Lines
        </Heading>
        <PdfCard
          title="File Name"
          subHeading={personalLines}
          downloadPdf={downloadPdf}
        />
        <Heading fontWeight="bold" fontSize="19px" marginTop="40px">
          Commercial Lines
        </Heading>
        <PdfCard
          title="File Name"
          subHeading={commercialLines}
          downloadPdf={downloadPdf}
        />
        <Heading fontWeight="bold" fontSize="19px" marginTop="40px">
          {"Farm & Ranch"}
        </Heading>
        <PdfCard
          title="File Name"
          subHeading={farmNRanch}
          downloadPdf={downloadPdf}
        />
        <HiddenBox height="20px" />
      </Card>
    </>
  );
};

export default ADJCommissions;

type GraphQLConfig = {
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: string;
  aws_appsync_apiKey?: string;
};

type Authentication = {
  aws_project_region: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  federationTarget: string;
};

type OAuth = {
  domain: string;
  scope: string[];
  redirectSignIn: string;
  redirectSignOut: string;
  responseType: string;
};

type AppConfig = {
  saconnect: string;
  'logrocketUrl': string;
  'logrocketId': string;
  'encryption-key': string;
  'session-encryption-enabled': boolean;
  'login-override-enabled': boolean;
  pingInterval: number;
  sweepReports : string;
  clientHistory : string;
  agency_bills : string;
  agencyReports : string;
  claims : string;
  directDeposit: string;
  PLlegacyCommSched: string;
  CLlegacyCommSched: string;
  PLconnectCommSched: string;
  PLCommPlan: string;
  CLconnectCommSched: string;
  CLCommPlan: string;
  FRlegacyCommSched: string;
  FRLegNConnectCommPlan: string;
  CLBusinessOwner : string;
  CLBusinessAuto : string;
  CLGLCoverage : string;
  CLGLClassCodeNDesc: string;
  CLIMClassCodeNDesc: string;
  CLPropCoverage: string;
  CLPropSOI: string;
  CLWorkerComp: string;
  PLAuto: string;
  PLDwellingFire: string;
  PLHomeowner: string;
  DriverDownAttr: string;
  NAIC: string;
  graphql: GraphQLConfig;
  authentication: Authentication;
  oauth: OAuth;
};

const l0: AppConfig = {
  saconnect: 'https://saconnect.stateauto.com',
  'logrocketUrl': 'https://lrocket.stateauto.com',
  'logrocketId': 'stateauto-prod/adjss-l2',
  'encryption-key': '4b9efec03667410998203d6201d31d9c',
  'session-encryption-enabled': false,
  'login-override-enabled': false,
  pingInterval: 60000,
  agency_bills: 'https://saconnect.stateauto.com/?q=agencies',
  sweepReports: 'https://echannel.stateauto.com/agentsite/custserv/tabAgency.asp',
  clientHistory: 'https://echannel.stateauto.com/agentsite/custserv/tabagency.asp',
  agencyReports: 'https://echannel.stateauto.com/agentsite/custserv/tabAgency.asp',
  claims: 'https://echannel.stateauto.com/agentsite/custserv/tabagency.asp',
  directDeposit: 'https://saconnect.stateauto.com/public/files/Direc001.pdf',
  PLlegacyCommSched: 'https://stateauto.widen.net/s/bbzzwzbmlj/smpl-80',
  PLconnectCommSched: 'https://stateauto.widen.net/s/gvxphfgbzq/smpl-34',
  PLCommPlan: 'https://stateauto.widen.net/s/sl78kfpkrq/smpl-33',
  CLlegacyCommSched: 'https://echannel.stateauto.com/agentsite/sales/commissionschedule.asp',
  CLconnectCommSched: 'https://stateauto.widen.net/s/gwcmncgld6/smcl-95',
  CLCommPlan: 'https://stateauto.widen.net/s/ss9g9jmzx6/smcl-94',
  FRlegacyCommSched: 'https://stateauto.widen.net/s/wggdf8vqwl/smfr-24',
  FRLegNConnectCommPlan: 'https://stateauto.widen.net/s/qftfhkq5xs/smfr-23',
  CLBusinessOwner: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Businessowners.pdf',
  CLBusinessAuto: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Business%20Auto.pdf',
  CLGLCoverage: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20GL%20Coverage.pdf',
  CLGLClassCodeNDesc: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20GL%20Class%20Codes%20and%20Descriptions.pdf',
  CLIMClassCodeNDesc: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Inland%20Marine%20Class%20Codes%20and%20Descriptions.pdf',
  CLPropCoverage: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Property%20SOI.pdf',
  CLPropSOI: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Property%20SOI.pdf',
  CLWorkerComp: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Worker%27s%20Compensation.pdf',
  PLAuto: 'https://saconnect.stateauto.com/public/files/Personal_Lines%20Auto_Download%20Code_September_2017.pdf',
  PLDwellingFire: 'https://saconnect.stateauto.com/public/files/Personal%20Lines%20Dwelling%20Fire.pdf',
  PLHomeowner: 'https://saconnect.stateauto.com/public/files/Personal_Lines_Homeowner_Download_Codes_September_2017.pdf',
  DriverDownAttr: 'https://saconnect.stateauto.com/public/files/Driver%20Download%20Attributes.pdf',
  NAIC: 'https://saconnect.stateauto.com/public/files/NAIC%20Codes%20May%202019_0.pdf',
  graphql: {
    aws_appsync_graphqlEndpoint: 'https://suvzeqyj2fdxhd4u7zs6vrydii.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOL'
  },
  authentication: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_62X5E8flv',
    aws_user_pools_web_client_id: '7rjc7m1etj4q44veg526ath2k',
    federationTarget: 'COGNITO_USER_POOLS'
  },
  oauth: {
    domain: 'selfservice-l3.auth.us-east-1.amazoncognito.com',
    scope: [
      'openid',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
    responseType: 'code'
  }
}

const l1: AppConfig = {
  saconnect: 'https://saconnect.stateauto.com',
  'logrocketUrl': 'https://lrocket.stateauto.com',
  'logrocketId': 'stateauto-prod/adjss-l2',
  'encryption-key': '4b9efec03667410998203d6201d31d9c',
  'session-encryption-enabled': true,
  'login-override-enabled': false,
  pingInterval: 60000,
  agency_bills: 'https://saconnect.stateauto.com/?q=agencies',
  sweepReports: 'https://echannel.stateauto.com/agentsite/custserv/tabAgency.asp',
  clientHistory: 'https://echannel.stateauto.com/agentsite/custserv/tabagency.asp',
  agencyReports: 'https://echannel.stateauto.com/agentsite/custserv/tabAgency.asp',
  claims: 'https://echannel.stateauto.com/agentsite/custserv/tabagency.asp',
  directDeposit: 'https://saconnect.stateauto.com/public/files/Direc001.pdf',
  PLlegacyCommSched: 'https://stateauto.widen.net/s/bbzzwzbmlj/smpl-80',
  PLconnectCommSched: 'https://stateauto.widen.net/s/gvxphfgbzq/smpl-34',
  PLCommPlan: 'https://stateauto.widen.net/s/sl78kfpkrq/smpl-33',
  CLlegacyCommSched: 'https://echannel.stateauto.com/agentsite/sales/commissionschedule.asp',
  CLconnectCommSched: 'https://stateauto.widen.net/s/gwcmncgld6/smcl-95',
  CLCommPlan: 'https://stateauto.widen.net/s/ss9g9jmzx6/smcl-94',
  FRlegacyCommSched: 'https://stateauto.widen.net/s/wggdf8vqwl/smfr-24',
  FRLegNConnectCommPlan: 'https://stateauto.widen.net/s/qftfhkq5xs/smfr-23',
  CLBusinessOwner: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Businessowners.pdf',
  CLBusinessAuto: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Business%20Auto.pdf',
  CLGLCoverage: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20GL%20Coverage.pdf',
  CLGLClassCodeNDesc: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20GL%20Class%20Codes%20and%20Descriptions.pdf',
  CLIMClassCodeNDesc: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Inland%20Marine%20Class%20Codes%20and%20Descriptions.pdf',
  CLPropCoverage: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Property%20SOI.pdf',
  CLPropSOI: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Property%20SOI.pdf',
  CLWorkerComp: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Worker%27s%20Compensation.pdf',
  PLAuto: 'https://saconnect.stateauto.com/public/files/Personal_Lines%20Auto_Download%20Code_September_2017.pdf',
  PLDwellingFire: 'https://saconnect.stateauto.com/public/files/Personal%20Lines%20Dwelling%20Fire.pdf',
  PLHomeowner: 'https://saconnect.stateauto.com/public/files/Personal_Lines_Homeowner_Download_Codes_September_2017.pdf',
  DriverDownAttr: 'https://saconnect.stateauto.com/public/files/Driver%20Download%20Attributes.pdf',
  NAIC: 'https://saconnect.stateauto.com/public/files/NAIC%20Codes%20May%202019_0.pdf',
  graphql: {
    aws_appsync_graphqlEndpoint: 'https://e24x43szmnet7e42j56ysbbuwy.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOL'
  },
  authentication: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_rvgqiEICG',
    aws_user_pools_web_client_id: 'tbsb7ma3u00cf8edjneudme2e',
    federationTarget: 'COGNITO_USER_POOLS'
  },
  oauth: {
    domain: 'selfservice-l1.auth.us-east-1.amazoncognito.com',
    scope: [
      'openid',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: 'https://selfservice-l1.msd0007.stateauto.com/',
    redirectSignOut: 'https://selfservice-l1.msd0007.stateauto.com/',
    responseType: 'code'
  }
}
  
const l2: AppConfig = {
  saconnect: 'https://saconnect.stateauto.com',
  'logrocketUrl': 'https://lrocket.stateauto.com',
  'logrocketId': 'stateauto-prod/adjss-l2',
  'encryption-key': '4b9efec03667410998203d6201d31d9c',
  'session-encryption-enabled': false,
  'login-override-enabled': true,
  pingInterval: 60000,
  agency_bills: 'https://saconnect.stateauto.com/?q=agencies',
  sweepReports: 'https://echannel.stateauto.com/agentsite/custserv/tabAgency.asp',
  clientHistory: 'https://echannel.stateauto.com/agentsite/custserv/tabagency.asp',
  agencyReports: 'https://echannel.stateauto.com/agentsite/custserv/tabAgency.asp',
  claims: 'https://echannel.stateauto.com/agentsite/custserv/tabagency.asp',
  directDeposit: 'https://saconnect.stateauto.com/public/files/Direc001.pdf',
  PLlegacyCommSched: 'https://stateauto.widen.net/s/bbzzwzbmlj/smpl-80',
  PLconnectCommSched: 'https://stateauto.widen.net/s/gvxphfgbzq/smpl-34',
  PLCommPlan: 'https://stateauto.widen.net/s/sl78kfpkrq/smpl-33',
  CLlegacyCommSched: 'https://echannel.stateauto.com/agentsite/sales/commissionschedule.asp',
  CLconnectCommSched: 'https://stateauto.widen.net/s/gwcmncgld6/smcl-95',
  CLCommPlan: 'https://stateauto.widen.net/s/ss9g9jmzx6/smcl-94',
  FRlegacyCommSched: 'https://stateauto.widen.net/s/wggdf8vqwl/smfr-24',
  FRLegNConnectCommPlan: 'https://stateauto.widen.net/s/qftfhkq5xs/smfr-23',
  CLBusinessOwner: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Businessowners.pdf',
  CLBusinessAuto: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Business%20Auto.pdf',
  CLGLCoverage: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20GL%20Coverage.pdf',
  CLGLClassCodeNDesc: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20GL%20Class%20Codes%20and%20Descriptions.pdf',
  CLIMClassCodeNDesc: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Inland%20Marine%20Class%20Codes%20and%20Descriptions.pdf',
  CLPropCoverage: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Property%20SOI.pdf',
  CLPropSOI: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Property%20SOI.pdf',
  CLWorkerComp: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Worker%27s%20Compensation.pdf',
  PLAuto: 'https://saconnect.stateauto.com/public/files/Personal_Lines%20Auto_Download%20Code_September_2017.pdf',
  PLDwellingFire: 'https://saconnect.stateauto.com/public/files/Personal%20Lines%20Dwelling%20Fire.pdf',
  PLHomeowner: 'https://saconnect.stateauto.com/public/files/Personal_Lines_Homeowner_Download_Codes_September_2017.pdf',
  DriverDownAttr: 'https://saconnect.stateauto.com/public/files/Driver%20Download%20Attributes.pdf',
  NAIC: 'https://saconnect.stateauto.com/public/files/NAIC%20Codes%20May%202019_0.pdf',
  graphql: {
    aws_appsync_graphqlEndpoint: 'https://2qg7atzrsjbcnkxsj3nsgqb4eu.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOL'
  },
  authentication: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_jboKTEh7g',
    aws_user_pools_web_client_id: '3n8davho0ub6v29dpa8a21g868',
    federationTarget: 'COGNITO_USER_POOLS'
  },
  oauth: {
    domain: 'selfservice-l2.auth.us-east-1.amazoncognito.com',
    scope: [
      'openid',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: 'https://selfservice-l2.msd0007.stateauto.com/',
    redirectSignOut: 'https://selfservice-l2.msd0007.stateauto.com/',
    responseType: 'code'
  }
}

const l3: AppConfig = {
  saconnect: 'https://saconnect.stateauto.com',
  'logrocketUrl': 'https://lrocket.stateauto.com',
  'logrocketId': 'stateauto-prod/adjss-l3',
  'encryption-key': '4b9efec03667410998203d6201d31d9c',
  'session-encryption-enabled': false,
  'login-override-enabled': false,
  pingInterval: 60000,
  agency_bills: 'https://saconnect.stateauto.com/?q=agencies',
  sweepReports: 'https://echannel.stateauto.com/agentsite/custserv/tabAgency.asp',
  clientHistory: 'https://echannel.stateauto.com/agentsite/custserv/tabagency.asp',
  agencyReports: 'https://echannel.stateauto.com/agentsite/custserv/tabAgency.asp',
  claims: 'https://echannel.stateauto.com/agentsite/custserv/tabagency.asp',
  directDeposit: 'https://saconnect.stateauto.com/public/files/Direc001.pdf',
  PLlegacyCommSched: 'https://stateauto.widen.net/s/bbzzwzbmlj/smpl-80',
  PLconnectCommSched: 'https://stateauto.widen.net/s/gvxphfgbzq/smpl-34',
  PLCommPlan: 'https://stateauto.widen.net/s/sl78kfpkrq/smpl-33',
  CLlegacyCommSched: 'https://echannel.stateauto.com/agentsite/sales/commissionschedule.asp',
  CLconnectCommSched: 'https://stateauto.widen.net/s/gwcmncgld6/smcl-95',
  CLCommPlan: 'https://stateauto.widen.net/s/ss9g9jmzx6/smcl-94',
  FRlegacyCommSched: 'https://stateauto.widen.net/s/wggdf8vqwl/smfr-24',
  FRLegNConnectCommPlan: 'https://stateauto.widen.net/s/qftfhkq5xs/smfr-23',
  CLBusinessOwner: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Businessowners.pdf',
  CLBusinessAuto: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Business%20Auto.pdf',
  CLGLCoverage: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20GL%20Coverage.pdf',
  CLGLClassCodeNDesc: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20GL%20Class%20Codes%20and%20Descriptions.pdf',
  CLIMClassCodeNDesc: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Inland%20Marine%20Class%20Codes%20and%20Descriptions.pdf',
  CLPropCoverage: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Property%20SOI.pdf',
  CLPropSOI: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Property%20SOI.pdf',
  CLWorkerComp: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Worker%27s%20Compensation.pdf',
  PLAuto: 'https://saconnect.stateauto.com/public/files/Personal_Lines%20Auto_Download%20Code_September_2017.pdf',
  PLDwellingFire: 'https://saconnect.stateauto.com/public/files/Personal%20Lines%20Dwelling%20Fire.pdf',
  PLHomeowner: 'https://saconnect.stateauto.com/public/files/Personal_Lines_Homeowner_Download_Codes_September_2017.pdf',
  DriverDownAttr: 'https://saconnect.stateauto.com/public/files/Driver%20Download%20Attributes.pdf',
  NAIC: 'https://saconnect.stateauto.com/public/files/NAIC%20Codes%20May%202019_0.pdf',
  graphql: {
    aws_appsync_graphqlEndpoint: 'https://suvzeqyj2fdxhd4u7zs6vrydii.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOL'
  },
  authentication: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_62X5E8flv',
    aws_user_pools_web_client_id: '7rjc7m1etj4q44veg526ath2k',
    federationTarget: 'COGNITO_USER_POOLS'
  },
  oauth: {
    domain: 'selfservice-l3.auth.us-east-1.amazoncognito.com',
    scope: [
      'openid',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: 'https://selfservice-l3.msd0007.stateauto.com/',
    redirectSignOut: 'https://selfservice-l3.msd0007.stateauto.com/',
    responseType: 'code'
  }
}

const l4: AppConfig = {
  saconnect: 'https://saconnect.stateauto.com',
  'logrocketUrl': 'https://lrocket.stateauto.com',
  'logrocketId': 'stateauto-prod/adjss',
  'encryption-key': '933cccffa0974e298bb5063b5e1581d9',
  'session-encryption-enabled': true,
  'login-override-enabled': false,
  pingInterval: 60000,
  agency_bills: 'https://saconnect.stateauto.com/?q=agencies',
  sweepReports: 'https://echannel.stateauto.com/agentsite/custserv/tabAgency.asp',
  clientHistory: 'https://echannel.stateauto.com/agentsite/custserv/tabagency.asp',
  agencyReports: 'https://echannel.stateauto.com/agentsite/custserv/tabAgency.asp',
  claims: 'https://echannel.stateauto.com/agentsite/custserv/tabagency.asp',
  directDeposit: 'https://saconnect.stateauto.com/public/files/Direc001.pdf',
  PLlegacyCommSched: 'https://stateauto.widen.net/s/bbzzwzbmlj/smpl-80',
  PLconnectCommSched: 'https://stateauto.widen.net/s/gvxphfgbzq/smpl-34',
  PLCommPlan: 'https://stateauto.widen.net/s/sl78kfpkrq/smpl-33',
  CLlegacyCommSched: 'https://echannel.stateauto.com/agentsite/sales/commissionschedule.asp',
  CLconnectCommSched: 'https://stateauto.widen.net/s/gwcmncgld6/smcl-95',
  CLCommPlan: 'https://stateauto.widen.net/s/ss9g9jmzx6/smcl-94',
  FRlegacyCommSched: 'https://stateauto.widen.net/s/wggdf8vqwl/smfr-24',
  FRLegNConnectCommPlan: 'https://stateauto.widen.net/s/qftfhkq5xs/smfr-23',
  CLBusinessOwner: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Businessowners.pdf',
  CLBusinessAuto: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Business%20Auto.pdf',
  CLGLCoverage: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20GL%20Coverage.pdf',
  CLGLClassCodeNDesc: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20GL%20Class%20Codes%20and%20Descriptions.pdf',
  CLIMClassCodeNDesc: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Inland%20Marine%20Class%20Codes%20and%20Descriptions.pdf',
  CLPropCoverage: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Property%20SOI.pdf',
  CLPropSOI: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Property%20SOI.pdf',
  CLWorkerComp: 'https://saconnect.stateauto.com/public/files/Commercial%20Lines%20Worker%27s%20Compensation.pdf',
  PLAuto: 'https://saconnect.stateauto.com/public/files/Personal_Lines%20Auto_Download%20Code_September_2017.pdf',
  PLDwellingFire: 'https://saconnect.stateauto.com/public/files/Personal%20Lines%20Dwelling%20Fire.pdf',
  PLHomeowner: 'https://saconnect.stateauto.com/public/files/Personal_Lines_Homeowner_Download_Codes_September_2017.pdf',
  DriverDownAttr: 'https://saconnect.stateauto.com/public/files/Driver%20Download%20Attributes.pdf',
  NAIC: 'https://saconnect.stateauto.com/public/files/NAIC%20Codes%20May%202019_0.pdf',
  graphql: {
    aws_appsync_graphqlEndpoint: 'https://2ypo3wfyxjbw7nwpef2h466ete.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOL'
  },
  authentication: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_WpNfTOLku',
    aws_user_pools_web_client_id: '180108i0dnv5l2npd7lsgrdcc3',
    federationTarget: 'COGNITO_USER_POOLS'
  },
  oauth: {
    domain: 'selfservice-l4.auth.us-east-1.amazoncognito.com',
    scope: [
      'openid',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: 'https://selfservice-l4.msp0007.stateauto.com/',
    redirectSignOut: 'https://selfservice-l4.msp0007.stateauto.com/',
    responseType: 'code'
  }
}

const windowRef: any = window;
const env: string = windowRef?.config?.env || process.env.REACT_APP_STAGE;
console.log("env = " + env);

let appConfig: AppConfig;

switch (env?.toLowerCase()) {
  case 'l0':
    appConfig = l0;
    break;
  case 'l1':
    appConfig = l1;
    break;
  case 'l2':
    appConfig = l2;
    break;
  case 'l3':
    appConfig = l3;
    break;
  case 'l4':
    appConfig = l4;
    break;
  default:
    appConfig = l1;
}

const config = appConfig;

const configuration = { ...config };

export default configuration;

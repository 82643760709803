/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendAddProducerEmail = /* GraphQL */ `
  mutation SendAddProducerEmail($body: SendAddProducerEmailBody) {
    SendAddProducerEmail(body: $body) {
      body {
        message
        status
      }
      statusCode
    }
  }
`;
export const updatePersonAddress = /* GraphQL */ `
  mutation UpdatePersonAddress(
    $body: updatePersonAddressBody
    $person_id: String!
  ) {
    UpdatePersonAddress(body: $body, person_id: $person_id) {
      body {
        message
        status
      }
      statusCode
    }
  }
`;
export const updatePersonPrivileges = /* GraphQL */ `
  mutation UpdatePersonPrivileges(
    $body: updatePersonPrivilegesBody
    $person_id: String!
  ) {
    UpdatePersonPrivileges(body: $body, person_id: $person_id) {
      body {
        message
        status
      }
      statusCode
    }
  }
`;
export const createServiceNowRequest = /* GraphQL */ `
  mutation CreateServiceNowRequest($body: String!) {
    createServiceNowRequest(body: $body) {
      body {
        result {
          sys_created_on
          sys_row_error
        }
      }
      statusCode
    }
  }
`;
export const personAddDetails = /* GraphQL */ `
  mutation PersonAddDetails($body: personAddDetailsBody) {
    personAddDetails(body: $body) {
      body {
        data {
          contact_id
          status
        }
        message
        status
      }
      statusCode
    }
  }
`;
export const postPersonPendingStatus = /* GraphQL */ `
  mutation PostPersonPendingStatus($body: postPersonPendingStatusBody) {
    postPersonPendingStatus(body: $body) {
      body {
        pending_persons {
          pending_status
          person_id
        }
      }
      statusCode
    }
  }
`;
export const terminateProducerAppointments = /* GraphQL */ `
  mutation TerminateProducerAppointments($body: terminateProducerAppointments) {
    terminateProducerAppointments(body: $body) {
      body {
        message
        status
      }
      statusCode
    }
  }
`;
export const updatePendinPersonBusinessAddress = /* GraphQL */ `
  mutation UpdatePendinPersonBusinessAddress(
    $body: updatePendingPersonAddressBody
    $person_id: String!
  ) {
    updatePendinPersonBusinessAddress(body: $body, person_id: $person_id) {
      body {
        message
        status
      }
      statusCode
    }
  }
`;
export const updatePendingPersonContactType = /* GraphQL */ `
  mutation UpdatePendingPersonContactType(
    $body: PendingPersonContactTypeBody
    $person_id: String!
  ) {
    updatePendingPersonContactType(body: $body, person_id: $person_id) {
      body {
        agency_codes
        contact_type
        message
        reason
        status
      }
      statusCode
    }
  }
`;
export const updatePendingPersonGeneralDetails = /* GraphQL */ `
  mutation UpdatePendingPersonGeneralDetails(
    $body: updatePendingPersonGeneralDetailsBody
    $person_id: String!
  ) {
    updatePendingPersonGeneralDetails(body: $body, person_id: $person_id) {
      body {
        message
        status
      }
      statusCode
    }
  }
`;
export const updatePendingPersonMailingAddress = /* GraphQL */ `
  mutation UpdatePendingPersonMailingAddress(
    $body: updatePendingPersonAddressBody
    $person_id: String!
  ) {
    updatePendingPersonMailingAddress(body: $body, person_id: $person_id) {
      body {
        message
        status
      }
      statusCode
    }
  }
`;
export const updatePendingPersonPrivileges = /* GraphQL */ `
  mutation UpdatePendingPersonPrivileges(
    $body: updatePendingPersonPrivilegesBody
    $person_id: String!
  ) {
    updatePendingPersonPrivileges(body: $body, person_id: $person_id) {
      body {
        message
        status
      }
      statusCode
    }
  }
`;
export const updatePendingPersonResidentialAddress = /* GraphQL */ `
  mutation UpdatePendingPersonResidentialAddress(
    $body: updatePendingPersonAddressBody
    $person_id: String!
  ) {
    updatePendingPersonResidentialAddress(body: $body, person_id: $person_id) {
      body {
        message
        status
      }
      statusCode
    }
  }
`;
export const updatePendingPersonsTerminate = /* GraphQL */ `
  mutation UpdatePendingPersonsTerminate(
    $body: updatePersonTerminate
    $person_id: String!
  ) {
    updatePendingPersonsTerminate(body: $body, person_id: $person_id) {
      body {
        message
      }
      statusCode
    }
  }
`;
export const updatePersonCommunicationPref = /* GraphQL */ `
  mutation UpdatePersonCommunicationPref(
    $body: updatePersonCommunicationPrefBody
    $person_id: String!
  ) {
    updatePersonCommunicationPref(body: $body, person_id: $person_id) {
      body {
        message
        status
      }
      statusCode
    }
  }
`;

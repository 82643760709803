import React, { useEffect, useState, useMemo } from 'react';
import {
  SACard,
  SAText,
  SAUXTheme
} from '@saux/design-system-react';
import styled from 'styled-components';
import {
  NewContact,
  NewContactContactType,
  AgencyCode
} from '../../interfaces/interfaces';
import normalizePhoneNumber from '../../utils/utils';
import { decrypt } from '../../utils/crypto';

type sidecardProps = {
  newContactData?: NewContact;
}

type StyleProps = {
  theme?: SAUXTheme;
};

const SideCard = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      span {
        color: ${theme?.colors.text};
        font-size: ${theme?.font.primary.size.small};
        font-weight: ${theme?.font.primary.weight.bold};
      } 

      p {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    
        &:first-child {
          margin-top: 0;
        }
      }

      div {
        overflow: hidden;
        text-overflow:ellipsis;
      }

      @media only screen and (max-width: 1024px) {
        display: none;
      }
    `;
  }};
`;

const ContactDetails = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      background-color: rgba(216, 223, 236, 0.25);
      padding: ${theme?.size.spacer.medium};
      margin: -${theme?.size.spacer.medium} -${theme?.size.spacer.medium} 0 -${theme?.size.spacer.medium};
    `;
  }};
`;

const ContactName = styled.span`
  ${({ theme }: StyleProps) => {
    return `
      span {
        color: ${theme?.colors.text};
        font-size: ${theme?.font.primary.size.mediumTEMP};
        line-height: 23px;
      }
    `;
  }};
`;

const LoginId = styled.span`
  ${({ theme }: StyleProps) => {
    return `
      small {
        color: ${theme?.colors.text};
        font-size: ${theme?.font.primary.size.smallTEMP};
        line-height: 21px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `;
  }};
`;

const Info = styled.span`
  ${({ theme }: StyleProps) => {
    return `
      span {
        color: ${theme?.colors.text};
        font-size: ${theme?.font.primary.size.medium};
        font-weight: ${theme?.font.primary.weight.normal};
        line-height: 21px;
      }
    `;
  }};
`;

const Ellipses = styled.span`
  ${({ theme }: StyleProps) => {
    return `
      overflow: hidden;
      text-overflow:ellipsis;

      &.spanfont {
        font-size: calc(${theme?.font.primary.size.mediumTEMP} - 2px);
        font-weight: ${theme?.font.primary.weight.normal};
      }
    `;
  }};
`;

export default function ContactDetailsSideCard({ newContactData }: sidecardProps) {
  const [newContact, setNewContact] = useState<NewContact>(newContactData ? newContactData : JSON.parse(decrypt(localStorage.getItem('NewContact'))));
  const primaryAgency: string = getPrimaryAgency();
  const hasLicenseProducer = useMemo(
    () => newContact.contactDetails.contactTypes.some((agency) => agency.contacttype === 37),
    [newContact]
  );
  function getPrimaryAgency() {
    let primaryAgency: string = '';
    if (newContact.contactDetails.primaryAgencyCode !== '') {
      const agencyCodesArray: AgencyCode[] = JSON.parse(decrypt(localStorage.getItem('AddContactAgencyCodes')));
      agencyCodesArray.forEach((ac: AgencyCode) => {
        if (ac.agency_code === newContact.contactDetails.primaryAgencyCode) {
          primaryAgency = `${ac.agency_code} - ${ac.dba !== '' ? ac.dba : ac.agency_name}, ${ac.agency_city}, ${ac.agency_state} ${ac.agency_zip.substr(0, 5)}`;
          return;
        }
      });
    }
    return primaryAgency;
  }

  useEffect(() => {
    console.log('newContact', newContact);
  }, [newContact]);

  return (
    <SideCard variant="minimal">
      <ContactDetails>
        <p>
          <span>Contact Email</span>
          <Ellipses className="spanfont" title={newContact.contactDetails.email}>{newContact.contactDetails.email}</Ellipses>
        </p>
        {newContact.contactDetails.contactTypes.length > 0 &&
          <p>
            <span>Contact Types</span>
            {newContact.contactDetails.contactTypes.map((ct: NewContactContactType, index: number) => {
              return ((index + 1) < newContact.contactDetails.contactTypes.length) ? ct.description + ', ' : ct.description;
            })}
          </p>
        }
      </ContactDetails>
      {newContact.contactDetails.generalDetails.firstName !== '' &&
        newContact.contactDetails.generalDetails.lastName !== '' &&
        newContact.contactDetails.generalDetails.loginId !== '' &&
        <p>
          <ContactName>
            <SAText type="standard" text={`${newContact.contactDetails.generalDetails.firstName} ${newContact.contactDetails.generalDetails.lastName}`} />
          </ContactName>
          <LoginId>
            <span title={newContact.contactDetails.generalDetails.loginId}><SAText type="small" text={`Login ID: ${newContact.contactDetails.generalDetails.loginId}`} /></span>
          </LoginId>
        </p>
      }
      {newContact.contactDetails.generalDetails.primaryPhone !== '' &&
        <p>
          <span>Primary Phone</span>
          <Info>
            <SAText type="standard" text={`${normalizePhoneNumber(newContact.contactDetails.generalDetails.primaryPhone)}`} />
          </Info>
        </p>
      }
      {newContact.contactDetails.primaryAgencyCode !== '' && !hasLicenseProducer &&
        <p>
          <span>Primary Agency Code</span>
          <Info>
            <SAText type="standard" text={`${primaryAgency}`} />
          </Info>
        </p>
      }
    </SideCard>
  );
}

import { API, graphqlOperation } from 'aws-amplify';
import { DatafluxAddressQuery } from '../API';
import * as queries from '../graphql/queries';
import { decrypt } from './crypto';
import handleGraphQLErrors from './handleGraphQLErrors';
import { checkAuthStatus } from './utils';

const addressScrubber = async (addressLine1: string, zip: string) => {
  const isAuthenticated: boolean = await checkAuthStatus();
  if (!isAuthenticated) {
    return;
  }
  const rst = await (API.graphql(
    graphqlOperation(
      queries.datafluxAddress,
      {
        body: {
          address1: addressLine1,
          zip: zip
        }
      }
    ),
    {
      Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
    }
  ) as Promise<{ data: DatafluxAddressQuery }>)
  .then((res) => {
    if (res.data) {
      return res.data.datafluxAddress;
    } else {
      handleGraphQLErrors(res);
    }
  })
  .then((res) => {
    if (res) {
      if (res?.statusCode === 200) {
        return res.body;
      }
    } else {
      throw new Error('Something went wrong - 51');
    }
  })
  .catch((err) => {
    if (err?.message) {
      console.error(err.message, err); 
    } else {
      handleGraphQLErrors(err);
    }
    window.location.href = "error";
  });
  return rst;
}

export default addressScrubber;

import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import {
  SACard,
  SAButton,
  SASpinner,
  SAUXTheme,
  SAUX360Theme,
  SAText,
  SAInput
} from '@saux/design-system-react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  Agency,   
  LoginIDInfo,
  PersonInfo,
  Person
} from '../../interfaces/interfaces';
import { decrypt, encrypt } from '../../utils/crypto';
import { constants } from '../../utils/constants';
import * as queries from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { GetPersonDetailsQuery, PersonLookupLoginIdDetailsQuery } from '../../API';
import handleGraphQLErrors from '../../utils/handleGraphQLErrors';
import { checkAuthStatus } from '../../utils/utils';


type StyleProps = {
  theme?: SAUXTheme;
  offset?: number;
};

const SearchAgencyContactCard = styled(SACard).attrs(({offset}: StyleProps) => ({
 offset
}))`
  ${({ theme, offset }: StyleProps) => {
    return `
      display: flex;
      flex-grow: 1;
      max-width: 600px;
      margin-top: ${offset}px;
      margin-bottom: 60px;
      margin-left: auto;
      margin-right: auto;
    `;
  }};
`;

const CardHeader = styled.div`
  padding-bottom: 45px;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    } 
  }
`;

const CardSubTitle = styled.div``;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;
      
      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }
    `;
  }};
`;

const SpinnerContainer = styled.div`
  margin-left: 10px;
  svg:last-child {
    margin-left: 0;
  }
`;

const DisabledSearchButton = styled(SAButton)`
  ${({ theme }: StyleProps) => {
    return `
      color: ${theme?.colors.white};
      background-color: ${theme?.colors.primary};

      &:hover {
        background-color: ${theme?.colors.primary};
      }
    `;
  }};
`;

const Search = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      button {
        margin-top: 4px;
        margin-left: 30px;
      }

      .saInputWrapper {
        display: flex;
        flex-grow: 1;
      }

      .textLikeInput {
        font-size: ${theme?.font.primary.size.medium};
      }
    `;
  }};
`;

const SearchResults = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      color: ${theme?.colors.text};
      background-color: #F4F6FA;
      border-radius: 5px;
      padding: 20px;
    `;
  }};
`;

const Input = styled(SAInput)`
  display: flex;
  flex-grow: 1;
`;

const Title = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      font-size: ${theme?.font.primary.size.medium};
      font-weight: ${theme?.font.primary.weight.bold};
      line-height: 19px;
    `;
  }};
`;

const Subtitle = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      margin-top: 10px;
      font-size: ${theme?.font.primary.size.smallTEMP};
      font-weight: ${theme?.font.primary.weight.normal};
      line-height: 16px;
    `;
  }};
`;

const Column = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      margin-right: 64px;
      color: ${theme?.colors.text};

      div:first-child {
        font-size: ${theme?.font.primary.size.smallTEMP};
        font-weight: ${theme?.font.primary.weight.normal};
        line-height: 16px;
      }

      div:last-child {
        font-size: ${theme?.font.primary.size.medium};
        font-weight: ${theme?.font.primary.weight.bold};
        line-height: 19px;
      }
    `;
  }};
`;

const Section = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 20px;
`;

const Nowrap = styled.div`
  white-space: nowrap;
`;

export default function SearchAgencyContact(props: any) {
  const [ personInfo, setPersonInfo ] = useState<PersonInfo | null>(null);
  const [ isAdmin, setIsAdmin ] = useState<boolean>(false);
  const [ noMatch, setNoMatch ] = useState<boolean>(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = (data: any) => validateForm(data);
  const history = useHistory();
  const { navigateTo } = (history.location?.state as { navigateTo: string }) ?? {};
  const redirectURL = decrypt(localStorage.getItem('navigatedFrom'));

  // Spinner
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const spinner = (
    <SpinnerContainer>
      <SASpinner variant="circular-continuous" delay={0} color={SAUX360Theme.colors.blueGray600} size="20px" />
    </SpinnerContainer>
  );

  async function validateForm(data: { loginID: string }) {
    setPersonInfo(null);
    setNoMatch(false);
    setShowSpinner(false);

    const delay = setTimeout(() => {
      setShowSpinner(true);
    }, 1000);

    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseLoginIdInfo = API.graphql(
      graphqlOperation(
        queries.personLookupLoginIdDetails,
        {
          login_id: data.loginID
        }
      ),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
      }
    ) as Promise<{ data: PersonLookupLoginIdDetailsQuery }>;

    promiseLoginIdInfo.then((res) => {
      if (res.data) {
        return res.data.personLookupLoginIdDetails;
      } else {
        handleGraphQLErrors(res);
      }
    })
    .then((res) => {
      if (res?.statusCode === 200) {
        return res.body as LoginIDInfo | null | undefined;
      } else if (res?.statusCode === 400) {
        setShowSpinner(false);
        clearTimeout(delay);
        setNoMatch(true);
        return res.body as LoginIDInfo | null | undefined;
      } else {
        return null;
      }
    })
    .then(async (res) => {
      if (res) {
        if (res.persons && res.persons.length) {
          const newPersonInfo: PersonInfo = res.persons[0];

          const isAuthenticated: boolean = await checkAuthStatus();
          if (!isAuthenticated) {
            return;
          }
          const promisePerson = API.graphql(
            graphqlOperation(
              queries.getPersonDetails, 
              {
                person_id: newPersonInfo?.person_id 
              }
            ),
            {
              Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
            }
          ) as Promise<{ data: GetPersonDetailsQuery }>;

          promisePerson.then((res) => {
            if (res.data) {
              return res.data.getPersonDetails;
            } else {
              handleGraphQLErrors(res);
            }
          })
          .then((res) => {
            if (res?.statusCode === 200) {
              return res.body as Person | null;
            } else {
              throw new Error('Something went wrong - 41');
            }
          })
          .then((res: Person | null) => {
            if (res) {
              setIsAdmin(false);
              
              localStorage.setItem('LoginIDInfo', encrypt(JSON.stringify(newPersonInfo)));
              setPersonInfo(newPersonInfo);
              
              setShowSpinner(false);
              clearTimeout(delay);
        
              res.agency_code_map.forEach((agency: Agency) => {
                agency.contact_type?.forEach((ct: string) => {
                  if (ct === "3" || ct === "6") {
                    // 3 - Agency Admin
                    // 6 - Principal
                    setIsAdmin(true);
                  }
                });
              });
            } else {
              throw new Error ('Something went wrong - 42');
            }
          })
          .catch((err) => {
            if (err?.message) {
              console.error(err.message, err); 
            } else {
              handleGraphQLErrors(err);
            }
            window.location.href = "error";
          });

        } else {
          setShowSpinner(false);
          clearTimeout(delay);
        }
      } else {
        throw new Error('Something went wrong - 43');
      }
    })
    .catch((err) => {
      setShowSpinner(false);
      clearTimeout(delay);
      if (err?.message) {
        console.error(err.message, err); 
      } else {
        handleGraphQLErrors(err);
      }
      window.location.href = "error";
    });
  }

  function handleClickContinue() {
    localStorage.removeItem('locationId');
    localStorage.removeItem('pageIndex');
    localStorage.removeItem('FilterSearchTerms');
    localStorage.removeItem('AddContactAgencyCodes');
    localStorage.removeItem('FilterContactType');
    localStorage.removeItem('FilterLocation');
    localStorage.setItem('personInfo', encrypt(JSON.stringify(personInfo)));
    localStorage.setItem('personId', encrypt(personInfo?.person_id as string));
    localStorage.setItem('isAdmin', encrypt(isAdmin.toString()));
    
    if (isAdmin) {
      localStorage.removeItem('selectedPersonId');
      localStorage.removeItem('selectedFirstName');
      localStorage.removeItem('selectedLastName');
      localStorage.removeItem('selectedLoginId');
      if ((navigateTo && navigateTo === "/contact-listing") || redirectURL === '/contact-listing') {
        history.push({
          pathname: navigateTo || redirectURL,
        });
      } else {
        history.push({
          pathname: `/agency-contacts`,
        });
      } 
    } else {
      localStorage.setItem('selectedPersonId', encrypt(personInfo?.person_id as string));
      localStorage.setItem('selectedFirstName', encrypt(personInfo?.person_first_name as string));
      localStorage.setItem('selectedLastName', encrypt(personInfo?.person_last_name as string));
      localStorage.setItem('selectedLoginId', encrypt(personInfo?.person_login_id as string));
      if ((navigateTo && navigateTo === "/contact-listing") || redirectURL === '/contact-listing') {
        history.push({
          pathname: navigateTo || redirectURL,
        });
      } else {
        history.push({
          pathname: `/agency-contact-profile`,
        });
      }
    } 
  }

  return (
    <SearchAgencyContactCard variant="minimal" offset={constants.headerHeight + constants.dockedWarningHeight + constants.verticalPageOffset}>
      <form id="searchagencycontactform" onSubmit={handleSubmit(onSubmit)} autoComplete="none">
        <CardHeader>
          <CardTitle>
            <SAText type="heading-2" text="Search Agency Contact" />
          </CardTitle>
          <CardSubTitle>
            <SAText type="standard" text="Enter the login id of the agency contact" />
          </CardSubTitle>
        </CardHeader>
        <CardBody>
          <Search>
            <Input
              {...register("loginID", {
                required: 'Login ID is required'
              })}
              autoFocus
              fullWidth
              id="loginid"
              name="loginID"
              label="Login ID"
              hint={errors.loginID?.message as string | undefined}
              type="text"
              error={errors.loginID as boolean | undefined}
            />
            {!showSpinner &&
              <SAButton label="Search" type="submit" variant="secondary-large" textTransform="uppercase" />
            }
            {showSpinner &&
              <DisabledSearchButton endIcon={spinner} disabled label="Search" type="submit" variant="secondary-large" textTransform="uppercase" />
            }
          </Search>
          {(personInfo || noMatch) &&
            <SearchResults>
              {!noMatch &&
                <Title>Agency Contact Details</Title>
              }
              {noMatch &&
                <>
                  <Title>No agency contact found with the above Login ID</Title>
                  <Subtitle>Try searching again with a different Login ID</Subtitle>
                </>
              }
              {!noMatch &&
                <Section>
                  <Column>
                    <div>
                      On Behalf Of
                    </div>
                    <Nowrap>
                      {personInfo?.person_first_name} {personInfo?.person_last_name}
                    </Nowrap>
                  </Column>
                  <Column>
                    <div>
                      Email ID
                    </div>
                    <div>
                      {personInfo?.person_email}
                    </div>
                  </Column>
                </Section>
              }
            </SearchResults>
          }
        </CardBody>
        {personInfo &&
          <CardFooter>
            <SAButton label="Continue" onClick={handleClickContinue} variant="secondary-large" color="secondary" textTransform="uppercase" />
          </CardFooter>
        }
      </form>
    </SearchAgencyContactCard>
  );
}

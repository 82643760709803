const statesJson = [
    {
        "label": "AL",
        "name": "Alabama",
        "value": "AL"
    },
    {
        "label": "AK",
        "name": "Alaska",
        "value": "AK"
    },
    {
        "label": "AZ",
        "name": "Arizona",
        "value": "AZ"
    },
    {
        "label": "AR",
        "name": "Arkansas",
        "value": "AR"
    },
    {
        "label": "CA",
        "name": "California",
        "value": "CA"
    },
    {
        "label": "CO",
        "name": "Colorado",
        "value": "CO"
    },
    {
        "label": "CT",
        "name": "Connecticut",
        "value": "CT"
    },
    {
        "label": "DE",
        "name": "Delaware",
        "value": "DE"
    },
    {
        "label": "DC",
        "name": "District Of Columbia",
        "value": "DC"
    },
    {
        "label": "FL",
        "name": "Florida",
        "value": "FL"
    },
    {
        "label": "GA",
        "name": "Georgia",
        "value": "GA"
    },
    {
        "label": "HI",
        "name": "Hawaii",
        "value": "HI"
    },
    {
        "label": "ID",
        "name": "Idaho",
        "value": "ID"
    },
    {
        "label": "IL",
        "name": "Illinois",
        "value": "IL"
    },
    {
        "label": "IN",
        "name": "Indiana",
        "value": "IN"
    },
    {
        "label": "IA",
        "name": "Iowa",
        "value": "IA"
    },
    {
        "label": "KS",
        "name": "Kansas",
        "value": "KS"
    },
    {
        "label": "KY",
        "name": "Kentucky",
        "value": "KY"
    },
    {
        "label": "LA",
        "name": "Louisiana",
        "value": "LA"
    },
    {
        "label": "ME",
        "name": "Maine",
        "value": "ME"
    },
    {
        "label": "MD",
        "name": "Maryland",
        "value": "MD"
    },
    {
        "label": "MA",
        "name": "Massachusetts",
        "value": "MA"
    },
    {
        "label": "MI",
        "name": "Michigan",
        "value": "MI"
    },
    {
        "label": "MN",
        "name": "Minnesota",
        "value": "MN"
    },
    {
        "label": "MS",
        "name": "Mississippi",
        "value": "MS"
    },
    {
        "label": "MO",
        "name": "Missouri",
        "value": "MO"
    },
    {
        "label": "MT",
        "name": "Montana",
        "value": "MT"
    },
    {
        "label": "NE",
        "name": "Nebraska",
        "value": "NE"
    },
    {
        "label": "NV",
        "name": "Nevada",
        "value": "NV"
    },
    {
        "label": "NH",
        "name": "New Hampshire",
        "value": "NH"
    },
    {
        "label": "NJ",
        "name": "New Jersey",
        "value": "NJ"
    },
    {
        "label": "NM",
        "name": "New Mexico",
        "value": "NM"
    },
    {
        "label": "NY",
        "name": "New York",
        "value": "NY"
    },
    {
        "label": "NC",
        "name": "North Carolina",
        "value": "NC"
    },
    {
        "label": "ND",
        "name": "North Dakota",
        "value": "ND"
    },
    {
        "label": "OH",
        "name": "Ohio",
        "value": "OH"
    },
    {
        "label": "OK",
        "name": "Oklahoma",
        "value": "OK"
    },
    {
        "label": "OR",
        "name": "Oregon",
        "value": "OR"
    },
    {
        "label": "PW",
        "name": "Palau",
        "value": "PW"
    },
    {
        "label": "PA",
        "name": "Pennsylvania",
        "value": "PA"
    },
    {
        "label": "PR",
        "name": "Puerto Rico",
        "value": "PR"
    },
    {
        "label": "RI",
        "name": "Rhode Island",
        "value": "RI"
    },
    {
        "label": "SC",
        "name": "South Carolina",
        "value": "SC"
    },
    {
        "label": "SD",
        "name": "South Dakota",
        "value": "SD"
    },
    {
        "label": "TN",
        "name": "Tennessee",
        "value": "TN"
    },
    {
        "label": "TX",
        "name": "Texas",
        "value": "TX"
    },
    {
        "label": "UT",
        "name": "Utah",
        "value": "UT"
    },
    {
        "label": "VT",
        "name": "Vermont",
        "value": "VT"
    },
    {
        "label": "VA",
        "name": "Virginia",
        "value": "VA"
    },
    {
        "label": "WA",
        "name": "Washington",
        "value": "WA"
    },
    {
        "label": "WV",
        "name": "West Virginia",
        "value": "WV"
    },
    {
        "label": "WI",
        "name": "Wisconsin",
        "value": "WI"
    },
    {
        "label": "WY",
        "name": "Wyoming",
        "value": "WY"
    }
]

export default statesJson
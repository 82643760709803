import {
  SACard,
  SAUXTheme,
} from '@saux/design-system-react';
import styled from 'styled-components';

type StyleProps = {
  theme?: SAUXTheme;
};

export const HeadingTab = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: ${theme?.size.spacer.large};
    `;
  }}
`;

export const SATypography = styled.div`
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
`;

export const SAReport = styled(SACard)`
  margin-top: 13px;
  font-size: 50rem !important;
`;

export const SAConnectCard = styled(SACard)`
  margin-bottom: 25px;
`;

interface SAHypeLinksProps {
  bold?: boolean;
  hide?: boolean;
}

interface AgencyCardProps {
  hide?: boolean;
}

interface IHiddenProps {
  height?: string;
  width?: string;
}

interface IHeading {
  fontWeight?: string;
  fontSize?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  color?: string;
  cursor?: string;
  lineHeight?: string;
}

interface IHr{
  borderTop?:string;
  borderRadius?:string;
}

interface IFlexBoxProps{
  flexDirection?: string;
  alignContent?: string;
  justifyContent?: string;
  alignItems?: string;
  cursor?: string;
  height?: string;
}

export const BlueCardRow = styled.div`
  display: flex;
  flex-direction: row;

  &.bluecardtext {
    div:first-child {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      margin-top: 15px;
      margin-right: 15px;
      color: white;
    }

    div:last-child {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      width: 200px;
    }
  }

  &.bluecardfullwidth {
    margin-top: 15px;
    margin-right: 30px;
    color: white;
  }

  &.bluecardbutton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    div {
      width: 180px;
    }
  }
`;

export const BlueCardButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;

export const FlexBox = styled.div<IFlexBoxProps>`
  display: flex;
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : "row")};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "flex-start")};
  align-content: ${(props) => (props.alignContent ? props.alignContent : "stretch")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'initial')};
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
  height: ${(props) => (props.height ? props.height : "auto")};
`;

export const Heading = styled.div<IHeading>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "auto")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "auto")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "auto")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "auto")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "auto")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "auto")};
  color: ${(props) => (props.color ? props.color : "auto")};
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "auto")};
`;

export const Card = styled(SACard)`
  flex-grow: 1;
  
  section {
    padding: 20px;
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding-right: 10px;
  padding-left: 10px;

  &:first-child {
    padding-right: 10px;
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
    padding-left: 10px;
  }
`;

export const HiddenBox = styled.div<IHiddenProps>`
  height: ${(props) => (props.height ? props.height : "auto")};
  width: ${(props) => (props.width ? props.width : "auto")};
`;

export const SAHr = styled.hr<IHr>`
border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "auto")};
border-top: ${(props) => (props.borderTop ? props.borderTop : "auto")};
`

export const SAHypeLinks = styled.div<SAHypeLinksProps>`
  /* LABEL */

  visibility: ${(props) => (props.hide ? "hidden" : "visible")};
  cursor: pointer;

  /* Bold/200 Bold */

  font-style: normal;
  font-weight: ${(props) => (props.bold ? "700" : "0")};
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  /* Primary/Primary State Auto Blue */
  color: #0173c6;
`;

export const AgencyCard = styled(SACard)<AgencyCardProps>`
  display: flex;
  // height: 190px;

  /* Accents/Accent Blue/900 Accent Blue */
  background: #005c86;
  
  /* Card Drop Shadow */
  box-shadow: 0px 2px 5px rgba(1, 83, 142, 0.2);
  
  border-radius: 10px;

  section {
    padding: 20px;
  }
`;

export const AgencyCardHead = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #ffffff;
`;

export const ContactDetails = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin-left: 10px;
  margin-top: 3.2px;
  /* identical to box height, or 21px */

  /* Primary/Primary State Auto Blue */

  color: #0173c6;
`;

import React, { useState, useEffect } from "react";
import {
  SACard,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
  SARadioGroup,
  SARadio,
  SASearch,
  SAUXTheme
} from '@saux/design-system-react';
import { LocationKeyPair } from '../../../interfaces/interfaces';
import styled from 'styled-components';
import { decrypt } from "../../../utils/crypto";

const mobileWidth = 600;

type ChangeLocationProps = {
  locations: LocationKeyPair[];
  onClickCancel?: Function;
  onClickSubmit: Function;
  selectedLocation: string;
};

type StyleProps = {
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const ChangeLocation = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 600px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 45px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    }
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      display: flex;
    }
  }
`;

const ModalBody = styled.div`
  legend {
    display: none;
  }
`;

const ModalFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
  }};
`;

const RadioGroup = styled(SARadioGroup)`
  ${({ theme }: StyleProps) => {
    return `
      margin-top: ${theme?.size.spacer.medium};
      border: rgb(217,223,235) solid 1px;
      padding: 6px 30px 6px 6px;
      max-height: 288px;
      overflow-y: auto;

      label {
        color: ${theme?.colors.text};
        overflow-y: hidden;
        padding: 8px 0px 8px 38px;
        margin-bottom: 0;

        &:not(.checked) {
          padding-left: 38px;
        }
      }

      div.radio {
        top: 9px;
        left: 8px;
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 92px;
        border: none;
      }
    
      &::-webkit-scrollbar-track {
        background: #F0EFEF;
        border-radius: 7px;
      }
    
      &::-webkit-scrollbar-thumb {
        background: #8A8A8A;
        border-radius: 7px;
        box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      }
    `;
  }};
`;

const SearchContainer = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      .saInputWrapper {
        margin-top: 0;
        font-size: ${theme?.font.primary.size.medium};
      }

      ul {
        align-items: center;
        span {
          button {
            margin-bottom: 0;
          }
        }
        button {
          margin-bottom: 5px;
        }
      }
    `;
  }};
`;

export default function ModalChangeLocation({ locations, onClickCancel, onClickSubmit, selectedLocation }: ChangeLocationProps) {
  const [location, setLocation] = useState<string>(selectedLocation);
  const [filteredLocations, setFilteredLocations] = useState<LocationKeyPair[]>(locations);
  const filterLocation: string | null = decrypt(localStorage.getItem('FilterLocation'));

  function handleClickChangeLocationModal(event: React.MouseEvent) {
    event.stopPropagation();

    // get the card element inside the modal
    const cardElement = document.querySelector('#modal > div > article > section');

    // close modal when clicking outside of the card element
    if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
      if (onClickCancel) {
        onClickCancel(event);
      }
    }
  }

  function handleClickCancel(event: React.MouseEvent) {
    event.stopPropagation();
    if (onClickCancel) {
      onClickCancel(event);
    }
  }

  function handleClickSubmit(event: React.MouseEvent) {
    event.stopPropagation();
    onClickSubmit(event, location);
  }

  function handleRadioChange(event: any) {
    setLocation(event.target.value);
  }

  function handleSearch(search: Array<string> | string) {
    // The callback function you provide in the onSearch prop will receive the current array
    // of search terms when a new search term is added or removed from the array.
    if (typeof search !== 'string') {
      // filtered results
      const filteredResults = locations.filter((location) => {
        let containsAllSearchTerms: boolean = true;
        search.forEach((searchTerm) => {
          let containsSearchTerm: boolean = false;
          if (location.value.toLowerCase().includes(searchTerm.toLowerCase())) {
            containsSearchTerm = true;
          };
          if (!containsSearchTerm) {
            containsAllSearchTerms = false;
          }
        });

        if (containsAllSearchTerms) {
          return true;
        } else {
          return false;
        }
      });        

      setFilteredLocations(filteredResults);
    }
  }

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modalchangelocation') as HTMLElement;
      if (modal) {
        const radio = modal.querySelector('label.checked') as HTMLElement;
        if (radio) {
          (radio?.firstChild as HTMLElement).focus();
        }
      } 
    }

    function focusModalChangeLocation(e: any) {
      const modal = document.querySelector('#modalchangelocation') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          let element = null;
          if (document.documentElement.clientWidth <= mobileWidth && filterLocation) {
            element = modal.querySelector('button') as HTMLElement;
          } else {
            element = modal.querySelector('button.icon-button') as HTMLElement;
          }
          if (element) {  
            element.focus();
          }
        }
      }
    }

    focusDefault();
    document.addEventListener('focusin', focusModalChangeLocation);
    document.addEventListener('focusout', focusModalChangeLocation);

    return () => {
      document.removeEventListener('focusin', focusModalChangeLocation);
      document.removeEventListener('focusout', focusModalChangeLocation);
    }
  }, []);

  return (
    <Modal id="modalchangelocation" onClickHandler={handleClickChangeLocationModal}>
      <ChangeLocation variant="minimal">
        <ModalHeader>
          <ModalTitle>
            {filterLocation !== null &&
              <>
                <SAText type="heading-2" text="Change Location" />
                <SAIcon className="closemodal" icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" onClick={handleClickCancel} />
              </>
            }
            {filterLocation === null &&
              <SAText type="heading-2" text="Choose Location" />
            }
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <SearchContainer>
            <SASearch
              fullWidth
              id="changelocationsearchbar"
              placeholder="Search by agency name, city, or state"
              onSearch={handleSearch}
              variant="search-terms"
            />
          </SearchContainer>
          <RadioGroup id="changelocation" value={location} variant="standard" direction="vertical" onChange={handleRadioChange}>
            {filteredLocations.map((loc) => {
              return (
                <SARadio key={loc.key} value={loc.key} label={loc.value} variant="standard" />
              )
            })}
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          {onClickCancel &&
            <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
          }
          <SAButton fullWidthUnder={mobileWidth} label="Submit" onClick={handleClickSubmit} variant="primary-large" textTransform="uppercase" />
        </ModalFooter>
      </ChangeLocation>
    </Modal>
  );
}

ModalChangeLocation.defaultProps = {
  onClickCancel: undefined,
}

import { Auth } from "aws-amplify";

export default function normalizePhoneNumber(value: string) {
  // remove white spaces and only allow numeric values up to 10 digits
  const newValue = value.replace(/\s/g, '').match(/\d{1,10}/g)?.join('').substr(0, 10) || '';

  if (newValue.length === 0) {
    return ''
  } else if (newValue.length >= 1 && newValue.length <= 3) {
    return newValue.substr(0, 3);
  } else if (newValue.length >= 4 && newValue.length <= 6) {
    return '(' + newValue.substr(0, 3) + ') ' + newValue.substr(3, 3);
  } else {
    return '(' + newValue.substr(0, 3) + ') ' + newValue.substr(3, 3) + '-' + newValue.substr(6, 4);
  }
}

export async function checkAuthStatus() {
  const isAuthenticated: boolean = await Auth.currentAuthenticatedUser({ bypassCache: true })
  .then((user) => {
    // user is authenticated
    return true;
  })
  .catch((err) => {
    window.location.reload();
    return false;
  });
  return isAuthenticated;
}
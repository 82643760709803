import React, { useEffect, useState } from "react";
import {
  SAButton,
  SACard,
  SACheckbox,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SASeparator,
  SAText,
  SAUXTheme
} from '@saux/design-system-react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import { LOB, State, SubscriptionGroup } from "../../../interfaces/interfaces";

const mobileWidth = 600;

type StateSelectionProps = {
  lob: LOB;
  subscriptionGroup: SubscriptionGroup;
  onClickCancel: Function;
  onClickSaveChanges: Function;
};

type StyleProps = {
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const StateSelection = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      // width: 600px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 45px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;

  button:last-child {
    &:focus {
      outline: auto;
    }
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      display: flex;
    }
  }
`;

const ModalSubTitle = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      margin-top: ${theme?.size.spacer.medium};

      span {
        font-size: 13px;
        font-weight: ${theme?.font.primary.weight.normal};
        line-height: 16px;
      }
    `;
  }};
`;

const ModalBody = styled.div`
  legend {
    display: none;
  }
`;

const ModalFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-top: 40px;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
  }};
`;

const AllStates = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: ${theme?.size.spacer.medium};
    `;
  }};
`;

const States = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      height: 728px;
      flex-wrap: wrap;

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-wrap: initial;
      }
    `;
  }};
`;

const StateScrollWrapper = styled.div`
  display: flex;
  height: 474px;
  width: 800px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    height: 92px;
    border: none;
  }

  &::-webkit-scrollbar-track {
      background: #F0EFEF;
      border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
      background: #8A8A8A;
      border-radius: 7px;
      box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }
`;

const StateCheckbox = styled(SACheckbox)`
  margin-top: 7px;
  margin-bottom: 7px;
  width: 260px;
  white-space: nowrap;
  cursor: initial;
`;

const WCOnly = styled(SAText)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export default function ModalStateSelection({ lob, subscriptionGroup, onClickCancel, onClickSaveChanges }: StateSelectionProps) {
  const wcOnlyStates = ['DC', 'DE', 'FL', 'ID', 'MT', 'NE', 'NJ', 'NM', 'NV'];
  const { register, handleSubmit, setValue } = useForm();
  const onSubmit = (data: any) => validateForm(data);
  const [ allStatesIndeterminateState, setAllStatesIndeterminateState] = useState<boolean>(false);

  function validateForm(data: any) {
    console.log('validateForm data', data);
    const newStateSubscriptions: string[] = [];
    lob.states.forEach((state: State) => {
      if (data[`checkbox_${subscriptionGroup.group_id}_${lob.lob_code}_${state.state_code}`] && state.state_code) {
        newStateSubscriptions.push(state.state_code);
      }
    });
    console.log(newStateSubscriptions);
    onClickSaveChanges(lob, newStateSubscriptions);
  }

  function handleClickStateSelectionModal(event: React.MouseEvent) {
    event.stopPropagation();

    // get the card element inside the modal
    const cardElement = document.querySelector('#modal > div > article > section');

    // close modal when clicking outside of the card element
    if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
      onClickCancel(event);
    }
  }

  function handleClickCancel(event: React.MouseEvent) {
    event.stopPropagation();
    if (onClickCancel) {
      onClickCancel(event);
    }
  }

  function stateSort(a: State, b: State) {
    if (a?.state && b?.state) {
      if (a.state.toLowerCase() === b.state.toLowerCase()) {
        return 0;
      } else {
        return (a.state.toLowerCase() < b.state.toLowerCase()) ? -1 : 1;
      }
    } else {
      return 0;
    }
  }

  function selectAllStates() {
    lob.states.forEach((state: State) => {
      const checkbox: HTMLInputElement = document.getElementById(`checkbox_${subscriptionGroup.group_id}_${lob.lob_code}_${state.state_code}`) as HTMLInputElement;
      if (checkbox && !checkbox.checked) {
        checkbox.click();
        setValue(`checkbox_${subscriptionGroup.group_id}_${lob.lob_code}_${state.state_code}`, true);
      } 
    });
  }

  function clearAllStates() {
    lob.states.forEach((state: State) => {
      const checkbox: HTMLInputElement = document.getElementById(`checkbox_${subscriptionGroup.group_id}_${lob.lob_code}_${state.state_code}`) as HTMLInputElement;
      if (checkbox && checkbox.checked) {
        checkbox.click();
        setValue(`checkbox_${subscriptionGroup.group_id}_${lob.lob_code}_${state.state_code}`, false);
      }
    });
  }

  function determineAllStatesIndeterminate(lob: LOB) {
    // determine if the All States checkbox needs to show as indeterminate based on current selection on screen
    let subscriptions: number = 0;
    lob.states.forEach((state: State) => {
      const checkbox: HTMLInputElement = document.getElementById(`checkbox_${subscriptionGroup.group_id}_${lob.lob_code}_${state.state_code}`) as HTMLInputElement;
      if (checkbox && checkbox.checked) {
        subscriptions += 1;
      } 
    });

    let value = false;
    if (subscriptions > 0 && lob.states.length !== subscriptions) {
      value = true;
    }
    return value;
  }

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modalstateselection') as HTMLElement;
      if (modal) {
        const checkbox = modal.querySelector('input') as HTMLElement;
        if (checkbox) {
          checkbox.focus();
        }
      } 
    }

    function focusModalStateSelection(e: any) {
      const modal = document.querySelector('#modalstateselection') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          let element = null;
          element = modal.querySelector('button') as HTMLElement;
          if (element) {  
            element.focus();
          }
        }
      }
    }

    // initialize form values
    lob.states.forEach((state: State) => {
      if (state.state_code && lob.subscriptions.includes(state.state_code)) {
        setValue(`checkbox_${subscriptionGroup.group_id}_${lob.lob_code}_${state.state_code}`, true);
      }
    });

    setAllStatesIndeterminateState(determineAllStatesIndeterminate(lob));

    focusDefault();
    document.addEventListener('focusin', focusModalStateSelection);
    document.addEventListener('focusout', focusModalStateSelection);

    return () => {
      document.removeEventListener('focusin', focusModalStateSelection);
      document.removeEventListener('focusout', focusModalStateSelection);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal id="modalstateselection" onClickHandler={handleClickStateSelectionModal}>
      <StateSelection variant="minimal">
        <Form id="stateselectionform" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            <ModalTitle>
              <SAText type="heading-2" text={lob?.lob_description as string} />
              <SAIcon className="closemodal" icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" onClick={handleClickCancel} />
            </ModalTitle>
            <ModalSubTitle>
              <SAText type="standard" text="Choose which state communications you would like to receive" />
            </ModalSubTitle>
          </ModalHeader>
          <ModalBody>
            <AllStates>
              <SACheckbox
                {...register('checkbox_all_states')}
                id={'checkbox_all_states'}
                type="checkbox"
                label="All States"
                defaultChecked={lob.states.length === lob.subscriptions.length}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    selectAllStates();
                  } else if (!e.currentTarget.checked) {
                    const skip = e.currentTarget.getAttribute('skip') === 'true' ? true : false;
                    e.currentTarget.removeAttribute('skip');
                    if (!skip) {
                      clearAllStates();
                    }
                  }
                }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                indeterminate={allStatesIndeterminateState}
              />
            </AllStates>
            <SASeparator horizontal />
            <StateScrollWrapper>
              <States>
                {lob.states.sort(stateSort).map((state: State) => {
                  return (
                    <StateCheckbox
                      {...register(`checkbox_${subscriptionGroup.group_id}_${lob.lob_code}_${state.state_code}`)}
                      id={`checkbox_${subscriptionGroup.group_id}_${lob.lob_code}_${state.state_code}`}
                      key={`checkbox_${subscriptionGroup.group_id}_${lob.lob_code}_${state.state_code}`}
                      type="checkbox"
                      label={`${state.state}${wcOnlyStates.includes(state.state_code as string) ? '*' : ''}`}
                      defaultChecked={lob.subscriptions.includes(state.state_code as string)}
                      onChange={(e: any) => {
                        if (!e.currentTarget.checked) {
                          const checkbox: HTMLInputElement = document.getElementById('checkbox_all_states') as HTMLInputElement;
                          if (checkbox.checked) {
                            checkbox.setAttribute('skip', 'true');
                            checkbox.click();
                          }
                        } else if (e.currentTarget.checked) {
                          let allStatesChecked: boolean = true;
                          lob.states.forEach((state: State) => {
                            const checkbox: HTMLInputElement = document.getElementById(`checkbox_${subscriptionGroup.group_id}_${lob.lob_code}_${state.state_code}`) as HTMLInputElement;
                            if (!checkbox.checked) {
                              allStatesChecked = false;
                            }
                          });
                          if (allStatesChecked) {
                            const allStatesCheckbox: HTMLInputElement = document.getElementById('checkbox_all_states') as HTMLInputElement;
                            if (!allStatesCheckbox.checked) {
                              allStatesCheckbox.click();
                            }
                          }
                        }
                        setAllStatesIndeterminateState(determineAllStatesIndeterminate(lob));
                      }}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}
                    />
                  );
                })}
              </States>
            </StateScrollWrapper>
            <SASeparator horizontal />
          </ModalBody>
          <ModalFooter>
            {(lob.lob_description === 'Commercial Lines') &&
              <WCOnly type="standard" text="* Workers' Compensation Only" />
            }
            <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
            <SAButton fullWidthUnder={mobileWidth} label="Save Changes" type="submit" variant="primary-large" textTransform="uppercase" />
          </ModalFooter>
        </Form>
      </StateSelection>
    </Modal>
  );
}

ModalStateSelection.defaultProps = {
  onClickCancel: undefined,
}

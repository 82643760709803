import React, { useEffect } from "react";
import {
  SACard,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
  SAUXTheme
} from '@saux/design-system-react'
import styled from 'styled-components';

const mobileWidth = 600;

type MarketFocusProps = {
  onClickYes: (event: React.MouseEvent) => void;
  onClickNo: (event: React.MouseEvent) => void;
  onClickClose: (event: React.MouseEvent) => void;
};

type StyleProps = {
  error?: boolean;
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const LicensedProducerMarketFocus = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 600px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 36px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    } 
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      display: flex;
    }
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  span {
    font-size: 18px;
    line-height: 26px;
  }

  span:last-child > strong {
    font-size: 18px;
    line-height: 26px;
    margin-right: 4px;
  }
`;

const ModalFooter: any = styled.div`
${({ theme }: StyleProps) => {
  return `
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 60px;
    
    button {
      font-weight: ${theme?.font.primary.weight.normal};
      margin-left: ${theme?.size.spacer.medium};

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        font-weight: ${theme?.font.primary.weight.bold};
      }
    }

    @media only screen and (max-width: ${mobileWidth}px) {
      justify-content: center;
      height: 100%;
    }
  `;
}};
`;

export default function ModalLicensedProducerMarketFocus({ onClickYes, onClickNo, onClickClose }: MarketFocusProps) {
  
  function handleClickYes(event: React.MouseEvent) {
    event.stopPropagation();
    onClickYes(event);
  }

  function handleClickNo(event: React.MouseEvent) {
    event.stopPropagation();
    onClickNo(event);
  }

  function handleClickLicensedProducerMarketFocus(event: React.MouseEvent) {
    event.stopPropagation();

    // get the card element inside the modal
    const cardElement = document.querySelector('#modal > div > article > section');

    // close modal when clicking outside of the card element
    if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
      onClickClose(event);
    }
  }

  function handleClickCancel(event: React.MouseEvent) {
    event.stopPropagation();
    onClickClose(event);
  }
  
  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modalLicensedProducerMarketFocus') as HTMLElement;
      if (modal) {
        const element = modal.querySelector('button.sa-button:nth-child(1)') as HTMLElement;
        if (element) {
          element.focus();
        }
      } 
    }

    function focusModalCancelAlert(e: any) {
      const modal = document.querySelector('#modalLicensedProducerMarketFocus') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          let element = modal.querySelector('button.sa-button') as HTMLElement;
          if (element) {  
            element.focus();
          }
        }
      }
    }

    focusDefault();
    document.addEventListener('focusin', focusModalCancelAlert);
    document.addEventListener('focusout', focusModalCancelAlert);

    return () => {
      document.removeEventListener('focusin', focusModalCancelAlert);
      document.removeEventListener('focusout', focusModalCancelAlert);
    }
  }, []);

  return (
    <Modal id="modalLicensedProducerMarketFocus" onClickHandler={handleClickLicensedProducerMarketFocus}>
      <LicensedProducerMarketFocus variant="minimal">
        <ModalHeader>
          <ModalTitle>
            <SAText type="heading-2" text="Licensed Producer Market Focus" />
            <SAIcon className="closemodal" icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" onClick={handleClickCancel} />
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <SAText type="standard" text="Is this licensed producer needed only for Personal Lines business?" />
          <SAText type="standard">
            <strong>Note*</strong> We are not currently accepting new business for personal lines. Please add the contact as agency staff to avoid unnecessary compliance steps for onboarding a licensed producer solely for personal lines.
          </SAText>
        </ModalBody>
        <ModalFooter>
          <SAButton fullWidthUnder={mobileWidth} label="Yes" onClick={handleClickYes} variant="large" color="secondary" textTransform="uppercase" />
          <SAButton fullWidthUnder={mobileWidth} label="No" onClick={handleClickNo} variant="link-large" color="text" textTransform="uppercase" />
        </ModalFooter>
      </LicensedProducerMarketFocus>
    </Modal>
  );
}

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Container,
  Button,
  TextField,
  Radio,
  RadioProps,
  withStyles,
  FormControlLabel,
} from "@material-ui/core";
import { LoginType, Person, Agency } from '../../interfaces/interfaces';
import { useHistory } from "react-router-dom";
import {decrypt, encrypt} from '../../utils/crypto';
import env from '../../env_config';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../graphql/queries';
import { GetPersonDetailsQuery } from "../../API";
import handleGraphQLErrors from "../../utils/handleGraphQLErrors";
import { checkAuthStatus } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(12.5),
    paddingBottom: theme.spacing(4),
  },
  radioLabel: {
    fontWeight: 500,
    color: "#000000",
    fontSize: "20px",
  },
}));

const BlueRadio = withStyles({
  root: {
    color: "#0173C6",
    "&$checked": {
      color: "#0173C6",
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

export default function Login() {
  const classes = useStyles();
  const [loginType, setLoginType] = useState<LoginType>('personID');
  const [searchValue, setSearchValue] = useState<string>('');
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginType(event.target.value as LoginType);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  function handleSubmit(event: { preventDefault: () => void }) {
    event.preventDefault();

    localStorage.removeItem('loginType');
    localStorage.removeItem('employeeId');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('personId');
    localStorage.removeItem('selectedPersonId');
    localStorage.removeItem('selectedFirstName');
    localStorage.removeItem('selectedLastName');
    localStorage.removeItem('selectedLoginId');
    localStorage.removeItem('selectedEmail');
    localStorage.removeItem('selectedContactTypes');
    localStorage.removeItem('selectedContactTypesAll');
    localStorage.removeItem('preferred_email');
    localStorage.removeItem('showEdit');
    localStorage.removeItem('edit_state');
    localStorage.removeItem('primaryAgencyCodeSaveButton');
    localStorage.removeItem('AddContactAgencyCodes');
    localStorage.removeItem('general_details');
    localStorage.removeItem('mail_address');
    localStorage.removeItem('res_address');
    localStorage.removeItem('bus_address');
    localStorage.removeItem('privileges');
    localStorage.removeItem('agency_address');
    localStorage.removeItem('agency_code');
    localStorage.removeItem('agencyContacts');
    localStorage.removeItem('allAgencyCodes');
    localStorage.removeItem('dataFetchProgress');
    localStorage.removeItem('FilterLocation');
    localStorage.removeItem('FilterContactType');
    localStorage.removeItem('FilterSearchTerms');
    localStorage.removeItem('intervalRef');
    localStorage.removeItem('NewContact');
    localStorage.removeItem('LoginIDInfo');
    localStorage.removeItem('personInfo');
    localStorage.removeItem('pageIndex');
    localStorage.removeItem('agency_code_map');
    localStorage.removeItem('loggedInUserFirstName');
    localStorage.removeItem('loggedInUserLastName');

    submit(loginType, searchValue);
  }

  async function submit(_loginType: string, _searchValue: string) {
    if (_loginType === 'personID') {
      localStorage.setItem('loginType', encrypt(_loginType));
      localStorage.setItem('personId', encrypt(_searchValue));

      const isAuthenticated: boolean = await checkAuthStatus();
      if (!isAuthenticated) {
        return;
      }
      const promisePerson = API.graphql(
        graphqlOperation(
          queries.getPersonDetails,
          { person_id: _searchValue }
        ),
        {
          Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
        }
      ) as Promise<{ data: GetPersonDetailsQuery }>;

      promisePerson.then((res) => {
        if (res.data) {
          return res.data.getPersonDetails;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200) {
          return res.body as Person | null;
        } else {
          throw new Error('Something went wrong - 35');
        }
      })
      .then((res) => {
        if (res) {
          console.log(res);

          let isAdmin: boolean = false;
          localStorage.setItem('loggedInUserFirstName', encrypt(res.prdcr_first_name));
          localStorage.setItem('loggedInUserLastName', encrypt(res.prdcr_last_name));
          localStorage.setItem('selectedPersonId', encrypt(res.person_id));
          localStorage.setItem('selectedFirstName', encrypt(res.prdcr_first_name));
          localStorage.setItem('selectedLastName', encrypt(res.prdcr_last_name));
          localStorage.setItem('selectedLoginId', encrypt(res.login_id));
          localStorage.setItem('preferred_email', encrypt(res.preferred_email));
    
          res.agency_code_map.forEach((agency: Agency) => {
            agency.contact_type?.forEach((ct: string) => {
              if (ct === "3" || ct === "6") {
                // 3 - Agency Admin
                // 6 - Principal
                isAdmin = true;
              }
            });
          });
    
          localStorage.setItem('isAdmin', encrypt(isAdmin.toString()));
    
          if (history.location.search) {
            const params: string[] = history.location.search.slice(1).split('&');
            params.forEach((param: string) => {
              const temp: string[] = param.split('=');
              const key: string = temp[0];
              const value = temp[1];
              
              if (key === 'page' && value) {
                if (!isAdmin && (value === 'agency-contacts' || value === 'contact-listing')) {
                  history.push({
                    pathname: '/agency-contact-profile',
                  });
                } else {
                  history.push({
                    pathname: `/${value}`,
                  });
                }
              }
            });
          } else {
            if (isAdmin) {
              history.push({
                pathname: '/agency-contacts',
              });
            } else {
              history.push({
                pathname: '/agency-contact-profile',
              });
            }
          }
        } else {
          throw new Error('Something went wrong - 36');
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err); 
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });
    } else if (_loginType === 'employeeID') {
      localStorage.setItem('loginType', encrypt(_loginType));
      localStorage.setItem('employeeId', encrypt(_searchValue));
      if (history.location.search) {
        const params: string[] = history.location.search.slice(1).split('&');
        params.forEach((param: string) => {
          const temp: string[] = param.split('=');
          const key: string = temp[0];
          const value = temp[1];
          
          if (key === 'page' && value) {
            if (['agency-administration','search-agency-contact'].includes(value)) {
              history.push({
                pathname: `/${value}`,
              });
            } else {
              history.push({
                pathname: '/agency-administration',
              });
            }
          }
        });
      } else {
        history.push({
          pathname: `/agency-administration`,
        });
      }
    }
  }

  useEffect(() => {
    if (env['login-override-enabled']) {
      // set default loginType
      setLoginType('personID');
    } else {
      const authUserType = decrypt(localStorage.getItem('auth_usertype'));
      const authPersonID = decrypt(localStorage.getItem('auth_personid'));
      const authUserID = decrypt(localStorage.getItem('auth_userid'));
      const authGainRefID = decrypt(localStorage.getItem('auth_gainrefid'));
      const authAccessToken = decrypt(localStorage.getItem('auth_accesstoken'));
      const authRefreshToken = decrypt(localStorage.getItem('auth_refreshtoken'));
      const redirect = decrypt(localStorage.getItem('redirect'));
      const redirectLocation = decrypt(localStorage.getItem('redirectLocation'));

      localStorage.removeItem('loginType');
      localStorage.removeItem('employeeId');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('personId');
      localStorage.removeItem('selectedPersonId');
      localStorage.removeItem('selectedFirstName');
      localStorage.removeItem('selectedLastName');
      localStorage.removeItem('selectedLoginId');
      localStorage.removeItem('selectedEmail');
      localStorage.removeItem('selectedContactTypes');
      localStorage.removeItem('selectedContactTypesAll');
      localStorage.removeItem('preferred_email');
      localStorage.removeItem('showEdit');
      localStorage.removeItem('edit_state');
      localStorage.removeItem('primaryAgencyCodeSaveButton');
      localStorage.removeItem('AddContactAgencyCodes');
      localStorage.removeItem('general_details');
      localStorage.removeItem('mail_address');
      localStorage.removeItem('res_address');
      localStorage.removeItem('bus_address');
      localStorage.removeItem('privileges');
      localStorage.removeItem('agency_address');
      localStorage.removeItem('agency_code');
      localStorage.removeItem('agencyContacts');
      localStorage.removeItem('allAgencyCodes');
      localStorage.removeItem('dataFetchProgress');
      localStorage.removeItem('FilterLocation');
      localStorage.removeItem('FilterContactType');
      localStorage.removeItem('FilterSearchTerms');
      localStorage.removeItem('intervalRef');
      localStorage.removeItem('NewContact');
      localStorage.removeItem('LoginIDInfo');
      localStorage.removeItem('personInfo');
      localStorage.removeItem('pageIndex');
      localStorage.removeItem('agency_code_map');
      localStorage.removeItem('loggedInUserFirstName');
      localStorage.removeItem('loggedInUserLastName');

      let _loginType: LoginType;
      let _searchValue: string;

      switch (authUserType) {
        case 'A':
          _loginType = 'personID';
          _searchValue = authPersonID;
          setLoginType(_loginType);
          setSearchValue(_searchValue);
          submit(_loginType, _searchValue);
          break;
        case 'E':
          _loginType = 'employeeID';
          _searchValue = authUserID;
          setLoginType(_loginType);
          setSearchValue(_searchValue);
          submit(_loginType, _searchValue);
          break;
        default:
          throw new Error('Unkown UserType');
      }
    }
  }, []);

  return (
    <>
      {env['login-override-enabled'] &&
        <Container maxWidth="md" className={classes.container}>
          <Box display="flex" flexDirection="row">
            <FormControlLabel
              label="Person ID"
              control={
                <BlueRadio
                  checked={loginType === 'personID'}
                  onChange={handleChange}
                  value="personID"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'personID' }}
                  className={classes.radioLabel}
                />
              }
            />
            <FormControlLabel
              label="Employee ID"
              control={
                <BlueRadio
                  checked={loginType === 'employeeID'}
                  onChange={handleChange}
                  value="employeeID"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'employeeID' }}
                  className={classes.radioLabel}
                />
              }
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <TextField
              type="search"
              variant="outlined"
              margin="normal"
              placeholder={loginType === 'personID' ? 'Enter person id' : 'Enter employee id'}
              onChange={handleSearch}
              value={searchValue}
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Container>
      }
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { SAButton, SACard, SACheckbox, SAIcon, SAIcons, SAIconSize, SAPopover, SASeparator, SASnackbar, SASpinner, SAText, SAUXTheme } from '@saux/design-system-react';
import { Constants, LOB, Offset, Position, State, SubscriptionGroup, SubscriptionObject} from '../../interfaces/interfaces';
import styled from 'styled-components';
import { decrypt } from '../../utils/crypto';
import { constants } from '../../utils/constants';
import { useForm } from 'react-hook-form';
import ModalStateSelection from '../modal/ModalStateSelection/ModalStateSelection';
import ModalUnsubscribeAlert from '../modal/ModalUnsubscribeAlert/ModalUnsubscribeAlert';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { GetPersonCommuncationPrefQuery, updatePersonCommunicationPrefBody, UpdatePersonCommunicationPrefMutation } from '../../API';
import simpleGTMDataLayer from "../../utils/GTMHelpers/simpleGTMDataLayer";
import handleGraphQLErrors from '../../utils/handleGraphQLErrors';
import { checkAuthStatus } from '../../utils/utils';

const mobileWidth = 600;

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
};

const Container = styled.div.attrs(({ constants }: StyleProps) => ({
  constants
}))`
  ${({ theme, constants }: StyleProps) => {
    const con: Constants = constants as Constants;
    const marginTop: number = con.headerHeight + con.verticalPageOffset;
    const marginTopMobile: number = con.headerHeightMobile + con.verticalPageOffset;

    return `
      margin-top: ${marginTop}px;
    
      @media only screen and (max-width: ${mobileWidth}px) {
        margin-top: ${marginTopMobile}px;
      }
    `;
  }};
`;

const SACardWrapper = styled.div`
  max-width:  1200px;
  margin: 0 15px 3rem 15px;
`;

const Subscription = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
`;

const GrayPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #F4F6FA;
  border-radius: 5px;
  padding: 30px 20px;
  margin-bottom: 10px;
`;

const Lob = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const SelectStates = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Checkbox = styled(SACheckbox)`
  padding-right: 15px;
  z-index: 0;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;

  div:first-child {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 10px;
  }

  div:last-child {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`;

const Popover = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      color: hsla(220, 38%, 97%, 1);
      font-size: ${theme?.font.primary.size.smallTEMP};
      font-weight: ${theme?.font.primary.weight.normal};
      line-height: 16px;
      background-color: hsla(213, 30%, 35%, 1);
      border: 1px solid hsla(213, 30%, 35%, 1);
      box-sizing: border-box;
      box-shadow: 0px 0px 5px rgba(0, 32, 56, 0.22);
      border-radius: 4px;
      padding: 15px;
      width: 360px;
    `;
  }};
`;

const HelpWrapper = styled.div`
   display: flex;
   align-items: center;

   button {
    margin-left: 10px;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

const HelpContainer = styled.div`
  display: flex;
  flex-directino: column;
`;

const HelpIcon = styled.div`
  display: flex;
  padding-right: 10px;
  align-items: flex-start;
`;

const HelpText = styled.div`
  display: flex;
  align-items: flex-start;
`;

const SeparatorWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 40px;
`;

interface IPanel {
  register: any;
  subscriptionGroup: SubscriptionGroup;
  lob: LOB;
  caption: string;
  onClickSelectStates: Function;
  onChange: Function;
  mobile: boolean;
}

interface ISubscriptionCategory {
  register: any;
  subscriptionGroup: SubscriptionGroup;
  onChange: Function;
  onClickSelectStates: Function;
  mobile: boolean;
  openPopover: Function;
  closePopover: Function;
  togglePopover: Function;
  handleKeyDown: Function;
}

function Panel(props: IPanel) {
  return (
    <GrayPanel>
      <Lob>
        <Checkbox
          {...props.register(`checkbox_${props.subscriptionGroup.group_id}_${props.lob.lob_code}`)}
          id={`checkbox_${props.subscriptionGroup.group_id}_${props.lob.lob_code}`}
          type="checkbox"
          defaultChecked={determineLobChecked(props.lob)}
          indeterminate={determineLobIndeterminate(props.lob)}
          onChange={(e: any) => {
            if (e.currentTarget.checked) {
              const newStates: string[] = [];
              props.lob.states.forEach((state: State) => {
                if (state && state.state_code) {
                  newStates.push(state.state_code);
                }
              });
              if (props.lob) {
                props.lob.subscriptions = newStates;
              }
              props.onChange();
            } else {
              if (props.lob) {
                props.lob.subscriptions = [];
              }
              props.onChange();
            }
          }}
        />
        <Stack>
          <div>{props.lob.lob_description}</div>
          <div>{props.caption}</div>
        </Stack>
      </Lob>
      {(props.lob.subscriptions && props.lob.subscriptions.length > 0) &&
        <SelectStates>
          {props.mobile &&
            <SAButton
              fullWidth={false}
              label="Select States"
              textTransform="uppercase"
              variant="link-medium"
              color="secondary"
              onClick={(e) => props.onClickSelectStates(props.subscriptionGroup, props.lob)}
            >
              <SAIcon icon={SAIcons.pencil} size={SAIconSize.small} colorVariant="secondary" />
            </SAButton>
          }
          {!props.mobile &&
            <SAButton
              fullWidth={false}
              label="Select States"
              textTransform="uppercase"
              variant="link-medium"
              color="secondary"
              onClick={(e) => props.onClickSelectStates(props.subscriptionGroup, props.lob)}
            />
          }
        </SelectStates>
      }
    </GrayPanel>
  );
}

function SubscriptionCategory(props: ISubscriptionCategory) {
  function handleChange() {
    props.onChange();
  }

  function handleClickSelectStates(subscriptionGroup: SubscriptionGroup, lob: LOB) {
    props.onClickSelectStates(subscriptionGroup, lob);
  }

  return (
    <>
      <Subscription>
        <Checkbox
          {...props.register(`checkbox_${props.subscriptionGroup.group_id}`)}
          id={`checkbox_${props.subscriptionGroup.group_id}`}
          type="checkbox"
          defaultChecked={determineGroupChecked(props.subscriptionGroup)}
          indeterminate={determineGroupIndeterminate(props.subscriptionGroup)}
          onChange={(e: any) => {
            if (e.currentTarget.checked) {
              props.subscriptionGroup.lobs.map((lob: LOB) => {
                const newStates: string[] = [];
                if (lob) {
                  lob.states.forEach((state: State) => {
                    if (state && state.state_code) {
                      newStates.push(state.state_code);
                    }
                  });
                  lob.subscriptions = newStates;
                }
                props.onChange();
              });
            } else {
              props.subscriptionGroup.lobs.map((lob: LOB) => {
                if (lob) {
                  lob.subscriptions = [];
                }
              });
              props.onChange();
            }
          }}
        />
        <div>{props.subscriptionGroup.description}</div>
        <HelpWrapper>
          <SAButton
            fullWidth={false}
            variant="link-small"
            label="Help Text"
            onClick={(e: any) => props.togglePopover(e)}
            onKeyDown={(e: any) => props.handleKeyDown(e)}
          >
            <SAIcon icon={SAIcons.information} size="20px" colorVariant="primary" onMouseOver={props.openPopover} onMouseLeave={props.closePopover} />
          </SAButton>
        </HelpWrapper>
      </Subscription>
      {props.subscriptionGroup.lobs.map((lob: LOB) => {
        return (       
          <Panel
            register={props.register}
            lob={lob}
            subscriptionGroup={props.subscriptionGroup}
            caption={determineLobCaption(props.subscriptionGroup, lob)}
            key={`panel_${props.subscriptionGroup.group_id}_${lob.lob_code}`}
            onChange={handleChange}
            onClickSelectStates={handleClickSelectStates}
            mobile={props.mobile}
          />
        );
      })}
    </>
  );
}

function determineGroupChecked(subscriptionGroup: SubscriptionGroup) {
  // a subscription group checkbox will only be checked if all states for each line of business is checked.
  let value = true;
  subscriptionGroup.lobs.forEach((lob: LOB) => {
    // compare number of available states to subscribed states
    if (lob.states.length !== lob.subscriptions.length || lob.states.length === 0) {
      value = false;
    }
  });
  return value;
}

function determineLobChecked(lob: LOB) {
  // a line of business checkbox will only be checked if all states for that line of business is checked.
  let value = true;
  if (lob.states.length !== lob.subscriptions.length || lob.states.length === 0) {
    value = false;
  }
  return value;
}

function determineGroupIndeterminate(subscriptionGroup: SubscriptionGroup) {
  // a subscription group checkbox will be indeterminate if any state for any line of business is not checked.
  let value = false;
  let lobsChecked: number = 0;
  subscriptionGroup.lobs.forEach((lob: LOB) => {
    // compare number of available states to subscribed states
    if (value === false && lob.subscriptions && lob.states && lob.subscriptions.length > 0 && lob.states.length > 0 && lob.states.length !== lob.subscriptions.length) {
      value = true;
    } else if (value === false && lob.states && lob.states.length > 0 && lob.states.length === lob.subscriptions.length) {
      lobsChecked++;
    }
  });
  if (value === false) {
    if (lobsChecked > 0 && lobsChecked !== subscriptionGroup.lobs.length) {
      value = true;
    }
  }
  return value;
}

function determineLobIndeterminate(lob: LOB) {
  // a line of business checkbox will be indeterminate if any state is not checked for that line of business is checked.
  let value = false;
  if (lob.subscriptions && lob.subscriptions.length > 0 && lob.states.length !== lob.subscriptions.length) {
    value = true;
  }
  return value;
}

function determineLobCaption(subscriptionGroup: SubscriptionGroup, lob: LOB) {
  if (lob.subscriptions.length === 0) {
    return `Not subscribed to ${lob.lob_description} ${subscriptionGroup.description}`;
  } else if (lob.subscriptions.length === lob.states.length) {
    return 'Subscribed to all states'
  } else {
    return `Subscribed to ${lob.subscriptions.length} of ${lob.states.length} states`;
  }
}

export default function CommunicationPreferences(props: any) {
  const { register } = useForm();
  const loggedInPersonId = decrypt(localStorage.getItem('personId'));
  const loginId = decrypt(localStorage.getItem('selectedLoginId'));
  const firstName = decrypt(localStorage.getItem('selectedFirstName'));
  const lastName = decrypt(localStorage.getItem('selectedLastName'));
  const [subscriptions, setSubscriptions] = useState<SubscriptionObject | null>(null);
  const helpTextInTouch = `Agent InTouch is our email bulletin used to share important company news with our agency partners.`;
  const helpTextMarketing = `Get the latest news about our small business, farm & ranch and middle market lines of business and why State Auto is the best choice for your clients.`;
  const [selectedSubscriptionGroup, setSelectedSubscriptionGroup] = useState<SubscriptionGroup | null>(null);
  const [selectedLob, setSelectedLob] = useState<LOB | null>(null);
  const [showUnsubscribe, setShowUnsubscribe] = useState<boolean>(false);
  const [savingEnabled, setSavingEnabled] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const [helpText, setHelpText] = useState("");
  const [mobile, setMobile] = useState<boolean>(true);
  const [gtm, setGTM] = useState<boolean>(false);
  const desktopPosition: Position = 'right';
  const mobilePosition: Position = 'top-end';
  const desktopOffset: Offset = [0, 10];
  const mobileOffset: Offset = [0, 10];
  const [position, setPosition] = useState<Position>(
    document.documentElement.clientWidth <= mobileWidth ? mobilePosition : desktopPosition,
  );
  const [offset, setOffset] = useState<Offset>(
    window.innerWidth <= mobileWidth ? mobileOffset : desktopOffset,
  );
  
  // Loading Spinner
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(true);

  // Modals
  const [displayStateModal, setDisplayStateModal] = useState<boolean>(false);
  const [displayUnsubscribeAlert, setDisplayUnsubscribeAlert] = useState<boolean>(false);

  // Snackbar
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

  useEffect(() => {
    if (!gtm) {
      simpleGTMDataLayer({
        event: 'pageview',
        page: {
          path: '/communication-preferences',
          title: 'ADJSS: Communications Preferences'
        }
      });
      setGTM(true);
    }
  }, []);

  function getHelpText(groupId: string) {
    if (groupId === "SMA") {
      return helpTextInTouch;
    } else if ("SMA1") {
      return helpTextMarketing;
    }
    return "";
  }

  function handleChange() {
    setSubscriptions(JSON.parse(JSON.stringify(subscriptions)));
  }

  function handleClickSelectStates(subscriptionGroup: SubscriptionGroup, lob: LOB) {
    setSelectedSubscriptionGroup(subscriptionGroup);
    setSelectedLob(lob);
    setDisplayStateModal(true);
  }

  function openPopover(event: React.MouseEvent<HTMLElement>, groupId: string) {
    setReferenceElement(event.currentTarget);
    setHelpText(getHelpText(groupId));
  }

  function closePopover() {
    setReferenceElement(null);
  }

  function togglePopover(event: React.MouseEvent<HTMLElement>, groupId: string) {
    if (referenceElement === null) {
      setReferenceElement(event.currentTarget.firstChild as HTMLElement);
    } else {
      setReferenceElement(null);
    }
    setHelpText(getHelpText(groupId));
  }

  function handleKeyDown(e: any) {
    if (e.key === 'Tab') {
      setReferenceElement(null);
    }
  }

  function handleClickCancelStateModal() {
    setDisplayStateModal(false);
  }

  function handleClickSaveChangesStateModal(lob: LOB, newStateSubscriptions: string[]) {
    lob.subscriptions = newStateSubscriptions;
    setSubscriptions(JSON.parse(JSON.stringify(subscriptions)));
    setDisplayStateModal(false);
  }

  function handleClickCancelUnsubscribeAlert() {
    setDisplayUnsubscribeAlert(false);
  }

  function handleClickYesUnsubscribeAlert() {
    subscriptions?.subscription_groups?.map((subscriptionGroup) => {
      (subscriptionGroup as SubscriptionGroup).lobs.map((lob: LOB) => {
        if (lob) {
          lob.subscriptions = [];
        }
      });
    });
    setSubscriptions(JSON.parse(JSON.stringify(subscriptions)));
    setDisplayUnsubscribeAlert(false);
  }

  function handleClickUnsubscribe () {
    setDisplayUnsubscribeAlert(true);
  }

  function onSnackbarClose() {
    setShowSnackbar(false);
  }

  useEffect(() => {
    function sortSubscriptionGroup(a: SubscriptionGroup, b: SubscriptionGroup) {
      if (a.description === b.description) {
        return 0;
      } else {
        return (a.description < b.description) ? -1 : 1;
      }
    }

    function sortLineOfBusiness(a: LOB, b: LOB) {
      if (a.lob_description === b.lob_description) {
        return 0;
      } else {
        return (a.lob_description < b.lob_description) ? -1 : 1;
      }
    }

    async function loadSubscriptions() {
      const isAuthenticated: boolean = await checkAuthStatus();
      if (!isAuthenticated) {
        return;
      }
      const promiseCommPrefs = API.graphql(
        graphqlOperation(
          queries.getPersonCommuncationPref,
          {
            person_id: loggedInPersonId
          }
        ),
        {
          Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
        }
      ) as Promise<{ data: GetPersonCommuncationPrefQuery }>;

      promiseCommPrefs.then((res) => {
        if (res.data) {
          return res.data.getPersonCommuncationPref;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200) {
          return res.body;
        } else {
          throw new Error('Response was not successful');
        }
      })
      .then((res) => {
        if (res) {
          // apply sorting to Subscription Groups and LOBs
          const resObj = res as SubscriptionObject;
          resObj.subscription_groups.sort(sortSubscriptionGroup);
          resObj.subscription_groups.forEach((group: SubscriptionGroup) => {
            group.lobs.sort(sortLineOfBusiness);
          });
          return setSubscriptions(resObj);
        } else {
          throw new Error('Something went wrong - 34');
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err); 
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      })
      .finally(() => {
        setShowLoadingSpinner(false);
      });
    }

    loadSubscriptions();
  }, []);

  useEffect(() => {
    function updatePosition() {
      if (window.innerWidth <= mobileWidth) {
        setMobile(true);
        setPosition(mobilePosition);
        setOffset(mobileOffset);
      } else {
        setMobile(false);
        setPosition(desktopPosition);
        setOffset(desktopOffset);
      }
    }
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function determineShowUnsubscribe() {
      // this function will determine if the Unsubscribe All link button needs to be show or not
      let value: boolean = true;
      subscriptions?.subscription_groups?.forEach((subscriptionGroup) => {
        (subscriptionGroup as SubscriptionGroup).lobs.forEach((lob: LOB) => {
          if (value && lob && lob.subscriptions && lob.subscriptions.length > 0) {
            value = false;
          }
        });
      });
      return !value;
    }

    async function saveSubscriptions() {
      const isAuthenticated: boolean = await checkAuthStatus();
      if (!isAuthenticated) {
        return;
      }
      const promiseCommPrefs = API.graphql(
        graphqlOperation(
          mutations.updatePersonCommunicationPref,
          {
            body: {
              subscription_groups: subscriptions?.subscription_groups,
              updated_by: loggedInPersonId
            } as updatePersonCommunicationPrefBody,
            person_id: loggedInPersonId
          }
        ),
        {
          Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
        }
      ) as Promise<{ data: UpdatePersonCommunicationPrefMutation }>;

      promiseCommPrefs.then((res) => {
        if (res.data) {
          return res.data.updatePersonCommunicationPref;
        } else {
          handleGraphQLErrors(res);
        }
      })
      .then((res) => {
        if (res?.statusCode === 200) {
          return res.body;
        } else {
          throw new Error('Response was not successful');
        }
      })
      .then((res) => {
        if (res?.status === 'success') {
          setShowSnackbar(true);
        } else {
          throw new Error(res?.message as string);
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.error(err.message, err); 
        } else {
          handleGraphQLErrors(err);
        }
        window.location.href = "error";
      });
    }

    if (showLoadingSpinner === false) {
      setShowUnsubscribe(determineShowUnsubscribe());
      if (savingEnabled) {
        saveSubscriptions();
      } else {
        setSavingEnabled(true);
      }
    }
  }, [subscriptions, showLoadingSpinner]);

  useEffect(() => {
    if (displayStateModal || displayUnsubscribeAlert) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [displayStateModal, displayUnsubscribeAlert]);

  useEffect(() => {
    function handleScroll() {
      setReferenceElement(null);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!document.getElementById('modal')) {
      const modalRoot: HTMLDivElement = document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      document.body.append(modalRoot);
    }
  }, []);

  useEffect(() => {
    if (!showLoadingSpinner) {
      // set default focus
      const element = document.querySelector('input') as HTMLElement;
      if (element) {
        element.focus();
      }
    }    
  }, [showLoadingSpinner]);

  return (
    <>
      {showLoadingSpinner && <SpinnerContainer><SASpinner variant="circular-continuous" /></SpinnerContainer>}
      {!showLoadingSpinner &&
        <>
          <SASnackbar
            open={showSnackbar}
            variant="snackbar-success"
            position="top-end"
            autoHideDuration={5000}
            title="Changes Saved"
            subtitle="All your changes have been saved"
            offset={[-25, 170]}
            onClose={onSnackbarClose}
          />
          <Container id="agency" constants={constants}>
            <SACardWrapper>
              <div className="personInfo">
                <div>
                  <SAText
                    type="heading-2"
                    text={
                      firstName + " " + lastName
                    }
                  />
                  <SAText type="standard" text={`Login ID: ${loginId ? loginId : '-'}`} />
                </div>
              </div>
            </SACardWrapper>
            <SACardWrapper>
              <SACard variant="standard" title="Communication Preferences">
                <Description>
                  Everyone likes choices. That's why we let you choose which type of agency communications you'd like to receive from us. We'll send you communications on things such as rate changes, product and system enhancements, marketing materials, contact announcements and severe weather alerts.
                </Description>
                {subscriptions?.subscription_groups?.map((subscriptionGroup) => {
                  return (
                    <div key={`subscription_group_${subscriptionGroup?.group_id}`}>
                      <SubscriptionCategory
                        register={register}
                        subscriptionGroup={subscriptionGroup as SubscriptionGroup}
                        onChange={handleChange}
                        onClickSelectStates={(subscriptionGroup: SubscriptionGroup, lob: LOB) => handleClickSelectStates(subscriptionGroup, lob)}
                        mobile={mobile}
                        openPopover={(e: React.MouseEvent<HTMLElement>) => openPopover(e, subscriptionGroup.group_id)}
                        closePopover={closePopover}
                        togglePopover={(e: React.MouseEvent<HTMLElement>) => togglePopover(e, subscriptionGroup.group_id)}
                        handleKeyDown={handleKeyDown}
                      />
                      <SeparatorWrapper>
                        <SASeparator horizontal />
                      </SeparatorWrapper>
                    </div>
                  );
                })}
                {showUnsubscribe &&
                  <SAButton
                    label="Unsubscribe from all optional communications"
                    textTransform="uppercase"
                    color="primary"
                    variant="link-small"
                    onClick={handleClickUnsubscribe}
                  />
                }
              </SACard>
            </SACardWrapper>
          </Container>
          <SAPopover
            onClose={(e: MouseEvent) => closePopover()}
            offset={offset}
            placement={position}
            referenceElement={referenceElement}
            variant={mobile ? 'popover-minimal-nopadding' : 'popover-menu-no-click'}
          >
            <Popover>
              <HelpContainer>
                <HelpIcon>
                  <SAIcon icon={SAIcons.information} size="20px" colorVariant="hsla(220, 38%, 97%, 1)" />
                </HelpIcon>
                <HelpText>
                  {helpText}
                </HelpText>
              </HelpContainer>
            </Popover>
          </SAPopover>
        </>
      }
      {displayStateModal &&
        <ModalStateSelection
          subscriptionGroup={selectedSubscriptionGroup as SubscriptionGroup}
          lob={selectedLob as LOB}
          onClickCancel={handleClickCancelStateModal}
          onClickSaveChanges={handleClickSaveChangesStateModal}
        />
      }
      {displayUnsubscribeAlert &&
        <ModalUnsubscribeAlert
          onClickCancel={handleClickCancelUnsubscribeAlert}
          onClickYes={handleClickYesUnsubscribeAlert}
        />
      }
    </>
  );
}

import configuration from "../../env_config";
import { decrypt, encrypt } from "../../utils/crypto";
import { Columns, Column, HiddenBox } from "../AgencyAdministration/AgencyAdminStyled";
import {
  BlueButtonCard,
  ButtonCard,
  SubCards,
  ContactCard,
} from "../common/commonComponents";

export const ADJOverview = () => {
  const loginType = decrypt(localStorage.getItem('loginType'));
  const isAdmin = decrypt(localStorage.getItem('isAdmin')) === 'true' ? true : false;
  const legacyReports: { [key: string]: string } = {
    "Sweep Reports": configuration.sweepReports,
    "Client History": configuration.clientHistory,
    "Agency Reports": configuration.agencyReports,
    "Claims": configuration.claims,
  };
  const directDeposit = () => {
    window.open(configuration.directDeposit, "_blank");
  };

  const viewAgencyBillStatements = () => {
    window.open(configuration.agency_bills, "_blank");
  };

  const viewManageContacts = () => {
    if (loginType === 'personID') {
      window.open(window.location.origin + '/agency-contacts', '_blank');
    } else if (loginType === 'employeeID') {
      localStorage.setItem('navigatedFrom', encrypt('/agency-contacts'));
      window.open(window.location.origin + '/search-agency-contact', '_blank');
    }
  };

  const viewContactListingReport = () => {
    if (loginType === 'personID') {
      window.open(window.location.origin + '/contact-listing', '_blank');
    } else if (loginType === 'employeeID') {
      localStorage.setItem('navigatedFrom', encrypt('/contact-listing'));
      window.open(window.location.origin + '/search-agency-contact', '_blank');
    }
  }

  const routeLegacyReports = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <>
      {(loginType === 'employeeID' || isAdmin) &&
        <>
          <HiddenBox height="20px" />
          <BlueButtonCard
            title="Agency Contacts"
            buttonLabel="Manage Contacts"
            handleClick={viewManageContacts}
            handleReportsClick={viewContactListingReport}
            text="You can self-service your agency! Use Manage Contacts to add and terminate contacts, update permissions and demographic information for people you manage."
          />
        </>
      }
      <HiddenBox height="20px" />
      <Columns>
        <Column>
          <ButtonCard
            title="Connect Agency Bill Statements"
            cardText="These statements show the balance due in the State Auto
                  Connect system only. Agency Bill statements issued in the State Auto Legacy
                  system will need to be paid separately."
            buttonLabel="VIEW AGENCY BILL STATEMENTS"
            handleClick={viewAgencyBillStatements}
          />
          <HiddenBox height="20px" />
          <ButtonCard
            title="Direct Deposit"
            cardText="Direct Deposit of Direct Bill Commissions Request Form"
            buttonLabel="VIEW DIRECT DEPOSIT FORM"
            handleClick={directDeposit}
          />
        </Column>
        <Column>
          <SubCards
            title="Legacy Reports (Agentsite)"
            legacyReports={legacyReports}
            routeLegacyReports={routeLegacyReports}
          />
        </Column>
      </Columns>
      <HiddenBox height="20px" />
      <Columns>
        <Column>
          <ContactCard
            title="Licensing and Appointment Questions?"
            emailId="Agency.Operations@StateAuto.com"
            phoneNumber="(800) 444-9950 ext.:5208"
          />
        </Column>
        <Column>
          <ContactCard
            title="Other Questions?"
            emailId="SAHelps@StateAuto.com"
            phoneNumber="(833) SAHelps or (833) 724-3577"
          />
        </Column>
      </Columns>
    </>
  );
};

import React, { useState } from "react";
import {
  SACard,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
  SAInput,
  SASpinner,
  SAPopover,
  SAUXTheme,
  SAUX360Theme
} from '@saux/design-system-react';
import { Offset, Position } from '../../../interfaces/interfaces';
import { useForm } from 'react-hook-form';
import styled from 'styled-components'
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import { PersonLookupLoginIdExistsQuery } from '../../../API';
import handleGraphQLErrors from "../../../utils/handleGraphQLErrors";
import { decrypt } from "../../../utils/crypto";
import { checkAuthStatus } from "../../../utils/utils";

const mobileWidth = 600;

type DuplicateLoginProps = {
  onClickCancel: Function;
  onClickConfirm: Function;
};

type StyleProps = {
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const DuplicateLogin = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 450px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 45px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  .closemodal {
    display: none;
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    .closemodal {
      display: flex;
    }
  }
`;

const ModalSubTitle = styled.div``;

const ModalBody = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      .textLikeInput {
        font-size: ${theme?.font.primary.size.medium};
      }
    `;
  }};
`;

const ModalFooter = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;
      
      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
  }};
`;

const SpinnerContainer = styled.div`
  margin-left: 10px;
  svg:last-child {
    margin-left: 0;
  }
`;

const DisabledConfirmButton = styled(SAButton)`
  ${({ theme }: StyleProps) => {
    return `
      color: ${theme?.colors.white};
      background-color: ${theme?.colors.green};

      &:hover {
        background-color: ${theme?.colors.green};
      }
    `;
  }};
`;

const Popover = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F5F7FA;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 32, 56, 0.22);
  border-radius: 3px;
  padding: 10px;
  width: 200px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: flex-start;
      padding-top: 10px;

      &:first-child {
        padding-top: 0;
      }

      svg {
        display: flex;
        flex-shrink: 0;
        margin-right: 5px;
        margin-top: 5px;
        height: 4px;
        width: 20px;

        &.alert {
          margin-top: 0px;
          height: 12px;
          width: 20px;
        }
      }

      span {
        display: flex;
      }
    }
  }
`;

const Red = styled.span`
  ${({ theme }: StyleProps) => {
    return `
      small {
        color: ${theme?.colors.red};
        font-weight: ${theme?.font.primary.weight.bold};
      }
    `;
  }};
`;

export default function ModalDuplicateLogin({ onClickCancel, onClickConfirm }: DuplicateLoginProps) {
  const { register, handleSubmit, setError, formState: { errors } } = useForm();
  const onSubmit = (data: any) => validateForm(data);
  // const newContact: NewContact = JSON.parse(decrypt(localStorage.getItem('NewContact')));
  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const desktopPosition: Position = 'right';
  const mobilePosition: Position = 'top-end';
  const desktopOffset: Offset = [0, 10];
  const mobileOffset: Offset = [0, 10];
  const [position, setPosition] = useState<Position>(
    document.documentElement.clientWidth <= mobileWidth ? mobilePosition : desktopPosition,
  );
  const [offset, setOffset] = useState<Offset>(
    window.innerWidth <= mobileWidth ? mobileOffset : desktopOffset,
  );

  // Spinner
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const spinner = (
    <SpinnerContainer>
      <SASpinner variant="circular-continuous" delay={0} color={SAUX360Theme.colors.blueGray600} size="20px" />
    </SpinnerContainer>
  );

  function openPopover(event: React.MouseEvent<HTMLElement>) {
    setReferenceElement(event.currentTarget);
  }

  function closePopover(event: React.MouseEvent<HTMLElement> | MouseEvent, index: number | null) {
    setReferenceElement(null);
  }

  async function validateForm(data: { loginId: string }) {
    setShowSpinner(false);

    const delay = setTimeout(() => {
      setShowSpinner(true);
    }, 1000);

    const isAuthenticated: boolean = await checkAuthStatus();
    if (!isAuthenticated) {
      return;
    }
    const promiseLoginIDValidation = API.graphql(
      graphqlOperation(
        queries.personLookupLoginIdExists,
        {
          login_id: data.loginId
        }
      ),
      {
        Authorization: `Bearer ${decrypt(localStorage.getItem('auth_accesstoken'))}`
      }
    ) as Promise<{ data: PersonLookupLoginIdExistsQuery }>;

    promiseLoginIDValidation.then((res) => {
      if (res.data) {
        return res.data.personLookupLoginIdExists;
      } else {
        handleGraphQLErrors(res);
      }
    })
    .then((res) => {
      if (res?.statusCode === 200) {
        return res.body as { login_id: string; exists: 'YES' | 'NO' } | null | undefined;
      } else {
        clearTimeout(delay);
        return null;
      }
    })
    .then((res) => {
      if (res) {
        clearTimeout(delay);
        if (res.exists === 'NO') {
          return res;
        } else if (res.exists === 'YES') {
          setError('loginId', { type: 'manual', message: 'Login ID already in use' });
          setShowSpinner(false);
          return null;
        } else {
          return null;
        }
      } else {
        clearTimeout(delay);
        return null;
      }
    })
    .then((res) => {
      if (res) {
        setShowSpinner(false);
        onClickConfirm(data.loginId);
      }
    })
    .catch((err) => {
      if (err?.message) {
        console.error(err.message, err); 
      } else {
        handleGraphQLErrors(err);
      }
      window.location.href = "error";
    });
  }

  function handleClickDuplicateLoginModal(event: React.MouseEvent) {
    event.stopPropagation();

    // get the card element inside the modal
    const cardElement = document.querySelector('#modal > div > article > section');

    // close modal when clicking outside of the card element
    if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
      onClickCancel(event);
    }
  }

  function handleClickDuplicateLoginModalCancel(event: React.MouseEvent) {
    event.stopPropagation();
    onClickCancel(event);
  }

  function checkForSpaces(value: string) {
    // value should not contain spaces
    if (value.includes(' ')) {
      return false;
    } else {
      return true;
    }
  }

  function checkForSALogin(value: string) {
    // Value must not match State Auto employ login ID format.
    // Value should not start with 3 alpha and end with 4 numeric characters
    // if the length is 7
    if (value.length === 7) {
      let startMatch: boolean = false;
      let endMatch: boolean = false;
      let regex: RegExp = /[A-Za-z]{3}/;

      if (value.slice(0, 3).match(regex)) {
        startMatch = true;
      }
      regex = /[0-9]{4}/;

      if (value.slice(3, 7).match(regex)) {
        endMatch = true;
      }
      
      if (startMatch && endMatch) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  function checkForMaxNumSpecialChars(value: string) {
    // value cannot contain more than 3 special characters:
    const specialChars: string = '~`!@#$%^&*()_-+={[}]|\\:;"\'<,>.?/';

    let specialCharOccurrences: number = 0;
    value.split('').forEach((char: string) => {
      if (specialChars.includes(char)) {
        specialCharOccurrences = specialCharOccurrences + 1;
      }
      if (specialCharOccurrences > 3) {
        return;
      }
    });

    if (specialCharOccurrences > 3) {
      return false;
    } else {
      return true;
    }
  }

  function getDot(size: string, color: string) {
    const renderSize = size;
    return (
      <svg
        width={renderSize}
        height={renderSize}
        viewBox="0 0 4 4"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <circle cx="2" cy="2" r="2" fill={color} />
      </svg>
    );
  }

  return (
    <>
      <Modal onClickHandler={handleClickDuplicateLoginModal}>
        <DuplicateLogin variant="minimal">
          <form id="duplicateloginform" onSubmit={handleSubmit(onSubmit)} autoComplete="none">
            <ModalHeader>
              <ModalTitle>
                <SAText type="heading-2" text="Login ID is already in use" />
                <SAIcon className="closemodal" icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" onClick={handleClickDuplicateLoginModalCancel} />
              </ModalTitle>
              <ModalSubTitle>
                <SAText type="standard" text="Please enter a new Login ID" />
              </ModalSubTitle>
            </ModalHeader>
            <ModalBody>
              <SAInput
                {...register('loginId', {
                  required: 'Login ID is required',
                  minLength: { value: 6, message: 'Login ID must be at least 6 characters' },
                  maxLength: { value: 50, message: 'Login ID cannot exceed 50 characters' },
                  validate: {
                    noWhiteSpaces: (value) => checkForSpaces(value) || 'Login ID cannot contain spaces',
                    noSALogins: (value) => checkForSALogin(value) || 'Login ID cannot begin with 3 alpha and end with 4 numerics',
                    maxNumSpecialChars: (value) => checkForMaxNumSpecialChars(value) || 'Login ID cannot contain more than 3 special characters',
                  },
                  // eslint-disable-next-line no-useless-escape
                  pattern: { value: /^[A-Za-z0-9`~!@#$%^&*()_\-+={[}\]|\\;:"'<,>.?\/ ]+$/, message: 'Login ID cannot contain invalid characters' },
                })}
                autoFocus
                hint={errors.loginId?.message as string | undefined}
                error={errors.loginId as boolean | undefined}
                id="loginid"
                name="loginId"
                label="Login ID"
                type="text"
                onBlur={(event: any) => setReferenceElement(null)}
                onClickIcon={openPopover}
                defaultValue={''}
                icon={SAIcons.information}
                iconSize="20px"
                iconColor="primary"
              />
            </ModalBody>
            <ModalFooter>
              <SAButton fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickDuplicateLoginModalCancel} variant="link-large" color="text" textTransform="uppercase" />
              {!showSpinner &&
                <SAButton fullWidthUnder={mobileWidth} label="Confirm" type="submit" variant="primary-large" textTransform="uppercase" />
              }
              {showSpinner &&
                <DisabledConfirmButton endIcon={spinner} disabled fullWidthUnder={mobileWidth} label="Confirm" type="submit" variant="primary-large" textTransform="uppercase" />
              }
            </ModalFooter>
          </form>
        </DuplicateLogin>
      </Modal>
      <SAPopover
        onClose={(e: MouseEvent, index: number | null) => closePopover(e, index)}
        offset={offset}
        placement={position}
        referenceElement={referenceElement}
        variant="popover-minimal-nopadding"
      >
        <Popover>
          <ul>
            <li>
              {errors.loginId?.type as any === 'minLength' &&
                <>
                  <SAIcon icon={SAIcons.alert} size={SAIconSize.small} colorVariant="alert" className="alert" />
                  <Red>
                    <SAText type="small" text="Minimum 6 characters" />
                  </Red>
                </>
              }
              {!(errors.loginId?.type as any === 'minLength') &&
                <>
                  {/* <SAIcon icon={SAIcons.checkmark} size={SAIconSize.small} colorVariant="secondary" className="checkmark" /> */}
                  {getDot('4px', SAUX360Theme.colors.text)}
                  <span>
                    <SAText type="small" text="Minimum 6 characters" />
                  </span>
                </>
              }
            </li>
            <li>
              {errors.loginId?.type as any === 'maxNumSpecialChars' &&
                <>
                  <SAIcon icon={SAIcons.alert} size={SAIconSize.small} colorVariant="alert" className="alert" />
                  <Red>
                    <SAText type="small" text="Maximum 3 special characters allowed" />
                  </Red>
                </>
              }
              {!(errors.loginId?.type as any === 'maxNumSpecialChars') &&
                <>
                  {/* <SAIcon icon={SAIcons.checkmark} size={SAIconSize.small} colorVariant="secondary" className="checkmark" /> */}
                  {getDot('4px', SAUX360Theme.colors.text)}
                  <span>
                    <SAText type="small" text="Maximum 3 special characters allowed" />
                  </span>
                </>
              }
            </li>
            <li>
              {errors.loginId?.type as any === 'maxLength' &&
                <>
                  <SAIcon icon={SAIcons.alert} size={SAIconSize.small} colorVariant="alert" className="alert" />
                  <Red>
                    <SAText type="small" text="Maximum 50 characters allowed" />
                  </Red>
                </>
              }
              {!(errors.loginId?.type as any === 'maxLength') &&
                <>
                  {/* <SAIcon icon={SAIcons.checkmark} size={SAIconSize.small} colorVariant="secondary" className="checkmark" /> */}
                  {getDot('4px', SAUX360Theme.colors.text)}
                  <span>
                    <SAText type="small" text="Maximum 50 characters allowed" />
                  </span>
                </>
              }
            </li>
            <li>
              {errors.loginId?.type as any === 'noWhiteSpaces' &&
                <>
                  <SAIcon icon={SAIcons.alert} size={SAIconSize.small} colorVariant="alert" className="alert" />
                  <Red>
                    <SAText type="small" text="Cannot contain spaces" />
                  </Red>
                </>
              }
              {!(errors.loginId?.type as any === 'noWhiteSpaces') &&
                <>
                  {/* <SAIcon icon={SAIcons.checkmark} size={SAIconSize.small} colorVariant="secondary" className="checkmark" /> */}
                  {getDot('4px', SAUX360Theme.colors.text)}
                  <span>
                    <SAText type="small" text="Cannot contain spaces" />
                  </span>
                </>
              }
            </li>
            <li>
              {errors.loginId?.type as any === 'noSALogins' &&
                <>
                  <SAIcon icon={SAIcons.alert} size={SAIconSize.small} colorVariant="alert" className="alert" />
                  <Red>
                    <SAText type="small" text="Cannot begin with 3 alpha and end with 4 numerics" />
                  </Red>
                </>
              }
              {!(errors.loginId?.type as any === 'noSALogins') &&
                <>
                  {/* <SAIcon icon={SAIcons.checkmark} size={SAIconSize.small} colorVariant="secondary" className="checkmark" /> */}
                  {getDot('4px', SAUX360Theme.colors.text)}
                  <span>
                    <SAText type="small" text="Cannot begin with 3 alpha and end with 4 numerics" />
                  </span>
                </>
              }
            </li>
            <li>
              {errors.loginId?.type as any === 'pattern' &&
                <>
                  <SAIcon icon={SAIcons.alert} size={SAIconSize.small} colorVariant="alert" className="alert" />
                  <Red>
                    <SAText type="small" text="Cannot contain invalid characters" />
                  </Red>
                </>
              }
              {!(errors.loginId?.type as any === 'pattern') &&
                <>
                  {/* <SAIcon icon={SAIcons.checkmark} size={SAIconSize.small} colorVariant="secondary" className="checkmark" /> */}
                  {getDot('4px', SAUX360Theme.colors.text)}
                  <span>
                    <SAText type="small" text="Cannot contain invalid characters" />
                  </span>
                </>
              }
            </li>
          </ul>
        </Popover>
      </SAPopover>
    </>
  );
}

export default function addressCompare(obj1: any, obj2: any) {
    const objProps1 = ["address_line1","address_line2","city","state","state_cd","zip"];
    const objProps2 = ["address_line1","address_line2","city","state","state_cd","zip"];
    const keysWithDiffValue = objProps1.reduce((keysWithDiffValueAccumulator, key) => {
        const keyValuePairBetweenBothObjectsIsEqual = obj1[key] === obj2[key];
        if (keyValuePairBetweenBothObjectsIsEqual) {
            const equalValueKeyIndex = keysWithDiffValueAccumulator.indexOf(key);
            keysWithDiffValueAccumulator.splice(equalValueKeyIndex, 1);
        }
        return keysWithDiffValueAccumulator;
    }, objProps2);

    return keysWithDiffValue;
}

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const checkPersonActiveNucleus = /* GraphQL */ `
  query CheckPersonActiveNucleus($person_id: String) {
    CheckPersonActiveNucleus(person_id: $person_id) {
      body {
        is_active
      }
      statusCode
    }
  }
`;
export const enableDisableSelfService = /* GraphQL */ `
  query EnableDisableSelfService {
    EnableDisableSelfService {
      body {
        disable_portal
        message
        show_banner
      }
      statusCode
    }
  }
`;
export const postPersonRequested = /* GraphQL */ `
  query PostPersonRequested(
    $admin_name: String
    $admin_person_id: String
    $person_id: String
  ) {
    PostPersonRequested(
      admin_name: $admin_name
      admin_person_id: $admin_person_id
      person_id: $person_id
    ) {
      body {
        updated
      }
      statusCode
    }
  }
`;
export const adminPersonDetails = /* GraphQL */ `
  query AdminPersonDetails($admin_person_id: String!, $person_id: String!) {
    adminPersonDetails(
      admin_person_id: $admin_person_id
      person_id: $person_id
    ) {
      body {
        agency_code_map {
          address_line1
          address_line2
          agency_code
          agency_code_name
          agency_id
          city
          contact_type
          contact_type_id
          is_agency_admin_authorized
          is_agency_admin_authorized_to_update
          is_prim_producer_nbr
          national_number
          state
          state_code
          zip
        }
        business_address {
          address_line1
          address_line2
          city
          county
          state
          state_cd
          zip
        }
        general_details {
          email_address
          first_name
          last_name
          primary_agency_code
          primary_phone
        }
        is_cross_hierarchy_exists
        last_updated_by
        last_updated_ts
        mailing_address {
          address_line1
          address_line2
          city
          county
          state
          state_cd
          zip
        }
        pending_status
        person_id
        privileges
        residential_address {
          address_line1
          address_line2
          city
          county
          state
          state_cd
          zip
        }
        status
        user_id
      }
      statusCode
    }
  }
`;
export const adminPersonDetailsIsTerminable = /* GraphQL */ `
  query AdminPersonDetailsIsTerminable(
    $admin_person_id: String!
    $person_id: String!
  ) {
    adminPersonDetailsIsTerminable(
      admin_person_id: $admin_person_id
      person_id: $person_id
    ) {
      body {
        reason
        required_contact_types {
          agency_codes
          contact_type_id
        }
        status
      }
      statusCode
    }
  }
`;
export const agencyLandingPage = /* GraphQL */ `
  query AgencyLandingPage($admin_person_id: String!, $agency_id: String!) {
    agencyLandingPage(
      admin_person_id: $admin_person_id
      agency_id: $agency_id
    ) {
      body {
        location_id
        persons {
          contact_type
          gain_contact_id
          is_active
          login_id
          pending_status
          person_id
          prdcr_first_name
          prdcr_last_name
          preferred_email
          primary_phone_number
        }
      }
      statusCode
    }
  }
`;
export const datafluxAddress = /* GraphQL */ `
  query DatafluxAddress($body: datafluxAddressbody) {
    datafluxAddress(body: $body) {
      body {
        AddressInformation {
          ErrorDescription
          FaultCode
          FaultCodeDescription
          GeoResultCd
          GeoResultDesc
          GeocodeFIPS
          Latitude
          Longitude
          PassedAddress {
            Address_Line1
            Address_Line2
            City
            EffectiveDate
            State
            ZipCode
          }
          ReturnedScrubbedAddress {
            County
            CountyFIPS
            ScrubbedAddress
            ScrubbedCity
            ScrubbedState
            ScrubbedZipCode
            ScrubbedZipCodePlus4
          }
          StatCode
        }
      }
      statusCode
    }
  }
`;
export const datafluxEmailAddress = /* GraphQL */ `
  query DatafluxEmailAddress($body: datafluxEmailAddressbody) {
    datafluxEmailAddress(body: $body) {
      body {
        result
      }
      statusCode
    }
  }
`;
export const getAgencyCodeAddress = /* GraphQL */ `
  query GetAgencyCodeAddress($agency_code: String!) {
    getAgencyCodeAddress(agency_code: $agency_code) {
      body {
        agency_code_addresses {
          addressline1
          addressline2
          addressline3
          agency_code
          agency_code_name
          agency_id
          agency_id_name
          alternate_email_address
          alternate_extension_number
          alternate_phone
          city_name
          country
          county_name
          dba_name
          email_address
          fax
          mobile
          nipr_name
          phone
          phone_extension_number
          po_box
          state_code
          state_name
          zip_code
        }
      }
      statusCode
    }
  }
`;
export const getBulkConfirmation = /* GraphQL */ `
  query GetBulkConfirmation(
    $admin_person_id: String
    $is_agencyops_internal_user: String!
    $person_ids: [String]
    $user_id: String!
  ) {
    getBulkConfirmation(
      admin_person_id: $admin_person_id
      is_agencyops_internal_user: $is_agencyops_internal_user
      person_ids: $person_ids
      user_id: $user_id
    ) {
      body
      statusCode
    }
  }
`;
export const getBulkSelect = /* GraphQL */ `
  query GetBulkSelect($admin_person_id: String!) {
    getBulkSelect(admin_person_id: $admin_person_id) {
      body {
        agency_location_persons {
          location_id
          persons {
            contact_type
            gain_contact_id
            is_active
            login_id
            pending_status
            person_id
            prdcr_first_name
            prdcr_last_name
            preferred_email
            primary_phone_number
          }
        }
        error
        message
        status
      }
      statusCode
    }
  }
`;
export const getBulkValidate = /* GraphQL */ `
  query GetBulkValidate(
    $admin_person_id: String
    $person_ids: [bulkValidationBody]
  ) {
    getBulkValidate(
      admin_person_id: $admin_person_id
      person_ids: $person_ids
    ) {
      body {
        is_nonterminable {
          person_id
          reason
          required_contact_types {
            agency_codes
            contact_type_id
          }
          status
          statusCode
        }
      }
      statusCode
    }
  }
`;
export const getLoginID = /* GraphQL */ `
  query GetLoginID($login_id: String!) {
    getLoginID(login_id: $login_id) {
      body {
        persons {
          person_contact_types
          person_email
          person_first_name
          person_id
          person_last_name
          person_login_id
          person_middle_name
          person_status
        }
      }
      statusCode
    }
  }
`;
export const getLoginIdExists = /* GraphQL */ `
  query GetLoginIdExists($login_id: String!) {
    getLoginIdExists(login_id: $login_id) {
      body {
        exists
        login_id
      }
      statusCode
    }
  }
`;
export const getNucleusUserExist = /* GraphQL */ `
  query GetNucleusUserExist($user_id: String) {
    getNucleusUserExist(user_id: $user_id) {
      body {
        is_nucleus_user
      }
      statusCode
    }
  }
`;
export const getPersonCommuncationPref = /* GraphQL */ `
  query GetPersonCommuncationPref($person_id: String!) {
    getPersonCommuncationPref(person_id: $person_id) {
      body {
        error
        message
        status
        subscription_groups {
          description
          group_id
          lobs {
            lob_code
            lob_description
            states {
              state
              state_code
            }
            subscriptions
          }
        }
      }
      statusCode
    }
  }
`;
export const getPersonDetails = /* GraphQL */ `
  query GetPersonDetails($person_id: String!) {
    getPersonDetails(person_id: $person_id) {
      body {
        addresses {
          address_line_1
          address_line_2
          address_type_desc
          cit
          country
          county_name
          extn
          fax
          mobile_number
          phone
          state
          state_code
          zip
        }
        agency_code_map {
          agency_code
          agency_code_name
          agency_id
          contact_type
          is_primary_agency_code
        }
        agency_id
        error
        gain_contact_id
        is_active
        locations {
          agency_id
          agency_location_name
          is_active
          is_primary
        }
        login_id
        message
        nipr_prdcr_first_name
        nipr_prdcr_last_name
        nipr_prdcr_mid_name
        nipr_prdcr_sffx
        npn
        person_id
        person_mobile_number
        person_primary_phone_number
        prdcr_cde
        prdcr_first_name
        prdcr_last_name
        prdcr_maiden_name
        prdcr_mid_name
        prdcr_name_prfx
        prdcr_sffx
        preferred_email
        primary_agency_code
        primary_agency_name
        primary_agency_phone
        primary_agency_state
        primary_agency_state_code
        primary_phone_number
        secondary_email
        status
        user_privilege
      }
      statusCode
    }
  }
`;
export const getPersonRequested = /* GraphQL */ `
  query GetPersonRequested(
    $admin_name: String
    $admin_person_id: String
    $person_id: String
  ) {
    getPersonRequested(
      admin_name: $admin_name
      admin_person_id: $admin_person_id
      person_id: $person_id
    ) {
      body {
        admin_name
        created
        is_requested
      }
      statusCode
    }
  }
`;
export const getRosterReportDetails = /* GraphQL */ `
  query GetRosterReportDetails(
    $agency_codes: [String]
    $email_address: String
    $is_active: String
    $is_actual: String
    $user_id: String
  ) {
    getRosterReportDetails(
      agency_codes: $agency_codes
      email_address: $email_address
      is_active: $is_active
      is_actual: $is_actual
      user_id: $user_id
    ) {
      body
      statusCode
    }
  }
`;
export const getagencyadminAgencyLocations = /* GraphQL */ `
  query GetagencyadminAgencyLocations($admin_person_id: String!) {
    getagencyadminAgencyLocations(admin_person_id: $admin_person_id) {
      body {
        agency_admin_person_id
        agency_locations {
          agency_addressline
          agency_city
          agency_country
          agency_id
          agency_name
          agency_state
          agency_zip
        }
        error
        message
        status
      }
      statusCode
    }
  }
`;
export const isEditable = /* GraphQL */ `
  query IsEditable($admin_person_id: String, $person_id: String) {
    isEditable(admin_person_id: $admin_person_id, person_id: $person_id) {
      body {
        admin_person_id
        is_editable
        person_id
      }
      statusCode
    }
  }
`;
export const isremovable = /* GraphQL */ `
  query Isremovable(
    $admin_person_id: String
    $contact_type: String
    $person_id: String
  ) {
    isremovable(
      admin_person_id: $admin_person_id
      contact_type: $contact_type
      person_id: $person_id
    ) {
      body {
        agency_codes
        contact_type
        reason
        status
      }
      statusCode
    }
  }
`;
export const landingPageAgencyCode = /* GraphQL */ `
  query LandingPageAgencyCode($admin_person_id: String!) {
    landingPageAgencyCode(admin_person_id: $admin_person_id) {
      body {
        agency_admin_person_id
        agency_codes {
          agency_city
          agency_code
          agency_country
          agency_id
          agency_name
          agency_state
          agency_zip
        }
      }
      statusCode
    }
  }
`;
export const personLookupLoginIdDetails = /* GraphQL */ `
  query PersonLookupLoginIdDetails($login_id: String!) {
    personLookupLoginIdDetails(login_id: $login_id) {
      body {
        persons {
          person_contact_types
          person_email
          person_first_name
          person_id
          person_last_name
          person_login_id
          person_middle_name
          person_status
        }
      }
      statusCode
    }
  }
`;
export const personLookupLoginIdExists = /* GraphQL */ `
  query PersonLookupLoginIdExists($login_id: String!) {
    personLookupLoginIdExists(login_id: $login_id) {
      body {
        exists
        login_id
      }
      statusCode
    }
  }
`;

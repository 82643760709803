type GraphQLErrors = {
  data?: any;
  errors: GraphQLError[];
};

type GraphQLError = {
  message: string;
  locations?: any[];
  path?: any;
};

export default function handleGraphQLErrors(graphQLReponse: any) {
  const errorResponse = graphQLReponse as GraphQLErrors;
  if (errorResponse.errors) {
    errorResponse.errors.forEach((error: GraphQLError) => {
      if (error.message === 'Request failed with status code 401') {
        window.location.reload();
      } else {
        console.error(error.message);
        window.location.href = "error";
      }      
    });
  }
}
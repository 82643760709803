const ContactData = {
    "contacttype": [
        {
            "contacttype": 3,
            "description": "Agency Admin",
            "checked": false,
            "definition": "Individual(s) designated to maintain agency information including the people associated with the agency. (formerly WebAdmin)"
        },
        {
            "contacttype": 6,
            "description": "Principal",
            "checked": false,
            "definition": "An owner, President, Vice President, LLC Manager/Member or any other officer of the agency. A Principal may not be added or removed through Agency Self-Service. The Principal is responsible for all aspects of agency function and holds signatory authority. They will also receive all legal and compliance documentation, such as agency contracts."
        },
        {
            "contacttype": 7,
            "description": "Agency Staff",
            "checked": false,
            "definition": "All other employed personnel within the agency who may be involved with the functions of selling and servicing insurance, including, but not limited to preparing quotes, policy changes, binding business on behalf of a Licensed Producer, follow up phone calls, marketing, or other administrative duties."
        },
        {
            "contacttype": 10,
            "description": "Agency Manager",
            "checked": false,
            "definition": "Individual, other than the agency Principal, who is responsible for the agency's daily operations. (ex. Office Manager)"
        },
        {
            "contacttype": 13,
            "description": "Primary Claim Contact",
            "checked": false,
            "definition": "The person or group designated as the main point of contact for all claims related issues. "
        },
        {
            "contacttype": 14,
            "description": "Primary Mail Recipient",
            "checked": false,
            "definition": "The email address of the agency/group/individual designated to receive all electronic mail for the agency. Additionally, a Principal may receive hard copy mailings for legal or compliance reasons."
        },
        // {
        //     "contacttype": 18,
        //     "description": "Stock Recipient",
        //     "checked": false
        // },
        {
            "contacttype": 36,
            "description": "Accounting Manager",
            "checked": false,
            "definition": "The agency contact responsible for accounting functions of the organization such as reconciling commissions and payment of agency billed policies and who will manage bank account information with State Auto. The Accounting Manager is the primary contact with State Auto for any billing or banking concerns."
        },
        {
            "contacttype": 37,
            "description": "Licensed Producer",
            "checked": false,
            "definition": "A licensed insurance producer or agent who sells insurance coverage. All agents must be licensed by the state in which they are doing business as well as the line of business (ex. Personal Lines, Commercial Lines, Farm Insurance). Agent(s) of Record (AOR) on a policy must be a Licensed Producer and appointed/authorized with State Auto."
        },
        // {
        //     "contacttype": 41,
        //     "description": "Company Employee",
        //     "checked": false
        // },
        // {
        //     "contacttype": 43,
        //     "description": "KS Affiliated Agent",
        //     "checked": false
        // },
        // {
        //     "contacttype": 45,
        //     "description": "Sponsored Company Employee",
        //     "checked": false
        // },
        // {
        //     "contacttype": 46,
        //     "description": "SubProducer",
        //     "checked": false
        // }
    ]
}

export default ContactData;
